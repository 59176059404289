import { faQrcode } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  profileCode: string;
  spanClassName?: string;
}

export function HiperPuntosProfileButton({
  profileCode,
  className,
  spanClassName,
  ...props
}: Props) {
  return (
    <button
      {...props}
      className={classNames("flex gap-1 items-center group", className)}
    >
      <FontAwesomeIcon icon={faQrcode} />
      <span className={classNames("group-hover:underline", spanClassName)}>
        Cliente: {profileCode}
      </span>
    </button>
  );
}
