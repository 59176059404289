import { Children, FunctionComponent, ReactNode, useState } from "react";
import { TabProps } from "./tab";
import classNames from "classnames";

interface TabsProps {
  children: ReactNode;
}

export const Tabs: FunctionComponent<TabsProps> = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = Children.toArray(children) as React.ReactElement<TabProps>[];

  return (
    <div
      className={classNames(
        "w-full flex flex-col h-full p-2",
        tabs[activeTab].props.wrapClassName
      )}
    >
      <div
        className={classNames(
          "flex",
          tabs[activeTab].props.buttonWrapperClassName
        )}
      >
        {tabs.map((tab, index) => (
          <button
            key={`button-tab-${index}`}
            className={`py-2 px-4 text-sm focus:outline-none font-bold rounded-t-lg ${
              activeTab === index
                ? tabs[activeTab].props.activeButtonClassName
                : tabs[activeTab].props.buttonClassName
            }`}
            onClick={() => setActiveTab(index)}
          >
            {tab.props.title}
          </button>
        ))}
      </div>
      {tabs.map((tab, index) => (
        <div
          key={`tab-${index}`}
          className={classNames("w-full  h-full", {
            hidden: index !== activeTab,
            "flex flex-col": index === activeTab,
          })}
        >
          {tab}
        </div>
      ))}
    </div>
  );
};
