export const TIPO_ACCESO_REDES_SOCIALES_NAME = {
  1: "Facebook",
  2: "Google",
};

export const TIPO_ACCESO_REDES_SOCIALES_VALUES = {
  FACEBOOK: 1,
  GOOGLE: 2,
};

export const SOCIAL_LOGIN_LOCALSTORAGE_KEY = {
    TOKEN: "redId",
    TIPO_ACCESO: "tipoAccesoId"
}
