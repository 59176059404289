import { buildProductJSONLd } from "./buildProductJSONLd";

export const ITEM_LIST_ORDER = {
  ITEM_LIST_ORDER_ASCENDING: "http://schema.org/ItemListOrderAscending",
  ITEM_LIST_ORDER_DESCENDING: "http://schema.org/ItemListOrderDescending",
  ITEM_LIST_UNORDERED: "http://schema.org/ItemListUnordered",
};

export const buildItemListListItemJSONLd = (
  { position, item: { name, image, url, sku, offers } },
  context
) => {
  return {
    "@context": context,
    "@type": "ListItem",
    position,
    item: buildProductJSONLd({
      name,
      image,
      url,
      sku,
      offers,
    }),
  };
};
export const buildItemListJSONLd = (
  { name, itemListOrder, itemListElement },
  context
) => {
  return {
    "@context": context,
    "@type": "ItemList",
    name,
    itemListOrder,
    itemListElement: itemListElement.map((element) =>
      buildItemListListItemJSONLd(element)
    ),
  };
};
