import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/pro-solid-svg-icons";
import { connect } from "react-redux";
import "../../assets/styles/responsive.scss";
import { get_lista_compra } from "../../redux/actions/shoppingList";
import Alert from "../Swalert";
import ButtonText from "../../widgets/button_text";
import RegistroInit from "../../components/modal/registroUser/registro_init";
import { colorEnUmbralDeBlanco } from "../Utils/utils-colores";
import classNames from "classnames";
import Modal from "react-modal";
import ModalAddListaCompra from "../../containers/ListaCompra/Partials/ModalAddListaCompra";

const customStylesFondo = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "1",
  },
  content: {
    borderRadius: "10px",
    padding: "0px",
    border: "0px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "calc(100% - 32px)",
    overflow: "hidden",
  },
};

const PurchaseList = ({
  get_lista_compra,
  shopping_list,
  peticion_shopping_list,
  cambio_sucursal,
  sucursal,
  profile,
}) => {
  const navigate = useNavigate();
  const { ciudadp, sucursalp } = useParams();
  const [openModalUserRegister, setOpenModalUserRegister] = useState(false);

  const [modalAddListPurchase, setAddListPurchase] = React.useState(false);
  const startAddListPurchase = () => setAddListPurchase(true);
  const endAddListPurchase = () => setAddListPurchase(false);

  useEffect(() => {
    if (
      shopping_list.length === 0 &&
      sucursal &&
      !peticion_shopping_list &&
      !!profile?.Telefono
    ) {
      get_lista_compra(sucursal.IdSucursal);
    } else if (cambio_sucursal) {
      get_lista_compra(sucursal.IdSucursal);
    }
  }, [
    shopping_list,
    cambio_sucursal,
    sucursal,
    peticion_shopping_list,
    profile,
    get_lista_compra,
  ]);

  const verifyList = () => {
    return shopping_list.length === 0 ? false : true;
  };

  const verdetalle = (id) => {
    navigate_menu(`detalle-lista-compra/${id}`);
  };
  const verifyUserAutenticate = () => {
    if (profile.Telefono !== "") {
      return true;
    }
    return false;
  };
  const new_lista = () => {
    if (verifyUserAutenticate()) {
      startAddListPurchase();
    } else {
      setOpenModalUserRegister(true);
    }
  };
  async function navigate_menu(url_menu) {
    if (verifyUserAutenticate()) {
      let param = await get_url();
      navigate(param + "/" + url_menu);
    } else {
      setOpenModalUserRegister(true);
    }
  }

  const get_url = async () => {
    let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
    if (ciudadp && sucursalp) {
      return "/" + ciudadp + "/" + sucursalp;
    } else if (url_nav) {
      return "/" + url_nav.url_parameters;
    }
  };

  const cerrarVentana = (valor) => {
    setOpenModalUserRegister(false);
  };
  return (
    <>
      <Alert></Alert>
      <div className="mb-4">
        <div className="flex  mb-2 justify-start">
          <a
            href={`/${ciudadp}/${sucursalp}/lista-compras`}
            className="text-hiperblue text-xl hover:underline"
            onClick={(e) => {
              e.preventDefault();
              navigate_menu(`lista-compras`);
            }}
          >
            Mis listas de compras
          </a>
        </div>

        <div className="flex flex-col md:grid md:grid-cols-4 lg:grid-cols-6 w-full gap-[.70rem] mb-4">
          {verifyList() &&
            shopping_list.map((item_shopping_list) => {
              const colorEsEnUmbralDeBlanco = colorEnUmbralDeBlanco(
                item_shopping_list.Color,
                180
              );
              return (
                <Link
                  className={classNames(
                    "flex gap-2 items-center px-2 py-1 rounded-lg  border-2",
                    {
                      "text-white": !colorEsEnUmbralDeBlanco,
                      "text-black": colorEsEnUmbralDeBlanco,
                    }
                  )}
                  style={{
                    backgroundColor: item_shopping_list.Color,
                    borderColor: colorEsEnUmbralDeBlanco
                      ? "#000000"
                      : item_shopping_list.Color,
                  }}
                  key={item_shopping_list.Id}
                  to={`detalle-lista-compra/${item_shopping_list.Id}`}
                >
                  <FontAwesomeIcon icon={faCartShopping} />
                  <span className="truncate">
                    {item_shopping_list.Descripcion}
                  </span>
                </Link>
              );
            })}
        </div>

        <div className="w-100">
          <ButtonText
            label="Nueva lista"
            principal={false}
            className="bg-hiperorange text-white width_responsive hover:bg-hiperorange_hover"
            onClick={() => new_lista()}
          />
        </div>
      </div>
      {openModalUserRegister && (
        <RegistroInit setCloseModal={cerrarVentana}></RegistroInit>
      )}
      <Modal
        isOpen={modalAddListPurchase}
        onRequestClose={endAddListPurchase}
        ariaHideApp={false}
        className="fixed inset-0 flex items-center justify-center p-2 md:p-4 w-full md:max-w-sm  md:mx-auto focus-visible:outline-none"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-30"
        contentLabel="shopping"
      >
        {/* container content */}
        <ModalAddListaCompra
          ps_closeModalAdd={endAddListPurchase}
          IdSucursal={sucursal.IdSucursal}
        ></ModalAddListaCompra>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  peticion_shopping_list: state.Shopping_list.peticion_shopping_list,
  shopping_list: state.Shopping_list.shopping_list,
  sucursal: state.BranchOffice.sucursal,
  cambio_sucursal: state.BranchOffice.cambio_sucursal,
  profile: state.Profile.profile,
});

export default connect(mapStateToProps, {
  get_lista_compra,
})(PurchaseList);
