import { useState } from "react";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { NotificationService } from "../../../notifications/domain/notification-service";
import { FormStatus } from "../../../shared/domain/enums/form-status";
import { useHandleErrors } from "../../../shared/ui/hooks/use-handle-errors";
import { getSucursalContactosBySucursalId } from "../../application/get/get-sucursal-contacto-by-sucursal-id";
import { SucursalContactosGetByIdSucursalParameters } from "../../domain/dto/sucursal-contactos-get-by-id-sucursal-parameters";
import { SucursalContacto } from "../../domain/interface/sucursal-contacto";
import { SucursalContactosRepository } from "../../domain/sucursal-contactos-repository";
import { setSucursalContacto as setSucursalContactoAction } from "../redux/actions/sucursal-contactos-action";

export const useGetSucursalContactoBySucursalId = (
  sucursalContactosRepository: SucursalContactosRepository,
  notificationService: NotificationService
): {
  formStatus: FormStatus;
  getSucursalContactoBySucursalId: (
    params: SucursalContactosGetByIdSucursalParameters
  ) => void;
  resetFormStatus: () => void;
  sucursalContacto: SucursalContacto | undefined;
  resetSucursalContactos: () => void;
  isLoading: boolean;
} => {
  const dispatch: Dispatch<any> = useDispatch();
  const [formStatus, setFormStatus] = useState(FormStatus.INITIAL);
  const [sucursalContacto, setSucursalContacto] = useState<
    SucursalContacto | undefined
  >(undefined);

  const { handleError } = useHandleErrors(notificationService);

  async function getSucursalContactoBySucursalId(
    params: SucursalContactosGetByIdSucursalParameters
  ) {
    setFormStatus(FormStatus.LOADING);

    try {
      const sucursalContacto: SucursalContacto =
        await getSucursalContactosBySucursalId(sucursalContactosRepository)(
          params
        );
        
      dispatch(setSucursalContactoAction(sucursalContacto));
      setSucursalContacto(sucursalContacto);
      setFormStatus(FormStatus.SUCCESS);
      //   notifySuccess(notificationService)("Clientes obtenidos correctamente.");
    } catch (error: unknown) {
      const message = "";
      handleError({ error, message });
      setFormStatus(FormStatus.ERROR);
    }
  }

  const resetFormStatus = () => setFormStatus(FormStatus.INITIAL);

  const resetSucursalContactos = () => setSucursalContacto(undefined);

  return {
    formStatus,
    getSucursalContactoBySucursalId,
    resetFormStatus,
    sucursalContacto,
    resetSucursalContactos,
    isLoading: formStatus === FormStatus.LOADING,
  };
};
