import { faTimesCircle, faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { compra_rapida } from "../../../src/redux/actions/shoppingList";
import "../../assets/styles/responsive.scss";
import "../../assets/styles/seccionResponsive.scss";
import { CarritoResumen } from "../../components/modal/carrito/carrito_resumen";
import {
  eliminar_cliente_nit,
  get_list_metodos_pagos,
  validar_nit,
} from "../../redux/actions/metodoPagos";
import { setSwAlert } from "../../redux/actions/swalert";
import ButtonClose from "../../widgets/button_close";
import ItemOpcionPago from "../../widgets/item_opcion_pago";
import NuevaTarjeta from "../MisTarjetas/NuevaTarjeta";
import Tarjeta from "./../../components/modal/carrito/tarjeta";
import Page_message from "./../../components/Page_message";
import { huellaDigital } from "./../../huellaDigital";
import RegistroVale from "../../components/modal/carrito/Partials/RegistroVale";
import TerminoCondicion from "../../components/modal/carrito/Partials/termino_condicion";
import { faFileInvoice } from "@fortawesome/pro-regular-svg-icons";
import LoaderSpinner from "../../widgets/loaderSpinner";
import { useThousandSeparator } from "../../hooks/useThousandSeparator";

const customStyles2 = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "35",
  },
  content: {
    padding: "0px",
    border: "0px",
    background: "transparent",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "calc(100% - 64px)",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
};

const style_vale = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "31",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: "0",
    borderRadius: "10px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "auto",
    border: "0px",
    background: "transparent",
  },
};

const addCardModalStyles = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "32",
  },
  content: {
    padding: "0px",
    border: "0px",
    background: "transparent",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "calc(100% - 16px)",
    overflow: "hidden",
  },
};

const FormaPago = ({
  profile,
  data,
  finalizado,
  mt_indice,
  get_list_metodos_pagos,
  metodopagos,
  sucursal,
  compra_rapida,
  eliminar_cliente_nit,
  importeTotal,
  importeAhorrodo,
  width,
  height,
  exit,
  validar_nit,
}) => {
  const { numberFormatter } = useThousandSeparator();
  const [tipoDocumento, setTipoDocumento] = useState("");
  const [pago_selec, setPagoSelec] = useState(1);
  const [facturaciones, setFacturacion] = useState(null);
  const [facturacionPedido, setFacturacionPedido] = useState(null);

  const [comentario, setComentario] = useState("");
  const [nit, setNit] = useState("");
  const [razonsocial, setRazonsocial] = useState("");
  const [complemento, setComplemento] = useState("");
  const [tarjeta_online, setTarjetaOnline] = useState(false);
  const [tarjeta_selec, setTarjetaSelec] = useState();
  const [sinDatosFacturacion, setSinDatosFacturacion] = useState(false);
  const [vale, setVale] = useState("");
  const [listaVales, setListaVales] = useState([]);
  const [modalVale, setModalVale] = useState(false);
  const [openModalTerminosCondiciones, setOpenModalTerminosCondiciones] =
    useState(false);

  const formatNumber = (value) => {
    return numberFormatter.format(value);
  };

  const handleCloseModalTerminosCondiciones = () => {
    setOpenModalTerminosCondiciones(false);
  };

  const startModalVale = () => {
    setModalVale(true);
  };
  const endModalVale = () => {
    setModalVale(false);
  };

  const updateListaVales = (arrayVales) => {
    setListaVales(arrayVales);
  };

  const [openAddCardModal, setOpenAddCardModal] = useState(false);

  const handleOpenAddCardModal = () => {
    setOpenAddCardModal(true);
  };

  const handleCloseAddCardModal = () => {
    setOpenAddCardModal(false);
  };

  const [formData, setFormData] = useState({
    pago_id: "",
    nit: "",
    razonsocial: "",
  });
  useEffect(() => {
    if (metodopagos) {
      setFacturacion(metodopagos.Facturaciones);
      setFacturacionPedido(metodopagos.FacturacionPedido);
    }
  }, [metodopagos]);
  useEffect(() => {
    if (facturacionPedido) {
      setNit(facturacionPedido.Nit);
      setRazonsocial(facturacionPedido.RazonSocial);
      setTipoDocumento(facturacionPedido.TipoDocumento.toString());
      setComplemento(facturacionPedido.TipoDocumento == 1 ? facturacionPedido.Complemento : "");
    } else {
      if (facturaciones?.length > 0) {
        let data = facturaciones[facturaciones.length - 1];
        setNit(data.Nit);
        setRazonsocial(data.RazonSocial);
        setTipoDocumento(data.TipoDocumento.toString());
        setComplemento(data.TipoDocumento == 1 ? data.Complemento : "");
      }
    }
  }, [facturaciones]);
  useEffect(() => {
    get_list_metodos_pagos();
  }, []);

  function existe_metodos_pagos() {
    return metodopagos != null;
  }

  const seleccionar_opcion = (facturacion, e) => {
    setNit(facturacion.Nit);
    setRazonsocial(facturacion.RazonSocial);
    setTipoDocumento(facturacion.TipoDocumento.toString());
    showModal_facturaciones();
  };
  const showModal_facturaciones = (e) => {
    document.getElementById("modalFacturacion").classList.toggle("hidden");
  };
  const showModal = (e) => {
    document.getElementById("modal").classList.toggle("hidden");
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  function cambiar_seleccion(id) {
    setPagoSelec(id);
    id == 12 ? setTarjetaOnline(true) : setTarjetaOnline(false);
  }

  const agregar_vale = (e) => {
    if (vale !== "") {
      const aux = listaVales.slice();
      aux.push(vale);
      setListaVales(aux);
    }
    setVale("");

    showModal();
  };

  const get_formato_vales = (lista_vales) => {
    var aux = [];
    for (var item of lista_vales) {
      aux.push({ Codigo: "", Monto: item });
    }
    return aux;
  };
  useEffect(() => {
    if (tipoDocumento != 1) {
      setComplemento("");
    }
  }, [tipoDocumento]);

  const onSubmit = async () => {
    if (!profile.TerminoCondicion.AceptoTerminoActual) {
      setOpenModalTerminosCondiciones(true);
      return;
    }
    if (tipoDocumento == 5 && !sinDatosFacturacion && !!nit) {
      const nitValido = await validar_nit(nit);
      if (!nitValido) return;
    }
    var metodo_pago = {
      IdMetodoPago: pago_selec,
      Nit: nit,
      RazonSocial: razonsocial,
      tipoDocumento: tipoDocumento,
      complemento:
        tipoDocumento == 1
          ? complemento
            ? complemento.substring(0, 2)
            : ""
          : "",
      IdTarjeta: tarjeta_selec,
    };

    if (pago_selec === 12) {
      if (!tarjeta_selec) {
        mensaje_alert("Debe Seleccionar un tarjeta");
        return;
      }
    }
    var res = await huellaDigital(profile.IdUsuario);

    var aux_vales = get_formato_vales(listaVales);
    var datos = {
      IdSucursal: sucursal.IdSucursal,
      IdTipoEntrega: data.IdTipoEntrega,
      IdUbicacion: data.IdUbicacion,
      Horario: data.Horario,
      NotaPedido: comentario,
      DatosPago: metodo_pago,
      Productos: data.productos,
      ListaVale: aux_vales,
      IdSession: res,
    };

    var res_compra_rap = await compra_rapida(datos);
    if (res_compra_rap.data.ConError) {
      mensaje_alert(res_compra_rap.data.Mensaje);
      return;
    }
    finalizado();
  };

  const volverAtras = () => {
    mt_indice(1);
  };

  const cambiar_seleccion_tarjeta = (id) => {
    setTarjetaSelec(id);
  };

  const mensaje_alert = (text) => {
    Swal.fire({
      icon: "warning",
      text: text,
      showConfirmButton: true,
      confirmButtonText: "Cerrar",
      confirmButtonColor: "#32338E",
    });
  };

  const eliminarFactura = (facturacion) => {
    let title = "¿Estás seguro que quiere eliminar el nit seleccionado?";
    let txt = `Nit:  ${facturacion.Nit}`;
    delete_registro(facturacion, title, txt);
  };

  const delete_registro = async (factura, title, txt) => {
    let resp = await Page_message.delete_register_confirmation(title, txt);
    if (resp) {
      let dato = {
        IdCliente: 0,
        Nit: factura.Nit,
      };
      eliminar_cliente_nit(dato);
    }
  };

  const handleCheckboxChange = (e) => {
    setSinDatosFacturacion(e.target.checked);
    if (e.target.checked) {
      setNit("0");
      setRazonsocial("Sin Nombre");
      setTipoDocumento("5");
      setComplemento("");
    } else {
      setNit(facturaciones[facturaciones.length - 1].Nit);
      setRazonsocial(facturaciones[facturaciones.length - 1].RazonSocial);
    }
  };
  return (
    <>
      <div className="w-full md:w-[82%] flex flex-col h-full">
        <form
          className="flex flex-col md:flex-row md:gap-2 h-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="w-full md:w-[70%] flex flex-col h-full">
            <div className="w-full bg-white rounded-t-lg md:rounded-lg h-full overflow-hidden">
              <div className="flex flex-col h-full">
                <div className="bg-hiperorange w-full text-white flex items-center justify-center  content_title relative">
                  <h2 className="p-0 m-0 title_carrito_responsivo ">
                    Formas de pago
                  </h2>
                  <div className="absolute right-2 ">
                    <ButtonClose
                      className="text-white bg-transparent"
                      onClick={() => exit()}
                    />
                  </div>
                </div>

                <div
                  className={classNames(
                    "bg-white overflow-y-auto flex flex-col h-full mb-36 md:mb-0"
                  )}
                >
                  <div className=" bg-white">
                    <div className="px-2 m-2 bg-hiperplomo_claro pb-2 pt-3 rounded-hiper_g  text-start">
                      <p className="text-black font-bold mb-2">
                        Seleccionar el método de pago
                      </p>
                      {existe_metodos_pagos() && (
                        <div className="flex flex-col gap-2">
                          <div className="flex flex-col gap-1">
                            {metodopagos.Metodos.map((m) => (
                              <ItemOpcionPago
                                key={m.Id}
                                id={m.Id}
                                Descripcion={m.Descripcion}
                                change_selec={cambiar_seleccion}
                                valor={pago_selec}
                              ></ItemOpcionPago>
                            ))}
                          </div>
                          {tarjeta_online && (
                            <Tarjeta
                              change_selec={cambiar_seleccion_tarjeta}
                              valor={tarjeta_selec}
                              startAddCard={handleOpenAddCardModal}
                            ></Tarjeta>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="m-2 p-2 rounded-hiper_g bg-hiperplomo_claro mt-3">
                      <div className="flex space">
                        <p className="text-black font-bold mb-2">
                          Datos de facturación
                        </p>
                        <label
                          htmlFor=""
                          className="text-hiperorange hover:text-hiperorange_hover ml-auto underline font-bold cursor-pointer"
                          onClick={(e) => showModal_facturaciones()}
                        >
                          Cambiar
                        </label>
                      </div>
                      <div className="flex flex-row  content-center mb-3">
                        <label className="cursor-pointer flex items-center gap-3">
                          <input
                            type="checkbox"
                            id="id"
                            name="name"
                            className="checked:bg-hiperorange checked:accent-hiperorange w-5 h-5 rounded-lg"
                            onChange={(e) => handleCheckboxChange(e)}
                          />
                          <span> Facturar sin nombre</span>
                        </label>
                      </div>
                      <div hidden={sinDatosFacturacion}>
                        <div className="mb-3">
                          <label
                            className="block fontzize_label text-black mb-2"
                            htmlFor="documento"
                          >
                            Tipo de documento*
                          </label>
                          <select
                            id="documento"
                            name="documento"
                            value={tipoDocumento}
                            onChange={(e) => {
                              setTipoDocumento(e.target.value);
                              if (e.target.value != 1) {
                                setComplemento("");
                              }
                            }}
                            className="block w-full rounded-md border-1 border-gray-400 py-2 p-3 pr-4 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                            required
                          >
                            <option value="" disabled>
                              Seleccionar
                            </option>
                            <option value="1">Cédula de identidad</option>
                            <option value="2">
                              Cédula de identidad extranjero
                            </option>
                            <option value="3">Pasaporte</option>
                            <option value="4">Otros</option>
                            <option value="5">Nit</option>
                          </select>
                        </div>

                        <div className="mb-3">
                          <label
                            className="block fontzize_label text-black mb-1"
                            htmlFor="nit"
                          >
                            Número de documento*
                          </label>
                          <input
                            className="block w-full rounded-md border-1 border-gray-400 py-2 p-3 pr-4 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                            id="nit"
                            type="text"
                            name="nit"
                            placeholder=""
                            value={nit}
                            onChange={(e) => setNit(e.target.value)}
                            maxLength={13}
                            required
                          />
                          {errors.nit?.type === "required" && (
                            <p style={{ color: "#920909" }}>
                              El campo Número de documento es obligatorio*
                            </p>
                          )}
                        </div>
                        <div className="mb-3">
                          <label
                            className="block fontzize_label text-black mb-1"
                            htmlFor="username"
                          >
                            Razón Social*
                          </label>
                          <input
                            className="block w-full rounded-md border-1 border-gray-400 py-2 p-3 pr-4 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                            id="username"
                            type="text"
                            name="razon"
                            placeholder=""
                            value={razonsocial}
                            onChange={(e) => setRazonsocial(e.target.value)}
                            required
                          />
                          {errors.razon?.type === "required" && (
                            <p style={{ color: "#920909" }}>
                              El campo Razón social es obligatorio*
                            </p>
                          )}
                        </div>
                        {tipoDocumento === "1" && (
                          <div className="mb-3">
                            <label
                              className="block fontzize_label text-black mb-1"
                              htmlFor="complemento"
                            >
                              Complemento
                            </label>
                            <input
                              className="block w-full rounded-md border-1 border-gray-400 py-2 p-3 pr-4 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                              id="complemento"
                              type="text"
                              name="complemento"
                              value={complemento}
                              onChange={(e) => setComplemento(e.target.value)}
                              placeholder=""
                              maxLength={2}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="m-2 p-2 rounded-hiper_g bg-hiperplomo_claro mt-3">
                      <div className="mb-3">
                        <label
                          className="block fontzize_label text-black  mb-1"
                          htmlFor="username"
                        >
                          Agrega un comentario o nota adicional (opcional)
                        </label>
                        <textarea
                          className="block w-full rounded-md border-1 border-gray-400 py-2 p-3 pr-4 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                          id="comentario"
                          type="text"
                          name="comentario"
                          placeholder="Comentario sobre tu pedido (Opcional)"
                          onChange={(e) => setComentario(e.target.value)}
                        />
                      </div>
                      <div className="mb-3" key="vale">
                        <button
                          type="button"
                          className="flex items-center cursor-pointer bg-hiperorange hover:bg-hiperorange_hover p-1 text-white text-start self-center group"
                          style={{ borderRadius: "8px" }}
                          onClick={startModalVale}
                        >
                          <p className="mx-2 mb-0 text-sm md:text-base inline">
                            Vales agregados
                          </p>
                          <span className="m-0 flex justify-center items-center w-5 h-5 bg-white rounded-full text-hiperorange group-hover:text-hiperorange_hover leading-none">
                            {listaVales.length}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-[30%] sticky bottom-0">
            <CarritoResumen
              costo_delivery={
                data.Horario.Costo > 0
                  ? formatNumber(data.Horario.Costo)
                  : "Gratis"
              }
              ahorro_total={formatNumber(importeAhorrodo)}
              total_carrito={formatNumber(importeTotal)}
              total_pagar={formatNumber(importeTotal + data.Horario.Costo)}
              volverAtras={volverAtras}
              guardarProceso={() => {}}
              backButtonLabel="Volver"
              nextButtonLabel="Realizar pedido"
              nextButtonType="submit"
            />
          </div>
        </form>
      </div>
      <Modal
        isOpen={modalVale}
        ariaHideApp={false}
        onRequestClose={endModalVale}
        className="fixed inset-0 flex items-center justify-center p-2 md:p-4 w-full md:max-w-lg md:mx-auto"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-30"
        contentLabel="VALE"
      >
        <RegistroVale
          listaVales={listaVales}
          updateVales={updateListaVales}
          endModalVale={endModalVale}
        />
      </Modal>
      <div
        className="fixed z-10  top-2 w-full left-0 hidden"
        id="modalFacturacion"
      >
        <div className=" items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-75 overflow-hidden " />
          </div>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;{" "}
          </span>
          <div
            className="inline-block align-center bg-white rounded-hiper_g text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className=" rounded-hiper_g overflow-hidden ">
              <div className="bg-hiperorange w-100 relative text-white flex items-center justify-center h-[52px]  content_title">
                <h2 className="p-0 m-0 title_responsivo  ">
                  Seleccione una razón social
                </h2>
                <div className="absolute right-2 ">
                  <ButtonClose
                    className="text-white bg-transparent"
                    onClick={(e) => showModal_facturaciones(e)}
                  />
                </div>
              </div>
              <div
                className="overflow-auto p-4"
                style={{ height: "30rem", maxHeight: "30rem" }}
              >
                {!!facturaciones ? (
                  <>
                    {facturaciones.length > 0 ? (
                      facturaciones.map((fact, key) => {
                        return (
                          <div
                            key={`item-facturacion-${key}`}
                            className={classNames(
                              "flex gap-2 border border-black  justify-between items-center bg-white hover:bg-hiperorange hover:border-hiperorange text-black hover:text-white rounded w-full mb-2"
                            )}
                          >
                            <button
                              className="py-2 pl-4 flex-grow text-left"
                              onClick={(e) => seleccionar_opcion(fact, e)}
                            >
                              {fact.RazonSocial} - {fact.Nit}{" "}
                            </button>
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              onClick={() => eliminarFactura(fact)}
                              style={{ fontSize: "1.4rem" }}
                              className="pr-4 cursor-pointer"
                            />
                          </div>
                        );
                      })
                    ) : (
                      <div className="flex flex-col gap-4 justify-center items-center mt-2 min-h-60 text-gray-400">
                        <FontAwesomeIcon icon={faFileInvoice} size="6x" />
                        <div className="text-xl">
                          No hay datos de facturación para mostrar
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <LoaderSpinner bool={true} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={openAddCardModal}
        ariaHideApp={false}
        onRequestClose={handleCloseAddCardModal}
        style={addCardModalStyles}
        contentLabel="create credit card"
      >
        <NuevaTarjeta close={handleCloseAddCardModal} />
      </Modal>
      <Modal
        isOpen={openModalTerminosCondiciones}
        ariaHideApp={false}
        onRequestClose={handleCloseModalTerminosCondiciones}
        style={customStyles2}
        contentLabel="Ingresar"
        id="theIdRegister"
      >
        <TerminoCondicion
          ps_save_pedidos={handleCloseModalTerminosCondiciones}
          ps_endModalRegistro={handleCloseModalTerminosCondiciones}
          ps_opcion_numero={() => {}}
          ps_check_no_anonimo={() => {}}
        ></TerminoCondicion>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  metodopagos: state.MetodoPago.metodopagos,
  sucursal: state.BranchOffice.sucursal,
  shopping_list_detalle: state.Shopping_list.shopping_list_detalle,
  carrito_lista_compra: state.Shopping_list.carrito_lista_compra,
  direccion: state.Location.direccion,
  profile: state.Profile.profile,
});

export default connect(mapStateToProps, {
  get_list_metodos_pagos,
  compra_rapida,
  setSwAlert,
  eliminar_cliente_nit,
  validar_nit,
})(FormaPago);
