import { useState } from "react";
import { ConfigParametersRepository } from "../../../config-parameters/domain/config-parameters-repository";
import { NotificationService } from "../../../notifications/domain/notification-service";
import { FormStatus } from "../../../shared/domain/enums/form-status";
import { ConfigParametesGetByKeyParameters } from "../../../config-parameters/domain/dto/config-parameters-get-by-key-parametes";
import { ConfigParameter } from "../../../config-parameters/domain/interface/config-parameter";
import { useHandleErrors } from "../../../shared/ui/hooks/use-handle-errors";
import { getConfigParametersByKey } from "../../../config-parameters/application/get/get-config-parameters-by-key";
import { useDispatch } from "react-redux";
import { setVerHiperPuntosParameter as setVerHiperPuntosParameterAction } from "../ui/redux/actions/hiper-puntos-action";
import { Dispatch } from "redux";

export const useGetVerHiperPuntosParameter = (
  configParametersRepository: ConfigParametersRepository,
  notificationService: NotificationService
): {
  formStatus: FormStatus;
  getVerHiperPuntosParameter: (
    params: ConfigParametesGetByKeyParameters
  ) => void;
  resetFormStatus: () => void;
  verHiperPuntosParameter: ConfigParameter | undefined;
  resetConfigParameters: () => void;
  isLoading: boolean;
} => {
  const dispatch: Dispatch<any> = useDispatch();
  const [formStatus, setFormStatus] = useState(FormStatus.INITIAL);
  const [verHiperPuntosParameter, setVerHiperPuntosParameter] = useState<
    ConfigParameter | undefined
  >(undefined);

  const { handleError } = useHandleErrors(notificationService);

  async function getVerHiperPuntosParameter(
    params: ConfigParametesGetByKeyParameters
  ) {
    setFormStatus(FormStatus.LOADING);

    try {
      const verHiperPuntosParameter: ConfigParameter =
        await getConfigParametersByKey(configParametersRepository)(params);
      dispatch(setVerHiperPuntosParameterAction(verHiperPuntosParameter));
      setVerHiperPuntosParameter(verHiperPuntosParameter);
      setFormStatus(FormStatus.SUCCESS);
      //   notifySuccess(notificationService)("Clientes obtenidos correctamente.");
    } catch (error: unknown) {
      const message = "";
      handleError({ error, message });
      setFormStatus(FormStatus.ERROR);
    }
  }

  const resetFormStatus = () => setFormStatus(FormStatus.INITIAL);

  const resetConfigParameters = () => setVerHiperPuntosParameter(undefined);

  return {
    formStatus,
    getVerHiperPuntosParameter,
    resetFormStatus,
    verHiperPuntosParameter,
    resetConfigParameters,
    isLoading: formStatus === FormStatus.LOADING,
  };
};
