import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Indice from "./carrito/indice";
import Carrito from "./carrito/carrito";
import {
  limpiar_carrito,
  limpiar_estado_confirmado,
} from "../../redux/actions/carrito";
import ModoEntrega from "./carrito/modo_entrega";
import Direccion from "./carrito/direccion";
import FormaPago from "./carrito/forma_pago";
import ComentarioVale from "./carrito/comentario_vale";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import { Link } from "react-router-dom";
import "../../assets/styles/responsive.scss";
import { useNavigate, useParams } from "react-router-dom";
import useScreenSize from "../../widgets/useScreenSize";

function MiCarrito({
  cerrarModal,
  limpiar_estado_confirmado,
  limpiar_carrito,
  cerrarModalAnonimo,
}) {
  const { width, height } = useScreenSize();
  const [posicion, guardarPosicion] = useState(1); //antes 1 modif lim
  const { ciudadp, sucursalp } = useParams();

  const [is_carrito, guardarIsCarrito] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
      limpiar_estado_confirmado();
      guardarIsCarrito(true);
    };
  }, []);

  function actualizar_posicion(numero) {
    guardarPosicion(numero);
  }

  function exit() {
    cerrarModal();
  }

  function exitAnonimo() {
    cerrarModalAnonimo();
  }

  function finalizado_exit() {
    guardarIsCarrito(false);
  }

  const ir_inicio = () => {
    let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
    if (ciudadp && sucursalp) {
      navigate("/" + ciudadp + "/" + sucursalp);
    } else if (url_nav) {
      navigate("/" + url_nav.url_parameters);
    }
    cerrarModal();
  };

  const navigate_menu = async (url_menu) => {
    let param = await get_url();
    navigate(param + "/" + url_menu);
  };

  const get_url = async () => {
    let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
    if (ciudadp && sucursalp) {
      return "/" + ciudadp + "/" + sucursalp;
    } else if (url_nav) {
      return "/" + url_nav.url_parameters;
    }
  };

  return (
    <>
      {is_carrito ? (
        <div className="rounded-lg overflow-hidden w-full h-full">
          <div className="w-full max-w-[1320px] h-full">
            <div
              className={classNames(
                "h-full flex flex-col md:flex-row md:gap-2 mx-0",
                {
                  "justify-center": width > 768,
                }
              )}
            >
              <div className="hidden md:flex w-[25rem] md:w-[18%]  flex-col h-full">
                <Indice posicion={posicion}></Indice>
                {/* <div onClick={exit} className="w-full h-[70vh]"></div> */}

              </div>
              {posicion === 1 && (
                <Carrito
                  mt_indice={actualizar_posicion}
                  width={width}
                  height={height}
                  exit={exit}
                ></Carrito>
              )}
              {posicion === 2 && (
                <ModoEntrega
                  mt_indice={actualizar_posicion}
                  width={width}
                  height={height}
                  exit={exit}
                ></ModoEntrega>
              )}
              {posicion === 3 && (
                <Direccion
                  mt_indice={actualizar_posicion}
                  width={width}
                  height={height}
                  exit={exit}
                ></Direccion>
              )}
              {posicion === 4 && (
                <FormaPago
                  mt_indice={actualizar_posicion}
                  width={width}
                  height={height}
                  exit={exit}
                ></FormaPago>
              )}
              {posicion === 5 && (
                <ComentarioVale
                  mt_indice={actualizar_posicion}
                  finalizado={finalizado_exit}
                  width={width}
                  height={height}
                  exit={exit}
                  exitAnonimo={exitAnonimo}
                ></ComentarioVale>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex w-full justify-center items-center h-full">
          <div className="w-full max-w-xl">
            <div className="card border-0 rounded-xl overflow-hidden">
              <div className="card-body  bg-hiperorange d-flex justify-center">
                <div className="py-5">
                  <div className=" text-center">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="text-white text-8xl"
                    />
                    <div>
                      <p className="text-white text-2xl">
                        Se realizó su pedido correctamente
                      </p>
                    </div>
                  </div>
                  <div className="grid grid-rows-2 gap-3 text-center">
                    <button
                      className="h-full w-full rounded-[7px] py-2 px-3 border_white text-white focus:outline-none focus:shadow-outline transition duration-300 ease-in-out hover:scale-105"
                      onClick={() => ir_inicio()}
                    >
                      Seguir comprando
                    </button>

                    <Link onClick={() => navigate_menu("mis-pedidos")}>
                      <button className="h-full w-full rounded-[7px] py-2 px-3 border_white text-white focus:outline-none focus:shadow-outline transition duration-300 ease-in-out hover:scale-105">
                        Detalle del pedido
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  limpiar_carrito,
  limpiar_estado_confirmado,
})(MiCarrito);
