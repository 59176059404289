import Layout from "../../hocs/Layout";
import Alert from "../../components/Swalert";

const Error404 = () => {
    return (
            <Layout>
            <Alert></Alert>
            <div className="w-full h-full flex flex-col justify-center items-center p-2">
            <div className="md:w-2/6 sm:w-full">
            <p className="text-hiperorange text-9xl font-bold">404</p>
            <p className="text-hiperorange text-4xl font-bold">No hemos encontrado la página a la que intentas acceder...</p>
            </div>
            
            
           </div>
            </Layout>
         
            

    );
};

 export default Error404;

