import { createAxiosHttpService } from "../../http/infrastructure/axios-http-service";
import { SucursalContactosRepository } from "../domain/sucursal-contactos-repository";
import { MensajeApi } from "../../http/domain/interfaces/mensaje-api";
import { SucursalContacto } from "../domain/interface/sucursal-contacto";
import { SucursalContactosGetByIdSucursalParameters } from "../domain/dto/sucursal-contactos-get-by-id-sucursal-parameters";
import { mapToURLSearchParams } from "../../http/infrastructure/utils/http-utils";

const http = createAxiosHttpService();
const apiVersion = process.env.REACT_APP_API_VERSION;
// const baseUrl = getBaseUrlBasedOnDomain(
// 	env.fidelizacionAtencionClienteBackendApiPath,
// 	env.fidelizacionAtencionClienteBackendApiDomainPath
// );
const baseUrl = process.env.REACT_APP_API_URL;
const backendPath = `${baseUrl}/api/${apiVersion}`;

export function createApiSucursalContactosRepository(): SucursalContactosRepository {
  async function getBySucursalId(
    sucursalContactosGetByIdSucursalParameters: SucursalContactosGetByIdSucursalParameters
  ): Promise<MensajeApi<SucursalContacto>> {
    const searchParams = mapToURLSearchParams(
      sucursalContactosGetByIdSucursalParameters
    );
    const response = await http.get<MensajeApi<SucursalContacto>>(
      `${backendPath}/sucursal-contacto/obtenerporidsucursal?${searchParams}`
    );

    return response;
  }

  return {
    getBySucursalId,
  };
}
