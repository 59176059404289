import AppleBadge from "../../../../assets/images/app-stores-images/apple-badge.png";
import GoogleBadge from "../../../../assets/images/app-stores-images/google-badge.png";

export enum AppName {
  "PLAYSTORE" = "Play Store",
  "APPSTORE" = "App Store",
}

export interface AppLink {
  name: string;
  href: string;
  image: string;
}

export function useBuildFooterAppLinks() {
  const playStoreLink: string = process.env.REACT_APP_PLAYSTORE_URL || "";

  const appStoreLink: string = process.env.REACT_APP_APPSTORE_URL || "";

  const appLinks: AppLink[] = [];

  if (playStoreLink) {
    appLinks.push({
      name: AppName.PLAYSTORE,
      href: playStoreLink,
      image: GoogleBadge,
    });
  }

  if (appStoreLink) {
    appLinks.push({
      name: AppName.APPSTORE,
      href: appStoreLink,
      image: AppleBadge,
    });
  }
  return { appLinks };
}
