import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { limpiar_horario } from "../../../redux/actions/carrito";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { useForm } from "react-hook-form";
import {
  cambiar_ubicacion,
  get_ubicacion,
  save_datos_entrega_p,
} from "../../../redux/actions/location";
import { info_session, update_usuario } from "../../../redux/actions/profile";

import "../../../assets/styles/globalColorPrincipal.css";
import "../../../assets/styles/responsive.scss";
import ButtonClose from "../../../widgets/button_close";
import { CarritoResumen } from "./carrito_resumen";
import { useThousandSeparator } from "../../../hooks/useThousandSeparator";

function Direccion({
  mt_indice,
  get_ubicacion,
  locations,
  update_usuario,
  info_session,
  profile,
  save_datos_entrega_p,
  limpiar_horario,
  direccion,
  cambiar_ubicacion,
  list_carrito,
  width,
  height,
  exit,
}) {
  const { numberFormatter } = useThousandSeparator();

  const [hubo_cambio_direccion, setHuboCambioDireccion] = useState(false);
  const [es_anonimo, setEsAnonimo] = useState(false);
  const [subPedidos, guardarsubPedidos] = useState([]);
  const [formData, setFormData] = useState({
    nombres: "",
    apellidopaterno: "",
    telefono: "",
    direccion: "",
    ref_direccion: "",
    nota_entrega: "",
  });

  const costoEnvio = subPedidos?.reduce((total, item) => {
    const costo = item.CostoEnvio.replace("Bs. ", "");
    return total + (costo ? Number(costo) : 0);
  }, 0);
  const formatNumber = (value) => {
    return numberFormatter.format(value);
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const _ahorroTotal = () => {
    let total = 0;
    subPedidos.forEach((sp) => {
      if (sp.check) {
        total += Number(sp.ahorro);
      }
    });
    return formatNumber(total);
  };
  const ahorro_total = _ahorroTotal();
  useEffect(() => {
    if (!profile) {
      info_session();
    } else {
      if (!profile.Nombres || !profile.ApellidoPaterno) {
        setEsAnonimo(true);
        // return;
      }
      setFormData({
        telefono: profile.Telefono,
        direccion: "",
        ref_direccion: "",
        nota_entrega: "",
      });
      cargar_datos_localst();
    }
  }, [profile]);
  useEffect(() => {
    setFormData({
      telefono: profile.Telefono,
      direccion: direccion.Direccion,
      ref_direccion: direccion.Referencia,
      nota_entrega: "",
    });
    setValue("refdireccion", direccion.Referencia);
    setValue("nota", "");
    limpiar_horario();
  }, []);
  useEffect(() => {
    if (list_carrito) {
      let subPedido = list_carrito.SubPedidos.filter((pedido) => pedido.check);
      guardarsubPedidos(subPedido);
    }
  }, [list_carrito]);

  useEffect(() => {
    if (hubo_cambio_direccion) {
      mt_indice(1);
    }
  }, [direccion]);

  const cargar_datos_localst = () => {
    let data = JSON.parse(localStorage.getItem("datos_personales"));
    if (data) {
      setValue("refdireccion", data.refdireccion);
      setValue("nota", data.nota);
    }
  };

  const onSubmit = async (data) => {
    localStorage.setItem("datos_personales", JSON.stringify(data));
    let datos = {
      Telefono: profile.Telefono,
      Prefijo: profile.Prefijo,
      IdPersonaUbicacion: direccion.Id,
      InstruccionDelivery: data.refdireccion,
      InstruccionEntrega: data.nota,
    };
    if (!profile.Nombres || !profile.ApellidoPaterno) {
      let data_profile = {
        Nombres: data.nombres.trim(),
        ApellidoPaterno: data.apellidopaterno.trim(),
        Telefono: profile.Telefono,
      };
      try {
        const result = await update_usuario(data_profile);
        if (!result) return;
      } catch (error) {
        return;
      }
    }
    save_datos_entrega_p(datos);
    mt_indice(4);
  };

  const cambio_direccion = (id) => {
    if (id != direccion.Id) {
      cambiar_ubicacion(locations, id);
      setHuboCambioDireccion(true);
    }
  };

  const hayubicacion = () => {
    return locations.length > 0;
  };

  const volverAtras = () => {
    mt_indice(2);
  };

  const get_total_carrito = () => {
    let total = 0;
    let precio_no_disponible = 0;
    subPedidos.forEach((sp) => {
      if (sp.check) {
        sp.Productos.forEach((prod) => {
          if (!prod.Disponible) {
            precio_no_disponible += prod.Importe;
          }
        });
      }
    });

    subPedidos.forEach((sp) => {
      if (sp.check) {
        total += sp.ImporteSubtotal;
      }
    });

    let result = parseFloat(total) - parseFloat(precio_no_disponible);
    return formatNumber(Number(result));
  };

  return (
    <>
      <div className="w-full md:w-[82%] flex flex-col h-full">
        <form
          className="flex flex-col md:flex-row md:gap-2 h-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="w-full md:w-[70%] flex flex-col h-full">
            <div className="w-full bg-white rounded-t-lg md:rounded-lg h-full overflow-hidden">
              <div className="flex flex-col h-full">
                <div className="bg-hiperorange w-full text-white flex items-center justify-center  content_title relative">
                  <h2 className="p-0 m-0 title_carrito_responsivo ">Datos</h2>
                  <div className="absolute right-2 ">
                    <ButtonClose
                      className="text-white bg-transparent"
                      onClick={() => exit()}
                    />
                  </div>
                </div>
                <div className={classNames("bg-white overflow-y-auto flex flex-col h-full mb-32 md:mb-0")}>
                  <div className="p-3 flex flex-col gap-3">
                    {es_anonimo && (
                      <>
                        {!profile.Nombres && (
                          <div>
                            <label
                              className="block fontzize_label text-black  mb-2"
                              htmlFor="name"
                            >
                              Nombre(s)*
                            </label>
                            <input
                              className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                              id="name"
                              type="text"
                              name="nombres"
                              {...register("nombres", {
                                required: {
                                  value: true,
                                  message: "El campo Nombre (s) es obligatorio",
                                },
                                minLength: {
                                  value: 3,
                                  message:
                                    "El campo Nombre (s) debe tener 3 caracteres como mínimo",
                                },
                                validate: (value) => {
                                  const trimmedValue = value.trim();
                                  if (!trimmedValue)
                                    return "El campo Nombre (s) es obligatorio";
                                  const pattern =
                                    /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ0-9\s]+$/;
                                  return (
                                    pattern.test(trimmedValue) ||
                                    "El campo Nombre (s) sólo acepta letras mayúsculas, minúsculas y números"
                                  );
                                },
                              })}
                            />
                            {errors.nombres && (
                              <span style={{ color: "#920909" }}>
                                {errors.nombres.message}
                              </span>
                            )}
                          </div>
                        )}
                        {!profile.ApellidoPaterno && (
                          <div>
                            <label
                              className="block fontzize_label text-black  mb-2"
                              htmlFor="name"
                            >
                              Apellido Paterno*
                            </label>
                            <input
                              className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                              id="name"
                              type="text"
                              name="apellidopaterno"
                              {...register("apellidopaterno", {
                                required: {
                                  value: true,
                                  message:
                                    "El campo Apellido Paterno es obligatorio",
                                },
                                minLength: {
                                  value: 3,
                                  message:
                                    "El campo Apellido Paterno debe tener 3 caracteres como mínimo",
                                },
                                validate: (value) => {
                                  const trimmedValue = value.trim();
                                  if (!trimmedValue)
                                    return "El campo Apellido Paterno es obligatorio";
                                  const pattern =
                                    /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ0-9\s]+$/;
                                  return (
                                    pattern.test(trimmedValue) ||
                                    "El campo Apellido Paterno sólo acepta letras mayúsculas, minúsculas y números"
                                  );
                                },
                              })}
                            />
                            {errors.apellidopaterno && (
                              <span style={{ color: "#920909" }}>
                                {errors.apellidopaterno.message}
                              </span>
                            )}
                          </div>
                        )}
                      </>
                    )}
                    {profile.Telefono && (
                      <div>
                        <label
                          className="block fontzize_label text-black  mb-2"
                          htmlFor="name"
                        >
                          Móvil de confirmación
                        </label>
                        <input
                          className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                          id="name"
                          type="text"
                          name="telefono"
                          value={formData.telefono}
                          disabled
                        />
                      </div>
                    )}

                    <div>
                      <label
                        className="block fontzize_label text-black  mb-2"
                        htmlFor="username"
                      >
                        Seleccione dirección
                      </label>

                      <select
                        id="direccion"
                        name="direccion"
                        value={direccion.Id}
                        onChange={(e) => cambio_direccion(e.target.value)}
                        className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                      >
                        <option value={undefined} disabled>Seleccione una opción</option>
                        {hayubicacion() ? (
                          locations.map((item) => (
                            <option key={item.Id} value={item.Id}>
                              {item.Descripcion}
                            </option>
                          ))
                        ) : (
                          <></>
                        )}
                      </select>

                      {errors.direccion?.type === "required" && (
                        <p style={{ color: "#920909" }}>
                          El campo dirección es obligatorio*
                        </p>
                      )}
                    </div>
                    <div>
                      <label
                        className="block fontzize_label text-black  mb-2"
                        htmlFor="email"
                      >
                        Referencia de la dirección*
                      </label>
                      <textarea
                        className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                        id="email"
                        type="text"
                        name="refdireccion"
                        {...register("refdireccion", { required: true })}
                      />
                      {errors.refdireccion?.type === "required" && (
                        <small style={{ color: "#920909" }}>
                          El campo referencia es obligatorio*
                        </small>
                      )}
                    </div>
                    <div>
                      <label
                        className="block fontzize_label text-black  mb-2"
                        htmlFor="ci"
                      >
                        Instrucciones de entrega
                      </label>
                      <textarea
                        className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                        id="ci"
                        type="text"
                        name="nota"
                        {...register("nota")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-[30%] sticky bottom-0">
            <CarritoResumen
              costo_delivery={
                subPedidos?.length === 0
                  ? "Calculando"
                  : costoEnvio > 0
                  ? formatNumber(costoEnvio)
                  : "Gratis"
              }
              ahorro_total={ahorro_total}
              total_carrito={get_total_carrito()}
              volverAtras={volverAtras}
              guardarProceso={() => {}}
              backButtonLabel="Volver"
              nextButtonLabel="Continuar"
              nextButtonType="submit"
            />
          </div>
        </form>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  locations: state.Location.locations,
  direccion: state.Location.direccion,
  profile: state.Profile.profile,
  list_carrito: state.Carrito.list_carrito,
});

export default connect(mapStateToProps, {
  get_ubicacion,
  update_usuario,
  info_session,
  save_datos_entrega_p,
  cambiar_ubicacion,
  limpiar_horario,
})(Direccion);
