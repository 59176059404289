import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ButtonText from "../../../../widgets/button_text";
import { faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonClose from "../../../../widgets/button_close";
import "../../../../assets/styles/responsive.scss";
import { useThousandSeparator } from "../../../../hooks/useThousandSeparator";

const RegistroVale = ({
  idsucursal,
  listaVales,
  updateVales,
  endModalVale,
}) => {
  const { numberFormatter } = useThousandSeparator();
  const [vales, setVales] = useState([]);
  const [vale, setVale] = useState("");
  const [errorMonto, setErrorMonto] = useState("");

  useEffect(() => {
    if (idsucursal) {
      let check = listaVales.find((list) => list.idsucursal === idsucursal);
      if (check) {
        setVales(check.vale);
      }
    } else {
      setVales(listaVales);
    }
  }, []);

  useEffect(() => {
    updateVales(vales, idsucursal);
  }, [vales]);

  const addVale = (event) => {
    event.preventDefault();
    setErrorMonto("");
    if (vale !== "") {
      if (vale > 0) {
        setVales([...vales, Number(vale)]);
        setVale("");
      }
    } else {
      setErrorMonto("Solo se permite números");
    }
  };

  const deleteVale = (index) => {
    let array = vales.toSpliced(index, 1);
    setVales(array);
  };

  const inputchangeVale = (e) => {
    setVale(e.target.value.replace(/[^0-9]/g, ""));
  };

  return (
    <>
      <form
        onSubmit={addVale}
        className="w-full flex flex-col h-full max-h-[600px]"
      >
        <div className="w-full bg-white rounded-lg md:rounded-lg h-full overflow-hidden">
          <div className="flex flex-col h-full">
            <div className="bg-hiperorange w-full text-white flex items-center justify-center  content_title relative">
              <h2 className="p-0 m-0 title_carrito_responsivo">
                Vales agregados
              </h2>
              <div className="absolute right-2 ">
                <ButtonClose
                  type="button"
                  className="text-white bg-transparent"
                  onClick={() => endModalVale()}
                />
              </div>
            </div>
            <div className="p-2 bg-white overflow-y-auto flex flex-col h-full">
              <div>
                <p className="font-bold">Agregar vales del Hipermaxi</p>
                <label
                  className="block text-black fontzize_label  mb-1"
                  htmlFor="vale"
                >
                  Monto (Bs.)
                </label>

                <div className="content_vale gap-2">
                  <div className="input-group mr-1">
                    <div className="flex w-100 relative">
                      <div className="p-1 px-3 absolute left-0 h-full flex items-center border-r-[1px] border-gray-400">
                        <span>Bs.</span>
                      </div>
                      <input
                        className="block w-full rounded-md border-1 border-gray-400 py-2 pl-16 pr-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                        id="vale"
                        type="number"
                        value={vale}
                        onChange={(e) => inputchangeVale(e)}
                        placeholder="Ingresar monto del vale"
                        min={1}
                        max={1000000}
                        required
                        autoComplete="off"
                        autoFocus
                      />
                    </div>
                  </div>
                  <ButtonText
                    type="submit"
                    label="Agregar vale"
                    principal={false}
                    className="bg-hiperorange text-white max-w-40 hover:bg-hiperorange_hover px-1"
                  />
                </div>
                <p className="text-sm  font-semibold italic pt-2 pb-3">
                  {" "}
                  * El vale se entregará como pago al momento de recibir los
                  productos
                </p>
              </div>
              {/*    body     */}
              <div className="overflow-y-auto flex flex-col h-full">
                {vales.map((item, key) => (
                  <div
                    key={key}
                    className="flex border border-black  justify-between items-center bg-white hover:bg-hiperorange hover:border-hiperorange text-black hover:text-white   py-2 pl-4 pr-2 rounded w-100 mb-2"
                  >
                    <label>
                      Vale Nro. {key + 1} por Bs. {numberFormatter.format(item)}{" "}
                    </label>
                    <FontAwesomeIcon
                      icon={faTrashCan}
                      onClick={() => deleteVale(key)}
                      style={{ fontSize: "1.4rem" }}
                      className="mr-1 cursor-pointer"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(RegistroVale);
