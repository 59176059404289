import { responseHandler } from "../../../http/application/response/response-handler";
import { HiperPuntosRepository } from "../../domain/hiper-puntos-repository";
import { CustomerBag } from "../../domain/interfaces/customer-bag";

export function getCustomerBags(customerRepository: HiperPuntosRepository) {
  return async (): Promise<CustomerBag[]> => {
    const response = await customerRepository.getCustomerBags();
    return responseHandler<CustomerBag[], null>(response);
  };
}
