import { useEffect, useState } from "react";
import { CustomerBagPointData } from "../../domain/interfaces/customer-bag-point-data";
import { CustomerBagPoint } from "../../domain/interfaces/customer-bag-point";

export const useGetCustomerBagHistoryData = (
  customerBagPointData?: CustomerBagPointData
): {
  customerBagPoints: CustomerBagPoint[];
} => {
  const [customerBagPoints, setCustomerBagPoints] = useState<
    CustomerBagPoint[]
  >([]);

  useEffect(() => {
    setCustomerBagPoints((prevState) => [
      ...prevState,
      ...(customerBagPointData?.Data || []),
    ]);
  }, [customerBagPointData]);

  return { customerBagPoints };
};
