import { SucursalContacto } from "../../../domain/interface/sucursal-contacto";

export type SucursalContactosState = {
  sucursalContacto: SucursalContacto | null;
};

export type SucursalContactosAction = {
  type: string;
  payload: SucursalContacto | null;
};

export type SucursalContactosDispatchType = (
  args: SucursalContactosAction
) => SucursalContactosAction;


export const SET_SUCURSAL_CONTACTO = "SET_SUCURSAL_CONTACTO";
export const REMOVE_SUCURSAL_CONTACTO =
  "REMOVE_SUCURSAL_CONTACTO";
