import { ConfigParameter } from "../../../../../config-parameters/domain/interface/config-parameter";
import { CustomerBag } from "../../../../domain/interfaces/customer-bag";

export type HiperPuntosState = {
  verHiperPuntosParameter?: ConfigParameter;
  customerBags?: CustomerBag[];
  customerBagsSearchTerm: string;
};

export type HiperPuntosAction = {
  type: string;
  payload?: ConfigParameter;
};

export type CustomerBagsAction = {
  type: string;
  payload?: CustomerBag[];
};

export type CustomerBagsSearchTermAction = {
  type: string;
  payload: string;
};

export type HiperPuntosDispatchType = (
  args: HiperPuntosAction
) => HiperPuntosAction;

export type CustomerBagsDispatchType = (
  args: CustomerBagsAction
) => CustomerBagsAction;

export type CustomerBagsSearchTermDispatchType = (
  args: CustomerBagsSearchTermAction
) => CustomerBagsSearchTermAction;

export const SET_VER_HIPERPUNTOS_PARAMETER = "SET_VER_HIPERPUNTOS_PARAMETER";
export const SET_CUSTOMER_BAGS = "SET_CUSTOMER_BAGS";
export const SET_CUSTOMER_BAGS_SEARCH_TERM = "SET_CUSTOMER_BAGS_SEARCH_TERM";
export const REMOVE_VER_HIPERPUNTOS_PARAMETER =
  "REMOVE_VER_HIPERPUNTOS_PARAMETER";
