import {
  faBell,
  faHeart,
  faMessage,
  faMessageSlash,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { onValue, ref, off } from "@firebase/database";
import "../../assets/styles/globals.scss";
import {
  delete_notificacion,
  get_notificaciones,
  show_notification,
} from "../../redux/actions/notification";
import { actualizar_tokenfb_usuario } from "../../redux/actions/profile";

import ButtonClose from "../../widgets/button_close";
import { database, getMessagingInstance } from "../../Firebase";
import { getToken } from "@firebase/messaging";
import classNames from "classnames";

const customStyles = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "30",
  },
  content: {
    padding: "0px",
    border: "0px",
    background: "transparent",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "calc(100% - 64px)",
    minHeight: 300,
    overflow: "hidden",
  },
};

function Notificacion({
  get_notificaciones,
  delete_notificacion,
  show_notification,
  notificaciones,
  profile,
  isFetching,
  onCloseModal = () => {},
  actualizar_tokenfb_usuario,
  isMobile = false
}) {
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [nro_notificaciones, setNro_notificaciones] = useState(0);

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
    requestPermission();
  };

  const handleClose = () => {
    setIsOpen(false);
    onCloseModal();
  };

  async function requestPermission() {
    //requesting permission using Notification API
    const permission = await Notification.requestPermission();
    const messaging = await getMessagingInstance();
    if (messaging && permission === "granted") {
      try {
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
        });

        if (token) {
          // Enviar el token al servidor
          if (profile?.TokenFirebase) {
            const datouser = {
              Telefono: profile.Telefono,
              NroDocumento: profile.NroDocumento,
              TokenFirebase: token,
              Nombres: profile.Nombres,
              ApellidoMaterno: profile.ApellidoMaterno,
              ApellidoPaterno: profile.ApellidoPaterno,
            };
            actualizar_tokenfb_usuario(datouser);
          }
          localStorage.setItem("tfb", token);
          // Aquí puedes enviar el token a tu servidor
        } else {
        }
      } catch (tokenError) {}
    } else if (permission === "denied") {
      //notifications are blocked
      // alert("You denied for the notification");
    }
  }

  // useEffect(() => {
  //   requestPermission();
  // }, []);

  useEffect(() => {
    let unsubscribe;
    async function fetchData() {
      try {
        let tipo_user = localStorage.getItem("user");
        if (profile !== null && tipo_user !== '"anonimo"') {
          const databaseRef = ref(
            database,
            "notificaciones/" + profile.IdUsuario
          );

          const listener = (snapshot) => {
            const data = snapshot.val();
            colocar_numero_notificaciones(data);
          };

          onValue(databaseRef, listener);
          unsubscribe = () => off(databaseRef, listener);
        }
      } catch (e) {}
    }

    fetchData();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [profile]);

  useEffect(() => {
    get_notificaciones();
  }, []);

  const colocar_numero_notificaciones = (objeto) => {
    const dato = objeto ? Object.values(objeto) : [];

    setNro_notificaciones(dato[0]?.CantidadTotal || 0);
  };

  const rechazar = async (notificacion_id) => {
    await delete_notificacion(notificacion_id);
    onCloseModal();
  };
  const show_notificacion = async (notificacion_id, entidad) => {
    show_notification(notificacion_id);
    onCloseModal();
    // navigate(`/lista-compra-compartida/${entidad}`);
  };

  return (
    <>
      <button onClick={handleOpen} className={classNames("py-2 w-full flex items-center", {
        "px-4": isMobile,
        "px-10": !isMobile
      })}>
        {notificaciones.length === 0 ? (
          <FontAwesomeIcon
            icon={faBell}
            className="text-white w-5"
            title="no tiene notificación pendiente"
          />
        ) : (
          <FontAwesomeIcon icon={faBell} className="text-white w-5" />
        )}
        <span className="text-white font-normal block truncate ml-2">
          {`Mis notificaciones (${nro_notificaciones})`}
        </span>
      </button>
      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        onRequestClose={handleClose}
        style={customStyles}
        contentLabel="notificaciones"
      >
        <div className="w-[21rem] md:w-[25rem] bg-white">
          <div className="bg-hiperorange w-100 relative text-white flex items-center justify-center h-[52px]  content_title">
            <h2 className="p-0 m-0 title_carrito_responsivo">
              Mis notificaciones
            </h2>
            <div className="absolute right-2 ">
              <ButtonClose
                className="text-white bg-transparent"
                onClick={handleClose}
              />
            </div>
          </div>

          <div className="overflow-y-auto z-20 h-[600px] w-full">
            {/* {isFetching ? (
              <LoaderSpinner bool={isFetching} />
            ) : ( */}
            <>
              {notificaciones?.length > 0 ? (
                <>
                  {notificaciones?.map((notificacion, index) => (
                    <div
                      key={`notificacion-${index}`}
                      className="bg-white flex py-2 px-3 "
                    >
                      <div>
                        <div className="grid grid-rows-3 overflow-hidden">
                          <div>
                            <FontAwesomeIcon
                              icon={faHeart}
                              className="text-hiperorange px-1"
                              style={{ fontSize: "20px" }}
                            />
                            <h3 className="text-xs font-bold inline">
                              {notificacion.Titulo}
                            </h3>
                          </div>
                          <div className="text-xs font-light">
                            {notificacion.Mensaje}
                          </div>
                          <div className="text-xs font-bold">
                            {notificacion.Fecha}
                          </div>

                          <div className="grid grid-cols-2 gap-2 mb-2 text-white">
                            <button
                              label="Rechazar"
                              className="flex justify-center items-center py-2 px-4  w-full rounded-lg text-black hover:text-white bg-hiperorange_claro hover:bg-hiperorange_claro_hover  transition-colors duration-300 font-medium"
                              onClick={() => rechazar(notificacion.Id)}
                            >
                              Rechazar
                            </button>
                            <Link to={notificacion.Entidad} reloadDocument>
                              <button
                                label="Ver"
                                className="flex justify-center items-center py-2 px-4  w-full rounded-lg text-white hover:text-white bg-hiperorange hover:bg-hiperorange_hover  transition-colors duration-300 font-medium"
                                onClick={() =>
                                  show_notificacion(
                                    notificacion.Id,
                                    notificacion.Entidad
                                  )
                                }
                              >
                                Ver
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="flex flex-col gap-4 justify-center items-center mt-2 min-h-60 text-gray-400">
                  <FontAwesomeIcon icon={faMessage} size="6x" />
                  <div className="text-xl">
                    No hay notificaciones para mostrar
                  </div>
                </div>
              )}
            </>
            {/* )} */}
          </div>
        </div>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  notificaciones: state.Notification.notificaciones,
  isFetching: state.Notification.isFetching,
  profile: state.Profile.profile,
});

export default connect(mapStateToProps, {
  get_notificaciones,
  delete_notificacion,
  show_notification,
  actualizar_tokenfb_usuario,
})(Notificacion);
