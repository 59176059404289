import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import "../../assets/styles/globalColorPrincipal.css";
import { default as convertSlug, default as slug } from "../../components/Utils/slug";
import { get_categorias, get_subcategorias } from "../../redux/actions/home";
import useScreenSize from "../../widgets/useScreenSize";

const Subcategoria = ({
  get_categorias,
  categorias,
  sucursal,
  ps_categoria,
  get_subcategorias,
  cambio_sucursal,
  set_all_prod_category,
  reset,
  handleMouseLeave,
  handleItemMouseEnter,
  activeSubMenu,
}) => {
  const params = useParams();
  const { width } = useScreenSize();
  let IdSucursal = sucursal.IdSucursal;
  let IdMarket = sucursal.IdMarket;
  const [subcategorias, setSubcategorias] = useState([]);
  const [catselec, setCatselec] = useState(null);
  const { ciudadp, sucursalp, IdCategoria, subcategoriaId } = params;
  const [url_navigation, setUrl_navigationl] = useState(null);
  const [positionWidth, setPositionWidth] = useState(0);
  const [countGrid, setCountGrid] = useState(1);

  let categoria_slug = useMemo(
    () => convertSlug.data_slug(ps_categoria.Descripcion),
    [ps_categoria.Descripcion]
  );

  useEffect(() => {
    if (width < 768) {
      setCountGrid(1);
    }
    if (width >= 768 && width < 991.98) {
      setCountGrid(2);
    }
    if (width >= 991.98) {
      setCountGrid(3);
    }
  }, [width]);

  useEffect(() => {
    if (categorias.length === 0) {
      get_categorias(IdSucursal, IdMarket);
    }
  }, [categorias, IdSucursal, IdMarket]);

  useEffect(() => {
    if (subcategorias.length === 0 && categorias.length > 0) {
      cargarSubcategoria();
    }
  }, [subcategorias]);

  useEffect(() => {
    setSubcategorias([]);
  }, [cambio_sucursal]);

  const outCategoria = async () => {
    setCatselec(null);
  };

  const obtenerDatos = async (e) => {
    let value = e.clientX / width;
    setPositionWidth(value);
    setCatselec(ps_categoria.IdCategoria);
  };

  const convert_url = async () => {
    let resp = ciudadp + " " + sucursalp;
    let new_url = convertSlug.data_barra(resp);

    let url_parameters = new_url;
    return url_parameters;
  };

  async function cargar_url_var() {
    let url_bas = await convert_url();
    setUrl_navigationl(url_bas);
  }

  useEffect(() => {
    cargar_url_var();
  }, [ciudadp, sucursalp]);

  function cargarSubcategoria() {
    var aux = [];
    categorias.forEach((category) => {
      category.SubCategorias.forEach((subcate) => {
        if (subcate.IdCategoria === ps_categoria.IdCategoria) {
          if (siExiste(subcate.IdSubcategoria)) {
            aux.push(subcate);
          }
        }
      });
    });
    setSubcategorias(aux);
  }

  function siExiste(Idsubcategoria) {
    let proAux = subcategorias.find(
      (item) => item.IdSubcategoria === Idsubcategoria
    );
    if (typeof proAux === "undefined" || proAux === null) {
      return true;
    }
    return false;
  }

  const isCategorySelected = slug.data_slug(ps_categoria.Descripcion) === IdCategoria;

  return (
    <>
      <nav className="block mainnav bg-hiperorange">
        <ul className="list-none m-0 px-0 pb-0 pt-1">
          <li
            className={classNames(
              "relative block my-0 rounded-t-md text-[14px] home mx-0.5 text-opacity-90 focus:outline-none items-center font-medium  hover:text-opacity-100 cursor-pointer transition duration-300 ease-out hover:ease-in group",
              {
                "bg-white text-black":
                  catselec === ps_categoria.IdCategoria || isCategorySelected,
                "text-white": !(
                  catselec === ps_categoria.IdCategoria || isCategorySelected
                ),
              }
            )}
            onMouseEnter={(e) => {
              obtenerDatos(e);
              handleItemMouseEnter(ps_categoria);
            }}
            onMouseLeave={() => {
              outCategoria();
              handleMouseLeave();
            }}
          >
            <Link
              to={`/${url_navigation}/categoria/${categoria_slug}`}
              onClick={() => {
                handleMouseLeave();
              }}
              className="text-inherit block px-3 py-2.5"
            >
              {ps_categoria.Descripcion}
            </Link>
            <div
              className={classNames(
                "submenu rounded-b-lg absolute bg-white z-30 w-auto top-[36px] text-left opacity-0 invisible block no-underline p-0 leading-4 transition duration-200 ease-in-out",
                {
                  "left-0": positionWidth <= 0.55,
                  "right-0": positionWidth > 0.55,
                  "group-hover:visible group-hover:opacity-100 group-hover:filter":
                    activeSubMenu?.IdCategoria === ps_categoria?.IdCategoria,
                }
              )}
            >
              <div className="rounded-lg rounded-t-none shadow-md p-2 bg-white">
                <ul
                  className="list-none m-0 p-0"
                  style={{
                    display: "grid",
                    gridTemplateColumns: `repeat(${countGrid},1fr)`,
                    gridGap: "14px",
                    width: "min-content",
                  }}
                >
                  {subcategorias.map((item_subcate_list) => {
                    const isSelected =
                      slug.data_slug(item_subcate_list.Descripcion) ===
                      subcategoriaId;
                    return (
                      <li
                        key={item_subcate_list.IdSubcategoria}
                        className={classNames(
                          "relative text-black p-1 transition duration-150 ease-in-out focus:outline-none focus-visible:ring cursor-pointer hover:underline flex items-center font-semibold ml-0",
                          { underline: isSelected }
                        )}
                        style={{
                          width: "max-content",
                        }}
                      >
                        <Link
                          to={`/${url_navigation}/categoria/${categoria_slug}/${convertSlug.data_slug(
                            item_subcate_list.Descripcion
                          )}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleMouseLeave();
                          }}
                          style={{ marginLeft: "10px" }}
                          className="text-inherit ml-2.5"
                        >
                          {item_subcate_list.Descripcion}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </>
  );
};

const mapStateToProps = (state) => ({
  cambio_sucursal: state.BranchOffice.cambio_sucursal,
  sucursal: state.BranchOffice.sucursal,
  categorias: state.Home.categorias,
});

export default connect(mapStateToProps, {
  get_categorias,
  get_subcategorias,
})(Subcategoria);
