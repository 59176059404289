import { useSelector } from "react-redux";
import { SucursalContactoMediaLink } from "../../domain/interface/sucursal-contacto-media-link";
import { SucursalContacto } from "../../domain/interface/sucursal-contacto";
import { MediaType } from "../../domain/types/media-types";

export function useGetSucursalContactoMediaLinks() {
  const sucursalContacto = useSelector(
    (state: { SucursalContactos: { sucursalContacto: SucursalContacto } }) =>
      state.SucursalContactos.sucursalContacto
  );

  const mediaLinks: Array<{ type: MediaType; url: string | null }> = [
    { type: "facebook", url: sucursalContacto?.FacebookEnlace },
    { type: "instagram", url: sucursalContacto?.InstagramEnlace },
    { type: "youtube", url: sucursalContacto?.YoutubeEnlace },
    { type: "tiktok", url: sucursalContacto?.TikTokEnlace },
    { type: "twitter", url: sucursalContacto?.XEnlace },
  ];

  const sucursalContactoMediaLinks: SucursalContactoMediaLink[] = mediaLinks
    .filter((link) => !!link.url)
    .map((link) => ({ type: link.type, url: link.url! }));

  return { sucursalContactoMediaLinks };
}
