import { MensajeApiError } from "../../../http/application/response/mensaje-api.error";
import { ResponseError } from "../../../http/application/response/response.error";
import { ErrorData } from "../../../http/domain/interfaces/error-data";
import { notifyError as _notifyError } from "../../../notifications/application/notify-error/notify-error";
import { notifyErrorWithData as _notifyErrorWithData } from "../../../notifications/application/notify-error/notify-error-with-data";
import { NotificationService } from "../../../notifications/domain/notification-service";

const DEFAULT_SERVER_ERROR_MESSAGE =
  process.env.REACT_APP_DEFAULT_SERVER_ERROR_MESSAGE ||
  "Hay problemas para conectarse con el servidor. Intente nuevamente en unos momentos.";
const DEFAULT_NOT_FOUND_ERROR_MESSAGE =
  process.env.REACT_APP_DEFAULT_NOT_FOUND_ERROR_MESSAGE ||
  "Ruta o archivo no encontrado.";
const DEFAULT_UNAUTHORIZED_ERROR_MESSAGE =
  process.env.REACT_APP_DEFAULT_UNAUTHENTICATED_ERROR_MESSAGE ||
  "Usuario no authorizado. Redireccionando a la plataforma de autenticación.";
const DEFAULT_METHOD_NOT_ALLOWED =
  process.env.REACT_APP_DEFAULT_METHOD_NOT_ALLOWED_ERROR_MESSAGE ||
  "No tienes permisos para completar la acción.";

enum ErrorStatusCode {
  UNAUTHORIZED = 401,
  METHOD_NOT_ALLOWED = 403,
  NOT_FOUND = 404,
  SERVER_ERROR = 500,
  MENSAJE_API_ERROR = 999,
}

// const authenticationRepository = createAuthenticationRepository();
// const authorizationRepository = createApiAuthorizationRepository();

export function useHandleErrors(notificationService: NotificationService): {
  handleError: ({
    error,
    message,
    data,
  }: {
    error: unknown;
    message: string;
    data?: ErrorData;
  }) => void;
} {
  // const { logout } = useAuthenticationLogout(
  // 	authenticationRepository,
  // 	authorizationRepository
  // );
  const handleError = ({
    error,
    message,
    data,
  }: {
    error: unknown;
    message: string;
    data?: ErrorData;
  }): void => {
    const notifyError = _notifyError(notificationService);
    const notifyErrorWithData = _notifyErrorWithData(notificationService);
    let messageToShow = message;
    let status: number = -1;
    if (error instanceof Error) {
      messageToShow += ` ${error}`;
    }
    if (error instanceof ResponseError) {
      status = error.status;
    }
    if (error instanceof MensajeApiError) {
      status = ErrorStatusCode.MENSAJE_API_ERROR;
    }

    switch (status) {
      case ErrorStatusCode.UNAUTHORIZED:
        notifyError(DEFAULT_UNAUTHORIZED_ERROR_MESSAGE);
        // logout();
        break;
      case ErrorStatusCode.NOT_FOUND:
        notifyError(DEFAULT_NOT_FOUND_ERROR_MESSAGE);
        break;
      case ErrorStatusCode.SERVER_ERROR:
        notifyError(DEFAULT_SERVER_ERROR_MESSAGE);
        break;
      case ErrorStatusCode.METHOD_NOT_ALLOWED:
        notifyError(DEFAULT_METHOD_NOT_ALLOWED);
        break;
      case ErrorStatusCode.MENSAJE_API_ERROR:
        if (data) {
          notifyErrorWithData(messageToShow, data);
        } else {
          notifyError(messageToShow);
        }
        break;
      default:
        notifyError(DEFAULT_SERVER_ERROR_MESSAGE);
        break;
    }
  };
  return {
    handleError,
  };
}
