import React, { useEffect, useState } from "react";

interface SpinnerProps {
  segundos: number;
  onTimeEnd: () => void;
}

const Spinner: React.FC<SpinnerProps> = ({ segundos, onTimeEnd }) => {
  const [progress, setProgress] = useState<number>(0);
  const [secondsLeft, setSecondsLeft] = useState<number>(segundos); // Tiempo inicial en segundos

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 100 / (segundos * 10); // Ajusta el incremento en función de la duración
        const newSecondsLeft = Math.max(
          segundos - (newProgress / 100) * segundos,
          0
        ); // Calcula segundos restantes
        setSecondsLeft(newSecondsLeft);

        if (newProgress >= 100) {
          clearInterval(intervalId);
          onTimeEnd(); // Ejecuta la función pasada por props
          setProgress(0); // Reinicia el progreso
          setSecondsLeft(secondsLeft); // Reinicia el contador de segundos
        }

        return newProgress;
      });
    }, 100); // Intervalo de 100 ms

    //
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        clearInterval(intervalId);
        onTimeEnd(); // Ejecuta la función pasada por props
        setProgress(0); // Reinicia el progreso
        setSecondsLeft(1); // Reinicia el contador de segundos
      } else {
        clearInterval(intervalId); // Pausa el temporizador cuando la página se oculta
      }
    };

    // Escucha el evento de cambio de visibilidad
    document.addEventListener("visibilitychange", handleVisibilityChange);
    //

    return () => {
      clearInterval(intervalId); // Limpia el intervalo al desmontar el componente
      document.removeEventListener("visibilitychange", handleVisibilityChange); // Elimina el event listener
    };
  }, [segundos, onTimeEnd]);

  return (
    <div className="relative w-14 h-14 flex items-center justify-center text-xl font-semibold">
      <div
        className="absolute inset-0 rounded-full"
        style={{
          background: `conic-gradient(from 0deg, white ${
            0 + progress
          }%, #86AB89 ${0 + progress}%)`,
          mask: "radial-gradient(closest-side, transparent calc(100% - 8px), black calc(100% - 8px))",
          WebkitMask:
            "radial-gradient(closest-side, transparent calc(100% - 8px), black calc(100% - 8px))",
          transition: "background 1s linear",
        }}
      ></div>
      {Math.ceil(secondsLeft)}
    </div>
  );
};

export default Spinner;
