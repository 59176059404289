import classNames from "classnames";

interface CustomerBagHistoryCardProps {
  descripcionBolsa: string;
  saldoPuntos: number;
}
export function CustomerBagHistoryCard({
  descripcionBolsa,
  saldoPuntos,
}: CustomerBagHistoryCardProps) {
  return (
    <div
      className={classNames(
        "flex rounded-xl border-1 shadow-md w-full bg-white text-black p-3"
      )}
    >
      <div className="flex flex-col gap-1 w-full">
        <div className="w-full truncate text-left font-semibold text-hiperorange">
          Promoción
        </div>
        <div className="w-full truncate text-left font-semibold text-lg">
          {descripcionBolsa}
        </div>
      </div>
      <div className="flex flex-col gap-1 items-center">
        <div className="w-full truncate text-center font-semibold text-hiperorange">
          Hiper puntos
        </div>
        <div className="min-w-14 h-14 p-2 text-lg font-semibold bg-hiperorange text-white flex items-center justify-center rounded-full">
          {saldoPuntos}
        </div>
      </div>
    </div>
  );
}
