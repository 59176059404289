import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import {
  get_fechas_entrega_sucursal_carrito,
  update_list_carrito_compras,
  save_horarios,
  get_horarios_disponible_carrito,
  store_carrito_data,
} from "../../../redux/actions/carrito";
import ItemHorario from "../../../widgets/item_Horario";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoped,
  faMotorcycle,
  faStore,
} from "@fortawesome/pro-solid-svg-icons";
import { set_tipo_entrega } from "../../../redux/actions/branch_office";
import "../../../assets/styles/responsive.scss";
import ButtonClose from "../../../widgets/button_close";
import { CarritoResumen } from "./carrito_resumen";
import { useThousandSeparator } from "../../../hooks/useThousandSeparator";

const ModoEntrega = ({
  mt_indice,
  sucursal,
  tipo_entrega,
  set_tipo_entrega,
  list_carrito,
  fechas_disponibles_entrega,
  get_fechas_entrega_sucursal_carrito,
  horarios_disponibles,
  get_horarios_disponible_carrito,
  update_list_carrito_compras,
  save_horarios,
  respuesta_update_horario,
  width,
  height,
  exit,
  store_carrito_data,
  direccion,
}) => {
  const { numberFormatter } = useThousandSeparator();

  const [token] = useState(localStorage.getItem("access_token"));
  const [tipo_entregas, guardarTipoEntrega] = useState([]);
  const [subPedidos, guardarsubPedidos] = useState([]);
  const [aux_list_fechas, setAux_list_fechas] = useState([]);
  const [items_selecionados, setItems_selecionados] = useState([]);
  const [list_horarios, setList_horarios] = useState([]);
  const [list_selec_fechas, setList_selec_fechas] = useState([]);
  const [cargado_fecha, GuardarCargadoFecha] = useState(false);

  const costoEnvio = items_selecionados?.reduce((total, item) => {
    return total + item.Costo || 0;
  }, 0);
  const [dias, setDias] = useState([
    {
      name: "Lunes",
      cod: "LU",
    },
    {
      name: "Martes",
      cod: "MA",
    },
    {
      name: "Miércoles",
      cod: "MI",
    },
    {
      name: "Jueves",
      cod: "JU",
    },
    {
      name: "Viernes",
      cod: "VI",
    },
    {
      name: "Sábado",
      cod: "SA",
    },
    {
      name: "Domingo",
      cod: "DO",
    },
  ]);
  const [meses, setMeses] = useState([
    {
      name: "en.",
      cod: "01",
    },
    {
      name: "feb",
      cod: "02",
    },
    {
      name: "mar.",
      cod: "03",
    },
    {
      name: "abr.",
      cod: "04",
    },
    {
      name: "may.",
      cod: "05",
    },
    {
      name: "jun.",
      cod: "06",
    },
    {
      name: "jul.",
      cod: "07",
    },
    {
      name: "ag.",
      cod: "08",
    },
    {
      name: "sept.",
      cod: "09",
    },
    {
      name: "oct.",
      cod: "10",
    },
    {
      name: "nov.",
      cod: "11",
    },
    {
      name: "dic.",
      cod: "12",
    },
  ]);

  const formatNumber = (value) => {
    return numberFormatter.format(value);
  };

  const _ahorroTotal = () => {
    let total = 0;
    subPedidos.forEach((sp) => {
      if (sp.check) {
        total += Number(sp.ahorro);
      }
    });
    return formatNumber(total);
  };
  const ahorro_total = _ahorroTotal();
  useEffect(() => {
    if (sucursal !== null) {
      guardarTipoEntrega(sucursal.TipoEntregas);
    }
  }, [sucursal]);
  useEffect(() => {
    if (fechas_disponibles_entrega && subPedidos.length > 0) {
      GuardarCargadoFecha(true);
      let aux_fecha = fechas_disponibles_entrega.map((item) => {
        let sucursal = subPedidos.find((ps) => ps.Sala.Id === item.IdSucursal);
        let dia = item.Dias[0];

        return {
          ...item,
          Importe: sucursal.ImporteSubtotal,
          seleccionado_id: dia.IdFecha,
          fecha_selec: dia.Fecha,
        };
      });
      setAux_list_fechas(aux_fecha);
    }
  }, [fechas_disponibles_entrega]);

  useEffect(() => {
    if (horarios_disponibles && fechas_disponibles_entrega) {
      if (horarios_disponibles.Horarios.length > 0) {
        colocar_lista_horario_en_sucursal(horarios_disponibles);
        let horario = horarios_disponibles.Horarios[0];

        cambiarSeleccion(
          horario.IdHorario,
          horario.IdHorarioEspecial,
          horarios_disponibles.IdSucursal,
          horario.CostoDelivery,
          horario.Costo
        );
      }
    }
  }, [horarios_disponibles]);

  useEffect(() => {
    async function cargarHorarioAsyn() {
      if (aux_list_fechas.length > 0) {
        GuardarCargadoFecha(true);
        await cargarHorario();
      }
    }
    cargarHorarioAsyn();
  }, [aux_list_fechas, tipo_entrega]);

  const actualizarTipoEntrega = (item_tipo_entrega) => {
    set_tipo_entrega(item_tipo_entrega);
    let objetoClonado = Object.assign({}, list_carrito);
    let listAux = {
      IdPedidoPrincipal: objetoClonado.IdPedidoPrincipal,
      IdUbicacion: objetoClonado.IdUbicacion,
      ImporteTotal: objetoClonado.ImporteTotal,
      InstruccionDelivery: objetoClonado.InstruccionDelivery,
      InstruccionEntrega: objetoClonado.InstruccionEntrega,
      NroTotalProductos: objetoClonado.NroTotalProductos,
      SubPedidos: subPedidos.map((subpedido) => ({
        ...subpedido,
        IdTipoEntrega: item_tipo_entrega.Id,
      })),
    };
    update_list_carrito_compras(listAux);
    for (let item of subPedidos) {
      let params = {
        IdMarket: item.IdMarket,
        IdSucursal: item.Sala.Id,
        IdTipoEntrega: item_tipo_entrega.Id,
        IdDireccion: direccion.Id,
      };
      let carritos_aux = item.Productos;

      store_carrito_data(params, carritos_aux, []);
    }
  };

  useEffect(() => {
    if (list_carrito) {
      let subPedido = list_carrito.SubPedidos.filter((pedido) => pedido.check);
      guardarsubPedidos(subPedido);
    }
  }, [list_carrito]);
  useEffect(() => {
    async function cargarFechaAsyn() {
      if (!cargado_fecha) {
        await cargarFecha();
      }
    }

    cargarFechaAsyn();
  }, [subPedidos]);
  useEffect(() => {
    if (!cargado_fecha) {
      cargarFecha();
    }
  }, [tipo_entrega]);

  const cargarFecha = async () => {
    if (!cargado_fecha) {
      if (subPedidos.length > 0) {
        GuardarCargadoFecha(true);
        let sucursal_ids = subPedidos.map((item) => item.Sala.Id);
        await get_fechas_entrega_sucursal_carrito(
          tipo_entrega.Id,
          sucursal_ids
        );
      }
    }
  };

  const cargarHorario = () => {
    aux_list_fechas.forEach(async (item) => {
      try {
        let dia = item.Dias[0];
        let data = {
          IdSucursal: item.IdSucursal,
          FechaEntrega: dia.Fecha,
          IdFecha: dia.IdFecha,
          Importe: item.Importe,
          IdTipoEntrega: tipo_entrega.Id,
        };
        await get_horarios_disponible_carrito(data);
      } catch (e) {}
    });

    setItems_selecionados(aux_list_fechas);
  };

  useEffect(() => {
    if (respuesta_update_horario) {
      if (!respuesta_update_horario.ConError) {
        guardar_lista();
        if (tipo_entrega.Id === 1) {
          mt_indice(4);
        } else {
          mt_indice(3);
        }
      }
    }
  }, [respuesta_update_horario]);

  const guardar_lista = () => {
    let objetoClonado = Object.assign({}, list_carrito);
    let listAux = {
      IdPedidoPrincipal: objetoClonado.IdPedidoPrincipal,
      IdUbicacion: objetoClonado.IdUbicacion,
      ImporteTotal: objetoClonado.ImporteTotal,
      InstruccionDelivery: objetoClonado.InstruccionDelivery,
      InstruccionEntrega: objetoClonado.InstruccionEntrega,
      NroTotalProductos: objetoClonado.NroTotalProductos,
      SubPedidos: subPedidos.map((subpedido) => {
        const costoEnvio = items_selecionados?.find(
          (item) => item.IdSucursal === subpedido.Sala.Id
        );
        return {
          ...subpedido,
          CostoEnvio: costoEnvio
            ? costoEnvio.CostoDelivery
            : subpedido.CostoEnvio,
        };
      }),
    };
    update_list_carrito_compras(listAux);
  };

  const existe_lista_horarios = () => {
    return typeof list_horarios != "undefined";
  };

  const get_dia = (fecha) => {
    var aux = fecha.split("/");
    return aux[0];
  };

  const get_dia_litera = (fecha) => {
    let aux = fecha.split(" ");
    let d = dias_text(aux[0]);
    if (d) {
      return d;
    }
    return aux[0];
  };

  const dias_text = (dia) => {
    let di = dias.find((item) => item.cod === dia);
    if (di) {
      return di.name;
    }
    return dia;
  };

  const tiene_elementos_aux_list_fechas = () => {
    return typeof aux_list_fechas != "undefined";
  };

  const seleccionarfecha = async (fechaid, sala_id, importe) => {
    let index_lista = aux_list_fechas
      .map((itemf) => itemf.IdSucursal)
      .indexOf(sala_id);
    let lista = aux_list_fechas[index_lista].Dias;
    let index = lista.map((item) => item.IdFecha).indexOf(fechaid);
    aux_list_fechas[index_lista].seleccionado_id = fechaid;
    setAux_list_fechas(aux_list_fechas);
    let copyarray = aux_list_fechas.slice();
    copyarray[index_lista].fecha_selec = lista[index].Fecha;
    setItems_selecionados(copyarray);
    let res = await get_lista_horarios_fecha(
      token,
      sala_id,
      lista[index].Fecha,
      fechaid,
      importe,
      tipo_entrega.Id
    );
    colocar_lista_horario_en_sucursal(res);
  };

  const colocar_lista_horario_en_sucursal = (horario) => {
    if (horario != null) {
      let existe_horario = list_horarios.find(
        (list_hr) => list_hr.IdSucursal === horario.IdSucursal
      );
      if (existe_horario) {
        setList_horarios(
          list_horarios.map((listahr) => {
            if (listahr.IdSucursal === horario.IdSucursal) {
              return {
                ...listahr,
                IdSucursal: horario.IdSucursal,
                IdFecha: horario.IdFecha,
                horarios: horario.Horarios,
              };
            } else {
              return {
                ...listahr,
              };
            }
          })
        );
      } else {
        var dato = {
          IdSucursal: horario.IdSucursal,
          IdFecha: horario.IdFecha,
          horarios: horario.Horarios,
        };
        setList_horarios((list_horarios) => [...list_horarios, dato]);
      }
    }
  };

  const get_lista_horarios_fecha = async (
    token,
    idsucursal,
    fecha_entrega,
    idfecha,
    importe,
    tipoentregaid
  ) => {
    let config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      let res = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/api/v1/pedidosmarket/carrito/horarios-disponible?IdSucursal=${idsucursal}&FechaEntrega=${fecha_entrega}&IdFecha=${idfecha}&Importe=${Number(
          importe
        )}&IdTipoEntrega=${tipoentregaid}`,
        config
      );

      if (res.status === 200) {
        return res.data.Dato;
      }
    } catch (err) {}
  };

  const save_datos_entrega = async (e) => {
    if (list_selec_fechas.length === 0) {
      mensaje_alert("debe seleccionar horarios");
      return;
    }
    await cargar_horarios(list_selec_fechas);
  };

  const mensaje_alert = (text) => {
    Swal.fire({
      icon: "warning",
      text: text,
      showConfirmButton: true,
      confirmButtonText: "Cerrar",
      confirmButtonColor: "#32338E",
    });
  };

  const cargar_horarios = async (list_selec_fechas) => {
    guardarsubPedidos(
      subPedidos.map((ps) => {
        if (ps.check && ps.check_activo) {
          let data_fecha = list_selec_fechas.find(
            (list_date) => list_date.IdSucursal === ps.Sala.Id
          );
          let is_horario = false;
          if (
            data_fecha.seleccionado_id !== "" &&
            data_fecha.horarioId !== "" &&
            data_fecha.IdHorarioEspecial !== ""
          ) {
            is_horario = true;
          }
          let Horario = {
            IdFecha: "",
            IdHorario: "",
            IdHorarioEspecial: "",
          };
          if (is_horario) {
            Horario = {
              IdFecha: data_fecha.seleccionado_id,
              IdHorario: data_fecha.horarioId,
              IdHorarioEspecial: data_fecha.IdHorarioEspecial,
            };
          }

          return {
            ...ps,
            Horario: Horario,
            is_horario: is_horario,
          };
        } else {
          return {
            ...ps,
          };
        }
      })
    );

    let aux = [];
    for (let item of list_selec_fechas) {
      let datos = {
        IdSucursal: item.IdSucursal,
        IdFecha: item.seleccionado_id,
        IdHorario: item.horarioId,
        IdHorarioEspecial: item.IdHorarioEspecial,
      };

      aux.push(datos);
    }

    const respuesta = await save_horarios(aux);
  };

  const is_item_check = (h1) => {
    let item = list_selec_fechas.find(
      (lista) => lista.horarioId === h1.IdHorario
    );
    if (item) {
      return true;
    } else {
      return false;
    }
  };

  const cambiarSeleccion = (
    horarioId,
    IdHorarioEspecial,
    IdSucursal,
    CostoDelivery,
    Costo
  ) => {
    let newItemsSeleccionados = items_selecionados.slice();

    let index = newItemsSeleccionados
      .map((item) => item.IdSucursal)
      .indexOf(IdSucursal);
    if (index !== -1) {
      newItemsSeleccionados[index].horarioId = horarioId;
      newItemsSeleccionados[index].IdHorarioEspecial = IdHorarioEspecial;
      newItemsSeleccionados[index].CostoDelivery = CostoDelivery;
      newItemsSeleccionados[index].Costo = Costo;
    }
    setList_selec_fechas(newItemsSeleccionados);
  };

  const volverAtras = () => {
    mt_indice(1);
  };

  const get_total_carrito = () => {
    let total = 0;
    let precio_no_disponible = 0;
    subPedidos.forEach((sp) => {
      if (sp.check) {
        sp.Productos.forEach((prod) => {
          if (!prod.Disponible) {
            precio_no_disponible += prod.Importe;
          }
        });
      }
    });

    subPedidos.forEach((sp) => {
      if (sp.check) {
        total += sp.ImporteSubtotal;
      }
    });

    let result = parseFloat(total) - parseFloat(precio_no_disponible);
    return formatNumber(Number(result));
  };

  function get_mes(fecha) {
    var aux = fecha.split("/");
    let m = mes_text(aux[1]);
    if (m) {
      return m;
    }
    return m;
  }

  const mes_text = (mes) => {
    let me = meses.find((item) => item.cod === mes);
    if (me) {
      return me.name;
    }
    return me;
  };

  return (
    <>
      <div className={classNames("w-full md:w-[60%] flex flex-col h-full")}>
        <div className="w-full bg-white rounded-t-lg md:rounded-lg h-full overflow-hidden">
          <div className="flex flex-col h-full">
            <div className="bg-hiperorange w-full text-white flex items-center justify-center  content_title relative">
              <h2 className="p-0 m-0 title_carrito_responsivo ">
                Modo de entrega
              </h2>
              <div className="absolute right-2 ">
                <ButtonClose
                  className="text-white bg-transparent"
                  onClick={() => exit()}
                />
              </div>
            </div>

            <div
              className={classNames(
                "bg-white overflow-y-auto flex flex-col h-full"
              )}
            >
              <div className=" w-auto overflow-auto flex flex-col h-full">
                <div className="my-2 mx-2 p-2 bg-hiperplomo_claro rounded-hiper_g">
                  <p className="text-black text-md md:text-base mb-1 font-bold">
                    Selecciona el modo de entrega
                  </p>

                  <div className="flex flex-wrap gap-2">
                    {tipo_entregas &&
                      tipo_entregas !== null &&
                      tipo_entregas !== undefined &&
                      tipo_entregas
                        .filter(
                          (x) =>
                            x.Id !=
                            process.env.REACT_APP_TIPO_ENTREGA_COMPRA_EN_SALA
                        )
                        .map((item_tipo_entrega, index) => (
                          <button
                            key={index}
                            className={classNames(
                              "border-1 font-medium rounded-lg flex items-center gap-2 px-2 py-1 text-sm text-hiperplomo_fuerte hover:bg-hiperorange hover:text-white hover:border-hiperorange leading-none",
                              {
                                "bg-hiperorange text-white border-hiperorange":
                                  item_tipo_entrega.Id ===
                                  (tipo_entrega != null && tipo_entrega.Id),
                                "border-black":
                                  item_tipo_entrega.Id !==
                                  (tipo_entrega != null && tipo_entrega.Id),
                              }
                            )}
                            onClick={() => {
                              actualizarTipoEntrega(item_tipo_entrega);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={
                                item_tipo_entrega.Id === 2 ? faMoped : faStore
                              }
                              style={{ height: "20px" }}
                              className="hover:text-white "
                            />
                            <span>{item_tipo_entrega.Descripcion}</span>
                          </button>
                        ))}
                  </div>
                </div>

                {subPedidos &&
                  subPedidos !== null &&
                  subPedidos !== undefined &&
                  subPedidos.map((pedido, index2) => (
                    <div
                      key={index2}
                      className="mx-2 last-of-type:mb-32 md:last-of-type:mb-0"
                    >
                      {pedido.check && (
                        <div className="bg-hiperplomo_claro rounded-hiper_g my-1 px-2 pt-2 pb-4">
                          <div className="mb-2">
                            <div className="inline-block text-md md:text-base text-black font-bold">
                              <span className="text-uppercase">
                                {pedido.TipoServicio.Descripcion}{" "}
                              </span>
                              {pedido.Sala.Descripcion}
                            </div>
                          </div>

                          <div className="bg-white p-2  rounded-hiper_g  mb-2">
                            <div className="text-start ">
                              <p className="text-black font-bold mb-2">
                                Selecciona el día de tu entrega
                              </p>
                            </div>
                            <div>
                              {tiene_elementos_aux_list_fechas() ? (
                                <>
                                  {aux_list_fechas.map((item, index3) => (
                                    <div key={index3 + "2c1"}>
                                      {item.IdSucursal === pedido.Sala.Id && (
                                        <div className="flex flex-wrap gap-2 ">
                                          {item.Dias.map((fecha) => (
                                            <button
                                              className={classNames(
                                                "rounded-hiper_g mx-1 px-2 transition ease-in-out delay-150 hover:scale-110 duration-300",
                                                {
                                                  "bg-hiperorange text-white border-1 border-hiperorange":
                                                    item.seleccionado_id ===
                                                    fecha.IdFecha,
                                                  "bg-white  border-1 border-black ":
                                                    !(
                                                      item.seleccionado_id ===
                                                      fecha.IdFecha
                                                    ),
                                                }
                                              )}
                                              style={{
                                                cursor: "pointer",
                                                width: "5rem",
                                                height: "5rem",
                                                willChange: "",
                                              }}
                                              onClick={async (e) =>
                                                await seleccionarfecha(
                                                  fecha.IdFecha,
                                                  pedido.Sala.Id,
                                                  item.Importe
                                                )
                                              }
                                              key={fecha.IdFecha}
                                            >
                                              <div
                                                className={`text-[12px] d-block font-bold `}
                                              >
                                                {get_dia_litera(fecha.Titulo)}
                                              </div>
                                              <div
                                                className={`text-[12px] d-block font-bold `}
                                              >
                                                {get_dia(fecha.Fecha)}{" "}
                                                {get_mes(fecha.Fecha)}{" "}
                                              </div>
                                            </button>
                                          ))}
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>

                          <div className="h-[calc(100%-4.5rem)] overflow-y-auto mt-2 bg-white p-2">
                            <div className="text-start py-1">
                              <p className="text-black font-bold mb-2">
                                Selecciona la franja horaria
                              </p>
                            </div>
                            {tipo_entrega?.Id === 2 && (
                              <div className="font-semibold text-right pr-2 mb-1">
                                Costo envío
                              </div>
                            )}
                            {existe_lista_horarios() ? (
                              <>
                                {list_horarios.map((horario, key) => (
                                  <div
                                    key={"key" + key}
                                    className="flex flex-col gap-1 overflow-hidden "
                                  >
                                    {horario.IdSucursal === pedido.Sala.Id ? (
                                      <>
                                        {horario.horarios.map((hora, index) => {
                                          return (
                                            <ItemHorario
                                              idHorario={hora.IdHorario}
                                              IdHorarioEspecial={
                                                hora.IdHorarioEspecial
                                              }
                                              Descripcion={hora.Descripcion}
                                              CostoDelivery={hora.CostoDelivery}
                                              Costo={hora.Costo}
                                              sucursal={horario.IdSucursal}
                                              tipo_entrega={tipo_entrega}
                                              is_check={is_item_check(hora)}
                                              changeSelec={cambiarSeleccion}
                                              key={"hr" + index}
                                            ></ItemHorario>
                                          );
                                        })}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                ))}
                              </>
                            ) : (
                              <> No hay</>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:w-[22%] sticky bottom-0">
        <CarritoResumen
          costo_delivery={
            items_selecionados?.length === 0
              ? "Calculando"
              : costoEnvio > 0
              ? formatNumber(costoEnvio)
              : "Gratis"
          }
          ahorro_total={ahorro_total}
          total_carrito={get_total_carrito()}
          volverAtras={volverAtras}
          guardarProceso={save_datos_entrega}
          backButtonLabel="Volver"
          nextButtonLabel="Continuar"
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  sucursal: state.BranchOffice.sucursal,
  tipo_entrega: state.BranchOffice.tipo_entrega,
  list_carrito: state.Carrito.list_carrito,
  fechas_disponibles_entrega: state.Carrito.fechas_disponibles_entrega,
  horarios_disponibles: state.Carrito.horarios_disponibles,
  respuesta_update_horario: state.Carrito.respuesta_update_horario,
  direccion: state.Location.direccion,
});

export default connect(mapStateToProps, {
  set_tipo_entrega,
  get_fechas_entrega_sucursal_carrito,
  save_horarios,
  update_list_carrito_compras,
  get_horarios_disponible_carrito,
  store_carrito_data,
})(ModoEntrega);
