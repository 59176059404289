import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import "../../assets/styles/globalColorPrincipal.css";
import "../../assets/styles/scanear.scss";
import Layout from "../../hocs/Layout";
import QrScanner from "qr-scanner";
import Swal from "sweetalert2";
import Alert from "../../components/Swalert";
import ButtonText from "../../widgets/button_text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode } from "@fortawesome/pro-solid-svg-icons";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { useIsDefaultStore } from "../../hooks/useIsDefaultStore";

const ERRORS = {
  "No QR code found": "La imagen no es un código QR",
  DEFAULT: "Hubo un error al leer la imagen",
};

const Escanear = (props) => {
  const sucursal = useSelector((state) => state.BranchOffice.sucursal);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(null);

  const mensaje_alert = (text) => {
    Swal.fire({
      icon: "warning",
      text: text,
      showConfirmButton: true,
      confirmButtonText: "Cerrar",
      confirmButtonColor: "#32338E",
    }).then((result) => {
      if (result.isConfirmed) {
        setFile(null);
        setFileName(null);
      }
    });
  };

  const onDrop = useCallback((acceptedFiles) => {
    const fileImgUno = acceptedFiles[0];
    cargarImgQr(fileImgUno);
  }, []);

  const cargarImgQr = async (fileImg) => {
    try {
      setFile(fileImg);
      setFileName(fileImg.name);
      const result = await QrScanner.scanImage(fileImg);
      window.location.href = result;
    } catch (error) {
      const errorMensaje = ERRORS[error];
      mensaje_alert(errorMensaje || ERRORS.DEFAULT);
    }
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
  });

  const volver = () => {
    window.history.back();
  };

  const { isDefaultStore } = useIsDefaultStore();

  return (
    <>
      <Helmet>
        <title>{`Escanear código | ${
          isDefaultStore ? "Hipermaxi" : sucursal?.Descripcion || ""
        }`}</title>
        {/* <meta name="“robots”" content="noindex" /> */}
      </Helmet>
      <Layout>
        <Alert></Alert>
        <div className="w-full h-full">
          <div className="w-full flex justify-center flex-wrap">
            <div className="w-full text-5xl mt-4 md:mt-10 text-center h-fit mb-4">
              <span className="text-4xl"> Escanear código</span>
            </div>
            <div className="min-w-80 w-1/3 h-80 rounded-lg">
              <div
                {...getRootProps()}
                className="bg-gray-100 flex flex-col gap-4 w-[100%] h-[100%] justify-center items-center rounded-lg border-[1px] border-gray-300 hover:border-[1px] hover:border-hiperorange hover:ring hover:ring-hiperorange_claro"
              >
                <input {...getInputProps()} />
                <FontAwesomeIcon icon={faQrcode} size="6x" />
                {isDragActive ? (
                  <p>Suelte los archivos aquí ...</p>
                ) : (
                  <p>Subir o arrastrar la imagen aquí</p>
                )}
              </div>
              <div className="flex mb-1">
                <aside>
                  <ul>{fileName}</ul>
                </aside>
              </div>
              <div className="flex justify-around flex-col items-center px-4">
                <div className="flex justify-around flex-col gap-2 px-2 text-white">
                  <ButtonText
                    label="Cancelar"
                    principal={false}
                    className="bg-hiperorange_claro hover:bg-hiperorange_claro_hover text-black hover:text-black w-[250px]"
                    onClick={() => volver()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Escanear;
