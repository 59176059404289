// import classNames from "classnames";
// import { useState } from "react";
// import { LazyLoadImage } from "react-lazy-load-image-component";

// export function Image({ src, alt, className, style }) {
//   // const [isLoading, setIsLoading] = useState(true);
//   return (
//     <LazyLoadImage
//       style={style}
//       src={src}
//       alt={alt}
//       className={className}
//       placeholder={
//         <span className="animate-pulse w-full backdrop-blur-lg bg-slate-200/40 text-slate-200/40 rounded-lg" >...</span>
//       }
//       // onLoad={() => setIsLoading(false)}
//     />
//   );
// }

import classNames from "classnames";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useImageCache } from "../hooks/useImageCache";

export function Image({
  src,
  alt,
  className,
  style,
  placeholder = (
    <div className="absolute animate-pulse inset-0 w-full h-full backdrop-blur-lg bg-slate-200/40 text-slate-200/40 rounded-lg" />
  ),
  ...props
}) {
  const { imageSrc } = useImageCache({ src });
  return (
    <>
      {/* {imageSrc ? ( */}
      {!imageSrc && placeholder}
      <LazyLoadImage
        style={style}
        src={imageSrc}
        alt={alt}
        className={classNames(
          `transition-opacity duration-100 ${
            !!imageSrc ? "opacity-100" : "opacity-0"
          }`,
          className
        )}
        placeholder={
          <span className="animate-pulse w-full backdrop-blur-lg bg-slate-200/40 text-slate-200/40 rounded-lg">
            ...
          </span>
        }
        {...props}
      />
      {/* ) : (
        <div
          className={classNames(
            "animate-pulse w-full backdrop-blur-lg bg-slate-200/40 text-slate-200/40 rounded-lg",
            className
          )}
        >
          ...
        </div>
      )} */}
    </>
  );
}
