import { PersistRepository } from "../domain/persist-repository";

export function createLocalStoragePersistRepository(): PersistRepository {
  function setItem(key: string, value: string): void {
    if (typeof window !== "undefined") {
      window.localStorage.setItem(key, value);
    }
  }

  function getItem(key: string): string | null {
    if (typeof window !== "undefined") {
      return window.localStorage.getItem(key);
    }
    return null;
  }

  function removeItem(key: string): void {
    if (typeof window !== "undefined") {
      window.localStorage.removeItem(key);
    }
  }

  return {
    setItem,
    getItem,
    removeItem,
  };
}
