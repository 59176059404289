import Layout from "../../hocs/Layout";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ItemHorario from "../../widgets/item_Horario";
import { connect } from "react-redux";
import { get_lista_carrito_compras } from "../../../src/redux/actions/carrito";
import { get_fechas_entrega_sucursal_carrito } from "../../../src/redux/actions/carrito";
import { save_horarios } from "../../../src/redux/actions/carrito";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const Entrega = ({
  get_lista_carrito_compras,
  list_carrito,
  list_fechas_sucursales,
  get_fechas_entrega_sucursal_carrito,
  save_horarios,
  respuesta_update_horario,
}) => {
  const [token] = useState(localStorage.getItem("access_token"));
  const [aux_list_fechas, setAux_list_fechas] = useState([]);
  const [aux_list_fechas2, setAux_list_fechas2] = useState([]);
  const [items_selecionados, setItems_selecionados] = useState([]);
  const [list_horarios, setList_horarios] = useState([]);
  const [list_selec_fechas, setList_selec_fechas] = useState([]);
  const [opcionselec, setOpcionselec] = useState("delivery");

  const navigate = useNavigate();
  const [lista_meses] = useState([
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ]);
  useEffect(() => {
    async function fetchData() {
      try {
        const res = await get_lista_carrito_compras(token);
        cargar_fechas();
      } catch (e) {}
    }
    fetchData();
  }, []);
  
  const get_tipo_entrega = () => {
    if (opcionselec == "delivery") {
      return 1;
    }
    return opcionselec == "sucursal" ? 2 : 0;
  }
  const cargar_fechas = async () => {
    setAux_list_fechas2([]);
    var tipoe = get_tipo_entrega();
    for (var resolvedPromise of list_carrito.SubPedidos) {
      const res = await get_fechas_entrega_sucursal_carrito2(
        token,
        tipoe,
        resolvedPromise.IdMarket
      );

      aux_list_fechas2.push(res[0]);
    }

    setAux_list_fechas([]);
    setAux_list_fechas(aux_list_fechas2);
  }

  const existe_lista_carrito = () => {
    return list_carrito != null ? true : false;
  }
  function existe_lista_horarios() {
    return typeof list_horarios != "undefined";
  }
 
  const get_dia = (fecha) => {
    var aux = fecha.split("/");
    return aux[0];
  }
  const get_dia_litera = (fecha) => {
    var aux = fecha.split(" ");
    return aux[0];
  }

  const get_mes_anio_literal = (fecha) => {
    if (typeof fecha !== "undefined") {
      var aux = fecha.split("/");
      var anio = aux[2];
      var mes_index = aux[1];
      return lista_meses[mes_index - 1] + " " + anio;
    }
    return "";
  }

  const tiene_elementos_aux_list_fechas = () => {
    return typeof aux_list_fechas != "undefined";
  }
  const get_label_fecha = (idsucursal) => {
    if (items_selecionados.length > 0) {
      var index_lista = items_selecionados
        .map((item) => item.IdSucursal)
        .indexOf(idsucursal);
      if (index_lista !== -1) {
        return get_mes_anio_literal(
          items_selecionados[index_lista].fecha_selec
        );
      }
    }

    return "";
  }

  const seleccionarfecha = async (
    fechaid,
    sala_id,
    importe,
    tipoentregaid,
    e
  ) => {
    var index_lista = aux_list_fechas
      .map((itemf) => itemf.IdSucursal)
      .indexOf(sala_id);
    var lista = aux_list_fechas[index_lista].Dias;
    let index = lista.map((item) => item.IdFecha).indexOf(fechaid);
    aux_list_fechas[index_lista].seleccionado_id = fechaid;
    setAux_list_fechas(aux_list_fechas);

    const copyarray = aux_list_fechas.slice();
    copyarray[index_lista].fecha_selec = lista[index].Fecha;
    setItems_selecionados(copyarray);
    var tipoe = 1;
    if (tipoentregaid == "delivery") {
      tipoe = 2;
    }
    if (tipoentregaid == "sucursal") {
      tipoe = 1;
    }
    const res = await get_lista_horarios_fecha(
      token,
      sala_id,
      lista[index].Fecha,
      fechaid,
      importe,
      tipoe
    );
    //
    //
    colocar_lista_horario_en_sucursal(res);
  };
  const colocar_lista_horario_en_sucursal = (horario) => {
    if (horario != null) {
      var copyarray = list_horarios.slice();
      var index = copyarray
        .map((item) => item.IdSucursal)
        .indexOf(horario.IdSucursal);
      var dato = {
        IdSucursal: horario.IdSucursal,
        IdFecha: horario.IdFecha,
        horarios: horario.Horarios,
      };
      if (index === -1) {
        copyarray.push(dato);
      } else {
        copyarray[index] = dato;
      }

      setList_horarios(copyarray);
    }
  }
  const get_fechas_entrega_sucursal_carrito2 = async(
    token,
    tipoentrega,
    idMarket
  ) => {
    let config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/pedidosmarket/fechas-disponible-entrega?IdTipoEntrega=${tipoentrega}&Sucursales[0]=${idMarket}`,
        config
      );
      if (res.status === 200) {
        return res.data.Dato;
      }
    } catch (err) {}
  }

  const get_monto_mycart = () => {
    return localStorage.getItem("monto_mycart");
  }
  const get_lista_horarios_fecha = async (
    token,
    idsucursal,
    fecha_entrega,
    idfecha,
    importe,
    tipoentregaid
  ) => {
    let config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/pedidosmarket/carrito/horarios-disponible?IdSucursal=${idsucursal}&FechaEntrega=${fecha_entrega}&IdFecha=${idfecha}&Importe=${importe}&IdTipoEntrega=${tipoentregaid}`,
        config
      );

      if (res.status === 200) {
        return res.data.Dato;
      }
    } catch (err) {}
  }

  const seleccionar_opcion = (op, e) => {
    setOpcionselec(op);
    cargar_fechas();
    set_tipo_entrega(op);
  };

  const set_tipo_entrega = (op) => {
    let tipo_entregax = 0;
    if (op == "delivery") {
      tipo_entregax = 2;
    }
    if (op == "sucursal") {
      tipo_entregax = 1;
    }

    localStorage.setItem("tipo_entrega_mycart", tipo_entregax);
  }

  const save_datos_entrega = async (e) => {
    localStorage.setItem("horario", JSON.stringify(list_selec_fechas));
    set_tipo_entrega(opcionselec);
    await cargar_horarios(list_selec_fechas);

    if (
      typeof respuesta_update_horario != "undefined" &&
      respuesta_update_horario != null
    ) {
      if (respuesta_update_horario.ConError) {
        for (var item of respuesta_update_horario.Errores) {
          mensaje_alert(item.Mensaje);
        }
        return;
      }
    }

    if (opcionselec == "sucursal") {
      navigate("/forma_pago");
    }
    if (opcionselec == "delivery") {
      navigate("/datos-entrega");
    }
  };

  const mensaje_alert = (text) => {
    Swal.fire({
      icon: "warning",
      text: text,
      showConfirmButton: true,
      confirmButtonText: "Cerrar",
      confirmButtonColor: "#32338E",
    });
  }
  const cargar_horarios =async(list_selec_fechas) => {
    var aux = [];
    for (var item of list_selec_fechas) {
      var datos = {
        IdSucursal: item.IdSucursal,
        IdFecha: item.seleccionado_id,
        IdHorario: item.horarioId,
        IdHorarioEspecial: item.IdHorarioEspecial,
      };

      aux.push(datos);
    }
     await save_horarios(aux);
    return respuesta_update_horario;
  }

  const cambiarSeleccion = (horarioId, IdHorarioEspecial, IdSucursal) => {
    var aux = items_selecionados.slice();
    var index = aux.map((item) => item.IdSucursal).indexOf(IdSucursal);
    if (index != -1) {
      aux[index].horarioId = horarioId;
      aux[index].IdHorarioEspecial = IdHorarioEspecial;
    }

    setList_selec_fechas(aux);
  }
  return (
    <Layout>
      <div className="flex justify-center items-center mt-12">
        <div className="">
          <h2 className="text-center mb-4 text-hiperorange">Delivery</h2>
          <div className="">
            <button
              onClick={(e) => seleccionar_opcion("delivery", e)}
              className={` px-3 border  rounded-lg mx-2 ${
                opcionselec === "delivery"
                  ? "bg-hiperorange text-white "
                  : "text-hiperorange"
              } `}
            >
              Delivery
            </button>
            <button
              onClick={(e) => seleccionar_opcion("sucursal", e)}
              className={`px-3 border border-hiperorange   rounded-lg ${
                opcionselec === "sucursal"
                  ? "bg-hiperorange text-white "
                  : "text-hiperorange"
              } `}
            >
              Retirar de Sucursal
            </button>
          </div>

          {existe_lista_carrito() ? (
            list_carrito.SubPedidos.map((sucursal) => (
              <>
                <div
                  className="sm:w-100 lg:w-[33rem]  h-[27rem] border border-hiperorange shadow rounded-xl"
                  key={sucursal.Sala.Id}
                >
                  <div className="bg-white shadow rounded-t-xl text-hiperblue mb-3 text-sm flex items-center h-[3rem] px-2">
                    <div>
                      <h6 className="mb-0">
                        <span className="text-hiperorange">
                          Fecha de entrega{" "}
                        </span>
                        -{sucursal.Sala.Descripcion}
                      </h6>
                      <span>Selecciona el dia de tu entrega</span>
                    </div>
                  </div>

                  <div className="flex justify-between flex-column h-[calc(100%-4.5rem)]">
                    <div className="p-2 mx-2 border rounded-[1rem] shadow">
                      <div className="flex gap-2">
                        {tiene_elementos_aux_list_fechas() ? (
                          <>
                            {aux_list_fechas.map((item) => (
                              <>
                                {item.IdSucursal == sucursal.Sala.Id ? (
                                  <>
                                    <span
                                      className="text-hiperorange mt-3"
                                      style={{
                                        fontSize: "19px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {get_label_fecha(item.IdSucursal)}{" "}
                                    </span>
                                    {
                                      <div>
                                        {item.Dias.map((fecha, index4) => (
                                          <>
                                            <button
                                              className={`h-[3.5rem] w-12 rounded text-white mx-1 px-2 ${
                                                item.seleccionado_id ===
                                                fecha.IdFecha
                                                  ? "bg-hiperorange"
                                                  : "bg-hipergris"
                                              }   `}
                                              style={{ cursor: "pointer" }}
                                              onClick={async (e) =>
                                                await seleccionarfecha(
                                                  fecha.IdFecha,
                                                  sucursal.Sala.Id,
                                                  get_monto_mycart(),
                                                  opcionselec,
                                                  e
                                                )
                                              }
                                              key={index4}
                                            >
                                              <div className="text-white">
                                                <span
                                                  className={`text-[12px] d-block text-dark font-bold ${
                                                    item.seleccionado_id ===
                                                    fecha.IdFecha
                                                      ? "text-white"
                                                      : "text-dark"
                                                  } `}
                                                >
                                                  {get_dia_litera(fecha.Titulo)}
                                                </span>
                                                <span
                                                  className={`text-[12px] d-block text-dark font-bold ${
                                                    item.seleccionado_id ===
                                                    fecha.IdFecha
                                                      ? "text-white"
                                                      : "text-dark"
                                                  } `}
                                                >
                                                  {get_dia(fecha.Fecha)}
                                                </span>
                                              </div>
                                            </button>
                                          </>
                                        ))}{" "}
                                      </div>
                                    }
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="h-[calc(100%-4.5rem)] overflow-y-auto mt-2">
                      {existe_lista_horarios() ? (
                        <>
                          {list_horarios.map((h) => (
                            <>
                              {h.IdSucursal === sucursal.Sala.Id ? (
                                <>
                                  {h.horarios.map((h1) => (
                                    <>
                                      <ItemHorario
                                        idHorario={h1.IdHorario}
                                        IdHorarioEspecial={h1.IdHorarioEspecial}
                                        Descripcion={h1.Descripcion}
                                        CostoDelivery={h1.CostoDelivery}
                                        Costo={h1.Costo}
                                        sucursal={h.IdSucursal}
                                        changeSelec={cambiarSeleccion}
                                      ></ItemHorario>
                                    </>
                                  ))}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ))}
                        </>
                      ) : (
                        <> No hay</>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ))
          ) : (
            <></>
          )}

          <div className="card-footer d-flex justify-between pt-2 pb-5">
            <Link to="/carrito">
              <button className="bg-hiperblue px-3 border text-white rounded-lg">
                Volver Carrito
              </button>
            </Link>
            <>
              {
                opcionselec == "delivery" ? (
                  <button
                    onClick={(e) => save_datos_entrega(e)}
                    className="bg-green-500 px-3 border  text-white rounded-lg"
                  >
                    Continuar
                  </button>
                ) : (
                  <button
                    onClick={(e) => save_datos_entrega(e)}
                    className="bg-green-500 px-3 border  text-white rounded-lg"
                  >
                    Continuar
                  </button>
                )

                //     <button onClick={ (e) => save_datos_entrega( e)}
                //         className="bg-green-500 px-3 border  text-white rounded-lg">Continuar
                //     </button>
                //    :
                //     <button  onClick={ (e) => save_datos_entrega( e)}
                //         className="bg-green-500 px-3 border  text-white rounded-lg">Continuar
                //     </button>
              }
            </>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  list_carrito: state.Carrito.list_carrito,
  list_fechas_sucursales: state.Carrito.list_fechas_sucursales,
  respuesta_update_horario: state.Carrito.respuesta_update_horario,
});

export default connect(mapStateToProps, {
  get_lista_carrito_compras,
  get_fechas_entrega_sucursal_carrito,
  save_horarios,
})(Entrega);
