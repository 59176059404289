export const buildBreadcrumbListListItemJSONLd = (
  { position, name, item },
  context
) => {
  return {
    "@context": context,
    "@type": "ListItem",
    position,
    name,
    item,
  };
};
export const buildBreadcrumbListJSONLd = ({ itemListElement }, context) => {
  return {
    "@context": context,
    "@type": "BreadcrumbList",
    itemListElement: itemListElement.map((element) =>
      buildBreadcrumbListListItemJSONLd(element)
    ),
  };
};
