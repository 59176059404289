import { responseHandler } from "../../../http/application/response/response-handler";
import { ConfigParametersRepository } from "../../domain/config-parameters-repository";
import { ConfigParametesGetByKeyParameters } from "../../domain/dto/config-parameters-get-by-key-parametes";
import { ConfigParameter } from "../../domain/interface/config-parameter";

export function getConfigParametersByKey(
  customerRepository: ConfigParametersRepository
) {
  return async (
    configParametesGetByKeyParameters: ConfigParametesGetByKeyParameters
  ): Promise<ConfigParameter> => {
    const response = await customerRepository.getByKey(
      configParametesGetByKeyParameters
    );
    return responseHandler<ConfigParameter, null>(response);
  };
}
