import { faWhatsapp, IconDefinition } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import { useSelector } from "react-redux";
import { useGetSucursalContactoEmails } from "../../../sucursal-contactos/ui/hooks/useGetSucursalContactoEmails";
import { useGetSucursalContactoTelefonos } from "../../../sucursal-contactos/ui/hooks/useGetSucursalContactoTelefonos";
import { buildWhatsappLink } from "../utils/build-whatsapp-link";
import { formatPhoneNumber } from "../utils/format-phone-number";

export interface ContactUsLink {
  name: string;
  href: string;
  icon?: IconDefinition;
  value: string;
}

export function useBuildFooterContactUsLinks() {
  const { sucursal } = useSelector((state: any) => state.BranchOffice);
  const { emailConsultas } = useGetSucursalContactoEmails();
  const { telefonoAtencionCliente } = useGetSucursalContactoTelefonos();

  const contactUsLinks: ContactUsLink[] = [];

  if (sucursal?.ContactoWhatsapp) {
    contactUsLinks.push({
      name: "Teléfono sucursal",
      icon: faWhatsapp,
      href: buildWhatsappLink(sucursal.ContactoWhatsapp),
      value: formatPhoneNumber(sucursal.ContactoWhatsapp),
    });
  }

  if (emailConsultas) {
    contactUsLinks.push({
      name: "Consultas",
      icon: faEnvelope,
      href: `mailto:${emailConsultas}`,
      value: emailConsultas,
    });
  }

  if (telefonoAtencionCliente) {
    contactUsLinks.push({
      name: "Atención al cliente",
      icon: faWhatsapp,
      href: buildWhatsappLink(telefonoAtencionCliente),
      value: formatPhoneNumber(telefonoAtencionCliente),
    });
  }

  return { contactUsLinks };
}
