import { isPointInPolygon } from "geolib";
import slug from "../components/Utils/slug";
const LOCATARIOS_TO_FILTER = process.env.REACT_APP_LOCATARIOS_TO_FILTER
  ? JSON.parse(process.env.REACT_APP_LOCATARIOS_TO_FILTER)
  : [];

export function useGetSucursalMasCercana() {
  function filtrarSucursales(sucursales) {
    const sucursalesFiltradas = sucursales ? [...sucursales] : [];
    if (LOCATARIOS_TO_FILTER?.length > 0) {
      for (let index = 0; index < LOCATARIOS_TO_FILTER.length; index++) {
        const locatarioAFiltrar = { ...LOCATARIOS_TO_FILTER[index] };
        const marketIndex = sucursalesFiltradas.findIndex(
          (sucursal) => sucursal.IdMarket === locatarioAFiltrar.IdMarket
        );
        if (marketIndex >= 0) {
          const market = { ...sucursalesFiltradas[marketIndex] };
          const filteredLocatarios = market.Locatarios?.filter(
            (locatario) => locatario.IdSucursal !== locatarioAFiltrar.IdSucursal
          );
          market.Locatarios = filteredLocatarios ? [...filteredLocatarios] : [];
          sucursalesFiltradas[marketIndex] = market;
        }
      }
      return sucursalesFiltradas;
    }
    return sucursales;
  }

  function getSucursalesCercanas(sucursales, direccion) {
    const dato = filtrarSucursales(sucursales);
    if (!direccion) return dato;
    let array_aux = [];
    if (
      direccion == null ||
      Object.keys(direccion).length === 0 ||
      typeof direccion == "undefined"
    ) {
      return array_aux;
    }
    if (dato.length > 0) {
      dato.forEach((sucursal) => {
        let array_location = [];
        sucursal.Locatarios.forEach((locatario) => {
          let array_zona_cobertura = [];
          locatario.ZonasCobertura.forEach((zona) => {
            const points = zona.PuntosCobertura.map((e) => {
              return {
                latitude: Number(e.Latitud),
                longitude: Number(e.Longitud),
              };
            });
            let coberturaSucursal = isPointInPolygon(
              {
                latitude: Number(direccion.Latitud),
                longitude: Number(direccion.Longitud),
              },
              points
            );
            if (coberturaSucursal) {
              array_zona_cobertura.push(zona);
            }
          });
          if (array_zona_cobertura.length > 0) {
            array_location.push({
              ...locatario,
              ZonasCobertura: array_zona_cobertura,
            });
          }
        });
        if (array_location.length > 0) {
          array_aux.push({
            ...sucursal,
            Locatarios: array_location,
            distancia: calcularDistanciaEntreDosCoordenadas(
              sucursal.UbicacionLatitud,
              sucursal.UbicacionLongitud,
              direccion.Latitud,
              direccion.Longitud
            ).toFixed(2),
          });
        }
      });
    }
    array_aux.sort(function (a, b) {
      if (Number(a.distancia) > Number(b.distancia)) {
        return 1;
      }
      if (Number(a.distancia) < Number(b.distancia)) {
        return -1;
      }
      return 0;
    });
    return array_aux;
  }

  const calcularDistanciaEntreDosCoordenadas = (lat1, lon1, lat2, lon2) => {
    // Convertir todas las coordenadas a radianes
    lat1 = gradosARadianes(lat1);
    lon1 = gradosARadianes(lon1);
    lat2 = gradosARadianes(lat2);
    lon2 = gradosARadianes(lon2);
    // Aplicar fórmula
    const RADIO_TIERRA_EN_KILOMETROS = 6371;
    let diferenciaEntreLongitudes = lon2 - lon1;
    let diferenciaEntreLatitudes = lat2 - lat1;
    let a =
      Math.pow(Math.sin(diferenciaEntreLatitudes / 2.0), 2) +
      Math.cos(lat1) *
        Math.cos(lat2) *
        Math.pow(Math.sin(diferenciaEntreLongitudes / 2.0), 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return RADIO_TIERRA_EN_KILOMETROS * c;
    // return (RADIO_TIERRA_EN_KILOMETROS * c).toFixed(2);
  };
  const gradosARadianes = (grados) => {
    return (grados * Math.PI) / 180;
  };
  const getSucursalPorDefecto = (sucursales) => {
    let sucursalEncontrada = getItemSucursal(
      sucursales,
      process.env.REACT_APP_DEFAULT_STORE || "hipermaxi-roca-y-coronado"
    );

    if (sucursalEncontrada) return sucursalEncontrada;

    return (
      sucursales[0]?.Locatarios.find(
        (locatario) => locatario.IdTipoServicio === 1
      ) || sucursales[0]?.Locatarios[0]
    );
  };

  const getItemSucursal = (sucursales, nombreSucursal) => {
    let item_slected = null;
    sucursales.forEach((sucursal) => {
      if (!item_slected) {
        item_slected = sucursal.Locatarios.find(
          (pre) => slug.data_slug(pre.Descripcion) === nombreSucursal
        );
      }
    });

    return item_slected;
  };

  return {
    getSucursalesCercanas,
    getSucursalPorDefecto,
    calcularDistanciaEntreDosCoordenadas,
  };
}
