import {
  GET_SHOPPING_LIST_SUCCESS,
  GET_SHOPPING_LIST_FAIL,
  GET_DETALLE_SHOPPING_LIST_SUCCESS,
  GET_DETALLE_SHOPPING_LIST_NULL,
  SET_LOADING,
  REMOVE_LOADING,
  ACTUALIZAR_LIST_CART,
  UPDATE_DETALLE_SHOPPING_LIST_SUCCESS,
  SET_ID_LIST_COMPRA,
  GET_SHOPPING_LIST_SHARED_SUCCESS,
  GET_SHOPPING_LIST_SHARED_FAIL,
  INC_TO_CART_SHOPPING_LIST,
  DEC_TO_CART_SHOPPING_LIST,
  CHECKED_TO_CART_SHOPPING_LIST,
  INC_PESO_CART_SHOPPING_LIST,
  ACT_DATA_CART_SHOPPING,
  UPDATE_ITEM_CARRITO_lISTA_COMPRA,
  CARRITO_LISTA_COMPRA_FAIL,
  ACT_PIEZA_CART_SHOPPING,
  DELETE_PRODUCT_SHOPPING_LIST,
  CLEAR_STATE_SHOPPING_LIST,
} from "./types";
import axios from "axios";
import { map } from "modern-async";
import { setSwAlert, showRespuesta } from "./swalert";
import { end_peticion, start_peticion } from "./peticion";
/*import {huellaDigital} from './../../huellaDigital';*/
import respuestas from "../../components/Alertas";

export const limpiar_data_lista_compra = () => async (dispatch) => {
  dispatch({
    type: CLEAR_STATE_SHOPPING_LIST,
  });
};

export const get_lista_compra = (idSucursal) => async (dispatch) => {
  dispatch(start_peticion("peticion..."));
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/listados-compra?IdSucursal=${idSucursal}`,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_SHOPPING_LIST_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_SHOPPING_LIST_FAIL,
      });
      dispatch(showRespuesta(res));
    }
    dispatch(end_peticion());
  } catch (err) {
    dispatch({
      type: GET_SHOPPING_LIST_FAIL,
    });
    dispatch(end_peticion());
    dispatch(setSwAlert(respuestas.peticionError, true, 500));
  }
};

export const get_lista_compra_favorito =
  (idProducto, idsucursal) => async (dispatch) => {
    dispatch({
      type: GET_DETALLE_SHOPPING_LIST_NULL,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/listados-compra`,
        config
      );
      const arrayclonado = res.data.Dato.slice();
      const result = await map(res.data.Dato, async (v) => {
        const res1 = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/listados-compra/1?IdListado=${v.Id}&idSucursal=${idsucursal}`,
          config
        );
        for (var item of res1.data.Dato.Productos) {
          var index = arrayclonado
            .map((item2) => item2.Id)
            .indexOf(res1.data.Dato.Id);
          if (parseInt(item.IdProducto) === idProducto) {
            arrayclonado[index].favorito = true;
          }
        }
      });

      if (res.status === 200) {
        dispatch({
          type: GET_SHOPPING_LIST_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_SHOPPING_LIST_FAIL,
        });
        dispatch(showRespuesta(res));
      }
    } catch (err) {
      //
      dispatch({
        type: GET_SHOPPING_LIST_FAIL,
      });
      dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
  };

export const get_detalle_lista_compra = (datos) => async (dispatch) => {
  // dispatch({
  //     type: CLEAR_CARRITO_LISTA_COMPRA,
  // });
  dispatch(start_peticion("registrando..."));
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };

  let idlista = datos.Id;
  let idsucursal = datos.Sucursal; //obtener id del sucursal
  try {
    // {{baseUrl}}/api/{{version}}/CuentasMarket/listados-compra/1?IdListado=168&idSucursal=85
    // const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/listados-compra/${idlista}?IdListado=${idlista}&idSucursal=${idsucursal}`, config);
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/listados-compra/${idlista}?&idSucursal=${idsucursal}`,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_DETALLE_SHOPPING_LIST_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_SHOPPING_LIST_FAIL,
      });
      dispatch(showRespuesta(res));
    }
    dispatch(end_peticion());
  } catch (err) {
    dispatch({
      type: GET_SHOPPING_LIST_FAIL,
    });
    dispatch(end_peticion());
    dispatch(setSwAlert(respuestas.peticionError, true, 500));
  }
};

export const get_detalle_lista_compra_compartida =
  (datos) => async (dispatch) => {
    // dispatch({
    //     type: CLEAR_CARRITO_LISTA_COMPRA,
    // });
    dispatch(start_peticion("registrando..."));

    const config = {
      headers: {
        // 'Access-Control-Allow-Origin':'*',
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    let Idmarket = datos.Idmarket;
    let idsucursal = datos.idsucursal;
    let HashListaCompra = datos.HashListaCompra;

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/listas-compra/detalle?HashListaCompra=${HashListaCompra}&IdSucursal=${idsucursal}&Idmarket=${Idmarket}`,
        config
      );
      if (res.status === 200 && res.data.ConError === false) {
        dispatch({
          type: GET_SHOPPING_LIST_SHARED_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_SHOPPING_LIST_SHARED_FAIL,
        });

        //   dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 400));
        dispatch(showRespuesta(res));
      }
      dispatch(end_peticion());
    } catch (err) {
      dispatch({
        type: GET_SHOPPING_LIST_SHARED_FAIL,
      });
      dispatch(end_peticion());
      dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
  };

export const actualizar_detalle_lista_compra_rapida =
  (datos) => async (dispatch) => {
    try {
      dispatch({
        type: UPDATE_DETALLE_SHOPPING_LIST_SUCCESS,
        payload: datos,
      });
    } catch (err) {
      dispatch({
        type: GET_SHOPPING_LIST_FAIL,
      });
    }
  };

export const set_id_list_compra = (id) => async (dispatch) => {
  try {
    dispatch({
      type: SET_ID_LIST_COMPRA,
      payload: id,
    });
  } catch (err) {}
};

export const actualizar_lista_compra =
  (datos, carritos, IdSucursal) => async (dispatch) => {
    dispatch({ type: SET_LOADING });
    dispatch(start_peticion("registrando..."));
    // agree
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    function mapearProductos() {
      let array = [];
      let pesos = [];
      carritos.forEach((item) => {
        if (item.PesoDefinido) {
          pesos = item.Pesos.map((e) => {
            return {
              IdPeso: e.IdPeso,
              Piezas: e.Piezas,
            };
          });
        }

        array.push({
          IdProducto: item.IdProducto,
          IdSucursal: IdSucursal,
          Cantidad: item.Cantidad,
          Pesos: pesos,
        });
      });
      return array;
    }

    let productos = mapearProductos();
    const data = {
      Id: datos.Id, //id lista_compra
      Descripcion: datos.Descripcion,
      Color: datos.Color,
      Productos: productos,
    };
    try {
      let sucursal = JSON.parse(localStorage.getItem("sucursal"));
      // {{baseUrl}}/api/{{version}}/CuentasMarket/listados-compra
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/listados-compra`,
        data,
        config
      );
      if (res.status === 200 && res.data.Estado === 200) {
        dispatch(get_lista_compra(sucursal.IdSucursal));
        dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 200));
      } else {
        dispatch({
          type: GET_SHOPPING_LIST_FAIL,
        });
        //   dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 400));
        dispatch(showRespuesta(res));
      }
      dispatch({ type: REMOVE_LOADING });
      dispatch(end_peticion());
    } catch (err) {
      dispatch({
        type: GET_SHOPPING_LIST_FAIL,
      });
      dispatch(end_peticion());
      dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
  };
export const actualizar_lista_compra_favorito =
  (datos, carritos, productos_eliminados, IdSucursal) => async (dispatch) => {
    dispatch({ type: SET_LOADING });
    dispatch(start_peticion("registrando..."));
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    function mapearProductos() {
      let array = [];
      let pesos = [];
      carritos.forEach((item) => {
        if (item.PesoDefinido) {
          pesos = item.Pesos.map((e) => {
            return {
              IdPeso: e.IdPeso,
              Piezas: e.Piezas,
            };
          });
        }

        array.push({
          IdProducto: item.IdProducto,
          IdSucursal: IdSucursal,
          Cantidad: item.Cantidad,
          Pesos: pesos,
        });
      });
      return array;
    }

    let productos = mapearProductos();

    const data = {
      Id: datos.Id, //id lista_compra
      Descripcion: datos.Descripcion,
      Color: datos.Color,
      Productos: productos,
      EliminarProductos: productos_eliminados,
    };
    try {
      //
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/listados-compra`,
        data,
        config
      );
      if (res.status === 200 && res.data.Estado === 200) {
        /* dispatch({
                 type: GET_SHOPPING_LIST_SUCCESS,
                 payload: res.data
             });
             */
        dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 200));
      } else {
        dispatch({
          type: GET_SHOPPING_LIST_FAIL,
        });
        //dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 400));
        dispatch(showRespuesta(res));
      }
      dispatch({ type: REMOVE_LOADING });
      dispatch(end_peticion());
    } catch (err) {
      dispatch({
        type: GET_SHOPPING_LIST_FAIL,
      });
      dispatch(end_peticion());
      dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
  };

export const actualizar_detalles_lista_compra =
  (datos, array_productos, array_productos_eliminar, IdSucursal) =>
  async (dispatch) => {
    dispatch(start_peticion(""));
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    function mapearProductos() {
      let array = [];
      let pesos = [];
      array_productos.forEach((item) => {
        if (item.PesoDefinido) {
          if (item.Pesos != null) {
            pesos = item.Pesos.map((e) => {
              return {
                IdPeso: e.IdPeso,
                Piezas: e.Piezas,
              };
            });
          }
        }
        array.push({
          IdProducto: item.IdProducto,
          IdSucursal: IdSucursal,
          Cantidad: item.Cantidad,
          Pesos: pesos,
        });
      });
      return array;
    }

    function mapearProductosEliminar() {
      let array = [];
      let pesos = [];
      array_productos_eliminar.forEach((item) => {
        if (item.PesoDefinido) {
          if (item.Pesos != null) {
            pesos = item.Pesos.map((e) => {
              return {
                IdPeso: e.IdPeso,
                Piezas: e.Piezas,
              };
            });
          }
        }
        array.push({
          IdProducto: item.IdProducto,
          IdSucursal: IdSucursal,
          Pesos: pesos,
        });
      });
      return array;
    }

    let productos = mapearProductos();
    let productos_eliminar = mapearProductosEliminar();
    const data = {
      Id: datos.Id,
      Descripcion: datos.Descripcion,
      Color: datos.Color,
      Productos: productos,
      EliminarProductos: productos_eliminar,
    };

    try {
      // {{baseUrl}}/api/{{version}}/CuentasMarket/listados-compra
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/listados-compra`,
        data,
        config
      );

      if (res.status === 200) {
        /* dispatch({
                 type: GET_SHOPPING_LIST_SUCCESS,
                 payload: res.data
             });
             */
      } else {
        dispatch({
          type: GET_SHOPPING_LIST_FAIL,
        });
        dispatch(showRespuesta(res));
      }
      dispatch(end_peticion());
    } catch (err) {
      dispatch({
        type: GET_SHOPPING_LIST_FAIL,
      });
      dispatch(end_peticion());

      dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
  };

///PAUSA CONTINUAREMOS DE AQUI
export const store_carrito_lista_compra =
  (datos, carritos, IdSucursal) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    function mapearProductos() {
      let array = [];
      let pesos = [];
      carritos.forEach((item) => {
        if (item.PesoDefinido) {
          pesos = item.Pesos.map((e) => {
            return {
              IdPeso: e.IdPeso,
              Piezas: e.Piezas,
            };
          });
        }

        array.push({
          IdProducto: item.IdProducto,
          IdSucursal: IdSucursal,
          Cantidad: item.Cantidad,
          Pesos: pesos,
        });
      });
      return array;
    }

    let productos = mapearProductos();
    const data = {
      Id: datos.Id, //id lista_compra
      Descripcion: datos.Descripcion,
      Color: datos.Color,
      Productos: productos,
    };

    // const data=null;
    try {
      // const res = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/PedidosMarket/carrito`, data, config);
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/listados-compra`,
        data,
        config
      );

      if (res.status === 200) {
        dispatch({
          type: UPDATE_ITEM_CARRITO_lISTA_COMPRA,
          payload: res.data,
        });
      } else {
        dispatch({
          type: CARRITO_LISTA_COMPRA_FAIL,
        });
        dispatch(showRespuesta(res));
      }
    } catch (err) {
      dispatch({
        type: CARRITO_LISTA_COMPRA_FAIL,
      });
      dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
  };
// export const set_peso_al_carrito = (producto_peso,cant,pesos) => async dispatch => {
//     dispatch({
//         type: SET_PESO_TO_CART,
//         payload: {producto_peso, cant, pesos}
//     });
// };

export const compra_rapida = (datos) => async (dispatch) => {
  dispatch(start_peticion(""));
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
  try {
    const res = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/v1/PedidosMarket/compra-rapida`,
      datos,
      config
    );

    if (res.status === 200) {
      /* dispatch({
                 type: GET_SHOPPING_LIST_SUCCESS,
                 payload: res.data
             });
             */
      dispatch(end_peticion());
      return res;
    } else {
      dispatch({
        type: GET_SHOPPING_LIST_FAIL,
      });
      if (res.data.Errores.length > 0) {
        dispatch(
          setSwAlert(res.data.Errores[0].Mensaje, res.data.ConError, 200)
        );
      } else {
        dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 200));
      }
    }
    dispatch(end_peticion());
  } catch (err) {
    dispatch({
      type: GET_SHOPPING_LIST_FAIL,
    });
    dispatch(end_peticion());
    dispatch(setSwAlert(respuestas.peticionError, true, 500));
  }
};

export const store_lista_compra =
  (datos, carritos, IdSucursal, object = null) =>
  async (dispatch) => {
    dispatch(start_peticion("Creando..."));
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };

    function mapearProductos() {
      let array = [];
      let pesos = [];
      carritos.forEach((item) => {
        if (item.PesoDefinido) {
          if (item.Pesos != null) {
            pesos = item.Pesos.map((e) => {
              return {
                IdPeso: e.IdPeso,
                Piezas: e.Piezas,
              };
            });
          }
        }

        array.push({
          IdProducto: item.IdProducto,
          IdSucursal: IdSucursal,
          Cantidad: item.Cantidad,
          Pesos: pesos,
        });
      });
      return array;
    }

    let productos = mapearProductos();
    const data = {
      // "Id": datos.Id, //id lista_compra
      Descripcion: datos.Descripcion,
      Color: datos.Color,
      Productos: productos,
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/listados-compra`,
        data,
        config
      );

      if (res.status === 200 && res.data.Estado === 200) {
        dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 200, object));
      } else {
        dispatch({
          type: GET_SHOPPING_LIST_FAIL,
        });
        //            dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 400));
        dispatch(showRespuesta(res));
      }
      dispatch(end_peticion());
    } catch (err) {
      dispatch({
        type: GET_SHOPPING_LIST_FAIL,
      });
      dispatch(end_peticion());
      dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
  };

export const eliminar_lista_compra = (datos) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
  try {
    const IdListCompra = datos.Id;
    const res = await axios.delete(
      `${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/listados-compra?Id=${IdListCompra}`,
      config
    );

    if (res.status === 200 && res.data.Estado === 200) {
      dispatch(get_lista_compra(datos.IdSucursal));
      dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 200));
    } else {
      dispatch({
        type: GET_SHOPPING_LIST_FAIL,
      });
      //dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 400));
      dispatch(showRespuesta(res));
    }
  } catch (err) {
    dispatch({
      type: GET_SHOPPING_LIST_FAIL,
    });
    dispatch(setSwAlert(respuestas.peticionError, true, 500));
  }
};

export const actualizar_lista_carrito = (array) => async (dispatch) => {
  dispatch({
    type: ACTUALIZAR_LIST_CART,
    payload: array,
  });
};
export const inc_al_carrito_lista_compra = (producto) => async (dispatch) => {
  dispatch({
    type: INC_TO_CART_SHOPPING_LIST,
    payload: producto,
  });
};
export const dec_al_carrito_lista_compra = (producto) => async (dispatch) => {
  dispatch({
    type: DEC_TO_CART_SHOPPING_LIST,
    payload: producto,
  });
};

export const checked_carrito_lista_compra =
  (producto, estado_checked) => async (dispath) => {
    dispath({
      type: CHECKED_TO_CART_SHOPPING_LIST,
      payload: { producto: producto, estado_checked: estado_checked },
    });
  };
export const inc_peso_carrito_lista_compra =
  (producto, pesoKg) => async (dispatch) => {
    let data = {
      producto: producto,
      pesoKg: pesoKg,
    };
    dispatch({
      type: INC_PESO_CART_SHOPPING_LIST,
      payload: data,
    });
  };
export const actualizar_item_carrito =
  (producto, valor) => async (dispatch) => {
    dispatch({
      type: ACT_DATA_CART_SHOPPING,
      payload: { producto, valor },
    });
  };
export const actualizar_producto_pieza =
  (producto_id, peso, operacion, cant_peso) => async (dispatch) => {
    dispatch({
      type: ACT_PIEZA_CART_SHOPPING,
      payload: { producto_id, peso, operacion, cant_peso },
    });
  };

// export const get_producto_carrito_lista_compra=()=>async dispatch=>{

// }
export const eliminar_product_lista_compra =
  (idproducto, idsucursal) => async (dispatch) => {
    let data = {
      idproducto: idproducto,
      idsucursal: idsucursal,
    };
    dispatch({
      type: DELETE_PRODUCT_SHOPPING_LIST,
      payload: data,
    });
  };
