import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/pro-solid-svg-icons";
import { faCcMastercard, faCcVisa } from "@fortawesome/free-brands-svg-icons";



const ItemTarjeta = ({ps_tarjeta,ps_deleteTarjeta}) => {

    function eliminarTarjeta(id){
        ps_deleteTarjeta(id);
    }


    return (

        <div className='w-[15rem] h-auto p-2 rounded-lg border border-1 flex flex-col justify-end text-right text-white'
            style={{backgroundColor:ps_tarjeta.color}}
            key={ps_tarjeta.idTarjeta}
        >   

            <div>
                {ps_tarjeta.code === '001' && (
                <FontAwesomeIcon
                    style={{alignSelf: 'flex-end', marginBottom: '4%', fontSize:'30px'}}
                    icon={faCcVisa}
                    color="white"
                />
                )}
                {ps_tarjeta.code === '002' && (
                <FontAwesomeIcon
                    style={{alignSelf: 'flex-end', marginBottom: '4%'}}
                    icon={faCcMastercard}
                    size={40}
                    color="white"
                />
                )}
            </div>

            <div className='flex flex-col justify-center text-white'>
                <span className="mb-2">XXXX XXXX XXXX {ps_tarjeta.cardSufix}</span>
                <span className="mb-1"> {ps_tarjeta.propietario}</span> 
            </div>
            <div className='flex justify-end mt-1 text-white items-center'>
                <FontAwesomeIcon icon={faTrash}
                className="cursor-pointer"
                onClick={()=>eliminarTarjeta(ps_tarjeta.idTarjeta)}
                style={{fontSize:'0.8rem'}}
                />
            </div>

        </div>


    );
};

export default ItemTarjeta;