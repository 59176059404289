import React, { useState, useEffect, useRef } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Autocomplete,
} from "@react-google-maps/api";
import Swal from "sweetalert2";
import ButtonText from "../widgets/button_text";
import { googleMapsStyles } from "./googleMapsStyles";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const Map = ({ onlatlng, data, onClicDireccion, editable }) => {
  const [direccion, guardarDireccion] = useState();

  const [location, setLocation] = useState(null);
  const [center, setCenter] = useState({
    lat: data ? parseFloat(data.Latitud) : -17.783312689798045,
    lng: data ? parseFloat(data.Longitud) : -63.18213701248169,
  });

  useEffect(() => {
    onlatlng(center);
  }, []);
  useEffect(() => {
    if (direccion) {
      if (onlatlng) {
        setCenter(location);
        onlatlng(location);
      }
      onClicDireccion(direccion);
    }
  }, [direccion]);

  useEffect(() => {
    if (location) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder
        .geocode({ location: location })
        .then((response) => {
          if (response.results[0]) {
            obtenerDireccion(response.results);
          } else {
            window.alert("No results found");
          }
        })
        .catch((e) => window.alert("Geocoder failed due to: " + e));
    }
  }, [location]);

  const myLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        function (error) {
          messageAlert();
        }
      );
    } else {
    }
  };

  const messageAlert = () => {
    Swal.fire({
      title: "Acceso denegado",
      icon: "info",
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      html:
        "Solicitud de geolocalización denegada por el usuario,<br>" +
        "<b> Para poder localizar su ubicación actual debe habilitar el permiso </b>",
    });
  }

  const onMarkerDragEnd = (e) => {
    const latLng = {
      lat: parseFloat(e.latLng.lat()),
      lng: parseFloat(e.latLng.lng()),
    };
    setLocation(latLng);
  };
  const onMarkerClick2 = (e) => {
    if (editable) {
      const latLng = {
        lat: parseFloat(e.latLng.lat()),
        lng: parseFloat(e.latLng.lng()),
      };
      setLocation(latLng);
    }
  };

  const obtenerDireccion = (array) => {
    let conteo = 0;
    array.forEach((item) => {
      let cadena = item.formatted_address;
      let primer_caracter = cadena.charAt(0);
      if (esLetra(primer_caracter)) {
        conteo++;
        if (conteo == 1) {
          guardarDireccion(item.formatted_address);
        }
      }
    });
  }

  const esLetra = (caracter) => {
    let ascii = caracter.toUpperCase().charCodeAt(0);
    return ascii > 64 && ascii < 91;
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_APIKEY,
    libraries: ["places"],
  });

  const [map, setMap] = React.useState(null);
  const [autocomplete, setAutocomplete] = React.useState(null);
  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onLoadAutocomplete = (map) => {
    setAutocomplete(map);
  };

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const onPlaceChanged = async () => {
    const place = autocomplete.getPlace();
    const { lat, lng } = place.geometry.location;
    const latLng = {
      lat: lat(),
      lng: lng(),
    };
    setLocation(latLng);
  };

  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={14}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={(e) => onMarkerClick2(e)}
        options={{
          gestureHandling: "greedy",
          streetViewControl: false,
          mapTypeControl: false,
          clickableIcons: false,
          styles: googleMapsStyles,
        }}
      >
        <>
          <Marker
            position={center}
            draggable={editable}
            visible={true}
            onDragEnd={(e) => onMarkerDragEnd(e)}
          />
        </>
        {editable && (
          <Autocomplete
            onLoad={onLoadAutocomplete}
            onPlaceChanged={onPlaceChanged}
          >
            <div className="flex absolute top-0 h-10 m-[10px] w-[calc(100%-70px)]">
              <input
                type="text"
                placeholder="Escribe tu dirección"
                className="w-full block rounded-md border-1 border-gray-400 py-2 p-3 pr-4  focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500 text-sm"
              />
            </div>
          </Autocomplete>
        )}
      </GoogleMap>
      {editable && (
        <div className="mt-2 text-white p-2 flex justify-center">
          <ButtonText
            className="text-sm md:text-base  bg-hiperorange  text-white hover:bg-hiperorange_hover max-w-52"
            type="button"
            onClick={() => myLocation()}
            label={"Obtener ubicación"}
          />
        </div>
      )}
    </>
  ) : (
    <></>
  );
};

export default Map;
