import {
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAIL,
  GET_PRODUCT_ITEM_SUCCESS,
  GET_PRODUCT_ITEM_FAIL,
  GET_PRODUCT_SECCION_SUCCESS,
  SET_LOADING,
  REMOVE_LOADING,
  CLEAR_DATA,
  CLEAR_ITEM_PRODUCTO,
  SET_SEARCH_SUCCESS,
  CLEAR_SEARCH_SUCCESS,
  SET_IS_FETCHING_PRODUCTOS,
  SET_PRODUCTOS_PETICION_SECCION,
  SET_IS_FETCHING_PRODUCTOS_SECCION,
} from "../actions/types";

export const HOME_SECCION_TYPES = {
  CATEGORIAS: 1,
  OFERTAS: 2,
  BANNER: 3,
  BANNER_CORP: 4,
  BANNER_SOC: 5,
  MAS_VENDIDOS: 6,
  MAS_VENDIDOS_USUARIO: 7,
  MI_LISTA_COMPRA: 8,
  MARKETS: 9,
  TEMPORADA: 10,
};

const initialState = {
  productos: [],
  producto: null,
  peticion: false,
  loading: false,
  productos_seccion: [],
  search: null,
  productos_peticion: true,
  productos_peticion_seccion: true,
  end_message: false,
  isFetching: false,
  isFetchingProductosSeccion: false,
  end_message_producto_seccion: false,
};

export default function Producto(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_PRODUCTOS_PETICION_SECCION:
      return {
        ...state,
        productos_peticion_seccion: payload,
      };
    case SET_IS_FETCHING_PRODUCTOS:
      return {
        ...state,
        isFetching: payload,
      };
    case SET_IS_FETCHING_PRODUCTOS_SECCION:
      return {
        ...state,
        isFetchingProductosSeccion: payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_LOADING:
      return {
        ...state,
        loading: false,
      };

    case GET_PRODUCT_SUCCESS:
      if (payload.Dato.length > 0) {
        return {
          ...state,
          productos: [...state.productos, ...payload.Dato],
          peticion: true,
          productos_peticion: true,
          end_message: false,
        };
      } else {
        //
        return {
          ...state,
          productos_peticion: false,
          peticion: false,
          end_message: true,
        };
      }

    case GET_PRODUCT_SECCION_SUCCESS:
      let prods = observerResponse(payload.res.Dato, payload.idTipoSeccion);
      const hasMore = payload.page === 1 && prods.length < payload.cantidad;
      if (prods.length > 0) {
        return {
          ...state,
          productos_seccion: [...state.productos_seccion, ...prods],
          productos_peticion_seccion: true,
          end_message_producto_seccion: hasMore,
        };
      } else {
        return {
          ...state,
          productos_peticion_seccion: false,
          end_message_producto_seccion: true,
        };
      }

    case GET_PRODUCT_ITEM_SUCCESS:
      return {
        ...state,
        producto: payload.Dato,
      };
    case GET_PRODUCT_ITEM_FAIL:
    case GET_PRODUCT_FAIL:
      return {
        ...state,
        productos_peticion_seccion: false,
        end_message_producto_seccion: true,
      };
    case CLEAR_DATA:
      return {
        ...state,
        productos: [],
        productos_seccion: [],
        productos_peticion: true,
        productos_peticion_seccion: true,
        end_message: false,
        end_message_producto_seccion: false,
      };
    case CLEAR_ITEM_PRODUCTO:
      return {
        ...state,
        producto: null,
      };
    case SET_SEARCH_SUCCESS:
      return {
        ...state,
        search: payload,
      };
    case CLEAR_SEARCH_SUCCESS:
      return {
        ...state,
        search: null,
      };

    default:
      return state;
  }
}

function observerResponse(data, idTipoSeccion) {
  if (!data || !idTipoSeccion) return [];

  switch (idTipoSeccion) {
    case HOME_SECCION_TYPES.OFERTAS:
      return data.Productos || [];
    case HOME_SECCION_TYPES.MAS_VENDIDOS:
      return data;
    case HOME_SECCION_TYPES.MAS_VENDIDOS_USUARIO:
      return data;
    case HOME_SECCION_TYPES.TEMPORADA:
      return data;
    default:
      return [];
  }
}
