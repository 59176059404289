import Swal from 'sweetalert2';
import { NotificationService } from '../domain/notification-service';
import { ErrorData } from '../../http/domain/interfaces/error-data';
import { ErrorDataDisplayType } from '../../http/domain/enum/error-data-display-type';

const TIMER = 2000;

export function createSweetAlertNotificationService(): NotificationService {
	return {
		notifyDefault,
		notifyInfo,
		notifySuccess,
		notifyError,
		notifyWarning,
		notifyConfirm,
		notifyErrorWithData,
	};
}

const Toast = Swal.mixin({});

function notifyDefault(message: string): void {
	Toast.fire(message);
}

function notifyInfo(message: string): void {
	Toast.fire({
		icon: 'info',
		text: message,
		toast: true,
		showConfirmButton: false,
		position: 'bottom-end',
	});
}
function notifySuccess(message: string): void {
	Toast.fire({
		icon: 'success',
		text: message,
		timer: TIMER,
		timerProgressBar: true,
		toast: true,
		showConfirmButton: false,
		position: 'bottom-end',
	});
}
function notifyError(message: string): void {
	Toast.fire({
		icon: 'error',
		text: message,
		// timer: TIMER,
		timerProgressBar: true,
	});
}

function getErrorData(data: ErrorData): string {
	switch (data.type) {
		case ErrorDataDisplayType.TABLE:
			let html = `<table class="table"><thead><tr><th>Código</th><th>Error</th></tr></thead><tbody>`;
			for (let index = 0; index < data.errors.length; index++) {
				const error = data.errors[index];
				html += `<tr><td>${error.value}</td><td>${error.message}</td></tr>`;
			}
			html += '</tbody></table>';
			return html;
		default:
			return '';
	}
}

function notifyErrorWithData(message: string, data: ErrorData): void {
	Toast.fire({
		icon: 'error',
		text: message,
		showCancelButton: !!data,
		confirmButtonText: !data ? 'OK' : 'Ver Detalle',
		cancelButtonText: 'Cerrar',
		// timer: TIMER,
		timerProgressBar: true,
	}).then((result) => {
		/* Read more about isConfirmed, isDenied below */
		if (result.isConfirmed) {
			Toast.fire({
				title: 'Detalle de errores',
				// timer: TIMER,
				timerProgressBar: true,
				html: getErrorData(data),
			});
		}
	});
}

function notifyWarning(message: string): void {
	Toast.fire({
		icon: 'warning',
		text: message,
		// timer: TIMER,
		timerProgressBar: true,
	});
}

function notifyConfirm(
	message: string,
	successMessage: string,
	errorMessage: string,
	onConfirm: () => Promise<boolean>
): void {
	Toast.fire({
		title: 'Confirmación',
		text: message,
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#fd7e14',
		confirmButtonText: 'Confirmar',
		cancelButtonText: 'Cancelar',
		showLoaderOnConfirm: true,
		preConfirm: () => {
			return onConfirm()
				.then((success) => {
					if (!success) {
						throw new Error(errorMessage);
					}
					return success;
				})
				.catch(() => {
					Toast.showValidationMessage(errorMessage);
				});
		},
		allowOutsideClick: () => !Swal.isLoading(),
	}).then(async (result) => {
		if (result.isConfirmed) {
			Toast.fire({
				icon: 'success',
				text: successMessage,
				timer: TIMER,
				timerProgressBar: true,
			});
		}
	});
}
