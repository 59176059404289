import { SwitchCodesComponent } from "../../../../shared/ui/components/switch-codes-component";

interface HiperPuntosProfileCodeProps {
  profileCode: string;
}

export function HiperPuntosProfileCode({
  profileCode,
}: HiperPuntosProfileCodeProps) {
  return <SwitchCodesComponent code={profileCode} />;
}
