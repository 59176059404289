import { faAngleLeft, faTruckFast } from "@fortawesome/free-solid-svg-icons";
import {
  faAngleRight,
  faCartCircleExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import styled, { css, keyframes } from "styled-components";
import "swiper/css";
import "swiper/css/lazy";
import { list_pedidos } from "../../redux/actions/pedido";
import PedidosSwiperCustom from "../PedidosSwiperCustom";
import Drawer from "react-modern-drawer";
import useScreenSize from "../../widgets/useScreenSize";
import classNames from "classnames";

const SlideContainer = styled.div`
  position: sticky;
  bottom: 12px;
  top: 50px;
  left: -12px;
  ${(props) =>
    props.show
      ? css`
          animation: ${props.slideOut} 1s forwards;
        `
      : css`
          animation: ${props.slideIn} 1.5s forwards;
        `}
  display: flex;
  justify-content: center;
`;

const Button = styled.button`
  color: black;
  font-weight: 500;
  font-size: 1.125rem; /* Equivalent to text-lg */
  margin: 0.5rem 0;
  float: right;
  border: 1px solid #0349ab;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  cursor: pointer;
  background-color: white;
  position: absolute;
  right: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
`;

const Pedidos = ({
  profile,
  pedidos: arrayPedido,
  list_pedidos,
  peticion_pedidos,
}) => {
  // const [arrayPedido, setArrayPedido] = useState([]);
  const [showTodoPedidos, setShowTodoPedidos] = useState(false);
  const { width } = useScreenSize();

  useEffect(() => {
    if (width >= 768 && showTodoPedidos) {
      setShowTodoPedidos(false);
    }
  }, [width]);

  const isUserLoggedIn = useMemo(() => {
    return profile?.Telefono != null && profile?.Telefono !== "";
  }, [profile]);

  useEffect(() => {
    if (isUserLoggedIn) {
      list_pedidos();
    }
  }, [isUserLoggedIn]);

  const toggleViewAll = (e) => {
    setShowTodoPedidos(true);
    if (!peticion_pedidos) {
      list_pedidos();
    }
  };

  const toggleCloseViewAll = (e) => {
    setShowTodoPedidos(false);
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
    }, 1000);
  };

  if (arrayPedido.length <= 0) return null;

  return (
    <>
      <button
        className={classNames(
          "p-2 bg-white fixed top-[200px] right-[-2px] border-[#0349ab] border-t-2 border-b-2 border-l-2 border-solid rounded-lg rounded-r-none cursor-pointer flex items-center justify-center transition-opacity duration-300",
          {
            "opacity-100": !showTodoPedidos,
            "opacity-0": showTodoPedidos,
          }
        )}
        onClick={toggleViewAll}
      >
        <FontAwesomeIcon
          icon={faTruckFast}
          className="text-hiperblue text-xl flex"
        />
      </button>
      <Drawer
        open={showTodoPedidos}
        onClose={toggleCloseViewAll}
        direction="right"
        className=" w-[275px] md:w-[350px] top-[200px] border-[#0349ab] border-t-2 border-b-2 border-l-2 border-r-0 h-[250px] md:h-[450px] rounded-bl-lg"
        overlayOpacity={0}
      >
        <button
          className={classNames(
            "p-2 bg-white fixed top-[-2px] left-[-31px] border-[#0349ab] border-t-2 border-b-2 border-l-2 border-solid rounded-lg rounded-r-none cursor-pointer flex items-center justify-center transition-opacity duration-300",
            {
              "opacity-100": showTodoPedidos,
              "opacity-0": !showTodoPedidos,
            }
          )}
          onClick={toggleCloseViewAll}
        >
          <FontAwesomeIcon
            icon={faAngleRight}
            className="text-hiperblue text-xl flex"
          />
        </button>
        {arrayPedido.length > 0 ? (
          <div
            className="flex flex-col w-full my-2 float-right bg-white rounded-lg p-2 cursor-default"
            onClick={(e) => e.stopPropagation()}
          >
            <PedidosSwiperCustom arrayPedido={arrayPedido} profile={profile} />
          </div>
        ) : (
          <div className="flex flex-col gap-4 justify-center items-center mt-2 w-60 min-h-60 text-gray-400">
            <FontAwesomeIcon icon={faCartCircleExclamation} size="4x" />
            <div className="text-lg">
              No hay pedidos pendientes para mostrar
            </div>
          </div>
        )}
      </Drawer>
    </>
  );
};

const mapStateToProps = (state) => ({
  profile: state.Profile.profile,
  pedidos: state.Pedido.pedidos,
  peticion_pedidos: state.Pedido.peticion_pedidos,
});

export default connect(mapStateToProps, {
  list_pedidos,
})(Pedidos);
