import { useSearchParams } from "react-router-dom";

const SEARCH_QUERY_PARAM = "s";

export function useSearchTerm() {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchTerm = searchParams.get(SEARCH_QUERY_PARAM);

  const updateSearchTerm = (term) => {
    setSearchParams(params => {
      params.set(SEARCH_QUERY_PARAM, term);
      return params;
    });
  };

  const deleteSearchTerm = () => {
    searchParams.delete(SEARCH_QUERY_PARAM);
    setSearchParams(searchParams);
  };
  return { searchTerm, updateSearchTerm, deleteSearchTerm };
}
