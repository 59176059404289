import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export function BotonContacto({ telefono }) {
  return (
    <Link
      to={"https://api.whatsapp.com/send?phone=" + telefono + "&text=Hola!"}
      target="_blank"
      className={`fixed z-10 right-4 bottom-4 md:bottom-8 md:right-8 bg-[#128C7E] hover:bg-[#075E54] text-white rounded-full shadow-md w-10 h-10 flex items-center justify-center transition-opacity duration-300 hover:ease-in-out hover:scale-105 ${
        !!telefono ? "opacity-100" : "opacity-0"
      }`}
    >
      <FontAwesomeIcon size="xl" icon={faWhatsapp} />
    </Link>
  );
}
