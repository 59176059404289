import {
  faCartCirclePlus,
  faChevronDown,
  faChevronUp,
  faCircleXmark,
  faMinus,
  faPlus,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Transition,
} from "@headlessui/react";
import classNames from "classnames";
import { isPointInPolygon } from "geolib";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import Modal from "react-modal";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import "../../../assets/styles/responsive.scss";
import {
  delete_list_carrito,
  get_lista_carrito_compras,
  limpiar_estado_confirmado,
  remove_al_carrito_sucursal,
  set_cambio_carrito,
  set_delete_pedido_carrito,
  store_carrito,
  store_carrito_data,
  update_carrito,
  update_list_carrito_compras,
} from "../../../redux/actions/carrito";
import { setSwAlert } from "../../../redux/actions/swalert";
import ButtonClose from "../../../widgets/button_close";
import Favorito from "../../../widgets/favoritoMycart";
import ItemCarritoModal from "../../../widgets/item_carrito_modal";
import LoaderSpinner from "../../../widgets/loaderSpinner";
import Alert from "../../Swalert";
import Direccion from "../direccion";
import { CarritoResumen } from "./carrito_resumen";
import { useThousandSeparator } from "../../../hooks/useThousandSeparator";
import { useGetSucursalMasCercana } from "../../../hooks/useGetSucursalesCercanas";

const customStyles = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "30",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    borderRadius: "0.75rem",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "visible",
  },
};

const style_favorito = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "31",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: "0",
    borderRadius: "0.75rem",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "auto",
  },
};
const style_direccion = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "31",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    borderRadius: "0.75rem",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "auto",
    padding: 0,
    border: 1,
  },
};

const pesajes = [
  { name: "500g", peso: "500" },
  { name: "1Kg", peso: "1000" },
  { name: "2Kg", peso: "2000" },
  { name: "3Kg", peso: "3000" },
  { name: "5Kg", peso: "5000" },
  { name: "10Kg", peso: "10000" },
  { name: "15Kg", peso: "15000" },
  { name: "20Kg", peso: "20000" },
  { name: "25Kg", peso: "25000" },
  { name: "30Kg", peso: "30000" },
  { name: "35Kg", peso: "35000" },
  { name: "40Kg", peso: "40000" },
  { name: "45Kg", peso: "45000" },
  { name: "50Kg", peso: "50000" },
];

const Carrito = ({
  limpiar_estado_confirmado,
  setSwAlert,
  get_lista_carrito_compras,
  set_delete_pedido_carrito,
  list_carrito,
  sucursal,
  branch_offices,
  tipo_entrega,
  carritos,
  producto_delete,
  direccion,
  store_carrito,
  store_carrito_data,
  delete_list_carrito,
  carrito_favorito,
  mt_indice,
  update_list_carrito_compras,
  set_cambio_carrito,
  update_carrito,
  remove_al_carrito_sucursal,
  cambio_carrito,
  error_save_carrito,
  width,
  height,
  exit,
  isFetchingCarrito,
}) => {
  const { calcularDistanciaEntreDosCoordenadas } = useGetSucursalMasCercana();
  const { numberFormatter } = useThousandSeparator();

  const [subPedidos, guardarsubPedidos] = useState([]);
  const [producto_aux, setProductoAux] = useState({});
  const [IdSucursal_aux, setIdSucursalAux] = useState("");
  const [modal_pieza, setModalPieza] = useState(false);
  const [modal_favorito, setModalFavorito] = useState(false);
  const [modal_direccion, setModalDireccion] = useState(false);
  const [sucursales, guardarSucursales] = useState([]);
  const [favoritoselect, setFavoritoselect] = useState({
    productId: 0,
    IdSucursal: 0,
    cantidad: 0,
  });
  const [clicDelete, setClicDelete] = useState(false);
  const endModalPieza = () => setModalPieza(false);
  const endModalDireccion = () => setModalDireccion(false);
  const endModalFavorito = () => {
    if (carrito_favorito) {
      get_lista_carrito_compras();
    }
    setModalFavorito(false);
  };
  const formatNumber = (value) => {
    return numberFormatter.format(value);
  };
  const _es_pieza = (producto) => {
    if (producto) {
      return producto.EsFraccionado && producto.PesoDefinido ? true : false;
    } else {
      return false;
    }
  };
  const _ahorroTotal = () => {
    let total = 0;
    subPedidos.forEach((sp) => {
      if (sp.check) {
        total += Number(sp.ahorro);
      }
    });
    return formatNumber(total);
  };

  const ahorro_total = _ahorroTotal();
  // useEffect(() => {
  //   const cartToUpdateValue = localStorage.getItem("cart-to-update");
  //   if (cartToUpdateValue) {
  //     const cartToUpdate = JSON.parse(cartToUpdateValue);
  //     store_carrito_data(cartToUpdate.params, cartToUpdate.carrito, cartToUpdate.producto_delete);
  //     localStorage.removeItem("cart-to-update");
  //   }
  // }, []);
  useEffect(() => {
    if (cambio_carrito && clicDelete) {
      get_lista_carrito_compras();
      set_cambio_carrito(false);
      setClicDelete(false);
    }
  }, [cambio_carrito, clicDelete]);

  useEffect(() => {
    if (error_save_carrito) {
      setSwAlert("Problemas técnicos para procesar la solicitud.", true, 400);
      limpiar_estado_confirmado();
      // "Problemas técnicos para continuar."
    }
  }, [error_save_carrito]);

  useEffect(() => {
    let lista = false;
    if (list_carrito) {
      if (list_carrito.SubPedidos.length > 0) {
        if (list_carrito.SubPedidos[0].check !== undefined) {
          lista = list_carrito.SubPedidos[0].check;
        }
      }
    }

    const cartToUpdateValue = localStorage.getItem("cart-to-update");
    const cartToUpdate = cartToUpdateValue
      ? JSON.parse(cartToUpdateValue)
      : null;

    const esMismaSucursal =
      cartToUpdate?.params.IdSucursal == sucursal.IdSucursal;

    if (lista === false) {
      const productosAAgregar = esMismaSucursal
        ? cartToUpdate?.carrito
        : carritos;

      const productosAEliminar = esMismaSucursal
        ? cartToUpdate?.producto_delete
        : producto_delete;
      store_carrito(
        sucursal,
        tipo_entrega,
        direccion,
        productosAAgregar,
        productosAEliminar
      );

      if (cartToUpdate !== null) {
        localStorage.removeItem("cart-to-update");
      }
    }

    if (cartToUpdate !== null && !esMismaSucursal) {
      store_carrito_data(
        cartToUpdate.params,
        cartToUpdate.carrito,
        cartToUpdate.producto_delete
      );
      localStorage.removeItem("cart-to-update");
    }

    return () => {
      guardarsubPedidos([]);
    };
  }, []);

  useEffect(() => {
    let array_aux = [];
    branch_offices.forEach((branch_office) => {
      let locatarios = branch_office.Locatarios;
      locatarios.forEach((sucursal) => {
        let array_zona_cobertura = sucursal.ZonasCobertura;
        array_zona_cobertura.forEach((zona) => {
          const points = zona.PuntosCobertura.map((e) => {
            return {
              latitude: Number(e.Latitud),
              longitude: Number(e.Longitud),
            };
          });
          let coberturaSucursal = isPointInPolygon(
            {
              latitude: Number(direccion.Latitud),
              longitude: Number(direccion.Longitud),
            },
            points
          );
          if (coberturaSucursal) {
            array_aux.push(sucursal);
          }
        });
      });
    });
    guardarSucursales(array_aux);
  }, [direccion]);

  const ordenarSubPedidosPorDistanciaYActivo = (subPedidos) => {
    return subPedidos.sort((a, b) => {
      const marketA = branch_offices.find(
        (branch) => branch.IdMarket === a.IdMarket
      );
      const locatarioA = marketA.Locatarios.find(
        (locatario) => locatario.IdSucursal === a.Sala.Id
      );
      const marketB = branch_offices.find(
        (branch) => branch.IdMarket === b.IdMarket
      );
      const locatarioB = marketB.Locatarios.find(
        (locatario) => locatario.IdSucursal === b.Sala.Id
      );

      const distanciaLocatarioA = calcularDistanciaEntreDosCoordenadas(
        locatarioA.Latitud,
        locatarioA.Longitud,
        direccion.Latitud,
        direccion.Longitud
      );
      const distanciaLocatarioB = calcularDistanciaEntreDosCoordenadas(
        locatarioB.Latitud,
        locatarioB.Longitud,
        direccion.Latitud,
        direccion.Longitud
      );

      if (b.check_activo !== a.check_activo) {
        // Primero, ordenar por check_activo (true primero)
        return b.check_activo - a.check_activo;
      } else {
        // Luego, ordenar por distancia de menor a mayor
        return distanciaLocatarioA - distanciaLocatarioB;
      }
    });
  };

  useEffect(() => {
    if (list_carrito) {
      let subPedidos = list_carrito.SubPedidos.map((ps) => {
        let _ahorro = 0;
        ps.Productos.forEach((prod) => {
          if (_es_pieza(prod) && prod.ConOferta) {
            let cantidad_pieza = _estimado_pieza(prod.Pesos);
            let aux = prod.ConOferta ? prod.PrecioOferta : prod.PrecioVenta;
            let total_sin_oferta = cantidad_pieza * prod.PrecioOriginal;
            let total_oferta = cantidad_pieza * aux;
            _ahorro += total_sin_oferta - total_oferta;
          } else if (prod.ConOferta) {
            _ahorro +=
              prod.PrecioOriginal * prod.Cantidad -
              prod.PrecioOferta * prod.Cantidad;
          }
        });
        let check_activo = sucursales.find(
          (sucursal) => sucursal.IdSucursal === ps.Sala.Id
        );
        let check = ps.check !== undefined ? ps.check : true;
        return {
          ...ps,
          check: check_activo ? check : false,
          ahorro: _ahorro,
          check_activo: check_activo ? true : false,
        };
      });
      let subPedidosOrdenadosPorDistancia = ordenarSubPedidosPorDistanciaYActivo(
        [...subPedidos],
        branch_offices,
        direccion
      );

      // let subPedidosOrdenadosPorActivo = ordenarSubPedidosPorActivo([...subPedidosOrdenadosPorDistancia]);
      guardarsubPedidos(subPedidosOrdenadosPorDistancia);
    }
  }, [list_carrito, sucursales, branch_offices, direccion]);

  const _estimado_pieza = (pesos) => {
    let result = 0;
    pesos.forEach((peso) => {
      result += peso.Piezas * peso.PesoAproxMax;
    });

    return Number(result);
  };

  useEffect(() => {
    if (Object.keys(producto_aux).length > 0) {
      actualizarDato(
        producto_aux.IdProducto,
        "ninguna",
        IdSucursal_aux,
        "pieza"
      );
    }
  }, [producto_aux]);

  const _importeSubTotal = (importeSubTotal, array) => {
    let precio_no_disponible = 0;
    array.forEach((prod) => {
      if (!prod.Disponible) {
        precio_no_disponible += Number(prod.Importe);
      }
    });
    let result = Number(importeSubTotal) - precio_no_disponible;
    return formatNumber(Number(result));
  };
  const redondeo = (num, dec) => {
    return Number(num.toFixed(dec));
  };
  const startModalDireccion = () => {
    setModalDireccion(true);
  };

  const startModalFavorito = (
    favorito,
    cantidad,
    productId,
    IdSucursal,
    Pesos
  ) => {
    setFavoritoselect({
      productId: productId,
      IdSucursal: IdSucursal,
      cantidad: cantidad,
      Pesos: Pesos,
    });
    setModalFavorito(true);
  };

  const startModalPieza = (producto, IdSucursal) => {
    setProductoAux(producto);
    setIdSucursalAux(IdSucursal);
    setModalPieza(true);
  };

  const check_carrito_sucursal = (IdPedido) => {
    const objetoClonado = Object.assign({}, list_carrito);
    let subpedidosaux = subPedidos.map((ps) => {
      if (ps.IdPedido === IdPedido) {
        return {
          ...ps,
          check: !ps.check,
        };
      } else {
        return { ...ps };
      }
    });
    const listAux = {
      IdPedidoPrincipal: objetoClonado.IdPedidoPrincipal,
      IdUbicacion: objetoClonado.IdUbicacion,
      ImporteTotal: objetoClonado.ImporteTotal,
      InstruccionDelivery: objetoClonado.InstruccionDelivery,
      InstruccionEntrega: objetoClonado.InstruccionEntrega,
      NroTotalProductos: objetoClonado.NroTotalProductos,
      SubPedidos: subpedidosaux,
    };
    update_list_carrito_compras(listAux);
  };

  const eliminarCarrito = (idPedido, IdMarket, idSala) => {
    Swal.fire({
      title: "Está seguro de eliminar la lista?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#32338E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        delete_sublista_carrito(idPedido, IdMarket, idSala);
      }
    });
  };

  const delete_sublista_carrito = (idPedido, IdMarket, idSala) => {
    if (idSala === sucursal.IdSucursal) {
      set_delete_pedido_carrito(true);
      update_carrito([]);
    }

    delete_list_carrito(idPedido);
  };

  const _es_peso = (producto) => {
    if (producto) {
      return producto.EsFraccionado && !producto.PesoDefinido ? true : false;
    } else {
      return false;
    }
  };

  const obtener_precio = (producto) => {
    let monto_aux = producto.ConOferta
      ? producto.PrecioOferta
      : producto.PrecioVenta;
    return monto_aux;
  };

  const _importe = (array) => {
    let resultado = 0;
    array.forEach((elem) => {
      resultado += Number(elem.Importe);
    });
    return resultado;
  };

  const actualizarDato = (productId, operacion, IdSucursal, pieza) => {
    let objetoClonado = Object.assign({}, list_carrito);
    let subpedidosaux = [];
    for (let item of objetoClonado.SubPedidos) {
      let ob = item;
      let productoaux = item.Productos;
      if (item.IdMarket == IdSucursal) {
        productoaux = [];
        for (let prod of item.Productos) {
          const aux = prod;
          if (pieza == "pieza" && prod.IdProducto == productId) {
            aux.Cantidad = producto_aux.Cantidad;
            aux.Importe = producto_aux.Importe;
          } else {
            if (prod.IdProducto == productId) {
              let quantity = 1;
              if (_es_peso(prod)) {
                quantity = 0.1;
              }
              if (operacion == "incremento") {
                aux.Cantidad = Math.round((prod.Cantidad + quantity) * 10) / 10;
              }
              if (operacion == "decremento" && prod.Cantidad > 0) {
                aux.Cantidad = Math.round((prod.Cantidad - quantity) * 10) / 10;
              }
              let monto = obtener_precio(prod);
              let importe = aux.Cantidad * monto;
              aux.Importe = importe;
            }
          }
          productoaux.push(aux);
        }
      }
      ob.ImporteSubtotal = _importe(productoaux);
      subpedidosaux.push(ob);
    }

    const listAux = {
      IdPedidoPrincipal: objetoClonado.IdPedidoPrincipal,
      IdUbicacion: objetoClonado.IdUbicacion,
      ImporteTotal: objetoClonado.ImporteTotal,
      InstruccionDelivery: objetoClonado.InstruccionDelivery,
      InstruccionEntrega: objetoClonado.InstruccionEntrega,
      NroTotalProductos: objetoClonado.NroTotalProductos,
      SubPedidos: subpedidosaux,
    };
    update_list_carrito_compras(listAux);
    set_cambio_carrito(true);
  };

  const fueraFoco = () => {};

  const actualizar_producto_aux = (valor, operacion) => {
    let producto = Object.assign({}, producto_aux);
    if (operacion == "incremento") {
      redondeo(producto.Cantidad + valor, 2);
      producto.Cantidad = redondeo(producto.Cantidad + valor, 2);
    }
    if (operacion == "decremento") {
      producto.Cantidad = redondeo(producto.Cantidad - valor, 2);
    }
    if (operacion == "ninguna") {
      let resultado = valor.peso / 1000;
      producto.Cantidad = redondeo(resultado, 2);
    }
    let monto = obtener_precio(producto);
    let importe = producto.Cantidad * monto;
    producto.Importe = importe;
    setProductoAux(producto);
  };

  const get_total_carrito = () => {
    let total = 0;
    let precio_no_disponible = 0;
    subPedidos.forEach((sp) => {
      if (sp.check) {
        sp.Productos.forEach((prod) => {
          if (!prod.Disponible) {
            precio_no_disponible += prod.Importe;
          }
        });
      }
    });

    subPedidos.forEach((sp) => {
      if (sp.check) {
        total += sp.ImporteSubtotal;
      }
    });

    let result = parseFloat(total) - parseFloat(precio_no_disponible);
    return formatNumber(Number(result));
  };

  const validar_monto_minimo = () => {
    let cantidad = 0;
    for (let sp of subPedidos) {
      if (sp.check) {
        cantidad = 1;
        if (sp.ImpMinimoPedido > sp.ImporteSubtotal) {
          return {
            sala: sp.Sala,
            monto_faltante: sp.ImpMinimoPedido - sp.ImporteSubtotal,
            validacion: false,
            cantidad: cantidad,
          };
        }
      }
    }
    return {
      sala: null,
      monto_faltante: 0,
      validacion: true,
      cantidad: cantidad,
    };
  };

  const mensaje_alert = (text) => {
    Swal.fire({
      icon: "warning",
      text: text,
      showConfirmButton: true,
      confirmButtonText: "Cerrar",
      confirmButtonColor: "#32338E",
    });
  };

  const getTexto = (t) => {
    return (
      "Faltan Bs. " +
      formatNumber(t.monto_faltante) +
      " para completar el mínimo de compra en " +
      t.sala.Descripcion
    );
  };

  const actualizar_lista_carrito_db = async () => {
    for (let item of subPedidos) {
      let params = {
        IdMarket: item.IdMarket,
        IdSucursal: item.Sala.Id,
        IdTipoEntrega: item.IdTipoEntrega,
        IdDireccion: direccion.Id,
      };
      let carritos_aux = item.Productos;

      await store_carrito_data(params, carritos_aux, []);
    }
  };

  const deleteDato = (productId, IdMarket, IdSucursal) => {
    let params = {
      IdMarket: IdMarket,
      IdSucursal: IdSucursal,
      IdTipoEntrega: tipo_entrega.Id,
      IdDireccion: direccion.Id,
    };
    let carritos_aux = get_carrito_sucursal(IdSucursal);
    let producto_delete = [{ IdProducto: productId }];
    setClicDelete(true);

    store_carrito_data(params, carritos_aux, producto_delete, true);
    if (IdSucursal === sucursal.IdSucursal) {
      remove_al_carrito_sucursal({ IdProducto: productId });
    }
  };

  const get_carrito_sucursal = (IdSucursal) => {
    let array = [];
    subPedidos.forEach((sp) => {
      if (sp.Sala.Id === IdSucursal) {
        array = sp.Productos;
      }
    });
    return array;
  };

  const volverAtras = () => {
    exit();
  };

  const guardarProceso = () => {
    let validacion = validar_monto_minimo();
    if (validacion.validacion) {
      localStorage.setItem("mycart", JSON.stringify(list_carrito));
      localStorage.setItem("monto_mycart", JSON.stringify(get_total_carrito()));
      actualizar_lista_carrito_db();
      if (validacion.cantidad === 1) {
        setTimeout(() => {
          next_indice();
        }, 1000);
      } else {
        mensaje_alert(
          "No tiene productos seleccionados para realizar la compra."
        );
      }
    } else {
      let texto = getTexto(validacion);
      mensaje_alert(texto);
    }
  };

  const next_indice = () => {
    let objetoClonado = Object.assign({}, list_carrito);
    let listAux = {
      IdPedidoPrincipal: objetoClonado.IdPedidoPrincipal,
      IdUbicacion: objetoClonado.IdUbicacion,
      ImporteTotal: objetoClonado.ImporteTotal,
      InstruccionDelivery: objetoClonado.InstruccionDelivery,
      InstruccionEntrega: objetoClonado.InstruccionEntrega,
      NroTotalProductos: objetoClonado.NroTotalProductos,
      SubPedidos: subPedidos,
    };
    update_list_carrito_compras(listAux);
    mt_indice(2);
  };

  const disableGuardarProceso = useMemo(
    () =>
      !(subPedidos?.filter((subPedido) => subPedido.check_activo).length > 0) ||
      !(subPedidos?.filter((subPedido) => subPedido.check).length > 0),
    [subPedidos]
  );
  return (
    <>
      <Alert></Alert>
      <div className={classNames("w-full md:w-[60%] flex flex-col h-full")}>
        <div className="w-full bg-white rounded-t-lg md:rounded-lg h-full overflow-hidden">
          <div className="flex flex-col h-full">
            <div className="bg-hiperorange w-full text-white flex items-center justify-center  content_title relative">
              <h2 className="p-0 m-0 title_carrito_responsivo">Mi carrito</h2>
              <div className="absolute right-2 ">
                <ButtonClose
                  className="text-white bg-transparent"
                  onClick={() => volverAtras()}
                />
              </div>
            </div>

            <div
              className={classNames(
                "bg-white overflow-y-auto flex flex-col h-full"
              )}
            >
              <div className="px-2 pb-2 pt-1">
                <p className="text-black mb-0">Dirección </p>
                <span
                  className="text-black font-bold cursor-pointer no-underline hover:underline  "
                  onClick={() => startModalDireccion()}
                >
                  {direccion?.Descripcion}
                  <FontAwesomeIcon
                    style={{ width: "15px", height: "15px" }}
                    icon={faChevronDown}
                  />
                </span>
              </div>
              <div className=" w-auto overflow-auto flex flex-col h-full">
                {!isFetchingCarrito ? (
                  subPedidos.length > 0 ? (
                    subPedidos.map((pedido, key) => (
                      <div
                        className="sm:w-full border border-hiperorange shadow px-0  mb-2 w-full last-of-type:mb-32 md:last-of-type:mb-0"
                        key={key}
                      >
                        <Disclosure defaultOpen as="div" className="my-2 px-2">
                          {({ open }) => (
                            <>
                              <div
                                className=" w-full px-2 bg-hiperplomo_claro overflow-hidden py-0 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-75"
                                style={{
                                  borderTopLeftRadius: "8px",
                                  borderTopRightRadius: "8px",
                                  borderBottom: "1px solid #9CA3AF",
                                }}
                              >
                                <div
                                  className={classNames(
                                    "w-full",
                                    "text-sm",
                                    "flex",
                                    "flex-wrap",
                                    "justify-between",
                                    "items-center",
                                    "md:px-2",
                                    {
                                      "bg-hiperplomo_claro":
                                        pedido.check_activo,
                                    }
                                  )}
                                  style={{
                                    boxShadow:
                                      "0px 10px 10px -10px rgba(33, 35, 38, 0.1)",
                                    color: pedido.check_activo
                                      ? "#ffffff"
                                      : "#494A4E",
                                  }}
                                >
                                  <div className="flex flex-col py-2 w-full">
                                    <div className="flex items-center gap-2">
                                      {pedido.check_activo && (
                                        <div className="round deshabilitar leading-none">
                                          <input
                                            type="checkbox"
                                            checked={pedido.check}
                                            onChange={() =>
                                              check_carrito_sucursal(
                                                pedido.IdPedido
                                              )
                                            }
                                            className="checkbox hidden"
                                            id={"checkbox" + pedido.IdPedido}
                                          />
                                          <label
                                            htmlFor={
                                              "checkbox" + pedido.IdPedido
                                            }
                                          ></label>
                                        </div>
                                      )}
                                      <div className="flex flex-col text-black justify-center align-middle m-0">
                                        <h6 className="m-0 font-bold text-black uppercase text-md leading-snug md:text-base md:leading-normal">
                                          {`${pedido.TipoServicio.Descripcion} - ${pedido.Sala.Descripcion}`}
                                        </h6>
                                        <p className="m-0">
                                          {" "}
                                          {pedido.Productos.length}
                                          {" productos"}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="flex justify-between">
                                      <div className="self-center">
                                        <button
                                          className={classNames(
                                            "cursor-pointer bg-hiperorange hover:bg-hiperorange_hover p-1 text-white text-start",
                                            {
                                              "bg-hiperplomo_fuerte hover:bg-hiperplomo_fuerte_hover":
                                                !pedido.check &&
                                                pedido.check_activo,
                                            }
                                          )}
                                          style={{ borderRadius: "8px" }}
                                          onClick={() =>
                                            eliminarCarrito(
                                              pedido.IdPedido,
                                              pedido.IdMarket,
                                              pedido.Sala.Id
                                            )
                                          }
                                        >
                                          <span className="mx-2 text-sm md:text-base">
                                            Eliminar lista
                                          </span>
                                        </button>

                                        {!pedido.check_activo && (
                                          <div className="text-sm md:text-base font-bold text-red-600 ">
                                            Se encuentra fuera de cobertura de
                                            entrega
                                          </div>
                                        )}
                                      </div>

                                      <div className="flex text-sm md:text-base font-bold text-black">
                                        <div className="flex flex-col items-end text-right">
                                          <p className="mb-1">
                                            {" "}
                                            Compra mínima :
                                          </p>
                                          {pedido.ahorro > 0 && (
                                            <p
                                              className={classNames(
                                                "rounded-bl-full rounded-tl-full pl-3 bg-hiperorange text-white py-1 mb-1",
                                                {
                                                  "bg-hiperplomo_fuerte":
                                                    !pedido.check,
                                                }
                                              )}
                                            >
                                              {" "}
                                              Total ahorro :
                                            </p>
                                          )}

                                          <p className="mb-1">
                                            {" "}
                                            Total parcial :{" "}
                                          </p>
                                        </div>
                                        <div className="flex flex-col items-end text-right ">
                                          <p className="pr-2 pl-1 mb-1">
                                            Bs.{" "}
                                            {formatNumber(
                                              pedido.ImpMinimoPedido
                                            )}{" "}
                                          </p>
                                          {pedido.ahorro > 0 && (
                                            <p
                                              className={classNames(
                                                "rounded-br-full w-full rounded-tr-full  bg-hiperorange pl-1 pr-2 text-white py-1 mb-1",
                                                {
                                                  "bg-hiperplomo_fuerte":
                                                    !pedido.check,
                                                }
                                              )}
                                            >
                                              Bs.{" "}
                                              {formatNumber(
                                                Number(pedido.ahorro)
                                              )}
                                            </p>
                                          )}

                                          <p className="pr-2 pl-1 mb-1">
                                            Bs.{" "}
                                            {_importeSubTotal(
                                              pedido.ImporteSubtotal,
                                              pedido.Productos
                                            )}{" "}
                                          </p>
                                        </div>
                                      </div>
                                      {/* <div className="flex flex-col gap-0.5 flex-grow hipergrisdark text-right text-hiperdark">
                                        <div className="text-sm md:text-base font-bold">
                                          Compra mínima : Bs.{" "}
                                          {pedido.ImpMinimoPedido}
                                        </div>

                                        <div>
                                          {pedido.ahorro > 0 && (
                                            <div
                                              className={classNames(
                                                "px-3 py-1 bg-hiperorange text-white rounded-full inline-block",
                                                {
                                                  "bg-hiperplomo_fuerte":
                                                    !pedido.check,
                                                }
                                              )}
                                            >
                                              <p className="mb-0 font-bold text-sm md:text-base">
                                                Ahorro : Bs. {pedido.ahorro}
                                              </p>
                                            </div>
                                          )}
                                        </div>

                                        <div>
                                          <p
                                            className="mb-0 text-sm md:text-base font-bold"
                                          >
                                            Total parcial Bs.{" "}
                                            {_importeSubTotal(
                                              pedido.ImporteSubtotal,
                                              pedido.Productos
                                            )}
                                          </p>
                                        </div>
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <DisclosurePanel className="flex justify-between  mt-1 flex-column ">
                                  <div className="overflow-y-auto  container_scroll">
                                    {pedido.Productos.map((producto, key) => (
                                      <ItemCarritoModal
                                        producto={producto}
                                        indice={key}
                                        IdMarket={pedido.IdMarket}
                                        IdSucursal={pedido.Sala.Id}
                                        favorito={producto.EnListadoCliente}
                                        ps_updateDato={actualizarDato}
                                        ps_startModalPieza={startModalPieza}
                                        ps_startModalFavorito={
                                          startModalFavorito
                                        }
                                        ps_deleteDato={deleteDato}
                                        key={producto.IdProducto}
                                        ps_pedidoCheked={pedido.check}
                                        carritoTieneCobertura={
                                          pedido.check_activo
                                        }
                                      ></ItemCarritoModal>
                                    ))}
                                  </div>
                                </DisclosurePanel>
                              </Transition>
                              <div
                                className="w-full bg-hiperorange flex justify-center overflow-hidden"
                                style={{
                                  borderBottomLeftRadius: "10px",
                                  borderBottomRightRadius: "10px",
                                  height: "30px",
                                }}
                              >
                                <DisclosureButton
                                  className={classNames(
                                    "bg-hiperorange hover:bg-hiperorange_hover",
                                    {
                                      "bg-hiperplomo_fuerte hover:bg-hiperplomo_fuerte_hover":
                                        !pedido.check,
                                    }
                                  )}
                                  style={{ width: "100%" }}
                                >
                                  <FontAwesomeIcon
                                    className={`${
                                      !open ? "rotate-180 transform" : ""
                                    } h-5 w-5 text-white`}
                                    style={{ width: "28px", height: "28px" }}
                                    icon={faChevronUp}
                                  />
                                </DisclosureButton>
                              </div>
                            </>
                          )}
                        </Disclosure>
                      </div>
                    ))
                  ) : (
                    <div className="flex flex-col gap-4 justify-center items-center mt-2 min-h-60 text-gray-400">
                      <FontAwesomeIcon icon={faCartCirclePlus} size="6x" />
                      <div className="text-xl">
                        Agrega productos a tu carrito
                      </div>
                    </div>
                  )
                ) : (
                  <div className="h-32 flex flex-column justify-center items-center font-bold text-xl">
                    <LoaderSpinner bool={true} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:w-[22%] sticky bottom-0">
        <CarritoResumen
          ahorro_total={ahorro_total}
          total_carrito={get_total_carrito()}
          volverAtras={volverAtras}
          guardarProceso={guardarProceso}
          disableGuardarProceso={disableGuardarProceso}
        />
      </div>
      <Modal
        isOpen={modal_pieza}
        ariaHideApp={false}
        onRequestClose={endModalPieza}
        className="fixed inset-0 flex items-center justify-center p-2 md:p-4 w-full md:max-w-md  md:mx-auto focus-visible:outline-none"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-30"
        contentLabel="Seleccionar peso"
      >
        <div className="w-full flex flex-col h-full md:max-h-[600px]">
          <div className="w-full bg-white rounded-lg md:rounded-lg h-full overflow-hidden">
            <div className="flex flex-col h-full">
              <div className="bg-hiperorange w-100 relative text-white flex items-center justify-center h-[52px]  content_title">
                <h2 className="p-0 m-0 title_carrito_responsivo">
                  Seleccione el Peso
                </h2>
                <div className="absolute right-2 ">
                  <ButtonClose
                    className="text-white bg-transparent"
                    onClick={() => endModalPieza()}
                  />
                </div>
              </div>
              <div className="bg-white overflow-y-auto grid grid-cols-2 w-full h-full gap-2 p-4">
                {pesajes.map((pesaje, index) => (
                  <button
                    key={`peso-${index}`}
                    className="w-full bg-[#EEEEEE] p-2 rounded hover:bg-gray-300 font-semibold text-xl flex items-center justify-center"
                    onClick={() => actualizar_producto_aux(pesaje, "ninguna")}
                  >
                    {pesaje.name}
                  </button>
                ))}
              </div>
              <div className="border-t-2 border-[#EEEEEE] p-2 flex justify-center sticky bottom-0">
                <div className="flex border-[3px] border-hiperorange p-1 rounded">
                  <button
                    disabled={producto_aux.Cantidad <= 0.1}
                    className="w-8 h-8 bg-hiperorange hover:bg-hiperorange_hover text-white rounded flex items-center justify-center disabled:cursor-not-allowed"
                    onClick={() => actualizar_producto_aux(0.1, "decremento")}
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </button>
                  <div className="mx-2 flex align-items-center items-center justify-center min-w-10">
                    <span>
                      {Number(producto_aux.Cantidad * 1000).toFixed(0)}g
                    </span>
                  </div>
                  <button
                    className="w-8 h-8 bg-hiperorange hover:bg-hiperorange_hover text-white rounded flex items-center justify-center"
                    onClick={() => actualizar_producto_aux(0.1, "incremento")}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modal_favorito}
        ariaHideApp={false}
        onRequestClose={endModalFavorito}
        className="fixed inset-0 flex items-center justify-center p-2 md:p-4 w-full md:max-w-md  md:mx-auto focus-visible:outline-none"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-30"
        contentLabel="favorito"
      >
        <Favorito
          idProducto={favoritoselect.productId}
          IdSucursal={favoritoselect.IdSucursal}
          cantidad={favoritoselect.cantidad}
          pesos={favoritoselect.Pesos}
          onBlur={fueraFoco}
          ps_closeModalFavorito={endModalFavorito}
        ></Favorito>
      </Modal>

      <Modal
        isOpen={modal_direccion}
        ariaHideApp={false}
        onRequestClose={endModalDireccion}
        className="fixed inset-0 flex items-center justify-center p-2 md:p-4 w-full md:w-[25rem] mx-auto focus-visible:outline-none"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-30"
        contentLabel="direccion"
      >
        <Direccion close_modal={endModalDireccion} />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  branch_offices: state.BranchOffice.branch_offices,
  list_carrito: state.Carrito.list_carrito,
  isFetchingCarrito: state.Carrito.isFetching,
  carrito_favorito: state.Carrito.carrito_favorito,
  sucursal: state.BranchOffice.sucursal,
  tipo_entrega: state.BranchOffice.tipo_entrega,
  direccion: state.Location.direccion,
  carritos: state.Carrito.carrito,
  cambio_carrito: state.Carrito.cambio_carrito,
  producto_delete: state.Carrito.producto_delete,
  error_save_carrito: state.Carrito.error_save_carrito,
});

export default connect(mapStateToProps, {
  get_lista_carrito_compras,
  update_carrito,
  store_carrito,
  store_carrito_data,
  delete_list_carrito,
  update_list_carrito_compras,
  set_cambio_carrito,
  remove_al_carrito_sucursal,
  limpiar_estado_confirmado,
  set_delete_pedido_carrito,
  setSwAlert,
})(Carrito);
