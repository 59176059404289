import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../hocs/Layout";
import { faPlus, faQrcode, faReceipt } from "@fortawesome/pro-solid-svg-icons";
import Modal from "react-modal";
import ItemListaCompra from "../../widgets/item_lista_compra";
import { connect } from "react-redux";
import {
  get_lista_compra,
  eliminar_lista_compra,
  limpiar_data_lista_compra,
} from "../../redux/actions/shoppingList";
import ModalEditListaCompra from "./Partials/ModalEditListaCompra.js";
import ContenidoCompartir from "./Partials/ModalCompartirLista.js";
import "../../assets/styles/globalColorPrincipal.css";
import "../../assets/styles/stylePageGlobals.scss";
import Swal from "sweetalert2";
import Alert from "../../components/Swalert";
import "../../assets/styles/responsive.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoaderSpinner from "../../widgets/loaderSpinner.js";
import ModalAddListaCompra from "./Partials/ModalAddListaCompra.js";
import { Helmet } from "react-helmet-async";
import { useIsDefaultStore } from "../../hooks/useIsDefaultStore";

const customStylesFondo = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "1",
  },
  content: {
    borderRadius: "10px",
    padding: "0px",
    border: "0px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "calc(100% - 32px)",
    overflow: "hidden",
  },
};

const PurchaseList = ({
  get_lista_compra,
  eliminar_lista_compra,
  shopping_list,
  sucursal,
  cambio_sucursal,
  peticion,
  limpiar_data_lista_compra,
}) => {
  const navigate = useNavigate();
  const { ciudadp, sucursalp } = useParams();
  const [modalShare, setModalShare] = React.useState(false);
  const [modalAddListPurchase, setAddListPurchase] = React.useState(false);
  const [modalEditListPurchase, setEditListPurchase] = React.useState(false);

  const startEditListPurchase = () => setEditListPurchase(true);
  const startAddListPurchase = () => setAddListPurchase(true);

  const endAddListPurchase = () => setAddListPurchase(false);
  const endAdEditListPurchase = () => setEditListPurchase(false);

  const openModalShare = () => setModalShare(true);
  const closeModalShare = () => setModalShare(false);
  let IdSucursal = sucursal.IdSucursal;

  const [listaCompraSeleccionada, setListaCompraSeleccionada] = useState({
    Id: "",
    Descripcion: "",
    Color: "",
    ImporteTotal: "",
    HashListaCompra: "",
  });

  useEffect(() => {
    get_lista_compra(IdSucursal);
  }, [IdSucursal]);

  useEffect(() => {
    return () => {
      limpiar_data_lista_compra();
    };
  }, []);

  const setSelectedList = (object, opcion) => {
    setListaCompraSeleccionada(object);
    if (opcion == "edit") {
      startEditListPurchase();
    }
    if (opcion == "share") {
      openModalShare();
    }
  };

  const closeModal = () => {
    endAdEditListPurchase();
  };

  const closeModalStore = () => {
    endAddListPurchase();
  };

  const deleteListaCompra = (Id) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Recuerda que no podrás revertir esta acción.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#32338E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteLista(Id);
      }
    });
  };

  const deleteLista = async (id) => {
    await eliminar_lista_compra({ Id: id, IdSucursal: IdSucursal });
    // await obtenerDatos();
  };

  const navegarScanear = async () => {
    let url_param = await get_url();
    navigate(url_param + "/escanearCodigo");
  };

  const navegarNuevaLista = async () => {
    let url_param = await get_url();
    navigate(url_param + "/nueva-lista-compra");
  };
  const get_url = async () => {
    let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
    if (ciudadp && sucursalp) {
      return "/" + ciudadp + "/" + sucursalp;
    } else if (url_nav) {
      return "/" + url_nav.url_parameters;
    }
  };
  const verifyLista = () => {
    if (
      shopping_list !== undefined &&
      shopping_list !== null &&
      shopping_list.length === 0
    ) {
      return true;
    }
    return false;
  };

  const verifyListaOk = () => {
    if (
      shopping_list !== undefined &&
      shopping_list !== null &&
      shopping_list.length > 0
    ) {
      return true;
    }
    return false;
  };
  const { isDefaultStore } = useIsDefaultStore();

  return (
    <>
      <Helmet>
        <title>{`Mis listas de compras | ${
          isDefaultStore ? "Hipermaxi" : sucursal?.Descripcion || ""
        }`}</title>
        {/* <meta name="“robots”" content="noindex" /> */}
      </Helmet>
      <Layout>
        <Alert></Alert>
        <div className="pt-4 z-10">
          <Alert></Alert>
          <div className="container">
            <h1 className="text-2xl md:text-3xl text-center text-hiperorange mb-4">
              Mis listas de compras
            </h1>
            <div className="flex w-full flex-col pb-4 justify-center items-center h-full">
              <div className="w-full md:w-[40rem] h-full md:h-[40rem] border bg-white border-hiperorange shadow-md rounded-xl">
                <div className="flex justify-between flex-col h-full p-3">
                  <div className="flex flex-col h-full overflow-y-auto min-h-[15rem]">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                      {verifyListaOk() ? (
                        shopping_list.map((item_shopping_list) => (
                          <ItemListaCompra
                            ps_shopping_list={item_shopping_list}
                            ps_opcSelectedList={setSelectedList}
                            key={item_shopping_list.Id}
                            ps_deleteList={deleteListaCompra}
                            ps_openModalShare={openModalShare}
                          ></ItemListaCompra>
                        ))
                      ) : (
                        <>
                          {peticion ? (
                            <LoaderSpinner bool={peticion} />
                          ) : (
                            verifyLista() &&
                            !peticion && (
                              <div className="col-span-2 flex flex-col gap-4 justify-center items-center mt-2 min-h-60 text-gray-400">
                                <FontAwesomeIcon icon={faReceipt} size="6x" />
                                <div className="text-xl">
                                  No tienes listas de compras para mostrar
                                </div>
                              </div>
                            )
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="bg-white w-full h-auto flex justify-around px-3 py-4 text-white gap-2 sticky bottom-0 z-10 md:static">
                    <button
                      className="bg-hiperblue hover:bg-hiperblue_hover flex flex-col justify-center text-center justify-items-center p-3 rounded-md group text-sm md:text-base"
                      onClick={() => navegarScanear()}
                    >
                      <FontAwesomeIcon
                        icon={faQrcode}
                        size="xl"
                        className="self-center transition duration-200 ease-in-out transform group-hover:scale-110"
                      />
                      Escanear lista
                    </button>
                    <button
                      className="bg-hiperblue hover:bg-hiperblue_hover flex flex-col justify-center text-center justify-items-center p-3 rounded-md group text-sm md:text-base"
                      onClick={() => startAddListPurchase()}
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        size="xl"
                        className="self-center transition duration-200 ease-in-out transform group-hover:scale-110"
                      />
                      Nueva lista
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          isOpen={modalShare}
          onRequestClose={closeModalShare}
          ariaHideApp={false}
          className="fixed inset-0 flex items-center justify-center p-2 md:p-4 w-full md:max-w-sm  md:mx-auto focus-visible:outline-none"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-30"
          contentLabel="share"
        >
          <ContenidoCompartir
            listaCompra={listaCompraSeleccionada}
            ps_closeModal={closeModalShare}
          ></ContenidoCompartir>
        </Modal>

        {/* nueva lista compra */}
        <Modal
          isOpen={modalAddListPurchase}
          onRequestClose={endAddListPurchase}
          ariaHideApp={false}
          className="fixed inset-0 flex items-center justify-center p-2 md:p-4 w-full md:max-w-sm  md:mx-auto focus-visible:outline-none"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-30"
          contentLabel="shopping"
        >
          {/* container content */}
          <ModalAddListaCompra
            ps_closeModalAdd={closeModalStore}
            IdSucursal={IdSucursal}
          ></ModalAddListaCompra>
        </Modal>

        {/* editar lista compra */}
        <Modal
          isOpen={modalEditListPurchase}
          onRequestClose={endAdEditListPurchase}
          ariaHideApp={false}
          className="fixed inset-0 flex items-center justify-center p-2 md:p-4 w-full md:max-w-sm  md:mx-auto focus-visible:outline-none"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-30"
          contentLabel="shopping"
        >
          {/* container content */}
          <ModalEditListaCompra
            listaCompra={listaCompraSeleccionada}
            setFormData={setListaCompraSeleccionada}
            ps_closeModalEdit={closeModal}
            IdSucursal={IdSucursal}
          ></ModalEditListaCompra>
        </Modal>
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => ({
  sucursal: state.BranchOffice.sucursal,
  shopping_list: state.Shopping_list.shopping_list,
  peticion: state.Peticion.peticion,
  cambio_sucursal: state.BranchOffice.cambio_sucursal,
});

export default connect(mapStateToProps, {
  get_lista_compra,
  eliminar_lista_compra,
  limpiar_data_lista_compra,
})(PurchaseList);
