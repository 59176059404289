import { MensajeApi } from "../../http/domain/interfaces/mensaje-api";
import { createAxiosHttpService } from "../../http/infrastructure/axios-http-service";
import { mapToURLSearchParams } from "../../http/infrastructure/utils/http-utils";
import { CustomerBagHistoryGetParams } from "../domain/dto/customer-bag-history-get-params";
import { HiperPuntosRepository } from "../domain/hiper-puntos-repository";
import { CustomerBag } from "../domain/interfaces/customer-bag";
import { CustomerBagHistory } from "../domain/interfaces/customer-bag-history";
import { CustomerBagPointData } from "../domain/interfaces/customer-bag-point-data";

const http = createAxiosHttpService();
const apiVersion = process.env.REACT_APP_API_VERSION;
// const baseUrl = getBaseUrlBasedOnDomain(
// 	env.fidelizacionAtencionClienteBackendApiPath,
// 	env.fidelizacionAtencionClienteBackendApiDomainPath
// );
const baseUrl = process.env.REACT_APP_API_URL;
const backendPath = `${baseUrl}/api/${apiVersion}`;

export function createApiHiperPuntosRepository(): HiperPuntosRepository {
  async function getCustomerBags(): Promise<MensajeApi<CustomerBag[]>> {
    const response = await http.get<MensajeApi<CustomerBag[]>>(
      `${backendPath}/fidelizacion-mobile/obtener-bolsas`
    );

    return response;
  }

  async function getCustomerBagHistory(
    params: CustomerBagHistoryGetParams
  ): Promise<MensajeApi<CustomerBagHistory>> {
    const searchParams = mapToURLSearchParams(params);
    const response = await http.get<MensajeApi<CustomerBagHistory>>(
      `${backendPath}/fidelizacion-mobile/obtener-historico-por-idbolsa?${searchParams}`
    );

    return response;
  }

  return {
    getCustomerBags,
    getCustomerBagHistory,
  };
}
