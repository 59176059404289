import ButtonClose from "../../../../../widgets/button_close";
import ButtonText from "../../../../../widgets/button_text";
import { HiperPuntosProfileCode } from "./hiper-puntos-profile-code";

interface HiperPuntosProfileQrModalContentProps {
  onCloseModal?: () => void;
  profileCode: string;
  mensaje: string;
}

export function HiperPuntosProfileQrModalContent({
  onCloseModal,
  profileCode,
  mensaje,
}: HiperPuntosProfileQrModalContentProps) {
  const handleCloseModal = () => {
    if (onCloseModal) onCloseModal();
  };

  return (
    <div className="w-full flex flex-col h-full rounded-lg md:rounded-lg">
      <div className="w-full rounded-lg md:rounded-lg h-full overflow-hidden">
        <div className="flex flex-col h-full">
          <div className="w-100 relative text-white flex items-center justify-center h-[52px]  content_title">
            <h2 className="p-0 m-0 text-lg md:text-xl">Código Cliente</h2>
            {/* <div className="absolute right-2 ">
              <ButtonClose
                className="text-white bg-transparent"
                onClick={handleCloseModal}
              />
            </div> */}
          </div>
          <div className="overflow-y-auto w-full h-full gap-8 p-4 text-white flex flex-col items-center">
            {mensaje && (
              <div className="text-center text-lg font-semibold leading-snug">
                {mensaje}
              </div>
            )}
            <HiperPuntosProfileCode profileCode={profileCode} />
            <div className="p-2 flex justify-center sticky bottom-0">
              <div>
                <ButtonText
                  label="Cerrar"
                  onClick={handleCloseModal}
                  className="bg-hiperblue hover:bg-hiperblue_hover text-white"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
