import React from "react";
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import "../../assets/styles/globalColorPrincipal.css";
import "../../assets/styles/responsive.scss";
import "../../assets/styles/stylePageGlobals.scss";
import Alert from "../../components/Swalert";
import Layout from "../../hocs/Layout";
import ListaSucursales from "./lista-sucursales";

const SucursalesPage = () => {
  return (
    <>
      <Helmet>
        <title>{`Sucursales Hipermaxi`}</title>
        {/* <meta name="“robots”" content="noindex" /> */}
      </Helmet>
      <Layout>
        <Alert></Alert>
        <div className="pt-4 z-10">
          <Alert></Alert>
          <div className="container">
            <h1 className="text-2xl md:text-3xl text-center text-hiperorange mb-2">
              Sucursales Hipermaxi
            </h1>
            <div className="flex w-full flex-col pb-4 justify-center items-center h-full">
              <div className="w-full h-full bg-white">
                <ListaSucursales />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(SucursalesPage);
