import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import { connect } from "react-redux";
import NavbarSucursal from "./NavbarMarketSucursal";
import { set_sucursal } from "../../redux/actions/branch_office";
import {
  limpiar_carrito,
  store_carrito,
  limpiar_solo_carrito,
  get_carrito_por_sucursal,
} from "../../redux/actions/carrito";
import classNames from "classnames";
import slug from "../Utils/slug";
import { useNavigate } from "react-router-dom";
import { useGetBackLink } from "../../hooks/useGetBackLink";

function NavbarMarket({
  sucursal,
  sucursales,
  branch_offices,
  ciudades,
  openSucursalesModal = () => {},
  set_sucursal,
  limpiar_carrito,
  carritos,
  producto_delete,
  direccion,
  tipo_entrega,
  store_carrito,
  limpiar_solo_carrito,
  get_carrito_por_sucursal,
}) {
  const navigate = useNavigate();
  const { backLink } = useGetBackLink();
  const marketActual = useMemo(() => {
    let foundMarket = sucursales?.find(
      (market) => market.IdMarket === sucursal.IdMarket
    );
    if (!foundMarket)
      foundMarket = branch_offices?.find(
        (market) => market.IdMarket === sucursal.IdMarket
      );

    return foundMarket;
  }, [sucursales, sucursal, branch_offices]);
  const ciudadSeleccionado = (id_region) => {
    return ciudades?.find(
      (pre) => parseInt(pre.IdRegion) === parseInt(id_region)
    );
  };

  const getUrl = (item_sucursal) => {
    if(!item_sucursal) return "";
    let ciudad = ciudadSeleccionado(item_sucursal.idRegion);
    if(!ciudad) return "";
    let parametro_url = {
      ciudad: slug.data_slug(ciudad.Nombre),
      sucursal: slug.data_slug(item_sucursal.Descripcion),
    };
    let resp = parametro_url.ciudad + " " + parametro_url.sucursal;
    let new_url = slug.data_barra(resp);

    let url_parameters = new_url + backLink;
    // navigate("/" + url_parameters);
    return "/" + url_parameters;
  };

  const redirectToSucursal = (item_sucursal) => {
    let url_parameters = getUrl(item_sucursal);
    window.location.href = url_parameters;
  };

  const handleSetSucursal = async (sucursal) => {
    const localStorageSucursal = localStorage.getItem("sucursal");
    const previousSucursal = localStorageSucursal
      ? JSON.parse(localStorageSucursal)
      : null;
    if (carritos && carritos.length > 0 && previousSucursal) {
      await store_carrito(
        previousSucursal,
        tipo_entrega,
        direccion,
        carritos,
        producto_delete,
        false
      );
      limpiar_solo_carrito();
    }
    await set_sucursal(sucursal);
    await limpiar_carrito();
    // get_carrito_por_sucursal(sucursal);
    redirectToSucursal(sucursal);
  };
  return (
    <div className="flex flex-col gap-1 flex-grow md:flex-grow-0">
      <button
        onClick={openSucursalesModal}
        className="text-left text-hiperblue hover:text-hiperblue_hover mb-0 flex gap-1 items-center underline p-1 text-xs md:text-sm font-semibold"
      >
        {marketActual?.Descripcion || "Cambiar de Sucursal"}
        <FontAwesomeIcon icon={faChevronDown} size="sm" />
      </button>
      {marketActual && ciudades?.length > 0 ? (
        <div
          className={classNames("grid grid-flow-col gap-2 ", {
            "grid-cols-2": marketActual?.Locatarios.length > 1,
          })}
        >
          {marketActual?.Locatarios?.map((locatario, index) => (
            <NavbarSucursal
              key={`navbar-locatario-${locatario.IdMarket}-${locatario.IdSucursal}-${index}`}
              sucursal={locatario}
              isSelected={locatario.IdSucursal === sucursal.IdSucursal}
              onSelect={handleSetSucursal}
              url={getUrl(locatario)}
            />
          ))}
        </div>
      ) : (
        <div
          className={classNames(
            "mb-0 text-black text-xs md:text-sm px-1.5 py-2.5 rounded-lg bg-gray-200 font-semibold text-center"
          )}
        >
          Noy hay cobertura
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  sucursal: state.BranchOffice.sucursal,
  sucursales: state.BranchOffice.sucursales,
  branch_offices: state.BranchOffice.branch_offices,
  ciudades: state.BranchOffice.ciudades,
  carritos: state.Carrito.carrito,
  producto_delete: state.Carrito.producto_delete,
  direccion: state.Location.direccion,
  tipo_entrega: state.BranchOffice.tipo_entrega,
});

export default connect(mapStateToProps, {
  set_sucursal,
  limpiar_carrito,
  store_carrito,
  limpiar_solo_carrito,
  get_carrito_por_sucursal,
})(NavbarMarket);
