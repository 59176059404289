export function useThousandSeparator(
  locales = "en-US",
  options = {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }
) {
  const numberFormatter = new Intl.NumberFormat(locales, options);

  return { numberFormatter };
}
