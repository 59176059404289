// import React, {useEffect} from 'react';

export default {

    get(key){
        const value = localStorage.getItem(key);
        if(!value){
            return null;
        }
        return JSON.parse(value);
    },
    set(key, value){
        // localStorage.setItem(key,(value));
        localStorage.setItem(key, JSON.stringify(value));
        // return null;
    },
    remove(key){    
        localStorage.removeItem(key);
    },
    clear(){
        localStorage.clear();
    },

};

