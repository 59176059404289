import Layout from "../../hocs/Layout";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { clear_pedido, getpedido } from "../../../src/redux/actions/pedido";
import ItemDetallePedido from "../../widgets/item_detalle_pedido";
import ButtonText from "../../widgets/button_text";
import classNames from "classnames";
import EstadoPedidoItem from "../../widgets/estadoPedidoItem";
import "../../assets/styles/detallePedido.scss";
import Alert from "../../components/Swalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/pro-solid-svg-icons";
import { Helmet } from "react-helmet-async";
import { useIsDefaultStore } from "../../hooks/useIsDefaultStore";

const DetallePedido = ({
  getpedido,
  pedido,
  profile,
  clear_pedido,
  sucursal,
}) => {
  const { isDefaultStore } = useIsDefaultStore();

  const { id } = useParams();
  const [pedidoPendiente, setPedidoPendiente] = useState(false);

  useEffect(() => {
    if (!pedido) {
      getpedido(id);
    } else {
      let pedidoEstadoId = pedido.Estado.Id;
      if (pedidoEstadoId !== 8 && pedidoEstadoId !== 9) {
        setPedidoPendiente(true);
      }
    }
  }, [pedido]);

  useEffect(() => {
    return () => {
      clear_pedido();
    };
  }, []);

  const haypedido = () => {
    return pedido ? true : false;
  };

  const volverAtras = () => {
    window.history.back();
  };

  const actualizar_item = () => {
    setPedidoPendiente(false);
    getpedido(id);
  };

  const formatPrice = (value) => {
    let val = (value / 1).toFixed(2).replace(",", ".");
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <>
      <Helmet>
        <title>{`Detalle de pedido | ${
          isDefaultStore ? "Hipermaxi" : sucursal?.Descripcion || ""
        }`}</title>
        {/* <meta name="“robots”" content="noindex" /> */}
      </Helmet>
      <Layout>
        <Alert></Alert>
        <div className="content_info py-4">
          <div className="container">
            <h3 className="text-2xl md:text-3xl text-hiperorange mb-3">
              Detalle de pedido
            </h3>
            {haypedido() && (
              <div className="w-full contenedor_flex contenedor_altura">
                <div
                  className="md:w-[65%]  p-0  contenedor_mobile rounded-md overflow-hidden "
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    borderRadius: "1.4rem",
                  }}
                >
                  <div className="">
                    <div className="row mx-0 py-3  bg-hiperorange px-2">
                      <div className="col-6 text-white data_sucursal_pdet">
                        <div className="flex  rounded-md data_sucursal_header_pdet">
                          <p className="mb-0 text-white font-bold  ">
                            Sucursal: {pedido.Sala.Descripcion}
                          </p>
                        </div>
                        <p className="mb-0 font-medium ">
                          Tipo de entrega: {pedido.TipoEntrega.Descripcion}
                        </p>
                        <p className="mb-0 ">
                          Fecha de entrega: {pedido.FechaEntrega}
                        </p>
                      </div>

                      <div className="col-6 text-white data_pedido_pdet">
                        <div className="flex  pr-3 rounded-md data_pedido_header_pdet">
                          <p className="mb-0   text-white font-bold">
                            Nro de pedido # {id}
                          </p>
                        </div>
                        <p className="mb-0 pr-3  data_pedido_parraf_pdet">
                          Fecha de solicitud: {pedido.FechaCreacion}
                        </p>

                        <p className="mb-0 data_pedido_parraf_pdet">
                          Hora de entrega: {pedido.HoraAproxEntrega}
                        </p>
                      </div>
                    </div>
                    <div className="w-100 bg-white h-[auto]">
                      <div className="w-100 h-[559px] container_scroll    overflow-auto    ">
                        {haypedido() &&
                          pedido.Productos.map((item, index) => (
                            <ItemDetallePedido
                              key={item.IdProducto}
                              UrlFoto={item.UrlFoto}
                              nombreproducto={item.Descripcion}
                              precio={item.Precio}
                              moneda={item.Moneda}
                              cantidad={item.Cantidad}
                              subtotal={item.SubTotal}
                            ></ItemDetallePedido>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" md:w-[35%]   style_panelB ">
                  <div
                    className="mb-2 rounded-md overflow-hidden"
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      borderRadius: "1.4rem",
                    }}
                  >
                    {pedidoPendiente ? (
                      <div className="bg-hiperorange text-white py-1">
                        <p className="text-center mb-0">Pedido en curso </p>
                      </div>
                    ) : (
                      <div
                        className={classNames("text-white", "py-1", {
                          "bg-hiperorange": pedido.Estado.Id === 8,
                          "bg-red-600": pedido.Estado.Id === 9,
                        })}
                      >
                        <p className="text-center mb-0">
                          {pedido.Estado.Descripcion}
                        </p>
                      </div>
                    )}

                    {pedidoPendiente && (
                      <EstadoPedidoItem
                        key={pedido.NroPedido}
                        pedido={pedido}
                        profile={profile}
                        actualizar_item={actualizar_item}
                      ></EstadoPedidoItem>
                    )}
                  </div>

                  <div
                    className="mb-2  rounded-md overflow-hidden "
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      borderRadius: "1.4rem",
                    }}
                  >
                    <div className="bg-hiperorange text-white py-1">
                      <p className="text-center mb-0">Método de pago</p>
                    </div>
                    <div className="row px-3 bg-white pb-2  pt-1 flex justify-center">
                      {pedido.PagoPedido.map((pago, key) => (
                        <div className="col-6" key={key}>
                          <div
                            className={`w-full pt-1 rounded-md border border-1 ${
                              pago.Cancelado ? "bg-gray-500" : "bg-hiperorange"
                            }`}
                          >
                            <div className="flex flex-col justify-center text-center text-white">
                              <div className="text-center">
                                <span className="font-bold  uppercase text-sm break-words">
                                  {pago.MetodoPago}
                                </span>
                              </div>
                            </div>
                            <div className="pb-1">
                              <div className="text-white mt-1 p-1 flex justify-end">
                                <span
                                  className="font-bold"
                                  style={{ fontSize: "0.7rem" }}
                                >
                                  Importe Bs. {formatPrice(pago.Importe)}{" "}
                                </span>
                              </div>
                              {pago.Cancelado && (
                                <div className="text-left p-1 flex items-center">
                                  <FontAwesomeIcon
                                    icon={faCircleXmark}
                                    color="white"
                                  ></FontAwesomeIcon>
                                  <span
                                    className="font-bold text-white ml-1"
                                    style={{ fontSize: "0.6rem" }}
                                  >
                                    Cancelado{" "}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div
                    className="content_card_footer bg-white text-dark p-4 mb-4 px-4 rounded-md font-bold"
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      borderRadius: "1.4rem",
                    }}
                  >
                    <div className="flex flex-col ">
                      <div className="flex p-1 justify-between text-zinc-400 rounded-md ">
                        <span className="text-center m-0">Costo de envío:</span>
                        <span className="font-bold">
                          Bs. {formatPrice(pedido.CostoEntrega)}
                        </span>
                      </div>
                      <div className="flex p-1 justify-between text-zinc-400 rounded-md ">
                        <span className="text-center m-0">Total Parcial:</span>
                        <span className="font-bold">
                          Bs. {formatPrice(pedido.TotalParcial)}
                        </span>
                      </div>
                      {!pedidoPendiente && (
                        <div className="flex p-1 mt-1 justify-between text-hiperorange font-bold">
                          <span>Total pagado:</span>
                          <span>Bs. {formatPrice(pedido.ImporteTotal)}</span>
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col justify-center mt-4  text-center text-white">
                      <ButtonText
                        label="Volver"
                        principal={false}
                        className="bg-hiperblue"
                        onClick={() => volverAtras()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => ({
  sucursal: state.BranchOffice.sucursal,
  pedido: state.Pedido.pedido,
  profile: state.Profile.profile,
});

export default connect(mapStateToProps, {
  getpedido,
  clear_pedido,
})(DetallePedido);
