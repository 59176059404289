// ZoomContext.jsx
import React, { createContext, useState } from 'react';

export const ZoomContext = createContext();

export const ZoomProvider = ({ children }) => {
  const [zoomData, setZoomData] = useState({
    imageSrc: '',
    highResImageSrc: '',
    backgroundPosition: '0% 0%',
    zoomFactor: 2,
    showZoom: false,
  });

  return (
    <ZoomContext.Provider value={{ zoomData, setZoomData }}>
      {children}
    </ZoomContext.Provider>
  );
};
