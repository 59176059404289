import { MensajeApi } from "../../domain/interfaces/mensaje-api";
import { MensajeApiError } from "./mensaje-api.error";

export function responseHandler<DataType, MensajeApiErrorDataType>(
  apiResponse: MensajeApi<DataType>
): DataType {
  if (apiResponse.ConError) {
    const errorData: MensajeApiErrorDataType | null =
      apiResponse.Dato as MensajeApiErrorDataType | null;
    handleError<MensajeApiErrorDataType>(apiResponse.Mensaje, errorData);
  }

  return handleData<DataType>(apiResponse);
}

function handleError<MensajeApiErrorDataType>(
  message: string,
  data: MensajeApiErrorDataType | null
): MensajeApiError<MensajeApiErrorDataType> {
  const dataError: MensajeApiErrorDataType | null = data;
  const error = new MensajeApiError(message, dataError);
  throw error;
}

function handleData<DataType>(response: MensajeApi<DataType>): DataType {
  const data: DataType = response.Dato;
  return data;
}
