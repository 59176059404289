import React, { useEffect, useState } from "react";
import SwiperCore, { Lazy, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/lazy";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import { useParams } from "react-router-dom";

import "../assets/styles/inicio.scss";
import EstadoPedidoSmall from "../widgets/estadoPedidoSmall";
SwiperCore.use([Navigation, Lazy]);

const PedidosSwiperCustom = ({ arrayPedido, profile }) => {
  const [index, setIndex] = useState(0);
  const { ciudadp, sucursalp } = useParams();

  useEffect(() => {}, [arrayPedido, ciudadp, sucursalp]);

  return (
    <div className=" flex flex-row">
      <div className="static z-10 top-0 bottom-0 md:p-2  text-gray-500 bg-transparent hover:text-gray-800 hover:bg-black hover:bg-opacity-5 disabled:bg-transparent disabled:text-gray-300 rounded-l-lg swiper-button-prev-pedidos cursor-pointer flex items-center justify-center">
        <FontAwesomeIcon icon={faAngleLeft} size="xl" />
      </div>
      <Swiper
        className="swiper swiper-initialized"
        onSlideChange={(swiper) => {
          setIndex(swiper.activeIndex);
        }}
        slidesPerView={1}
        loop={true}
        navigation={{
          nextEl: ".swiper-button-next-pedidos",
          prevEl: ".swiper-button-prev-pedidos",
        }}
      >
        {arrayPedido.map(
          (pedido, i) =>
            i % 2 === 0 && (
              <SwiperSlide key={i}>
                <div className="flex flex-col space-y-1 md:space-y-4 px-2">
                  <div className="row-1">
                    <a
                      href={`/${ciudadp}/${sucursalp}/pedido-detalle/${arrayPedido[i].NroPedido}`}
                    >
                      <EstadoPedidoSmall
                        pedido={arrayPedido[i]}
                        profile={profile}
                        showtodopedidos={true}
                      />
                    </a>
                  </div>
                  {i + 1 < arrayPedido.length && (
                    <div className="row-2 hidden md:block">
                      <a
                        href={`/${ciudadp}/${sucursalp}/pedido-detalle/${arrayPedido[i].NroPedido}`}
                      >
                        <EstadoPedidoSmall
                          pedido={arrayPedido[i + 1]}
                          profile={profile}
                          showtodopedidos={true}
                        />
                      </a>
                    </div>
                  )}
                </div>
              </SwiperSlide>
            )
        )}
      </Swiper>
      <div className="static z-10 top-0 bottom-0 md:p-2  text-gray-500 bg-transparent hover:text-gray-800 hover:bg-black hover:bg-opacity-5 disabled:bg-transparent disabled:text-gray-300 rounded-r-lg swiper-button-next-pedidos cursor-pointer  flex items-center justify-center">
        <FontAwesomeIcon icon={faAngleRight} size="xl" />
      </div>
    </div>
  );
};

export default PedidosSwiperCustom;
