interface Props {
  nombre: string;
  apellidoPaterno: string;
}

export function useGetProfileInitials({ nombre, apellidoPaterno }: Props) {
  const iniciales =
    nombre.trim().charAt(0).toUpperCase() +
    apellidoPaterno.trim().charAt(0).toUpperCase();

  return { profileInitials: iniciales };
}
