import classNames from "classnames";
import { Image } from "../../components/image";

const NavbarSucursal = ({ sucursal, isSelected, onSelect = () => {}, url = "" }) => {
  const handleOnSelect = (sucursal) => {
    onSelect(sucursal);
  };
  return (
    <a
      href={url}
      className={classNames(
        "flex gap-1 items-center mb-0 text-black text-xs md:text-sm px-1.5 py-1 rounded-lg bg-gray-200 hover:bg-hiperorange_claro font-semibold",
        {
          "bg-hiperorange_claro": isSelected,
        }
      )}
      onClick={(e) => {
        e.preventDefault();
        handleOnSelect(sucursal);
      }}
    >
      {/* <Image
        className="hidden md:block"
        width={28}
        height={28}
        src={sucursal.TipoServicio.UrlFoto}
        alt={sucursal.TipoServicio.Descripcion}
      /> */}

      <div className="hidden md:block w-7 h-7 relative">
        <Image
          className="hidden md:block w-7"
          width={28}
          height={28}
          src={sucursal.TipoServicio.UrlFoto}
          alt={sucursal.TipoServicio.Descripcion}
        />
      </div>
      <div className="w-full md:text-left">
        <div className="hidden md:inline-block">{`${
          !isSelected ? "Ir a " : ""
        }${sucursal.TipoServicio.Descripcion}`}</div>
        <div className="block md:hidden">{`${sucursal.TipoServicio.Descripcion}`}</div>
      </div>
    </a>
  );
};

export default NavbarSucursal;
