import Layout from "../../hocs/Layout";
import React, { useEffect, useState } from "react";
import ItemCarrito from "../../widgets/item_carrito";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faSquareMinus,
  faSquarePlus,
  faTrashCan,
} from "@fortawesome/pro-solid-svg-icons";
import { connect } from "react-redux";

import {
  get_lista_carrito_compras,
  store_carrito,
  store_carrito_data,
} from "../../../src/redux/actions/carrito";
import { delete_list_carrito } from "../../../src/redux/actions/carrito";
import { update_list_carrito_compras } from "../../../src/redux/actions/carrito";
import Favorito from "./../../widgets/favoritoMycart";
import "../../assets/styles/mycart.scss";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Alert from "../../components/Swalert";
import Modal from "react-modal";
import { useThousandSeparator } from "../../hooks/useThousandSeparator";

const customStyles = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "1",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    borderRadius: "0.75rem",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "visible",
  },
};
const pesajes = [
  { name: "500g", peso: "500" },
  { name: "1Kg", peso: "1000" },
  { name: "2Kg", peso: "2000" },
  { name: "3Kg", peso: "3000" },
  { name: "5Kg", peso: "5000" },
  { name: "10Kg", peso: "10000" },
  { name: "15Kg", peso: "15000" },
  { name: "20Kg", peso: "20000" },
  { name: "25Kg", peso: "25000" },
  { name: "30Kg", peso: "30000" },
  { name: "35Kg", peso: "35000" },
  { name: "40Kg", peso: "40000" },
  { name: "45Kg", peso: "45000" },
  { name: "50Kg", peso: "50000" },
];
const MyCart = ({
  store_carrito,
  store_carrito_data,
  sucursal,
  tipo_entrega,
  direccion,
  carritos,
  producto_delete,
  get_lista_carrito_compras,
  list_carrito,
  delete_list_carrito,
  update_list_carrito_compras,
}) => {
  const {numberFormatter} = useThousandSeparator();
  const [token] = useState(localStorage.getItem("access_token"));
  const [subPedidos, guardarsubPedidos] = useState([]);

  const [producto_aux, setProductoAux] = useState({});
  const [IdSucursal_aux, setIdSucursalAux] = useState("");
  const [showmodal, setShowmodal] = useState(false);
  const [modal_pieza, setModalPieza] = useState(false);
  const [favoritoselect, setFavoritoselect] = useState({
    productId: 0,
    IdSucursal: 0,
    cantidad: 0,
  });
  const navigate = useNavigate();

  const endModalPieza = () => setModalPieza(false);
  

  const formatPrice = (value) => {
    return numberFormatter.format(value);
  };

  const _ahorroTotal = () => {
    let total = 0;
    subPedidos.forEach((sp) => {
      if (sp.check) {
        total += Number(sp.ahorro);
      }
    });
    return total;
  }
  const ahorro_total = _ahorroTotal();

  const startModalPieza = (producto, IdSucursal) => {
    setProductoAux(producto);
    setIdSucursalAux(IdSucursal);
    setModalPieza(true);
  }

  const actualizar_carrito_db =async () => {
    await store_carrito(
      sucursal,
      tipo_entrega,
      direccion,
      carritos,
      producto_delete
    );
  }

  const check_carrito_sucursal = (IdMarket) => {
    let objetoClonado = Object.assign({}, list_carrito);
    let subpedidosaux = subPedidos.map((ps) => {
      if (ps.IdMarket == IdMarket) {
        return { ...ps, check: !ps.check };
      } else {
        return { ...ps };
      }
    });
    let listAux = {
      IdPedidoPrincipal: objetoClonado.IdPedidoPrincipal,
      IdUbicacion: objetoClonado.IdUbicacion,
      ImporteTotal: objetoClonado.ImporteTotal,
      InstruccionDelivery: objetoClonado.InstruccionDelivery,
      InstruccionEntrega: objetoClonado.InstruccionEntrega,
      NroTotalProductos: objetoClonado.NroTotalProductos,
      SubPedidos: subpedidosaux,
    };
    update_list_carrito_compras(listAux);
  }

  const actualizar_lista_carrito_db = async () => {
    for (var item of subPedidos) {
      let params = {
        IdMarket: item.IdMarket,
        IdSucursal: item.Sala.Id,
        IdTipoEntrega: item.IdTipoEntrega,
        IdDireccion: direccion.Id,
      };
      let carritos_aux = item.Productos;
      await store_carrito_data(params, carritos_aux, []);
    }
  }

  useEffect(() => {
    if (Object.keys(producto_aux).length > 0) {
      actualizarDato(
        producto_aux.IdProducto,
        "ninguna",
        IdSucursal_aux,
        "pieza"
      );
    }
  }, [producto_aux]);

  useEffect(() => {
    async function fetchData() {
      try {
        await actualizar_carrito_db();
        await get_lista_carrito_compras(token);
      } catch (e) {}
    }
    fetchData();
  }, []);
  useEffect(() => {
    if (list_carrito) {
      guardarsubPedidos(
        list_carrito.SubPedidos.map((ps) => {
          let _ahorro = 0;
          ps.Productos.forEach((prod) => {
            if (prod.ConOferta) {
              _ahorro +=
                prod.PrecioOriginal * prod.Cantidad -
                prod.PrecioOferta * prod.Cantidad;
            }
          });
          return {
            ...ps,
            check: ps.check != undefined ? ps.check : true,
            ahorro: _ahorro.toFixed(2),
          };
        })
      );
    }
  }, [list_carrito]);

  const existe_list_carrito = () => {
    return subPedidos.length > 0 ? true : false;
  }


  const deleteDato = (productId, IdSucursal) => {
    let params = {
      IdMarket: IdSucursal,
      IdSucursal: IdSucursal,
      IdTipoEntrega: tipo_entrega.Id,
      IdDireccion: direccion.Id,
    };
    let carritos_aux = get_carrito_sucursal(IdSucursal);
    let producto_delete = [{ IdProducto: productId }];
    store_carrito_data(params, carritos_aux, producto_delete);
    get_lista_carrito_compras(token);
  }

  const get_carrito_sucursal = (IdSucursal) => {
    let array = [];
    subPedidos.forEach((sp) => {
      if (sp.IdMarket == IdSucursal) {
        array = sp.Productos;
      }
    });
    return array;
  }

  const cambiarFavorito = (favorito, cantidad, productId, IdSucursal) => {
    setFavoritoselect({
      productId: productId,
      IdSucursal: IdSucursal,
      cantidad: cantidad,
    });
    abrir_modal_lista_compras(favorito);
    get_lista_carrito_compras(token);
  }

  const abrir_modal_lista_compras = (favorito) => {
    if (!showmodal) {
      setShowmodal(true);
    } else {
      setShowmodal(false);
    }
  }

  const _es_pieza = (producto) => {
    if (producto) {
      return producto.EsFraccionado && !producto.PesoDefinido ? true : false;
    } else {
      return false;
    }
  }

  const obtener_precio = (producto) => {
    let monto_aux = producto.ConOferta
      ? producto.PrecioOferta
      : producto.PrecioVenta;
    return monto_aux;
  }

  const _importe = (array) => {
    let resultado = 0;
    array.forEach((elem) => {
      resultado += Number(elem.Importe);
    });
    return resultado;
  }

  const actualizar_producto_aux = (valor, operacion) => {
    let producto = Object.assign({}, producto_aux);
    if (operacion == "incremento") {
      producto.Cantidad += valor;
    }
    if (operacion == "decremento") {
      producto.Cantidad -= valor;
    }
    if (operacion == "ninguna") {
      let resultado = valor.peso / 1000;
      producto.Cantidad = resultado;
    }
    let monto = obtener_precio(producto);
    let importe = producto.Cantidad * monto;
    producto.Importe = importe.toFixed(2);
    setProductoAux(producto);
  }

  const actualizarDato = (productId, operacion, IdSucursal, pieza) => {
    const objetoClonado = Object.assign({}, list_carrito);
    var subpedidosaux = [];
    for (let item of objetoClonado.SubPedidos) {
      var ob = item;
      var productoaux = item.Productos;
      if (item.IdMarket == IdSucursal) {
        productoaux = [];
        for (let prod of item.Productos) {
          const aux = prod;
          if (pieza == "pieza" && prod.IdProducto == productId) {
            aux.Cantidad = producto_aux.Cantidad;
            aux.Importe = producto_aux.Importe;
          } else {
            if (prod.IdProducto == productId) {
              let quantity = 1;
              if (_es_pieza(prod)) {
                quantity = 0.1;
              }
              if (operacion == "incremento") {
                aux.Cantidad = prod.Cantidad + quantity;
              }
              if (operacion == "decremento" && prod.Cantidad > 0) {
                aux.Cantidad = prod.Cantidad - quantity;
              }
              let monto = obtener_precio(prod);

              let importe = aux.Cantidad * monto;
              aux.Importe = importe.toFixed(2);
            }
          }
          productoaux.push(aux);
        }
      }
      ob.ImporteSubtotal = _importe(productoaux);
      subpedidosaux.push(ob);
    }

    const listAux = {
      IdPedidoPrincipal: objetoClonado.IdPedidoPrincipal,
      IdUbicacion: objetoClonado.IdUbicacion,
      ImporteTotal: objetoClonado.ImporteTotal,
      InstruccionDelivery: objetoClonado.InstruccionDelivery,
      InstruccionEntrega: objetoClonado.InstruccionEntrega,
      NroTotalProductos: objetoClonado.NroTotalProductos,
      SubPedidos: subpedidosaux,
    };

    update_list_carrito_compras(listAux);
  }

  const get_total_carrito = () => {
    let total = 0;
    subPedidos.forEach((sp) => {
      if (sp.check) {
        total += sp.ImporteSubtotal;
      }
    });
    return parseFloat(total);
  }

  const validar_monto_minimo = () => {
    let cantidad = 0;
    for (let sp of subPedidos) {
      if (sp.check) {
        cantidad = 1;
        if (sp.ImpMinimoPedido > sp.ImporteSubtotal) {
          return {
            sala: sp.Sala,
            monto_faltante: sp.ImpMinimoPedido - sp.ImporteSubtotal,
            validacion: false,
            cantidad: cantidad,
          };
        }
      }
    }
    return {
      sala: null,
      monto_faltante: 0,
      validacion: true,
      cantidad: cantidad,
    };
  }

  const mensaje_alert = (text) => {
    Swal.fire({
      icon: "warning",
      text: text,
      showConfirmButton: true,
      confirmButtonText: "Cerrar",
      confirmButtonColor: "#32338E",
    });
  }

  const getTexto = (t) => {
    return (
      "Faltan Bs. " +
      formatPrice(t.monto_faltante) +
      " para completar el mínimo de compra en " +
      t.sala.Descripcion
    );
  }

  const add_carritos = (e) => {
    var validacion = validar_monto_minimo();
    if (validacion.validacion) {
      localStorage.setItem("mycart", JSON.stringify(list_carrito));
      localStorage.setItem("monto_mycart", JSON.stringify(get_total_carrito()));
      actualizar_lista_carrito_db();
      if (validacion.cantidad == 1) {
        navigate("/entrega");
      } else {
        mensaje_alert(
          "No tiene productos seleccionados para realizar la compra."
        );
      }
    } else {
      var texto = getTexto(validacion);
      mensaje_alert(texto);
    }
  };

  const fueraFoco = () => {};

  //validacion
  const eliminarCarrito = (idPedido) => {
    Swal.fire({
      title: "Estás seguro?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#32338E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        delete_sublista_carrito(idPedido);
        // deleteTarjeta(idPedido);
      }
    });
  }

  const delete_sublista_carrito = async(idPedido) => {
    await delete_list_carrito(idPedido);
    await get_lista_carrito_compras(token);
  }

  //end validacion
  return (
    <Layout>
      <>
        <Alert></Alert>
        <div className="flex justify-center items-center mt-12 flex-col relative">
          <h2 className="text-center mb-4 text-hiperorange">Mi Carrito</h2>
          {existe_list_carrito() ? (
            <div className="px-2 ">
              {/* <h2 className="text-center mb-4 text-hiperorange">Mi Carrito</h2> */}

              {subPedidos.map((item, index) => (
                <div
                  className="sm:w-100 lg:w-[33rem]  h-[26rem] border border-hiperorange shadow rounded-xl mb-2"
                  key={item.IdMarket}
                >
                  <div className="bg-white shadow rounded-t-xl text-hiperblue mb-3 text-sm flex justify-between items-center h-[3rem] px-2">
                    <div className="flex">
                      <input
                        type="checkbox"
                        className="mr-2"
                        checked={item.check}
                        onChange={() => check_carrito_sucursal(item.IdMarket)}
                      />
                      <h6 className="mb-0">
                        <span className="text-hiperorange">
                          {item.TipoServicio.Descripcion}{" "}
                        </span>
                        -{item.Sala.Descripcion}
                      </h6>
                    </div>
                    <div className="bg-hiperorange d-inline px-2 py-0.5 rounded-md text-white">
                      <button
                        className="cursor-pointer"
                        onClick={() => eliminarCarrito(item.IdPedido)}
                      >
                        <span className="mr-3 text-sm">Eliminar Lista</span>
                        <FontAwesomeIcon
                          className="text-sm"
                          icon={faTrashCan}
                        />
                      </button>
                    </div>
                  </div>
                  <div className="flex justify-between flex-column h-[calc(100%-3rem)]  ">
                    <div className="overflow-y-auto  ">
                      {item.Productos.map((producto) => (
                        <ItemCarrito
                          colour={true}
                          producto={producto}
                          idProducto={producto.IdProducto}
                          key={producto.IdProducto}
                          descripcion={producto.Descripcion}
                          cantidad={producto.Cantidad}
                          precioVenta={producto.PrecioVenta}
                          moneda={producto.Moneda}
                          importe={producto.Importe}
                          imagen={producto.UrlFoto}
                          IdMarket={item.IdMarket}
                          unidadMedida={producto.UnidadMedida}
                          favorito={producto.EnListadoCliente}
                          ps_updateDato={actualizarDato}
                          ps_deleteDato={deleteDato}
                          changeFavorito={cambiarFavorito}
                          ps_startModal={startModalPieza}
                          ps_endModal={endModalPieza}
                        ></ItemCarrito>
                      ))}
                    </div>
                    <div className="sticky py-2.5 border-t border-hiperorange text-gray-600 flex justify-around">
                      <p>
                        Compra Mínima:{" "}
                        <span className="font-bold">
                          {" "}
                          Bs. {item.ImpMinimoPedido}
                        </span>
                      </p>
                      <p>
                        ahorro:{" "}
                        <span className="font-bold">
                          Bs. &nbsp; {item.ahorro}
                        </span>
                      </p>
                      <p>
                        Total parcial:{" "}
                        <span className="font-bold">
                          Bs. &nbsp; {item.ImporteSubtotal}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              ))}

              <div className="card-footer d-flex justify-between pt-2 pb-5">
                <Link to="/">
                  <button className="bg-hiperblue px-3 border text-white rounded-lg">
                    Seguir comprando
                  </button>
                </Link>
                <p>ahorro bs {ahorro_total}</p>
                <button
                  onClick={(e) => add_carritos(e)}
                  className="bg-green-500 px-3 border  text-white rounded-lg"
                >
                  Ir a caja BS. &nbsp;{get_total_carrito()}
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
          {showmodal == true ? (
            <>
              <Favorito
                idProducto={favoritoselect.productId}
                IdSucursal={favoritoselect.IdSucursal}
                cantidad={favoritoselect.cantidad}
                onBlur={fueraFoco}
              ></Favorito>
            </>
          ) : (
            <></>
          )}
        </div>
        <Modal
          isOpen={modal_pieza}
          ariaHideApp={false}
          onRequestClose={endModalPieza}
          style={customStyles}
          contentLabel="address"
        >
          <div className="w-80 relative">
            <div className="card border-0 relative">
              <div className="absolute top-[-30px] right-[-2rem]">
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  className="w-[2.5rem] h-[2.5rem] rounded-full text-hiperorange bg-white cursor-pointer "
                  onClick={() => endModalPieza()}
                />
              </div>
              <div className="text-center">
                <h5 className="font-bold">Seleccione el Peso</h5>
              </div>
              <div className="card-body pt-0">
                <div className="row">
                  {pesajes.map((pesaje, index) => (
                    <div className="col-6" key={index}>
                      <div
                        className="bg-[#EEEEEE] m-2 px-3 py-2 rounded text-center cursor-pointer hover:bg-gray-400"
                        onClick={() =>
                          actualizar_producto_aux(pesaje, "ninguna")
                        }
                      >
                        <span className="font-semibold text-xl">
                          {pesaje.name}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="border-t-2 border-[#EEEEEE] p-2 flex justify-center">
                <div className="flex border-[3px] border-hiperorange p-1 rounded">
                  <FontAwesomeIcon
                    icon={faSquareMinus}
                    className="w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer"
                    onClick={() => actualizar_producto_aux(0.1, "decremento")}
                  />
                  <div className="mx-2 flex align-items-center">
                    <span>{Number(producto_aux.Cantidad).toFixed(2)}</span>
                  </div>
                  <FontAwesomeIcon
                    icon={faSquarePlus}
                    className="w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer"
                    onClick={() => actualizar_producto_aux(0.1, "incremento")}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  list_carrito: state.Carrito.list_carrito,
  sucursal: state.BranchOffice.sucursal,
  tipo_entrega: state.BranchOffice.tipo_entrega,
  direccion: state.Location.direccion,
  carritos: state.Carrito.carrito,
  producto_delete: state.Carrito.producto_delete,
});

export default connect(mapStateToProps, {
  get_lista_carrito_compras,
  store_carrito,
  store_carrito_data,
  delete_list_carrito,
  update_list_carrito_compras,
})(MyCart);
