import { useState } from "react";
import { FormStatus } from "../../../shared/domain/enums/form-status";
import { getHashCuentaCorriente as _getUserProfileById } from "../../application/get/get-user-profile-by-id";
import { UserProfile } from "../../domain/interface/user-profile";
import { UserProfilesRepository } from "../../domain/user-profiles-repository";

export const useGetUserProfileById = (
  userProfilesRepository: UserProfilesRepository
  // notificationService: NotificationService
): {
  formStatus: FormStatus;
  getUserProfileById: () => void;
  resetFormStatus: () => void;
  userProfile: UserProfile | undefined;
  resetUserProfiles: () => void;
  isLoading: boolean;
} => {
  const [formStatus, setFormStatus] = useState(FormStatus.INITIAL);
  const [userProfile, setUserProfile] = useState<UserProfile | undefined>(
    undefined
  );

  // const { handleError } = useHandleErrors(notificationService);

  async function getUserProfileById() {
    setFormStatus(FormStatus.LOADING);

    try {
      const userProfile: UserProfile = await _getUserProfileById(
        userProfilesRepository
      )();
      // const userProfile: UserProfile = {
      //   duration: getRandomInt(),
      //   tokenAutorizacion: createRandomString(4),
      //   Id: params.idUserProfile
      // }

      setUserProfile(userProfile);
      setFormStatus(FormStatus.SUCCESS);
      //   notifySuccess(notificationService)("Clientes obtenidos correctamente.");
    } catch (error: unknown) {
      // const message = "";
      // handleError({ error, message });
      setFormStatus(FormStatus.ERROR);
    }
  }

  const resetFormStatus = () => setFormStatus(FormStatus.INITIAL);

  const resetUserProfiles = () => setUserProfile(undefined);

  return {
    formStatus,
    getUserProfileById,
    resetFormStatus,
    userProfile,
    resetUserProfiles,
    isLoading: formStatus === FormStatus.LOADING,
  };
};
