import React, { useEffect } from "react";

import { Link, useParams } from "react-router-dom";
import Layout from "../../hocs/Layout";
import ItemPedido from "../../widgets/item_pedido";
import "../../../src/assets/styles/pedidoFinalizado.scss";
import { connect } from "react-redux";
import {
  clear_pedidos,
  list_pedidos,
  list_pedidos_finalizados,
  list_pedidos_paginados,
} from "../../../src/redux/actions/pedido";
import InfiniteScroll from "react-infinite-scroll-component";
import ItemPedidoFinalizado from "../../widgets/item_pedido_finalizado";
import LoaderSpinner from "../../widgets/loaderSpinner";
import Alert from "../../components/Swalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartCircleCheck,
  faCartCircleExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import { Helmet } from "react-helmet-async";
import { useIsDefaultStore } from "../../hooks/useIsDefaultStore";
var page_finalizado = 0;
var page_pendiente = 0;
const MisPedidos = ({
  list_pedidos,
  clear_pedidos,
  list_pedidos_finalizados,
  pedidosfinalizados,
  pedidosfinalizados_peticion,
  pedidospendientes,
  pedidospendientes_peticion,
  list_pedidos_paginados,
  sucursal,
}) => {
  const { ciudadp, sucursalp } = useParams();
  useEffect(() => {
    llenar_data_pendiente();
    llenar_data_finalizado();
    return () => {
      clear_pedidos(false);
      page_finalizado = 0;
      page_pendiente = 0;
    };
  }, []);

  const llenar_data_pendiente = () => {
    setTimeout(() => {
      if (pedidospendientes_peticion) {
        let datos = {
          Confirmado: false,
          Cantidad: 5,
        };
        page_pendiente = page_pendiente + 1;
        list_pedidos_paginados(datos, page_pendiente);
      }
    }, 800);
  };

  const llenar_data_finalizado = () => {
    setTimeout(() => {
      if (pedidosfinalizados_peticion) {
        let datos = {
          Confirmado: true,
          Cantidad: 5,
        };
        page_finalizado = page_finalizado + 1;
        list_pedidos_paginados(datos, page_finalizado);
      }
    }, 800);
  };

  const refresh_pedido = async () => {
    await clear_pedidos();
    page_finalizado = 0;
    page_pendiente = 0;
    llenar_data_pendiente();
    llenar_data_finalizado();
  };
  const { isDefaultStore } = useIsDefaultStore();

  return (
    <>
      <Helmet>
        <title>{`Mis pedidos | ${isDefaultStore ? "Hipermaxi" : sucursal?.Descripcion || ""}`}</title>
        {/* <meta name="“robots”" content="noindex" /> */}
      </Helmet>
      <Layout>
        <Alert></Alert>
        <div className="container overflow-x-auto my-4">
          <div className=" w-full">
            <h1 className="text-2xl md:text-3xl text-center text-hiperorange mb-4">
              Mis pedidos{" "}
            </h1>
            <div className="flex gap-4 container_cardPedF">
              <div className="card2  h-[35rem] border border-hiperorange shadow rounded-xl">
                <div className="bg-hiperorange rounded-t-xl h-[3rem] flex justify-center items-center">
                  <h6 className="text-center text-white text-md mb-0">
                    Pendientes
                  </h6>
                </div>
                <div className="flex justify-between flex-column h-[calc(100%-3rem)] bg-white">
                  <InfiniteScroll
                    className="container_item"
                    height={"30rem"}
                    dataLength={pedidospendientes.length}
                    next={llenar_data_pendiente}
                    hasMore={true}
                    loader={
                      pedidospendientes_peticion ? (
                        <LoaderSpinner
                          bool={pedidospendientes_peticion}
                        ></LoaderSpinner>
                      ) : (
                        <>
                          {pedidospendientes.length > 0 ? (
                            <div className="flex w-full justify-center">
                              <p className="text-gray-400">
                                No hay más pedidos para mostrar
                              </p>
                            </div>
                          ) : (
                            <div className="flex flex-col gap-4 justify-center items-center mt-2 min-h-60 text-gray-400">
                              <FontAwesomeIcon
                                icon={faCartCircleExclamation}
                                size="6x"
                              />
                              <div className="text-xl">
                                No hay pedidos pendientes para mostrar
                              </div>
                            </div>
                          )}
                        </>
                      )
                    }
                  >
                    {pedidospendientes.map((d, index) => (
                      <Link
                        to={
                          "/" +
                          ciudadp +
                          "/" +
                          sucursalp +
                          "/pedido-detalle/" +
                          d.NroPedido
                        }
                        key={index}
                        className="w-100"
                      >
                        <ItemPedido
                          item={index}
                          nropedido={d.NroPedido}
                          pedid={d}
                          fechaEntrega={d.FechaEntrega}
                          sala={d.Sala.Descripcion}
                          metodoPago={d.MetodoPago}
                          importeTotal={d.ImporteTotal}
                          refresh_pedido={refresh_pedido}
                        ></ItemPedido>
                      </Link>
                    ))}
                  </InfiniteScroll>
                </div>
              </div>
              <div className="card2  h-[35rem] border border-hiperorange shadow rounded-xl">
                <div className="bg-hiperorange rounded-t-xl h-[3rem] flex justify-center items-center">
                  <h6 className="text-center text-white text-md mb-0">
                    Finalizados
                  </h6>
                </div>
                <div className="flex justify-between flex-column h-[calc(100%-3rem)] bg-white">
                  <InfiniteScroll
                    className="container_item"
                    height={"30rem"}
                    dataLength={pedidosfinalizados.length}
                    next={llenar_data_finalizado}
                    hasMore={true}
                    loader={
                      pedidosfinalizados_peticion ? (
                        <LoaderSpinner
                          bool={pedidosfinalizados_peticion}
                        ></LoaderSpinner>
                      ) : (
                        <>
                          {pedidosfinalizados.length > 0 ? (
                            <div className="flex w-full justify-center">
                              <p className="text-gray-400">
                                No hay más pedidos finalizados para mostrar
                              </p>
                            </div>
                          ) : (
                            <div className="flex flex-col gap-4 justify-center items-center mt-2 min-h-60 text-gray-400">
                              <FontAwesomeIcon
                                icon={faCartCircleCheck}
                                size="6x"
                              />
                              <div className="text-xl">
                                No hay pedidos finalizados para mostrar
                              </div>
                            </div>
                          )}
                        </>
                      )
                    }
                  >
                    {pedidosfinalizados.map((d, index) => (
                      <Link
                        to={
                          "/" +
                          ciudadp +
                          "/" +
                          sucursalp +
                          "/pedido-detalle/" +
                          d.NroPedido
                        }
                        key={index}
                        className="w-100"
                      >
                        <ItemPedidoFinalizado
                          key={index}
                          pedido={d}
                          nropedido={d.NroPedido}
                          fechaEntrega={d.FechaEntrega}
                          sala={d.Sala.Descripcion}
                          metodoPago={d.MetodoPago}
                          importeTotal={d.ImporteTotal}
                        ></ItemPedidoFinalizado>
                      </Link>
                    ))}
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => ({
  pedidospendientes: state.Pedido.pedidospendientes,
  pedidosfinalizados: state.Pedido.pedidosfinalizados,
  pedidosfinalizados_peticion: state.Pedido.pedidosfinalizados_peticion,
  pedidospendientes_peticion: state.Pedido.pedidospendientes_peticion,
  sucursal: state.BranchOffice.sucursal,
});

export default connect(mapStateToProps, {
  list_pedidos,
  clear_pedidos,
  list_pedidos_finalizados,
  list_pedidos_paginados,
})(MisPedidos);
