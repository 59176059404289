import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../assets/styles/radio.scss";
import classNames from "classnames";
import { faCircle, faCircleDot } from "@fortawesome/free-regular-svg-icons";
const ItemOpcionPago = ({ id, Descripcion, change_selec, valor }) => {
  const handleOnChange = (e) => {
    change_selec(id);
  };

  return (
    <button
      type="button"
      onClick={handleOnChange}
      className={classNames(
        "py-1 w-auto h-auto",
        {
          "bg-hiperorange border-1 border-hiperorange text-white": id === valor,
        },
        { "transition ease-in-out delay-150 duration-300 hover:scale-[1.02]": id !== valor }
      )}
      key={"fs" + id}
      style={{ borderRadius: "10px" }}
    >
      <div className="d-flex justify-between gap-2 py-1 px-2 w-full">
        <FontAwesomeIcon
          icon={id === valor ? faCircleDot : faCircle}
          size="xl"
        />

        <div className="text-left text-sm md:text-base flex-grow">
          {Descripcion}
        </div>
      </div>
    </button>
  );
};

export default ItemOpcionPago;
