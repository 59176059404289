import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/css";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import "../../../assets/styles/responsive.scss";
import {
  DEFAULT_COLOR,
  colorEnUmbralDeBlanco,
  listaColores as listColor,
} from "../../../components/Utils/utils-colores";
import {
  actualizar_lista_compra,
  get_lista_compra,
  store_lista_compra,
} from "../../../redux/actions/shoppingList";
import ButtonClose from "../../../widgets/button_close";
import ButtonText from "../../../widgets/button_text";
import { useNavigate, useParams } from "react-router-dom";

const ModalAddListaCompra = ({
  productos = [],
  ps_closeModalAdd,
  redirect = false,
  IdSucursal,
  store_lista_compra,
  get_lista_compra,
}) => {
  const { ciudadp, sucursalp } = useParams();
  const navigate = useNavigate();

  const listaCompra = {
    Descripcion: "",
    Color: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    setValue,
  } = useForm({
    defaultValues: listaCompra,
  });
  const [customColor, setCustomColor] = useColor(DEFAULT_COLOR);
  const isCustomColorInList = useMemo(
    () => !!listColor.find((color) => color.color === customColor.hex),
    [customColor.hex]
  );

  const customColorEnUmbralDeBlanco = colorEnUmbralDeBlanco(
    customColor.hex,
    180
  );

  const color = watch("Color");
  const messages = {
    required: "Este campo es obligatorio",
    minLength: "Este campo requiere al menos 3 caracteres.",
    maxLength: "El límite de este campo es de 50 caracteres.",
    espacioBlanco: "No es permitido espacios en blanco",
    requiredColor: "Debe seleccionar un color",
  };
  const patterns = {
    espacioBlanco: /^(?!\s)/,
  };

  const selectColor = (color) => {
    setValue("Color", color);
  };

  const onSubmit = (datos) => {
    guardarListaCompra(datos);
  };

  const guardarListaCompra = (datos) => {
    async function guardar() {
      try {
        await store_lista_compra(datos, productos, IdSucursal, null);
        await get_lista_compra(IdSucursal);
        ps_closeModalAdd();
        if (redirect) {
          navigate(`/${ciudadp}/${sucursalp}/lista-compras`);
        }
      } catch (e) {}
    }
    guardar();
  };

  return (
    <>
      <div className="w-full flex flex-col h-full md:max-h-[600px]">
        <div className="w-full bg-white rounded-lg md:rounded-lg h-full overflow-hidden">
          <div className="flex flex-col h-full">
            <div className="bg-hiperorange w-full text-white flex items-center justify-center  content_title relative">
              <h2 className="p-0 m-0 title_carrito_responsivo">
                Crear nueva lista
              </h2>
              <div className="absolute right-2 ">
                <ButtonClose
                  className="text-white bg-transparent"
                  onClick={() => ps_closeModalAdd()}
                />
              </div>
            </div>

            <form className="bg-white overflow-y-auto flex flex-col h-full" onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-rows-1 gap-3 p-3">
                <div className="W-100">
                  <label
                    htmlFor="Descripcion"
                    className="block fontzize_label text-black mb-2"
                  >
                    Nombre de la lista
                  </label>
                  <div className="mt-1">
                    <input
                      id="Descripcion"
                      name="Descripcion"
                      type="text"
                      autoComplete="Descripcion"
                      placeholder="Nombre de la lista"
                      className=" appearance-none border_black radius_input w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      {...register("Descripcion", {
                        required: {
                          value: true,
                          message: messages.required,
                        },
                        minLength: {
                          value: 3,
                          message: messages.minLength + ", 3 caracteres",
                        },
                        maxLength: {
                          value: 50,
                          message: messages.maxLength,
                        },
                        pattern: {
                          value: patterns.espacioBlanco,
                          message: messages.espacioBlanco,
                        },
                      })}
                    />
                    {errors.Descripcion && (
                      <small className="text-red-600">
                        {errors.Descripcion.message}
                      </small>
                    )}
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <label
                    htmlFor="Color"
                    className="block fontzize_label text-black"
                  >
                    Selecciona un color personalizado
                  </label>
                  <div className="flex flex-col gap-1">
                    <Controller
                      name="Color"
                      id="Color"
                      control={control}
                      defaultValue=""
                      rules={{ required: messages.requiredColor }}
                      render={({ field }) => {
                        return (
                          <>
                            <ColorPicker
                              height={100}
                              hideInput
                              hideAlpha
                              color={customColor}
                              onChange={(color) => {
                                setCustomColor(color);
                                field.onChange(color.hex);
                              }}
                            />
                            <div
                              onClick={() => field.onChange(customColor.hex)}
                              className="w-full h-10 rounded-md relative p-1 flex justify-center items-center border-2 cursor-pointer"
                              key="color-personalizado"
                              style={{
                                backgroundColor: customColor.hex,
                                borderColor: customColorEnUmbralDeBlanco
                                  ? "#000000"
                                  : customColor.hex,
                              }}
                            >
                              {!isCustomColorInList &&
                                customColor.hex === color && (
                                  <div
                                    className="w-5 h-5 flex items-center justify-center bg-white rounded-full border-2 absolute -right-1 -top-1"
                                    style={{
                                      color: customColorEnUmbralDeBlanco
                                        ? "#000000"
                                        : customColor.hex,
                                      borderColor: customColorEnUmbralDeBlanco
                                        ? "#000000"
                                        : customColor.hex,
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faCheck} size="xs" />
                                  </div>
                                )}
                            </div>
                          </>
                        );
                      }}
                    />
                  </div>

                  <div className="flex flex-col gap-2 justify-center">
                    <div
                      htmlFor="Color"
                      className="block fontzize_label text-black"
                    >
                      O selecciona un color de esta lista
                    </div>
                    <div className="grid grid-cols-4 grid-rows-2 gap-2 ">
                      <Controller
                        name="Color"
                        id="Color"
                        control={control}
                        defaultValue=""
                        rules={{ required: messages.requiredColor }}
                        render={({ field }) => (
                          <>
                            {listColor.map((item_list) => (
                              <div
                                className="w-10 h-10 rounded-md cursor-pointer flex justify-center items-center p-1 relative"
                                key={item_list.id}
                                style={{
                                  backgroundColor: item_list.color,
                                  // borderColor:
                                  //   item_list.color === color
                                  //     ? "#000000"
                                  //     : item_list.color,
                                }}
                                onClick={() => {
                                  selectColor(item_list.color);
                                  field.onChange(item_list.color); // Asegúrate de llamar a onChange para que react-hook-form actualice su estado
                                }}
                              >
                                {item_list.color === color && (
                                  <div
                                    className="w-5 h-5 flex items-center justify-center bg-white rounded-full border-2 absolute -right-1 -top-1"
                                    style={{
                                      color: item_list.color,
                                      borderColor: item_list.color,
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faCheck} size="xs" />
                                  </div>
                                )}
                              </div>
                            ))}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div>
                    {errors.Color && (
                      <small className="text-red-600">
                        {errors.Color.message}
                      </small>
                    )}
                  </div>
                </div>
                <div>
                  <ButtonText
                    type="submit"
                    label="Crear"
                    principal={false}
                    className="bg-hiperorange text-white mb-2 hover:bg-hiperorange_hover"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.Shopping_list.loading,
});

export default connect(mapStateToProps, {
  actualizar_lista_compra,
  store_lista_compra,
  get_lista_compra,
})(ModalAddListaCompra);
