import {
    GET_MYCARD_LIST_SUCCESS, GET_MYCARD_LIST_FAIL, GET_DETALLE_MYCARD_LIST_SUCCESS,
    GET_DETALLE_MYCARD_LIST_NULL
} from '../actions/types';

const initialState = {
    mycard_list: null,
    mycard_list_detalle: null,
    peticion: false
};

export default function Mycard_list(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case GET_MYCARD_LIST_SUCCESS:
            return {
                ...state,
                mycard_list: payload.Dato,
                peticion: true
            }
        case GET_DETALLE_MYCARD_LIST_SUCCESS:
            return {
                ...state,
                mycard_list_detalle: payload.Dato,
                peticion: true
            }
        case GET_DETALLE_MYCARD_LIST_NULL:
            return{
                ...state,
                mycard_list_detalle:null,
            }
        case GET_MYCARD_LIST_FAIL:
            return {
                ...state
            }
        default:
            return state
    }
}