import {
  HiperPuntosAction,
  HiperPuntosState,
  SET_CUSTOMER_BAGS,
  SET_CUSTOMER_BAGS_SEARCH_TERM,
  SET_VER_HIPERPUNTOS_PARAMETER,
} from "../types/hiper-puntos-types";

const initialState: HiperPuntosState = {
  verHiperPuntosParameter: undefined,
  customerBags: undefined,
  customerBagsSearchTerm: "",
};

export default function HiperPuntos(
  state: HiperPuntosState = initialState,
  action: HiperPuntosAction
) {
  const { type, payload } = action;

  switch (type) {
    case SET_VER_HIPERPUNTOS_PARAMETER:
      return {
        ...state,
        verHiperPuntosParameter: payload,
      };

    case SET_CUSTOMER_BAGS:
      return {
        ...state,
        customerBags: payload,
      };

    case SET_CUSTOMER_BAGS_SEARCH_TERM:
      return {
        ...state,
        customerBagsSearchTerm: payload,
      };

    default:
      return state;
  }
}
