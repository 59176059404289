import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { connect } from "react-redux";
import { actualizar_lista_compra } from "../redux/actions/shoppingList";

const ModalVale = ({ showModal, agregar_vale, idsucursal }) => {
  const [vale, setVale] = useState("");

  const showModal2 = () => {
    showModal(idsucursal);
  }

  const agregar_vale2 = () => {
    if (vale !== "") {
      agregar_vale(idsucursal, vale);
      setVale("");
    } else {
      showModal2();
    }
  }

  return (
    <>
      <div
        className="fixed z-10 overflow-y-auto top-20 w-full left-0 hidden"
        id={`modal${idsucursal}`}
      >
        <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity">
            <div className="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;{" "}
          </span>
          <div
            className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="float-right">
              <FontAwesomeIcon
                icon={faTimesCircle}
                className="mr-1 text-hiperdark cursor-pointer"
                onClick={(e) => showModal2(e)}
              />
            </div>
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 ">
              <div className="flex  justify-center">
                <label>Agregar Vales del Hipermaxi</label>
              </div>
              <div className="flex  justify-center">
                <label className="font-bold">Monto del Vale</label>
              </div>

              <input
                type="number"
                className="form-control w-full bg-gray p-2 mt-2 mb-3"
                value={vale}
                onChange={(e) => setVale(e.target.value)}
              />
              <span className="text-xs italic leading-3">
                El vale se entregará como pago al momento de recibir los
                productos{" "}
              </span>
            </div>
            <div className="  px-4 py-3 text-right flex content-center">
              {/* <button type="button" class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2" onClick={ (e) => showModal( e)}  ><i class="fas fa-times"></i> Cancel</button> */}
              <button
                type="button"
                className="py-2 px-4 bg-hiperorange text-white rounded hover:bg-hiperorange-700 mx-auto"
                onClick={(e) => agregar_vale2(e)}
              >
                {" "}
                Agregar Vale
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, {
  actualizar_lista_compra,
})(ModalVale);
