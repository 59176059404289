import {
    ENABLE_PETITION,
    DISABLE_PETITION,
} from '../actions/types';

const initialState = {
    peticion: false,
    peticion_label: ''
};

export default function Peticion(state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case ENABLE_PETITION:
            return {
                ...state,
                peticion: true,
                peticion_label: payload.message
            };
        case DISABLE_PETITION:
            return {
                ...state,
                peticion: false,
                peticion_label: ''
            }
        default:
            return state
    }
    ;
}