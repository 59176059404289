// firebase.js
import { initializeApp } from "@firebase/app";
import { getDatabase } from "@firebase/database";
import { getMessaging, isSupported } from "@firebase/messaging";
import "firebase/compat/database";
import "react-toastify/dist/ReactToastify.css";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const getMessagingInstance = async () => {
  const supported = await isSupported();
  try {
    if (supported) {
      return getMessaging(firebaseApp);
    } else {
      return null;
    }
  } catch (error) {
    return null
  }
};
//try{
const database = getDatabase(firebaseApp);
console.log(database);
//}catch(ex){
//  console.log(ex)
//}
export { database, getMessagingInstance };

