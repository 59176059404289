import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation } from "swiper";
import { get_categorias } from "../../redux/actions/home";
import Subcategoria from "./Subcategoria";
import "swiper/css";
import "swiper/css/navigation";
import "../../assets/styles/globalColorPrincipal.css";
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { debounce } from "lodash";
import { useParams } from "react-router-dom";
import slug from "../Utils/slug";
const Categoria = ({ get_categorias, categorias, sucursal, reset }) => {
  const params = useParams();
  const { sucursalp } = params;
  const currentSucursalName = sucursal
    ? slug.data_slug(sucursal.Descripcion)
    : "";
  useEffect(() => {
    if (currentSucursalName === sucursalp) {
      if (categorias.length === 0) {
        get_categorias(sucursal.IdSucursal, sucursal.IdMarket);
      } else if (categorias.length > 0) {
        let item = categorias[0];
        if (item.IdSucursal !== sucursal.IdSucursal) {
          get_categorias(sucursal.IdSucursal, sucursal.IdMarket);
        }
      }
    }
  }, [sucursal.IdSucursal, sucursal.IdMarket, currentSucursalName, sucursalp]);

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const handleMouseEnter = useCallback(
    debounce((item) => {
      setActiveSubMenu(item);
    }, 400),
    []
  );

  const handleMouseLeave = () => {
    setActiveSubMenu(null);
    handleMouseEnter.cancel();
  };

  const handleItemMouseEnter = (item) => {
    handleMouseEnter(item);
  };
  return (
    <div className="sticky lg:top-[2.65rem] z-30" style={{ overflowX: "clip" }}>
      {categorias?.length <= 0 && (
        <div className="animate-pulse w-full backdrop-blur-lg bg-slate-200/40 text-slate-200/40 rounded-lg h-10"></div>
      )}
      <div
        className={`seccion_categoria transition-opacity duration-300 ${
          categorias?.length > 0 ? "opacity-100" : "opacity-0"
        }`}
      >
        <Swiper
          slidesPerView="auto"
          spaceBetween={2}
          freeMode={true}
          pagination={{ clickable: true }}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          modules={[FreeMode, Navigation]}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
          }}
          className="mySwiper bg-hiperorange text-xs text-center font-light md:overflow-visible"
          style={{
            paddingLeft: "2rem",
            paddingRight: "2rem",
          }}
        >
          {categorias?.map((categoria, index) => (
            <SwiperSlide
              key={index}
              className={"alineado_categoria"}
              style={{
                alignSelf: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Subcategoria
                ps_categoria={categoria}
                reset={reset}
                handleMouseLeave={handleMouseLeave}
                handleItemMouseEnter={() => handleItemMouseEnter(categoria)}
                activeSubMenu={activeSubMenu}
              ></Subcategoria>
            </SwiperSlide>
          ))}

          <div
            ref={navigationPrevRef}
            className="absolute left-0 top-0 bottom-0 z-1"
          >
            <button className="w-8 bg-hiperorange hover:bg-hiperorange_hover text-white flex justify-center items-center h-full">
              <FontAwesomeIcon icon={faAngleLeft} className=" px-2 text-xl" />
            </button>
          </div>
          <div
            ref={navigationNextRef}
            className="absolute right-0 top-0 bottom-0 z-1"
          >
            <button className="w-8 bg-hiperorange hover:bg-hiperorange_hover text-white flex justify-center items-center  h-full">
              <FontAwesomeIcon icon={faAngleRight} className=" px-2 text-xl" />
            </button>
          </div>
        </Swiper>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  sucursal: state.BranchOffice.sucursal,
  categorias: state.Home.categorias,
});

export default connect(mapStateToProps, {
  get_categorias,
})(Categoria);
