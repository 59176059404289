import React from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-solid-svg-icons";
import { cn } from "../libs/utils";
const ButtonClose = ({
  onClick,
  disabled = false,
  className,
  type = "button",
}) => {
  return (
    <button
      type={type}
      className="disabled:cursor-not-allowed"
      disabled={disabled}
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon={faClose}
        className={cn(
          "bg-white text-[#DC842D] p-1   w-[1.3rem] h-[1.3rem]",
          className
        )}
        style={{ borderRadius: "50%" }}
      />{" "}
    </button>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(ButtonClose);
