import {
  SET_AUTH_LOADING,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_FAIL,
  UPDATE_USER_PROFILE_FAIL,
  UPDATE_USER_PROFILE_SUCCESS,
  REMOVE_AUTH_LOADING,
  CLEAR_PROFILE,
} from "./types";
import axios from "axios";

import { setSwAlert, showRespuesta } from "./swalert";
import { end_peticion, start_peticion } from "./peticion";
import respuestas from "../../components/Alertas";

export const limpiar_perfil = () => async (dispatch) => {
  dispatch({
    type: CLEAR_PROFILE,
  });
  dispatch(end_peticion());
};
export const info_session =
  (token, loading = false) =>
  async (dispatch) => {
    if (loading) {
      dispatch(start_peticion("cargando"));
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${
          token || localStorage.getItem("access_token")
        }`,
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/Info-Sesion`,
        config
      );
      if (res.status === 200) {
        dispatch({
          type: GET_USER_PROFILE_SUCCESS,
          payload: res.data,
        });
        return res.data;
      } else {
        dispatch({
          type: GET_USER_PROFILE_FAIL,
        });
        dispatch(showRespuesta(res));
      }

      if (loading) {
        dispatch(end_peticion());
      }
      return null;
    } catch (err) {
      dispatch({
        type: GET_USER_PROFILE_FAIL,
      });
      dispatch(setSwAlert(respuestas.peticionError, true, 500));
      if (loading) {
        dispatch(end_peticion());
      }
      return null;
    }
  };

export const update_usuario_anonimo = (datos) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
  try {
    const res = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/usuario`,
      datos,
      config
    );
    if (res.status === 200) {
      dispatch(info_session());
    } else {
      dispatch({
        type: UPDATE_USER_PROFILE_FAIL,
      });
      dispatch(showRespuesta(res));
    }
  } catch (err) {
    dispatch({
      type: GET_USER_PROFILE_FAIL,
    });
    dispatch(setSwAlert(respuestas.peticionError, true, 500));
  }
};
export const update_usuario =
  (datos, object = null) =>
  async (dispatch) => {
    dispatch(start_peticion("actualizando..."));
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/usuario`,
        datos,
        config
      );
      if (res.status === 200 && res.data.Estado === 200) {
        dispatch(info_session());
        dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 200, object));
        dispatch(end_peticion());
        return true;
      } else {
        dispatch({
          type: UPDATE_USER_PROFILE_FAIL,
        });
        dispatch(showRespuesta(res));
      }
      dispatch(end_peticion());
      return false;
    } catch (err) {
      dispatch({
        type: GET_USER_PROFILE_FAIL,
      });
      dispatch(end_peticion());
      dispatch(setSwAlert(respuestas.peticionError, true, 500));
      throw new Error(err);
    }
  };

export const update_user_data =
  (datos, object = null) =>
  async (dispatch) => {
    // 
    dispatch(start_peticion("actualizando..."));
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/fidelizacion-mobile/modificar-datos-mi-cuenta`,
        datos,
        config
      );
      if (res.status === 200 && res.data.Estado === 200) {
        dispatch(info_session());
        dispatch(
          setSwAlert(
            "Datos actualizados correctamente",
            res.data.ConError,
            200,
            object
          )
        );
        dispatch(end_peticion());
        return true;
      } else {
        dispatch({
          type: UPDATE_USER_PROFILE_FAIL,
        });
        dispatch(showRespuesta(res));
      }
      dispatch(end_peticion());
      return false;
    } catch (err) {
      dispatch({
        type: GET_USER_PROFILE_FAIL,
      });
      dispatch(end_peticion());
      dispatch(setSwAlert(respuestas.peticionError, true, 500));
      throw new Error(err);
    }
  };

export const actualizar_tokenfb_usuario = (datos) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
  try {
    const res = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/usuario/externo`,
      datos,
      config
    );
    if (res.status === 200 && !res.data.ConError) {
      dispatch(info_session());
    } else {
      dispatch({
        type: UPDATE_USER_PROFILE_FAIL,
      });
      dispatch(showRespuesta(res));
    }
  } catch (err) {
    dispatch({
      type: GET_USER_PROFILE_FAIL,
    });
    dispatch(setSwAlert(respuestas.peticionError, true, 500));
    //   dispatch(setAlert('Error al conectar con el servidor, intenta mas tarde.', 'red'));
  }
};

export const eliminar_usuario = () => async (dispatch) => {
  dispatch(start_peticion("actualizando..."));
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/eliminar-usuario`,
      config
    );

    if (res.status === 200 && res.data.Estado === 200) {
      dispatch(info_session());
      dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 200));
    } else {
      dispatch({
        type: UPDATE_USER_PROFILE_FAIL,
      });
      dispatch(showRespuesta(res));
    }
    dispatch(end_peticion());
  } catch (err) {
    dispatch({
      type: GET_USER_PROFILE_FAIL,
    });
    dispatch(end_peticion());
    dispatch(setSwAlert(respuestas.peticionError, true, 500));
  }
};

export const get_user_data = (idUsuario) => async (dispatch) => {
  // 

  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/fidelizacion-mobile/obtener-datos-mi-cuenta?codigoCliente=${idUsuario}`,
      config
    );
    if (!res.ConError) {
      // 
      return res.data.Dato[0];
    }
  } catch (error) {
    
  }
};
