import Layout from "../hocs/Layout";
import React, { useState, useEffect } from "react";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import ItemProducto from "../widgets/item_producto";
// import {useParams} from 'react-router'
import { connect } from "react-redux";
import { get_categorias } from "../redux/actions/home";
import { get_producto } from "../redux/actions/producto";
import {
  get_filtro_productos_categoria,
  get_filtro_productos,
} from "../redux/actions/producto";
import BackToPage from "../components/BackPage/BackToPage";
import InfiniteScroll from "react-infinite-scroll-component";
import Alert from "../components/Swalert";
import LoaderSpinner from "../widgets/loaderSpinner";

let page = 0;
const HomeCategoriaProducto = ({
  get_categorias,
  categorias,
  sucursal,
  get_producto,
  productos,
  subcategorias,
  category,
  subcategoria_id,
  loading,
  get_filtro_productos_categoria,
  productos_peticion,
  get_filtro_productos,
}) => {
  const { filter } = useParams();

  let IdSucursal = sucursal.IdSucursal;
  let IdMarket = sucursal.IdMarket;
  const [subcateid, setSubcateid] = useState(null);
  const [aux, setAux] = useState();
  const [filtro, setFiltro] = useState("");
  const [categoriaid_select, SetCategoriaid_select] = useState(null);

  useEffect(() => {
    onloadData();
    setFiltro("");
  }, [filter]);

  useEffect(() => {
    setSubcateid(subcategoria_id);
    setAux(subcategoria_id);
  }, [subcategoria_id]);

  useEffect(() => {
    if (
      category !== null &&
      category !== "" &&
      typeof subcateid !== "undefined" &&
      subcateid === aux
    ) {
      obtenerDatosProducto(subcategoria_id);
    }
  }, [subcateid]); //ctaeogry

  const onloadData = async () => {
    await obtenerDatosCategoria();
    await filtro_productos_barcode();
  };

  const filtro_productos_barcode = async () => {
    const filtro_codigo = filter;
    const datos = {
      //   categoria_id:categoria_id,
      filtro_text: "",
      filtro_codigo: filtro_codigo,
      IdMarket: sucursal.IdMarket,
      IdLocatario: sucursal.IdSucursal,
    };
    await get_filtro_productos(datos);
  };

  const obtenerDatosCategoria = async (subcatId) => {
    await get_categorias(IdSucursal, IdMarket, subcatId);
  };

  const obtenerDatosProducto = async (subcatId) => {
    page = page + 1;

    let datos = {
      IdMarket: IdMarket,
      IdLocatorio: 91, //revisar_estatico
      IdCategoria: category.IdCategoria, //verify
      IdSubcategoria: Number(subcatId),
    };
    await get_producto(datos, page);
  };
  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const obtenerProductos = async () => {
    if (productos_peticion && categoriaid_select !== null) {
      page = page + 1;

      let datos = {
        IdMarket: IdMarket,
        IdLocatorio: 91, //revisar_estatico
        IdCategoria: category.IdCategoria, //verify
        IdSubcategoria: Number(subcateid),
      };
      await delay(1500);
      await get_producto(datos, page);
    } else {
    }
  };
  const obtenerProductosSelected = async (subcate_id) => {
    page = page + 1;
    let datos = {
      IdMarket: IdMarket,
      IdLocatorio: 91, //revisar_estatico
      IdCategoria: category.IdCategoria, //verify
      IdSubcategoria: Number(subcate_id),
    };
    await get_producto(datos, page);
  };

  const filtro_productos = async (id_categoria) => {
    SetCategoriaid_select(id_categoria);
    const categoria_id = id_categoria;
    const filtro_text = filtro;
    const datos = {
      categoria_id: categoria_id,
      filtro_text: filtro_text,
      IdMarket: sucursal.IdMarket,
      IdLocatario: sucursal.IdSucursal,
    };
    await get_filtro_productos_categoria(datos);
  };
  const handleChange = (event) => {
    setFiltro(event.target.value);
  };

  const verifyProductos = () => {
    if (productos !== undefined && productos !== null) {
      return true;
    }
    return false;
  };

  const obtenerCantidad = () => {
    if (verifyProductos) {
      return productos.length;
    }
    return 0;
  };

  const enter_opcion = (event) => {
    if (event.key === "Enter") {
      filtro_productos();
    }
  };
  return (
    <Layout>
      <BackToPage
        ps_url_page={"/"}
        ps_contenerdor={"sinContenedor"}
      ></BackToPage>
      <Alert></Alert>
      <main className="relative flex-grow">
        <div className="mx-auto max-w-[1920px] px-4">
          <div name="grid" className="flex pb-16 pt-7">
            <div className="sticky top-5 h-full w-80">
              <div className="block">
                <div className="max-h-full overflow-hidden rounded-xl border border-border-base shadow">
                  <h3 className="text-center text-hiperorange">
                    {category != null && category.Descripcion}
                  </h3>

                  <div className="relative flex w-full flex-wrap items-stretch px-4">
                    <input
                      type="text"
                      className="py-1.5 placeholder-slate-300 text-slate-600 relative rounded-2xl text-sm border border-hiperorange  outline-none w-full  "
                      value={filtro}
                      onChange={handleChange}
                      onKeyDown={enter_opcion}
                      style={{ paddingRight: "2rem", paddingLeft: "0.5rem" }}
                    />
                    <span className=" absolute text-hiperorange right-5 pr-3 py-1.5">
                      <FontAwesomeIcon
                        icon={faSearch}
                        onClick={(e) => filtro_productos(e)}
                      />
                    </span>
                  </div>

                  <div className="mt-2">
                    <ul className=" px-3 grid gap-1">
                      {categorias &&
                        categorias !== null &&
                        categorias !== undefined &&
                        categorias.length > 0 &&
                        categorias.map((cat, indexcat) => (
                          <li
                            key={indexcat}
                            className="py-1.5 px-2 rounded-lg border  cursor-pointer"
                            style={{
                              backgroundColor:
                                Number(cat.IdCategoria) ===
                                Number(categoriaid_select)
                                  ? "var(--primario)"
                                  : "transparent",
                              color:
                                Number(cat.IdCategoria) ===
                                Number(categoriaid_select)
                                  ? "#FFFFFF"
                                  : "var(--secundario)",
                            }}
                            onClick={(e) =>
                              filtro_productos(cat.IdCategoria, e)
                            }
                          >
                            {cat.Descripcion}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full">
              <div className="text-center my-3">
                <div className="relative flex w-full flex-wrap items-stretch px-4">
                  <input
                    type="text"
                    className=" py-1.5 placeholder-slate-300 text-slate-600 relative rounded-2xl text-sm border border-hiperorange  outline-none w-full"
                    value={filtro}
                    onChange={handleChange}
                    onKeyDown={enter_opcion}
                    style={{ paddingRight: "2rem", paddingLeft: "0.5rem" }}
                  />
                  <span className=" absolute text-hiperorange right-5 pr-3 py-1.5">
                    <FontAwesomeIcon
                      icon={faSearch}
                      onClick={(e) => filtro_productos(e)}
                    />
                  </span>
                </div>
              </div>
              <div className="p-1">
                <div className="w-100 p-4">
                  <InfiniteScroll
                    dataLength={obtenerCantidad}
                    next={obtenerProductos}
                    hasMore={true}
                    loader={
                      <div
                        style={{
                          visibility:
                            productos_peticion === true &&
                            categoriaid_select !== null
                              ? "visible"
                              : "hidden",
                        }}
                      >
                        <LoaderSpinner bool={true} />
                      </div>
                    }
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      gap: "1rem",
                      paddingBottom: "2rem",
                    }}
                  >
                    {productos &&
                      productos !== null &&
                      productos !== undefined &&
                      productos.map((producto, index) => (
                        // <>
                        <ItemProducto key={index} producto={producto} />

                        // </>
                      ))}
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
};
const mapStateToProps = (state) => ({
  sucursal: state.BranchOffice.sucursal,
  categorias: state.Home.categorias,
  subcategorias: state.Home.subcategorias,
  category: state.Home.category,
  subcategoria_id: state.Home.subcategoria_id,

  loading: state.Producto.loading,
  productos: state.Producto.productos,
  productos_peticion: state.Producto.productos_peticion,
});

export default connect(mapStateToProps, {
  get_categorias,
  get_producto,
  get_filtro_productos_categoria,
  get_filtro_productos,
})(HomeCategoriaProducto);

// export default HomeCategoria;
