import { useSelector } from "react-redux";
import { SucursalContacto } from "../../domain/interface/sucursal-contacto";

export function useGetSucursalContactoTelefonos() {
  const sucursalContacto = useSelector(
    (state: { SucursalContactos: { sucursalContacto: SucursalContacto } }) =>
      state.SucursalContactos.sucursalContacto
  );

  const telefonoAtencionCliente = sucursalContacto?.Telefono1;
  const telefonoProductoNoDisponible = sucursalContacto?.Telefono2;

  return { telefonoAtencionCliente, telefonoProductoNoDisponible };
}
