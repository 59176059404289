import React, { useEffect, useState } from "react";
import "../../../assets/fontawesome";
import "../../../assets/styles/mystyle.scss";
import "../../../assets/styles/globalColorPrincipal.css";
import "../../../assets/styles/stylePageGlobals.scss";
import { connect } from "react-redux";
import {
  codigo_acceso,
  get_token,
  get_prefijos,
  activate,
  activate_sin_autologin,
  reset_activate_sin_autologin,
  set_token_externo,
  get_anonimo_token,
} from "../../../redux/actions/auth";
import {
  info_session,
  actualizar_tokenfb_usuario,
} from "../../../redux/actions/profile";
import { useForm } from "react-hook-form";

import ButtonText from "../../../widgets/button_text";
import { clear_pedidos } from "../../../redux/actions/pedido";
import Button_close from "../../../widgets/button_close";
import useContador from "../../../hooks/useContador";
import { useNavigate, useParams } from "react-router-dom";
import slug from "../../Utils/slug";
import { useGetSucursalMasCercana } from "../../../hooks/useGetSucursalesCercanas";
import { useGetBackLink } from "../../../hooks/useGetBackLink";
import {
  DEFAULT_STORE_BY_TIPO_SERVICIO,
  useGetSucursalTipoServicioBySlug,
} from "../../../hooks/useGetSucursalTipoServicioBySlug";
import { useGetSucursalesMasCercanaPorTipo } from "../../../hooks/useGetSucursalesMasCercanaPorTipo";

const Registro_activate = ({
  clear_pedidos,
  array_prefijo,
  peticion,
  loading,
  sendAcceso,
  get_prefijos,
  codigo_acceso,
  profile,
  datoLogin,
  get_token,
  access_token,
  ps_posicion,
  activate,
  activate_sin_autologin,
  reset_activate_sin_autologin,
  phone,
  isAuthenticated,
  authenticated,
  info_session,
  set_token_externo,
  ps_save_pedidos,
  ps_endModalRegistro,
  ps_opcion_numero,
  ps_check_no_anonimo,
  socialUserData,
  get_anonimo_token,
  actualizar_tokenfb_usuario,
  sucursales,
  direccion,
  ciudades,
}) => {
  const { getSucursalesCercanas, getSucursalPorDefecto } =
    useGetSucursalMasCercana();

  const params = useParams();
  const { backLink, searchParams } = useGetBackLink();
  const { ciudadp, sucursalp } = params;
  const { mostrarComponente, segundosRestantes, reiniciarContador } =
    useContador(60);
  const [obtenerDataUsuario, setObtenerDataUsuario] = useState(false);
  const [deshabilitar, setDeshabilitar] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const messages = {
    required: "Este campo es obligatorio",
    minLength: "No puede ingresar menor caracteres de lo establecido",
    maxLength: "No puede ingresar más caracteres en este campo",
    espacioBlanco: "No es permitido espacios en blanco",
    requiredColor: "Debe seleccionar un color",
    aceptaNumeros: "Solo se acepta números en este campo",
    aceptaLetra: "Solo se acepta letras en este campo",
  };
  const patterns = {
    espacioBlanco: /^(?!\s)/,
    aceptaNumeros: /^[0-9,-]+$/,
    aceptaLetra: /^[A-Za-z\s]+$/,
  };
  const { getSucursalTipoServicioBySlug } = useGetSucursalTipoServicioBySlug();
  const { getSucursalesMasCercanaPorTipo } =
    useGetSucursalesMasCercanaPorTipo();
  // useEffect(() => {
  //   if (access_token && obtenerDataUsuario) {
  //     info_session();
  //   }
  // }, [access_token, obtenerDataUsuario]);
  useEffect(() => {
    if (
      profile &&
      profile.Telefono != "" &&
      sucursales?.length > 0 &&
      ciudades?.length > 0
    ) {
      const sucursalPorDefecto = getSucursalPorDefecto(sucursales);
      let sucursalCliente = null;
      if (profile.Ubicaciones.length > 0) {
        const direccionActual = profile.Ubicaciones.find(
          (ubicacion) => ubicacion.EsPrincipal
        );
        if (direccionActual) {
          const marketCercanos = getSucursalesCercanas(
            sucursales,
            direccionActual
          );
          const previousSucursalTipoServico =
            getSucursalTipoServicioBySlug(sucursalp);

          sucursalCliente = getSucursalesMasCercanaPorTipo(
            marketCercanos,
            previousSucursalTipoServico
          );
          if (!sucursalCliente) {
            sucursalCliente = sucursalPorDefecto;
          }
        }
      } else {
        sucursalCliente = sucursalPorDefecto;
      }
      const ciudad = ciudades.find(
        (pre) => parseInt(pre.IdRegion) === parseInt(sucursalCliente.idRegion)
      );
      let sucursal_slug = slug.data_slug(sucursalCliente.Descripcion);
      let ciudad_slug = slug.data_slug(ciudad.Nombre);
      const sucursalUrl = `/${ciudad_slug}/${sucursal_slug}`;
      if (sucursalp !== sucursal_slug) {
        window.location.href = `${sucursalUrl}${backLink}${searchParams}`;
      }
      ps_endModalRegistro();
    }
  }, [profile, sucursales, ciudades, sucursalp, backLink, searchParams]);

  const regresarIngresarCodigo = () => {
    reset_activate_sin_autologin();
    ps_posicion(1);
  };

  const onSubmit = async (data) => {
    ejecutar();
    try {
      await activate_sin_autologin(phone, data.codigoAcceso);
      if (socialUserData) {
        const result = await set_token_externo(
          socialUserData.socialUserId,
          socialUserData.socialTipoAcceso
        );
        if (result) {
          const datos = {
            Nombres: "",
            ApellidoPaterno: "",
            // ApellidoMaterno: '',
            Telefono: phone,
            Email: socialUserData.socialEmail,
          };
          await actualizar_tokenfb_usuario(datos, null);
          await info_session();
          // ps_endModalRegistro();
        } else {
          setDeshabilitar(false);
          get_anonimo_token();
          regresarIngresarCodigo();
        }
      } else {
        await info_session();
        // ps_endModalRegistro();
      }
    } catch (error) {
      setDeshabilitar(false);
    }
    clear_pedidos();
  };

  const ejecutar = () => {
    setDeshabilitar(true);
  };

  const reenviarCodigo = () => {
    let prefijo = localStorage.getItem("prefijo");
    let tipoEnvicoAcceso = localStorage.getItem("tipoEnvicoAcceso");
    let phone = localStorage.getItem("phone");
    codigo_acceso(phone, prefijo, tipoEnvicoAcceso);
    reiniciarContador();
  };

  return (
    <>
      <div className="bg-white rounded-lg content_modal_registro">
        <div className="bg-hiperorange rounded-t-lg w-100 relative text-white flex items-center justify-center h-[52px] content_title">
          <h2 className="p-0 m-0 title_carrito_responsivo">Ingresar</h2>
          <div className="absolute right-2 ">
            <Button_close
              disabled={!!socialUserData}
              className="text-white bg-transparent"
              onClick={() => ps_endModalRegistro()}
            />
          </div>
        </div>
        <div className="w-full">
          <div className="h-auto max-h-full p-3">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex justify-center mb-4"
            >
              <div className="box-input w-full">
                <div>
                  <label htmlFor="codigo" className="block font-bold">
                    Ingresa el código de acceso
                  </label>
                  <div className="mt-1 grid grid-cols-1">
                    <input
                      name="CodigoAcceso"
                      type="Number"
                      className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                      autoComplete="off"
                      maxLength={10}
                      autoFocus
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                      {...register("codigoAcceso", {
                        required: {
                          value: true,
                          message: messages.required,
                        },
                        minLength: {
                          value: 6,
                          message: "El código de acceso debe tener 6 caracteres.",
                        },
                        maxLength: {
                          value: 6,
                          message: "El código de acceso debe tener 6 caracteres.",
                        },
                        pattern: {
                          value: patterns.espacioBlanco,
                          message: "El código de acceso no debe tener espacios en blanco.",
                        },
                        pattern: {
                          value: patterns.aceptaNumeros,
                          message: messages.aceptaNumeros,
                        },
                      })}
                    />
                    {errors.codigoAcceso && (
                      <small className="text-red-600">
                        {errors.codigoAcceso.message}
                      </small>
                    )}
                  </div>
                </div>
                <div className="d-grid gap-2 mt-2">
                  <div className="flex flex-col sm:flex-row gap-2">
                    <button
                      type="button"
                      onClick={regresarIngresarCodigo}
                      disabled={deshabilitar}
                      className={
                        "flex justify-center items-center py-1.5 px-4 border w-full rounded-lg bg-hiperorange_claro hover:bg-hiperorange_claro_hover transition-colors duration-300 font-medium"
                      }
                    >
                      Atrás
                    </button>
                    {deshabilitar ? (
                      <>
                        <button
                          type="submit"
                          disabled={deshabilitar}
                          style={{ cursor: "not-allowed" }}
                          className={
                            "flex justify-center items-center py-1.5 px-4 border w-full rounded-lg text-white bg-hiperorange hover:bg-hiperorange_hover transition-colors duration-300 font-medium"
                          }
                        >
                          Verificar
                        </button>
                      </>
                    ) : (
                      <>
                        <ButtonText
                          type="submit"
                          label="Verificar"
                          principal={true}
                          className="text-white bg-hiperorange hover:bg-hiperorange_hover"
                          onClick={() => {}}
                        />
                      </>
                    )}
                  </div>
                  {!mostrarComponente ? (
                    <div className="text-sm">
                      Para solicitar un nuevo código espera {segundosRestantes}{" "}
                      segundos
                    </div>
                  ) : (
                    <button
                      className="font-bold underline"
                      type="button"
                      onClick={() => reenviarCodigo()}
                    >
                      Solicitar nuevo código
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  loading: state.Auth.loading,
  datoLogin: state.Auth.datoLogin,
  sendAccesoAnonimo: state.Auth.sendAccesoAnonimo,
  authenticated: state.Auth.authenticated,
  profile: state.Profile.profile,
  access_token: state.Auth.access_token,
  peticion: state.Peticion.peticion,
  array_prefijo: state.Auth.array_prefijo,
  sendAcceso: state.Auth.sendAcceso,
  phone: state.Auth.phone,
  isAuthenticated: state.Auth.isAuthenticated,
  socialUserData: state.Auth.socialUserData,
  sucursales: state.BranchOffice.branch_offices,
  direccion: state.Location.direccion,
  ciudades: state.BranchOffice.ciudades,
});

export default connect(mapStateToProps, {
  get_prefijos,
  codigo_acceso,
  get_token,
  activate,
  info_session,
  set_token_externo,
  activate_sin_autologin,
  reset_activate_sin_autologin,
  clear_pedidos,
  get_anonimo_token,
  actualizar_tokenfb_usuario,
})(Registro_activate);
