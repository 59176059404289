import React, { useState } from "react";
import { connect } from "react-redux";
import "../../assets/styles/hiper.scss";
import { test_loading_token } from "../../redux/actions/auth";
import { ColorRing } from "react-loader-spinner";

const TokenMomentaneo = ({ test_loading_token, loading }) => {
  const [formData, setFormData] = useState({
    token: "",
  });
  const { token } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    test_loading_token(token);
  };
  return (
    <div>
      <div className="flex justify-center">
        <div className="w-96">
          <label htmlFor="token">token:</label>
          <textarea
            className="bg-white border w-100 px-4 py-2 rounded"
            type="text"
            name="token"
            value={token}
            placeholder="token"
            onChange={(e) => onChange(e)}
          />
          {loading ? (
            <button className=" py-1 px-4 border bg-hiperorange border-white hover:border-transparent rounded-lg w-full mt-2 flex items-center justify-between">
              <ColorRing
                colors={["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"]}
                width={20}
                height={20}
                ariaLabel="circles-loading"
              />
            </button>
          ) : (
            <button
              className=" py-1 px-4 border bg-hiperorange border-white hover:border-transparent rounded-lg w-full mt-2 flex items-center justify-between"
              onClick={onSubmit}
            >
              Guardar
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  loading: state.Auth.loading,
});

export default connect(mapStateToProps, {
  test_loading_token,
})(TokenMomentaneo);
