export const ITEM_CONDITION = {
    NEW: "http://schema.org/NewCondition",
    USED: "http://schema.org/UsedCondition",
    REFURBISHED: "http://schema.org/RefurbishedCondition",
    DAMAGED: "http://schema.org/DamagedCondition",
  };
  
  export const AVAILABILITY = {
    IN_STOCK: "http://schema.org/InStock",
    OUT_OF_STOCK: "http://schema.org/OutOfStock",
    PRE_ORDER: "http://schema.org/PreOrder",
    PRE_SALE: "http://schema.org/PreSale",
    DISCONTINUED: "http://schema.org/Discontinued",
    LIMITED_AVAILABILITY: "http://schema.org/LimitedAvailability",
    ONLINE_ONLY: "http://schema.org/OnlineOnly",
    SOLD_OUT: "http://schema.org/SoldOut",
    BACK_ORDER: "http://schema.org/BackOrder",
  };

export const buildSellerJSONLd = ({ name }, context) => {
    return {
      "@context": context,
      "@type": "Organization",
      name,
    };
  };
  
  export const buildOfferJSONLd = (
    { priceCurrency, price, itemCondition, availability, seller },
    context
  ) => {
    return {
      "@context": context,
      "@type": "Offer",
      priceCurrency,
      price,
      itemCondition,
      availability,
      seller: buildSellerJSONLd(seller),
    };
  };

export const buildProductJSONLd = (
    { name, image, url, sku, offers },
    context
  ) => {
    return {
      "@context": context,
      "@type": "Product",
        name,
        image,
        url,
        sku,
        offers: buildOfferJSONLd(offers),
    };
  };