import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faMinus,
  faPlus,
  faTrashCan,
} from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const ItemCarrito = ({
  producto,
  idProducto,
  colour,
  descripcion,
  cantidad,
  precioVenta,
  moneda,
  importe,
  imagen,
  unidadMedida,
  ps_updateDato,
  IdMarket,
  ps_deleteDato,
  favorito,
  changeFavorito,
  ps_startModal,
  ps_endModal,
}) => {
  const _es_pieza = () => {
    if (producto) {
      return producto.EsFraccionado && !producto.PesoDefinido ? true : false;
    } else {
      return false;
    }
  }
  const navigate = useNavigate();

  const iniciar_modal_pieza = () => {
    if (_es_pieza()) {
      ps_startModal(producto, IdMarket);
    }
  }

  const decrement_carrito = () => {
    if (_es_peso()) {
      navigate("/carrito/" + producto.IdProducto + "/" + IdMarket);
    } else {
      normal("decremento");
    }
  }

  const incremento_carrito = () => {
    if (_es_peso()) {
      navigate("/carrito/" + producto.IdProducto + "/" + IdMarket);
    } else {
      normal("incremento");
    }
  }

  const _es_peso = () => {
    if (producto) {
      return producto.EsFraccionado && producto.PesoDefinido ? true : false;
    } else {
      return false;
    }
  }

  

  const normal = (operacion) => {
    if (operacion === "incremento") {
      ps_updateDato(producto.IdProducto, "incremento", IdMarket, null);
    } else {
      ps_updateDato(producto.IdProducto, "decremento", IdMarket, null);
    }
  }

  const toggle_favorito = (favorito) => {
    changeFavorito(favorito, cantidad, idProducto, IdMarket);
  }

  const preguntar = (titulo, operacion) => {
    Swal.fire({
      title: titulo,
      showCancelButton: true,
      confirmButtonText: "Confirmar",
    }).then((result) => {
      if (result.isConfirmed) {
        operacion();
      } else if (result.isDenied) {
      }
    });
  }

  const delete_item_carrito = () => {
    ps_deleteDato(idProducto, IdMarket);
  }

  const delete_item = (idProducto) => {
    preguntar(
      "¿Estás seguro que quieres quitar este producto de tu carrito?",
      delete_item_carrito
    );
  };

  return (
    <div
      className={classNames("flex", "px-2", "py-1", "mt-2", {
        "bg-[#e5e7eb]": colour,
      })}
      style={{ height: "100px" }}
    >
      <div>
        <img
          src={imagen}
          alt={descripcion}
          width={100}
          style={{ objectFit: "cover", height: "100%" }}
          className="rounded-xl"
        />
      </div>
      <div className="flex justify-between w-full overflow-hidden">
        <div className="px-2 grid grid-rows-2 content-between">
          <div>
            <div className="text-xs font-light text-gray-600">
              {descripcion}
            </div>
            <div>
              {producto.ConOferta ? (
                <div className="flex justify-between w-100 py-1">
                  <del className="text-zinc-400 text-sm">
                    {producto.Moneda} {producto.PrecioOriginal}
                  </del>
                  <span
                    className="text_tercero font-bold text-sm "
                    style={{ fontSize: "1rem" }}
                  >
                    {" "}
                    {producto.Moneda} {producto.PrecioOferta}{" "}
                  </span>
                </div>
              ) : (
                <div className="flex justify-end w-100 py-1">
                  <span
                    className="text_tercero font-bold text-sm"
                    style={{ fontSize: "1rem" }}
                  >
                    {" "}
                    {producto.Moneda} {producto.PrecioVenta}{" "}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between  max-w-fit ">
            <div className="bg-hiperorange flex items-center px-2 py-0.5 rounded-2xl">
              <span className="text-white text-xs mr-1">Unidades</span>
              <div className="flex items-center justify-between rounded overflow-hidden inline-flex">
                <button
                  className="flex items-center justify-center w-4 h-4 border rounded-full"
                  onClick={() => {
                    decrement_carrito();
                  }}
                >
                  <FontAwesomeIcon
                    icon={faMinus}
                    className="text-white text-xs"
                  />
                </button>

                <span
                  className={classNames(
                    "text-white",
                    "text-center",
                    "w-4",
                    "text-xs",
                    {
                      "cursor-pointer mx-2": _es_pieza(),
                    }
                  )}
                  onClick={() => iniciar_modal_pieza()}
                >
                  {cantidad.toFixed(2)}
                </span>
                <button
                  className="flex items-center justify-center w-4 h-4 border rounded-full"
                  onClick={() => {
                    incremento_carrito();
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    className="text-white text-xs"
                  />
                </button>
              </div>
            </div>
            <button className="ml-2" onClick={() => delete_item(idProducto)}>
              <FontAwesomeIcon icon={faTrashCan} className="text-hiperblue" />
            </button>

            <button
              className="ml-2"
              onClick={() => {
                toggle_favorito(favorito);
              }}
            >
              <FontAwesomeIcon
                icon={faHeart}
                className={`${
                  favorito ? "text-hiperorange" : "text-hipergrisdark"
                }`}
              />

              {favorito.length}
            </button>
          </div>
        </div>

        <div className="flex items-center">
          <span className="text-lime-600 font-bold">
            {moneda}. &nbsp; {importe}{" "}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ItemCarrito;
