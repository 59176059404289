import React, { useEffect, useState } from "react";

import { faPeopleCarryBox } from "@fortawesome/pro-regular-svg-icons";
import {
  faAnglesRight,
  faDownload,
  faMoped,
  faRotate,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { setPedidosUsuarioFireb } from "../../src/redux/actions/pedido";
import { database } from "../Firebase";
import { onValue, ref, off } from "@firebase/database";
import "../assets/styles/inicio.scss";

const EstadoPedidoItem = ({ pedido, profile, actualizar_item }) => {
  const [showItem, setShowItem] = useState(true);
  const [pedido_firebase, setPedido_firebase] = useState(null);
  useEffect(() => {
    if (pedido_firebase) {
      let estado_pedido_id = pedido_firebase.EstadoPedidoCliente.Id;
      if (estado_pedido_id === 7 || estado_pedido_id === 6) {
        setTimeout(() => {
          actualizar_item();
          setShowItem(false);
        }, "2000");
      } else {
        setShowItem(true);
      }
    }
  }, [pedido_firebase]);

  useEffect(() => {
    let unsubscribe;
    async function fetchData() {
      try {
        let tipo_user = localStorage.getItem("user");
        if (
          profile !== null &&
          Object.keys(pedido).length !== 0 &&
          tipo_user !== '"anonimo"'
        ) {
          // const db = getDatabase();
          const databaseRef = ref(database, "pedidos/" + profile.IdUsuario);

          const listener = (snapshot) => {
            const data = snapshot.val();
            search_pedido(data, pedido.NroPedido);
          };

          onValue(databaseRef, listener);
          unsubscribe = () => off(databaseRef, listener);
        }
      } catch (e) {
        console.error(e);
      }
    }

    fetchData();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [profile, pedido]);

  const search_pedido = (pedidos, pedido_id) => {
    if (pedidos) {
      const pedidos_aux = Object.values(pedidos);
      pedidos_aux.forEach((el) => {
        if (el.NroPedido === pedido_id) {
          setPedido_firebase(el);
          return;
        }
      });
    }
  };

  const pintar = (pedido) => {
    if (pedido === null) {
      return null;
    }

    const code = pedido.EstadoPedidoCliente.Id;
    if (code === 1) {
      return "solicitado";
    } else {
      if (code === 3) {
        return "enproceso";
      } else {
        if (code === 4 || code === 5) {
          return "encamino";
        } else {
          if (code === 6) {
            return "entregado";
          }
        }
      }
    }
  };

  return showItem ? (
    <div>
      <div
        className=" bg-white border  "
        style={{
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
        }}
      >
        <div className="body_panel_state_pedido">
          <>
            <div className="estilo_container_icono_state_pedido2">
              <div
                className={`circulo_icono_state_pedidos flex justify-center items-center ${
                  pintar(pedido_firebase) === "solicitado"
                    ? "bg-hiperorange"
                    : "color_plomo"
                }`}
              >
                <FontAwesomeIcon
                  icon={faDownload}
                  style={{
                    height: "20px",
                    color: "#fff",
                    fontSize: "13px",
                  }}
                />
              </div>
              <span
                style={{ color: "#acacac" }}
                className="estilo_size_font_icon"
              >
                Solicitado
              </span>
            </div>
            <div
              className="estilo_icon_rigth2"
              style={{
                position: "relative",
                marginRight: "3px",
                marginLeft: "3px",
              }}
            >
              <div style={{ position: "absolute", top: "26%" }}>
                <FontAwesomeIcon
                  icon={faAnglesRight}
                  style={{
                    height: "20px",
                    color: "#acacac",
                    fontSize: "13px",
                  }}
                  className="estilo_size_font_icon"
                />
              </div>
            </div>
            <div
              className="estilo_container_icono_state_pedido2"
              style={{ width: "64px", height: "auto" }}
            >
              <div
                className={`circulo_icono_state_pedidos flex justify-center items-center ${
                  pintar(pedido_firebase) === "enproceso"
                    ? "bg-hiperorange"
                    : "color_plomo"
                } `}
              >
                <FontAwesomeIcon
                  icon={faRotate}
                  style={{
                    height: "20px",
                    color: "#fff",
                    fontSize: "13px",
                  }}
                />
              </div>
              <span
                style={{ color: "#acacac", width: "100%", textAlign: "center" }}
                className="estilo_size_font_icon"
              >
                En&nbsp;proceso
              </span>
            </div>
            <div
              className="estilo_icon_rigth2"
              style={{
                position: "relative",
                marginRight: "3px",
                marginLeft: "3px",
              }}
            >
              <div style={{ position: "absolute", top: "26%" }}>
                <FontAwesomeIcon
                  icon={faAnglesRight}
                  style={{
                    height: "20px",
                    color: "#acacac",
                    fontSize: "13px",
                  }}
                  className="estilo_size_font_icon"
                />
              </div>
            </div>

            <div className="estilo_container_icono_state_pedido2 ">
              <div
                className={`circulo_icono_state_pedidos flex justify-center items-center ${
                  pintar(pedido_firebase) === "encamino"
                    ? "bg-hiperorange"
                    : "color_plomo"
                } `}
              >
                <FontAwesomeIcon
                  icon={faMoped}
                  style={{
                    height: "20px",
                    color: "#fff",
                    fontSize: "13px",
                  }}
                />
              </div>
              <span
                style={{ color: "#acacac" }}
                className="estilo_size_font_icon"
              >
                En camino
              </span>
            </div>
            <div
              className="estilo_icon_rigth2"
              style={{
                position: "relative",
                marginRight: "3px",
                marginLeft: "3px",
              }}
            >
              <div style={{ position: "absolute", top: "26%" }}>
                <FontAwesomeIcon
                  icon={faAnglesRight}
                  style={{
                    height: "20px",
                    color: "#acacac",
                    fontSize: "13px",
                  }}
                  className="estilo_size_font_icon"
                />
              </div>
            </div>
            <div className="estilo_container_icono_state_pedido2">
              <div
                className={`circulo_icono_state_pedidos flex justify-center items-center ${
                  pintar(pedido_firebase) === "entregado"
                    ? "bg-hiperorange text-white"
                    : "color_plomo"
                } `}
              >
                <FontAwesomeIcon
                  icon={faPeopleCarryBox}
                  style={{
                    height: "20px",
                    color: "#fff",
                    fontSize: "13px",
                  }}
                />
              </div>
              <span
                style={{ color: "#acacac" }}
                className="estilo_size_font_icon"
              >
                Entregado
              </span>
            </div>
          </>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

const mapStateToProps = (state) => ({
  pedidos_firebase: state.Pedido.pedidos_firebase,
});

export default connect(mapStateToProps, {
  setPedidosUsuarioFireb,
})(EstadoPedidoItem);
