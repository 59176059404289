import { useEffect, useState } from "react";

function useContador(segundosIniciales) {
  const [mostrarComponente, setMostrarComponente] = useState(false);
  const [segundosRestantes, setSegundosRestantes] = useState(segundosIniciales);

  useEffect(() => {
    const intervalo = setInterval(() => {
      if (segundosRestantes > 0) {
        setSegundosRestantes(segundosRestantes - 1);
      } else {
        setMostrarComponente(true);
        clearInterval(intervalo);
      }
    }, 1000);

    return () => clearInterval(intervalo);
  }, [segundosRestantes]);

  const reiniciarContador = () => {
    setSegundosRestantes(segundosIniciales);
    setMostrarComponente(false);
  };

  return { mostrarComponente, segundosRestantes, reiniciarContador };
}

export default useContador;
