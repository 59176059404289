import { responseHandler } from "../../../http/application/response/response-handler";
import { SucursalContactosRepository } from "../../domain/sucursal-contactos-repository";
import { SucursalContactosGetByIdSucursalParameters } from "../../domain/dto/sucursal-contactos-get-by-id-sucursal-parameters";
import { SucursalContacto } from "../../domain/interface/sucursal-contacto";

export function getSucursalContactosBySucursalId(
  sucursalContactosRepository: SucursalContactosRepository
) {
  return async (
    configParametesGetByKeyParameters: SucursalContactosGetByIdSucursalParameters
  ): Promise<SucursalContacto> => {
    const response = await sucursalContactosRepository.getBySucursalId(
      configParametesGetByKeyParameters
    );
    return responseHandler<SucursalContacto, null>(response);
  };
}
