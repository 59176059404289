import Swal from 'sweetalert2'

export default{
  
     async   delete_register_confirmation(title,text){
        let resp=false;
        await Swal.fire({
            title: title? title: '¿Estás seguro de eliminar este registro?',
            text: text? text : "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0349AB',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar!',
            cancelButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
              resp=true;
            }
            if (result.isDismissed) {
              resp=false;
            }
      
        });
        return resp;
    },

    show_message_alert(resp,mensaje){
        if(resp===true){
            Swal.fire({
              confirmButtonColor: '#0349AB',
              title: "Éxito!",
              text: mensaje,
              icon: "success",
              type: "success"
            })
            }else{
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: mensaje,
                confirmButtonColor: '#000000',
            })
        
        }
    }

}
