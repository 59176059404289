import {
  GET_SHOPPING_LIST_SUCCESS,
  GET_SHOPPING_LIST_FAIL,
  GET_DETALLE_SHOPPING_LIST_SUCCESS,
  GET_DETALLE_SHOPPING_LIST_NULL,
  GET_SHOPPING_LIST_SHARED_FAIL,
  SET_LOADING,
  REMOVE_LOADING,
  UPDATE_LIST_CARRITO,
  ACTUALIZAR_LIST_CART,
  UPDATE_DETALLE_SHOPPING_LIST_SUCCESS,
  SET_ID_LIST_COMPRA,
  GET_SHOPPING_LIST_SHARED_SUCCESS,
  INC_TO_CART_SHOPPING_LIST,
  DEC_TO_CART_SHOPPING_LIST,
  CLEAR_CARRITO_LISTA_COMPRA,
  CHECKED_TO_CART_SHOPPING_LIST,
  INC_PESO_CART_SHOPPING_LIST,
  ACT_DATA_CART_SHOPPING,
  UPDATE_ITEM_CARRITO_lISTA_COMPRA,
  CARRITO_LISTA_COMPRA_FAIL,
  ACT_PIEZA_CART_SHOPPING,
  DELETE_PRODUCT_SHOPPING_LIST,
  CLEAR_STATE_SHOPPING_LIST,
} from "../actions/types";

const initialState = {
  lista_compartida: null,
  shopping_list: [],
  shopping_list_detalle: null,
  carrito_lista_compra:
    JSON.parse(localStorage.getItem("carrito_lista_compra")) ?? [],
  // productos_delete_lista_compra: JSON.parse(localStorage.getItem('productos_delete_lista_compra')) ?? [],
  productos_delete_lista_compra: [],
  monto_total: 0,
  peticion_shopping_list: false,
  peticion: false,
  loading: false,
  products_shoping: [],
  compra_id: 0,

  totalQuantities: 0,

  estado_cambios: false,
};

export default function Shopping_list(state = initialState, action) {
  let findPro;
  let index;

  let product;
  const { type, payload } = action;

  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_STATE_SHOPPING_LIST:
      return {
        ...state,
        lista_compartida: null,
        shopping_list: [],
        shopping_list_detalle: null,
        carrito_lista_compra: [],
        productos_delete_lista_compra: [],
        monto_total: 0,
        peticion: false,
        loading: false,
        products_shoping: [],
        compra_id: 0,
        totalQuantities: 0,
        estado_cambios: false,
        peticion_shopping_list: false,
      };

    case GET_SHOPPING_LIST_SHARED_SUCCESS:
      let data_producto_compartido = [];
      if (payload.Dato !== null) {
        data_producto_compartido = cargarProductoDetalle(
          payload.Dato.Productos
        );
        updateStorage(data_producto_compartido);
        updateListaStorage(payload.Dato);
        deleteProductoListaCompraInit();
      }

      return {
        ...state,
        shopping_list_detalle: payload.Dato,
        carrito_lista_compra: data_producto_compartido,
        lista_compartida: payload.Dato,
        peticion: true,
        // lista_compartida: payload.Dato
        // }
        // let data_producto = cargarProductoDetalle(payload.Dato.Productos);
        // updateStorage(data_producto);
        // return {
        //     ...state,
        //     shopping_list_detalle: payload.Dato,
        //     carrito_lista_compra:data_producto,
        //     peticion: true
      };

    case SET_ID_LIST_COMPRA:
      updateCompraId(payload);
      return {
        ...state,
        compra_id: payload,
      };
    case REMOVE_LOADING:
      return {
        ...state,
        loading: false,
      };
    case GET_SHOPPING_LIST_SUCCESS:
      let bool_peticion_shopping_list = payload.Dato.length > 0 ? false : true;
      return {
        ...state,
        shopping_list: payload.Dato,
        peticion_shopping_list: bool_peticion_shopping_list,
      };
    case GET_DETALLE_SHOPPING_LIST_SUCCESS:
      let data_producto = [];
      if (payload.Dato !== null) {
        data_producto = cargarProductoDetalle(payload.Dato.Productos);

        updateStorage(data_producto);
        updateListaStorage(payload.Dato);
        deleteProductoListaCompraInit();
        return {
          ...state,
          shopping_list_detalle: payload.Dato !== null ? payload.Dato : [],
          carrito_lista_compra: data_producto,
          peticion: true,
        };
      } else {
        return {
          ...state,
          peticion: false,
        };
      }
    case UPDATE_DETALLE_SHOPPING_LIST_SUCCESS:
      return {
        ...state,
        products_shoping: payload,
        peticion: true,
      };
    case GET_DETALLE_SHOPPING_LIST_NULL:
      return {
        ...state,
        shopping_list_detalle: null,
      };
    case GET_SHOPPING_LIST_SHARED_FAIL:
      // localStorage.setItem("carrito_lista_compra",carrito_lista_compra);
      updateStorage(state.carrito_lista_compra);
      return {
        ...state,
      };
    case GET_SHOPPING_LIST_FAIL:
      return {
        ...state,
      };
    case UPDATE_LIST_CARRITO:
      return {
        ...state,
        list_carrito: payload,
      };
    case CLEAR_CARRITO_LISTA_COMPRA:
      return {
        ...state,
        carrito_lista_compra: [],
        productos_delete_lista_compra: [],
      };
    case ACTUALIZAR_LIST_CART:
      deleteStorageListCarrito();
      updateStorage(payload);
      return {
        ...state,
        carrito_lista_compra: payload,
      };
    case INC_TO_CART_SHOPPING_LIST:
      findPro = state.carrito_lista_compra.find(
        (pd) => pd.IdProducto === payload.IdProducto
      );
      index = state.carrito_lista_compra.findIndex(
        (pd) => pd.IdProducto === payload.IdProducto
      );

      findPro.Cantidad += 1;
      state.carrito_lista_compra[index] = findPro;
      let product_price1 = findPro.ConOferta
        ? findPro.PrecioOferta
        : findPro.PrecioVenta;
      updateStorageShoppingList(state.carrito_lista_compra);
      return {
        ...state,
        totalPrice: state.totalPrice + product_price1,
        totalQuantities: state.totalQuantities + 1,
        estado_cambios: true,
      };

    case DEC_TO_CART_SHOPPING_LIST:
      findPro = state.carrito_lista_compra.find(
        (pd) => pd.IdProducto === payload.IdProducto
      );
      index = state.carrito_lista_compra.findIndex(
        (pd) => pd.IdProducto === payload.IdProducto
      );
      if (findPro.Cantidad >= 1) {
        findPro.Cantidad -= 1;
        state.carrito_lista_compra[index] = findPro;
        let product_price2 = findPro.ConOferta
          ? findPro.PrecioOferta
          : findPro.PrecioVenta;
        updateStorageShoppingList(state.carrito_lista_compra);
        return {
          ...state,
          totalPrice: state.totalPrice - product_price2,
          totalQuantities: state.totalQuantities - 1,
          estado_cambios: true,
        };
      } else {
        return state;
      }
    case DELETE_PRODUCT_SHOPPING_LIST:
      let idproducto = payload.idproducto;
      let idsucursal = payload.idsucursal;
      findPro = state.carrito_lista_compra.find(
        (pd) => pd.IdProducto === idproducto
      );
      //
      // state.productos_delete_lista_compra.push({IdProducto:findPro.IdProducto, IdSucursal:idsucursal});
      state.productos_delete_lista_compra.push({
        ...findPro,
        IdSucursal: idsucursal,
      });

      state.carrito_lista_compra = state.carrito_lista_compra.filter(function (
        item
      ) {
        return item.IdProducto !== findPro.IdProducto;
      });

      updateStorageShoppingList(state.carrito_lista_compra);
      updateProductoListaCompra(state.productos_delete_lista_compra);
      return {
        ...state,
        estado_cambios: true,
      };

    case CHECKED_TO_CART_SHOPPING_LIST:
      product = payload.producto;
      let estado_checked = payload.estado_checked;

      findPro = state.carrito_lista_compra.find(
        (pd) => pd.IdProducto === product.IdProducto
      );
      index = state.carrito_lista_compra.findIndex(
        (pd) => pd.IdProducto === product.IdProducto
      );
      findPro.Checked = estado_checked;
      state.carrito_lista_compra[index] = findPro;
      let product_price3 = findPro.ConOferta
        ? findPro.PrecioOferta
        : findPro.PrecioVenta;
      updateStorageShoppingList(state.carrito_lista_compra);
      return {
        ...state,
        totalPrice: state.totalPrice + product_price3,
        totalQuantities: state.totalQuantities + 1,
        estado_cambios: true,
      };
    case INC_PESO_CART_SHOPPING_LIST:
      product = payload.producto;
      let pesoKg = payload.pesoKg;

      findPro = state.carrito_lista_compra.find(
        (pd) => pd.IdProducto === product.IdProducto
      );
      index = state.carrito_lista_compra.findIndex(
        (pd) => pd.IdProducto === product.IdProducto
      );
      findPro.Cantidad = pesoKg;
      state.carrito_lista_compra[index] = findPro;
      // let product_price3 = findPro.ConOferta ? findPro.PrecioOferta : findPro.PrecioVenta;
      updateStorageShoppingList(state.carrito_lista_compra);
      return {
        ...state,
        estado_cambios: true,
      };
    case ACT_DATA_CART_SHOPPING:
      const { producto: prod, valor } = payload;
      findPro = find_producto(prod);
      index = index_producto(prod);
      findPro.Cantidad = valor;
      state.carrito_lista_compra[index] = findPro;
      updateStorage(state.carrito_lista_compra);
      return {
        ...state,
        // carrito_lista_compra: state.carrito_lista_compra
      };
    case ACT_PIEZA_CART_SHOPPING:
      let producto_id = payload.producto_id;
      let peso = payload.peso;
      let operacion = payload.operacion;
      let cant_peso = payload.cant_peso;
      let pesos = [];

      findPro = state.carrito_lista_compra.find(
        (pd) => pd.IdProducto === producto_id
      );
      index = state.carrito_lista_compra.findIndex(
        (pd) => pd.IdProducto === producto_id
      );

      let cantidad = 0;
      if (operacion === "definido") {
        pesos = findPro.Pesos.map((ps) => {
          if (ps.IdPeso === peso.IdPeso) {
            return {
              ...ps,
              Piezas: cant_peso,
            };
          } else {
            return ps;
          }
        });
      } else {
        pesos = findPro.Pesos.map((ps) => {
          if (ps.IdPeso === peso.IdPeso) {
            return {
              ...ps,
              Piezas:
                operacion === "incremento" ? ps.Piezas + 1 : ps.Piezas - 1, //(ps.Piezas + 1)
            };
          } else {
            return ps;
          }
        });
      }

      pesos.map((peso) => {
        cantidad += peso.Piezas;
      });

      findPro.Cantidad = cantidad;
      findPro.Pesos = pesos;

      state.carrito_lista_compra[index] = findPro;
      updateStorage(state.carrito_lista_compra);
      return {
        ...state,
        // carrito_lista_compra: state.carrito_lista_compra
      };

    default:
      return state;
  }
  function find_producto(producto) {
    return state.carrito_lista_compra.find(
      (pd) => pd.IdProducto === producto.IdProducto
    );
  }

  function index_producto(producto) {
    return state.carrito_lista_compra.findIndex(
      (pd) => pd.IdProducto === producto.IdProducto
    );
  }
}
function cargarProductoDetalle(productos) {
  let array_product = [];
  if (productos.length > 0) {
    productos.forEach((element) => {
      let is_check = false;
      if (element.Vigente && element.Cantidad > 0) {
        is_check = true;
      }
      array_product.push({ ...element, Checked: is_check });
    });
    return array_product;
  }
  return array_product;
}

function deleteStorageListCarrito() {
  localStorage.removeItem("carrito_lista_compra");
}
function deleteProductoListaCompraInit() {
  localStorage.removeItem("productos_delete_lista_compra");
}
function updateStorage(carrito_lista_compra) {
  localStorage.setItem(
    "carrito_lista_compra",
    JSON.stringify(carrito_lista_compra)
  );
}
function updateListaStorage(item_lista_compra) {
  localStorage.setItem("item_lista_compra", JSON.stringify(item_lista_compra));
}
function updateStorageShoppingList(carrito_lista_compra) {
  localStorage.setItem(
    "carrito_lista_compra",
    JSON.stringify(carrito_lista_compra)
  );
}
function updateProductoListaCompra(productos_delete_lista_compra) {
  localStorage.setItem(
    "productos_delete_lista_compra",
    JSON.stringify(productos_delete_lista_compra)
  );
}

function updateCompraId(id) {
  localStorage.setItem("compra_id", JSON.stringify(id));
}
