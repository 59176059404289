import { differenceInSeconds } from "date-fns";
import { useEffect } from "react";
import ButtonText from "../../../../widgets/button_text";
import Spinner from "../../../shared/ui/components/spinner";
import { createApiUserProfilesRepository } from "../../infrastructure/api-user-profiles-repository";
import { useGetUserProfileById } from "../hooks/useGetUserProfileById";
import { UserProfileAuthorizationToken } from "./user-profile-authorization-token";

const userProfilesRepository = createApiUserProfilesRepository();

interface UserProfileAuthorizationTokenQrModalContentProps {
  onCloseModal?: () => void;
}

export function UserProfileAuthorizationTokenQrModalContent({
  onCloseModal,
}: UserProfileAuthorizationTokenQrModalContentProps) {
  const { getUserProfileById, userProfile, isLoading } = useGetUserProfileById(
    userProfilesRepository
  );

  useEffect(() => {
    getUserProfileById();
  }, []);

  const handleCloseModal = () => {
    if (onCloseModal) onCloseModal();
  };

  return (
    <div className="w-full flex flex-col h-full rounded-lg md:rounded-lg">
      <div className="w-full rounded-lg md:rounded-lg h-full overflow-hidden">
        <div className="flex flex-col h-full">
          <div className="w-100 relative text-black flex items-center justify-center h-[52px]  content_title">
            <h2 className="p-0 m-0 text-lg md:text-xl">
              Token de Autorización
            </h2>
            {/* <div className="absolute right-2 ">
              <ButtonClose
                className="text-white bg-transparent"
                onClick={handleCloseModal}
              />
            </div> */}
          </div>
          <div className="overflow-y-auto w-full h-full gap-8 p-4 text-black flex flex-col items-center">
            {/* <div className="text-center text-lg font-semibold leading-snug">
              Este es tu código de cliente Hipermaxi. ¡Preséntalo cada vez que
              hagas una compra y comienza a ganar Hiper Puntos!
            </div> */}

            <div className="w-full relative">
              {!isLoading && userProfile && (
                <div className="absolute right-0 -top-5">
                  <Spinner
                    segundos={
                      differenceInSeconds(
                        new Date(userProfile.Fecha_Vencimiento),
                        new Date()
                      ) + 1
                    }
                    onTimeEnd={() => {
                      getUserProfileById();
                    }}
                  />
                </div>
              )}

              <UserProfileAuthorizationToken
                profileAuthorizationToken={userProfile?.Hash || ""}
              />
            </div>

            <div className="p-2 flex justify-center sticky bottom-0">
              <div>
                <ButtonText
                  label="Cerrar"
                  onClick={handleCloseModal}
                  className="bg-hiperblue hover:bg-hiperblue_hover text-white"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
