import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCircleDot } from "@fortawesome/pro-regular-svg-icons";
import classNames from "classnames";
import "../assets/styles/radio.scss";
import { useThousandSeparator } from "../hooks/useThousandSeparator";

const ItemHorario = ({
  idHorario,
  Descripcion,
  CostoDelivery,
  Costo,
  sucursal,
  changeSelec,
  IdHorarioEspecial,
  tipo_entrega,
  is_check,
}) => {
  const handleOnChange = (nro, e) => {
    changeSelec(idHorario, IdHorarioEspecial, sucursal, CostoDelivery, Costo);
  };

  const { numberFormatter } = useThousandSeparator();

  const formatNumber = (value) => {
    return numberFormatter.format(value);
  };

  return (
    <button
      type="button"
      onClick={handleOnChange}
      className={classNames(
        "py-1 w-auto h-auto",
        { "bg-hiperorange border-1 border-hiperorange text-white": is_check },
        {
          "border-1 border-white transition ease-in-out delay-150 duration-300 hover:scale-[1.02]":
            !is_check,
        }
      )}
      style={{ borderRadius: "10px" }}
      key={idHorario}
    >
      <div
        className="d-flex justify-between gap-2 py-1 px-2 w-full "
      >
        <FontAwesomeIcon icon={is_check ? faCircleDot : faCircle} size="xl" />

        <div className="text-left text-sm md:text-base flex-grow">
          {Descripcion}
        </div>

        {tipo_entrega.Id === 2 && (
          <div className="text-sm md:text-base">
            {Costo > 0 ? `Bs. ${formatNumber(Costo)}` : "Gratis"}
          </div>
        )}
      </div>
    </button>
  );
};

export default ItemHorario;
