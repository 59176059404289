import { useState } from "react";
import { NotificationService } from "../../../notifications/domain/notification-service";
import { FormStatus } from "../../../shared/domain/enums/form-status";
import { useHandleErrors } from "../../../shared/ui/hooks/use-handle-errors";
import { HiperPuntosRepository } from "../../domain/hiper-puntos-repository";
import { CustomerBagHistoryGetParams } from "../../domain/dto/customer-bag-history-get-params";
import { CustomerBagHistory } from "../../domain/interfaces/customer-bag-history";
import { getCustomerBagHistory as _getCustomerBagHistory } from "../../application/get/get-customer-bag-history";
import { CustomerBagPromo } from "../../domain/interfaces/customer-bag-promo";

interface CustomerBagHistoryInfo {
  SaldoPuntos: number;
  IdBolsaPunto: string;
  DescripcionBolsa: string;
  ListaPromocion: CustomerBagPromo[];
}

export const useGetCustomerBagHistory = (
  hiperPuntosRepository: HiperPuntosRepository,
  notificationService: NotificationService
): {
  formStatus: FormStatus;
  getCustomerBagHistory: (params: CustomerBagHistoryGetParams) => void;
  resetFormStatus: () => void;
  customerBagHistory: CustomerBagHistory | undefined;
  customerBagHistoryInfo: CustomerBagHistoryInfo | undefined;
  resetCustomerBagHistory: () => void;
  isLoading: boolean;
} => {
  // const dispatch: Dispatch<any> = useDispatch();
  const [formStatus, setFormStatus] = useState(FormStatus.INITIAL);
  const [customerBagHistoryInfo, setCustomerBagHistoryInfo] = useState<
    CustomerBagHistoryInfo | undefined
  >(undefined);
  const [customerBagHistory, setCustomerBagHistory] = useState<
    CustomerBagHistory | undefined
  >(undefined);

  const { handleError } = useHandleErrors(notificationService);

  async function getCustomerBagHistory(params: CustomerBagHistoryGetParams) {
    setFormStatus(FormStatus.LOADING);

    try {
      const customerBagHistory: CustomerBagHistory =
        await _getCustomerBagHistory(hiperPuntosRepository)(params);
      // dispatch(setCustomerBagsHistoryAction(customerBagHistory));
      if (!customerBagHistoryInfo) {
        const info: CustomerBagHistoryInfo = {
          DescripcionBolsa: customerBagHistory.DescripcionBolsa,
          IdBolsaPunto: customerBagHistory.IdBolsaPunto,
          ListaPromocion: customerBagHistory.ListaPromocion,
          SaldoPuntos: customerBagHistory.SaldoPuntos,
        };
        setCustomerBagHistoryInfo(info);
      }
      setCustomerBagHistory(customerBagHistory);
      setFormStatus(FormStatus.SUCCESS);
      //   notifySuccess(notificationService)("Clientes obtenidos correctamente.");
    } catch (error: unknown) {
      const message = "";
      handleError({ error, message });
      setFormStatus(FormStatus.ERROR);
    }
  }

  const resetFormStatus = () => setFormStatus(FormStatus.INITIAL);

  const resetCustomerBagHistory = () => setCustomerBagHistory(undefined);

  return {
    formStatus,
    getCustomerBagHistory,
    resetFormStatus,
    customerBagHistory,
    customerBagHistoryInfo,
    resetCustomerBagHistory,
    isLoading: formStatus === FormStatus.LOADING,
  };
};
