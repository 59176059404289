import {
  GET_NOTIFICACION_SUCCESS,
  GET_NOTIFICACION_FAIL,
  SET_IS_FETCHING_NOTIFICACIONES,
} from "./types";
import axios from "axios";
import { end_peticion, start_peticion } from "./peticion";
import { setSwAlert, showRespuesta } from "./swalert";
import respuestas from "../../components/Alertas";

export const get_notificaciones = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
  dispatch({
    type: SET_IS_FETCHING_NOTIFICACIONES,
    payload: true,
  });
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/notificaciones?pagina=1&cantidad=10`,
      config
    );
    //
    if (res.status === 200) {
      dispatch({
        type: GET_NOTIFICACION_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_NOTIFICACION_FAIL,
      });
      dispatch(showRespuesta(res));
    }
    dispatch({
      type: SET_IS_FETCHING_NOTIFICACIONES,
      payload: false,
    });
  } catch (err) {
    dispatch({
      type: GET_NOTIFICACION_FAIL,
    });
    dispatch(setSwAlert(respuestas.peticionError, true, 500));
    dispatch({
      type: SET_IS_FETCHING_NOTIFICACIONES,
      payload: false,
    });
    //   dispatch(setAlert('Error al conectar con el servidor, intenta mas tarde.', 'red'));
  }
};
export const delete_notificacion = (notificacion_id) => async (dispatch) => {
  dispatch(start_peticion(""));

  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/notificaciones/${notificacion_id}/eliminar`,
      config
    );

    if (res.status === 200) {
      dispatch(get_notificaciones());
    } else {
      dispatch(showRespuesta(res));
    }
    dispatch(end_peticion());
  } catch (err) {
    dispatch({
      type: GET_NOTIFICACION_FAIL,
    });
    dispatch(end_peticion());
    dispatch(setSwAlert(respuestas.peticionError, true, 500));
  }
};
export const show_notification = (notificacion_id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/CuentasMarket/notificaciones/${notificacion_id}/leido`,
      null,
      config
    );

    if (res.status !== 200) {
      dispatch(showRespuesta(res));
    }
    /*  if (res.status === 200) {
            dispatch({
                type: GET_NOTIFICACION_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: GET_NOTIFICACION_FAIL
            });
        }
        */
  } catch (err) {
    dispatch({
      type: GET_NOTIFICACION_FAIL,
    });
    dispatch(setSwAlert(respuestas.peticionError, true, 500));
    //   dispatch(setAlert('Error al conectar con el servidor, intenta mas tarde.', 'red'));
  }
};
