import {
  GET_HOME_SUCCESS,
  GET_HOME_FAIL,
  SET_HOME_SUCCESS,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FILTRO_SUCCESS,
  GET_CATEGORY_FAIL,
  GET_BANNER_SUCCESS,
  GET_BANNER_FAIL,
  GET_SUBCATEGORY_SUCCESS,
  GET_HOME_CLEAR_SUCCESS,
  SET_ALL_PROD_CATEGORY,
  SET_CAMBIO_SUCURSAL,
  SET_IS_FETCHING_HOME,
} from "./types";
import axios from "axios";
import { setSwAlert, showRespuesta } from "./swalert";
import { set_cambio_sucursal } from "./branch_office";
import respuestas from "../../components/Alertas";

export const set_mensaje = (mensaje, bool, codigo) => async (dispatch) => {
  dispatch(setSwAlert(mensaje, bool, codigo));
};

export const get_categorias =
  (IdSucursal, IdMarket, SubcategoriaId) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/markets/clasificaciones?IdMarket=${IdMarket}&IdSucursal=${IdSucursal}`,
        config
      );
      //
      if (res.status === 200) {
        let category = findCategoria(SubcategoriaId, res.data);
        dispatch({
          type: GET_CATEGORY_SUCCESS,
          payload: { res: res.data, cat: category, subcateId: SubcategoriaId },
        });
      } else {
        dispatch({
          type: GET_CATEGORY_FAIL,
        });

        dispatch(showRespuesta(res));
      }
    } catch (err) {
      /* if (typeof (err.response) !== undefined && err !== null) {
            dispatch(setSwAlert(err.response.data.Message, true, 401));
        } else {
            dispatch(setSwAlert('Se ha denegado la autorización para esta solicitud, debes iniciar sesion', true, 401));
        }
        */

      dispatch({
        type: GET_CATEGORY_FAIL,
      });
      dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
  };

export const get_categorias_filtro =
  (IdSucursal, IdMarket, SubcategoriaId, filtro) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/markets/clasificaciones?IdMarket=${IdMarket}&IdSucursal=${IdSucursal}`,
        config
      );
      //
      if (res.status === 200) {
        let category = findCategoria(SubcategoriaId, res.data);
        dispatch({
          type: GET_CATEGORY_FILTRO_SUCCESS,
          payload: {
            res: res.data,
            cat: category,
            subcateId: SubcategoriaId,
            filtro: filtro,
          },
        });
      } else {
        dispatch({
          type: GET_CATEGORY_FAIL,
        });
        dispatch(showRespuesta(res));
      }
    } catch (err) {
      /*  if (typeof (err.response) !== undefined && err !== null) {
            dispatch(setSwAlert(err.response.data.Message, true, 401));
        } else {
            dispatch(setSwAlert('Se ha denegado la autorización para esta solicitud, debes iniciar sesion', true, 401));
        }
        */

      dispatch({
        type: GET_CATEGORY_FAIL,
      });
      dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
  };

export const get_subcategorias =
  (IdSucursal, IdMarket, categoriaId) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/markets/clasificaciones?IdMarket=${IdMarket}&IdSucursal=${IdSucursal}`,
        config
      );
      if (res.status === 200) {
        // let category = findSubcategoria(categoriaId,res.data);
        dispatch({
          type: GET_SUBCATEGORY_SUCCESS,
          payload: { res: res.data, categoriaId: categoriaId },
        });
      } else {
        dispatch({
          type: GET_CATEGORY_FAIL,
        });
        dispatch(showRespuesta(res));
      }
    } catch (err) {
      /*  if (typeof (err.response) !== undefined && err !== null) {
            dispatch(setSwAlert(err.response.data.Message, true, 401));
        } else {
            dispatch(setSwAlert('Se ha denegado la autorización para esta solicitud, debes iniciar sesion', true, 401));
        }
        */

      dispatch({
        type: GET_CATEGORY_FAIL,
      });
      dispatch(setSwAlert(respuestas.peticionError, true, 500));
    }
  };

export const get_banners = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
  try {
    let Pagina = 1;
    let Cantidad = 12;
    let IdSeccionPagina = 2;

    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/markets/banners?Pagina=${Pagina}&Cantidad=${Cantidad}&IdSeccionPagina=${IdSeccionPagina}`,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_BANNER_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_BANNER_FAIL,
      });
      dispatch(showRespuesta(res));
    }
  } catch (err) {
    dispatch({
      type: GET_BANNER_FAIL,
    });
    dispatch(setSwAlert(respuestas.peticionError, true, 500));
  }
};

export const get_homepage =
  (IdSucursal, IdMarket, IdTipoEntrega) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    try {
      dispatch({
        type: SET_IS_FETCHING_HOME,
        payload: true,
      });
      dispatch({
        type: GET_HOME_CLEAR_SUCCESS,
      });
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/markets/homepage?IdSucursal=${IdSucursal}&IdMarket=${IdMarket}&IdTipoEntrega=${IdTipoEntrega}`,
        config
      );
      if (res.status === 200) {
        dispatch(set_cambio_sucursal(false));
        dispatch({
          type: GET_HOME_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_HOME_FAIL,
        });

        dispatch(showRespuesta(res));
      }
    } catch (err) {
      dispatch({
        type: GET_HOME_FAIL,
      });
      dispatch(setSwAlert(respuestas.peticionError, true, 500));
      dispatch({
        type: SET_IS_FETCHING_HOME,
        payload: false,
      });
    }
  };
export const set_all_prod_category = (datos) => async (dispatch) => {
  try {
    dispatch({
      type: SET_ALL_PROD_CATEGORY,
      payload: datos,
    });
  } catch (err) {
    dispatch({
      type: GET_HOME_FAIL,
    });
  }
};

export const set_sucursal = (sucursal) => async (dispatch) => {
  dispatch({
    type: SET_HOME_SUCCESS,
    payload: sucursal,
  });
};

function findCategoria(SubcategoriaId, data) {
  let resp = "";
  if (typeof SubcategoriaId != "undefined") {
    data.Dato.forEach((rubro) => {
      rubro.Categorias.forEach((category) => {
        let cateAxu = category;
        category.SubCategorias.forEach((subcate) => {
          if (Number(subcate.IdSubcategoria) === Number(SubcategoriaId)) {
            resp = cateAxu;
            return resp;
          }
        });
      });
    });
  }
  return resp;
}

function findSubcategoria(SubcategoriaId, data) {
  let resp = "";
  if (typeof SubcategoriaId != "undefined") {
    data.Dato.forEach((rubro) => {
      rubro.Categorias.forEach((category) => {
        let cateAxu = category;
        category.SubCategorias.forEach((subcate) => {
          if (Number(subcate.IdSubcategoria) === Number(SubcategoriaId)) {
            resp = cateAxu;
            return resp;
          }
        });
      });
    });
  }
  return resp;
}
