import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCircleCheck } from "@fortawesome/pro-solid-svg-icons";

import "../../../assets/styles/globalColorPrincipal.css";
import "../../../assets/styles/stylePageGlobals.scss";
import "../../../assets/styles/responsive.scss";
import { get_tarjeta } from "../../../redux/actions/tarjeta";
import { faCcMastercard, faCcVisa } from "@fortawesome/free-brands-svg-icons";

const Tarjeta = ({
  get_tarjeta,
  tarjetas,
  change_selec,
  valor,
  startAddCard = () => {},
}) => {
  useEffect(() => {
    get_tarjeta();
  }, []);

  const selec_op = (id) => {
    change_selec(id);
  };
  const handleStartAddCard = async () => {
    startAddCard();
  };

  return (
    <>
      <div className="flex justify-center flex-wrap gap-2">
        {tarjetas &&
          tarjetas !== null &&
          tarjetas !== undefined &&
          tarjetas.map((ps_tarjeta, index) => (
            <div
              className={classNames(
                "w-[13rem]",
                "h-auto",
                ps_tarjeta.idTarjeta == valor ? "p-2" : "p-2",
                "rounded-sm",
                "cursor-pointer",
                {
                  "shadow-md": ps_tarjeta.idTarjeta == valor,
                }
              )}
              style={{
                backgroundColor:
                  ps_tarjeta.idTarjeta == valor ? ps_tarjeta.color : "#D6D6D6",
              }}
              key={ps_tarjeta.idTarjeta}
              onClick={(e) => selec_op(ps_tarjeta.idTarjeta)}
            >
              <div className="flex h-[1rem] justify-between items-center mt-1">
                <FontAwesomeIcon
                  icon={
                    ps_tarjeta.idTarjeta == valor ? faCircleCheck : faCircle
                  }
                  size="lg"
                  color={ps_tarjeta.idTarjeta == valor ? "white" : "#A6A6A6"}
                />
                <div className="flex items-center">
                  {ps_tarjeta.code === "001" && (
                    <FontAwesomeIcon
                      className="text-2xl ml-2"
                      icon={faCcVisa}
                      color={ps_tarjeta.idTarjeta == valor ? "white" : "black"}
                    />
                  )}
                  {ps_tarjeta.code === "002" && (
                    <FontAwesomeIcon
                      className="text-xl ml-2"
                      icon={faCcMastercard}
                      color={ps_tarjeta.idTarjeta == valor ? "white" : "black"}
                    />
                  )}
                </div>
              </div>
              <div className="flex flex-col mt-4 justify-end text-right text-white">
                <span
                  className={`mb-2 ${
                    ps_tarjeta.idTarjeta == valor ? "text-white" : "text-black"
                  }`}
                >
                  XXXX XXXX XXXX {ps_tarjeta.cardSufix}
                </span>
                <span
                  className={`mb-1 ${
                    ps_tarjeta.idTarjeta == valor ? "text-white" : "text-black"
                  }`}
                >
                  {" "}
                  {ps_tarjeta.propietario.toUpperCase()}
                </span>
              </div>
            </div>
          ))}
      </div>
      <div className="flex justify-center my-2">
        <button
          type="button"
          onClick={() => handleStartAddCard()}
          className="bg-hiperorange hover:bg-hiperorange_hover rounded-lg text-center py-2  text-white px-2 font-bold text-sm md:text-base"
        >
          Registrar tarjeta de crédito o débito
        </button>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  tarjetas: state.Tarjeta.tarjetas,
});

export default connect(mapStateToProps, {
  get_tarjeta,
})(Tarjeta);
