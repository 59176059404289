import { buildOrganizationJSONLd } from "../../libs/richSnippetsUtils/buildOrganizationJSONLd";

export function useOrganizationJSONLd({
  url,
  logo,
  name,
  mediaLinks,
  contactPoint,
}) {
  const organizationJSONLd = buildOrganizationJSONLd(
    {
      url,
      logo,
      name,
      mediaLinks,
      contactPoint,
    },
    "https://schema.org"
  );
  const organizationJSONLdScript = (
    <script type="application/ld+json">
      {JSON.stringify(organizationJSONLd)}
    </script>
  );

  return { organizationJSONLdScript, organizationJSONLd };
}
