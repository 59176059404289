import { buildBreadcrumbListJSONLd } from "../../libs/richSnippetsUtils/buildBreadcrumbListJSONLd";

export function useBreadcrumbListJSONLd({ itemListElement }) {
  const breadcrumbListJSONLd = buildBreadcrumbListJSONLd(
    {
      itemListElement,
    },
    "https://schema.org"
  );
  const breadcrumbListJSONLdScript = (
    <script type="application/ld+json">
      {JSON.stringify(breadcrumbListJSONLd)}
    </script>
  );

  return { breadcrumbListJSONLdScript, breadcrumbListJSONLd };
}
