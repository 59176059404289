import Layout from "../hocs/Layout";
import React, { useState, useEffect, useMemo } from "react";
import {
  faAngleLeft,
  faAngleRight,
  faArrowUp,
  faCartCircleXmark,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import ItemProducto from "../widgets/item_producto";
import { connect } from "react-redux";
import { get_homepage } from "../redux/actions/home";
import {
  get_producto_seccion,
  limpiar_array_producto,
  set_productos_peticion_seccion,
} from "../redux/actions/producto";
import BackToPage from "../components/BackPage/BackToPage";
import InfiniteScroll from "react-infinite-scroll-component";
import "../../src/assets/styles/categoria.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";

import "../assets/styles/seccionResponsive.scss";

import Alert from "../components/Swalert";

import classNames from "classnames";
import LoaderSpinner from "../widgets/loaderSpinner";
import { useScrollToElementTop } from "../hooks/useScrollToElementTop";
import { useGetCurrentUrl } from "../hooks/useGetCurrentUrl";
import { useBreadcrumbs } from "../hooks/useBreadcrumbs";
import { useBreadcrumbListJSONLd } from "../hooks/richSnippets/useBreadcrumbListJSONLd";
import { Helmet } from "react-helmet-async";
import { useItemListJSONLd } from "../hooks/richSnippets/useItemListJSONLd";
import { ITEM_LIST_ORDER } from "../libs/richSnippetsUtils/buildItemListJSONLd";
import slug from "../components/Utils/slug";
import {
  AVAILABILITY,
  ITEM_CONDITION,
} from "../libs/richSnippetsUtils/buildProductJSONLd";
import { HomeCategoriaSEO } from "../components/SEOComponents/HomeCategoriaSEO";
import {
  DEFAULT_KEYWORDS,
  KEYWORD_BY_SERVICE_TYPE,
} from "../components/SEOComponents/HomeSEO";
import { useGetSucursalCiudad } from "../hooks/useGetSucursalCiudad";
import BuscadorGeneral from "../widgets/BuscadorGeneral";
import { useIsDefaultStore } from "../hooks/useIsDefaultStore";
import { HOME_SECCION_TYPES } from "../redux/reducers/producto";
import { MensajeTelefonoProductoDisponible } from "./MensajeTelefonoProductoDisponible";

const HomeSeccion = ({
  get_homepage,
  homes,
  secciones,
  sucursal,
  tipo_entrega,
  productos_seccion,
  productos_peticion_seccion,
  get_producto_seccion,
  limpiar_array_producto,
  set_productos_peticion_seccion,
  isFetchingProductosSeccion,
  profile,
  end_message_producto_seccion,
  isFetchingHomes,
}) => {
  const { isDefaultStore } = useIsDefaultStore();

  const { currentUrl } = useGetCurrentUrl();
  const { scrollToElementTop } = useScrollToElementTop({ id: "scrollableDiv" });

  const { SeccionId } = useParams();
  const navigate = useNavigate();
  const { ciudadp, sucursalp } = useParams();
  let IdSucursal = sucursal.IdSucursal;
  let IdMarket = sucursal.IdMarket;
  const [page, setPage] = useState(1);

  const isUserAuthenticated = useMemo(() => {
    if (!profile) return null;
    return profile?.Telefono != null && profile?.Telefono !== "";
  }, [profile]);
  const tiene_producto = (sec) => {
    switch (sec?.IdTipoSeccion) {
      case HOME_SECCION_TYPES.OFERTAS:
        return true;
      case HOME_SECCION_TYPES.MAS_VENDIDOS:
        return true;
      case HOME_SECCION_TYPES.MAS_VENDIDOS_USUARIO:
        return true;
      case HOME_SECCION_TYPES.TEMPORADA:
        return true;
      default:
        return false;
    }
  };

  const arraySeccion = useMemo(() => {
    return secciones?.filter((sec) => tiene_producto(sec));
  }, [secciones]);
  const seccion = useMemo(() => {
    if (arraySeccion.length > 0) {
      let result = arraySeccion.find(
        (sec) => Number(sec.IdSeccion) === Number(SeccionId)
      );

      if (result) return result;
      set_productos_peticion_seccion(false);
    }
    return {
      IdSeccion: "",
      Descripcion: "",
      Api: "",
    };
  }, [SeccionId, arraySeccion, set_productos_peticion_seccion]);
  const dataInfo = useMemo(() => {
    if (!seccion) return null;
    return {
      IdSucursal: IdSucursal,
      Cantidad: 50,
      ApiSeccion: seccion.Api,
      SeccionId: seccion.IdSeccion,
      IdTipoSeccion: seccion.IdTipoSeccion,
    };
  }, [seccion, sucursal, sucursalp]);
  useEffect(() => {
    mover_scroll();

    setPage(1);
    limpiar_array_producto();
  }, [SeccionId, isUserAuthenticated]);

  useEffect(() => {
    if (!homes && sucursalp === slug.data_slug(sucursal.Descripcion)) {
      get_homepage(sucursal.IdSucursal, sucursal.IdMarket, tipo_entrega.Id);
    }
  }, [homes, tipo_entrega.Id, sucursal, sucursalp]);

  // useEffect(() => {
  //   if (seccion.IdSeccion === Number(SeccionId)) {
  //     setPage(1);
  //   }
  // }, [seccion, SeccionId]);

  useEffect(() => {
    if (
      !isFetchingHomes &&
      Object.keys(dataInfo).length > 0 &&
      productos_peticion_seccion &&
      page > 0 &&
      isUserAuthenticated !== null
    ) {
      get_producto_seccion(dataInfo, page);
    }
  }, [
    dataInfo,
    productos_peticion_seccion,
    page,
    isUserAuthenticated,
    isFetchingHomes,
  ]);

  const handleSetPage = () => {
    if (!isFetchingProductosSeccion) {
      const newPage = page + 1;
      setPage(newPage);
    }
  };

  const selectSeccion = (section) => {
    setPage(1);
    limpiar_array_producto();
    navigate(`/${ciudadp + "/" + sucursalp}/seccion/${section.IdSeccion}`);
  };

  const obtenerCantidad = () => {
    return productos_seccion.length;
  };

  const mover_scroll = () => {
    scrollToElementTop();
  };

  const { breadcrumbs: itemListElement } = useBreadcrumbs({
    categoria: seccion?.Descripcion || null,
  });

  const { breadcrumbListJSONLdScript } = useBreadcrumbListJSONLd({
    itemListElement,
  });

  const { itemListJSONLdScript } = useItemListJSONLd({
    name: seccion?.Descripcion || "",
    itemListOrder: ITEM_LIST_ORDER.ITEM_LIST_UNORDERED,
    itemListElement: productos_seccion?.map((product, index) => ({
      position: index + 1,
      item: {
        name: product.Descripcion,
        image: `${product.UrlFoto}&size=400x400`,
        url: `${currentUrl}/${slug.data_slug(product.Descripcion)}`,
        sku: product.IdProducto,
        offers: {
          priceCurrency: "BOB",
          price: product.PrecioVenta,
          itemCondition: ITEM_CONDITION.NEW,
          availability: AVAILABILITY.IN_STOCK,
          seller: {
            name: sucursal.Descripcion,
          },
        },
      },
    })),
  });

  const { sucursalCiudad } = useGetSucursalCiudad({
    idRegion: sucursal.idRegion,
  });

  const keywords = [
    `${process.env.REACT_APP_ORGANIZATION_NAME} ${
      sucursalCiudad?.Nombre || ""
    }`,
    sucursal?.Descripcion || "",
    `${sucursal?.TipoServicio.Descripcion || ""} ${
      sucursalCiudad?.Nombre || ""
    }`,
    `${sucursal?.TipoServicio.Descripcion || ""} online Bolivia`,
    `Tienda online ${sucursal?.Descripcion}`,
    `Compras online ${sucursalCiudad?.Nombre || ""}`,
    `Entrega a domicilio ${sucursalCiudad?.Nombre || ""}`,
    `Compras en línea ${sucursalCiudad?.Nombre || ""}`,
    `Ofertas ${sucursal?.Descripcion}`,
    `Descuentos ${sucursal?.Descripcion}`,
    `Marcas exclusivas ${sucursal?.Descripcion}`,
    `Promociones ${sucursal?.Descripcion}`,
    `Ofertas especiales ${sucursalCiudad?.Nombre || ""}`,
    `Delivery de ${sucursal?.TipoServicio.Descripcion || ""} Bolivia`,
    `Delivery de ${sucursal?.TipoServicio.Descripcion || ""} ${
      sucursalCiudad?.Nombre
    }`,
    `Delivery de ${sucursal?.TipoServicio.Descripcion || ""} ${
      sucursal?.Descripcion
    }`,
    DEFAULT_KEYWORDS,
  ];

  if (!!seccion?.Descripcion) {
    keywords.push(seccion?.Descripcion);
    keywords.push(`Compra de ${seccion?.Descripcion}`);

    keywords.push(`${seccion?.Descripcion} Bolivia`);
    keywords.push(`Compra de ${seccion?.Descripcion} en Bolivia`);

    keywords.push(`${seccion?.Descripcion} ${sucursal?.Descripcion || ""}`);
    keywords.push(
      `Compra de ${seccion?.Descripcion} en ${sucursal?.Descripcion || ""}`
    );
  }

  const displaySEO = useMemo(() => {
    if (!sucursal) return false;
    if (!sucursalCiudad) return false;
    if (SeccionId && !seccion) return false;

    return true;
  }, [SeccionId, seccion, sucursal, sucursalCiudad]);
  return (
    <>
      {displaySEO && (
        <HomeCategoriaSEO
          title={`${seccion?.Descripcion || "Sección no encontrada"} | ${
            isDefaultStore ? "Hipermaxi" : sucursal?.Descripcion || ""
          }`}
          store={`${sucursal?.Descripcion}`}
          description={`Realiza tu compra en línea de productos de ${
            seccion?.Descripcion || "esta sección"
          } en ${
            !sucursal || isDefaultStore
              ? "tu sucursal Hipermaxi más cercana"
              : `la sucursal ${sucursal.Descripcion}`
          } en Bolivia. Mediante unos simples pasos, envía tus productos al carrito de compra, decide cómo pagar y recíbelos en la puerta de tu casa. Disfruta de la conveniencia de ${
            !sucursal || isDefaultStore
              ? "nuestra sucursal"
              : `${
                  KEYWORD_BY_SERVICE_TYPE[
                    sucursal.TipoServicio.Descripcion.toUpperCase()
                  ]
                }`
          } en línea Hipermaxi y encuentra una amplia variedad de productos locales, productos importados y ofertas exclusivas.`}
          breadcrumbListJSONLdScript={breadcrumbListJSONLdScript}
          itemListJSONLdScript={itemListJSONLdScript}
          image={`${sucursal?.UrlFoto}&size=400x400`}
          url={currentUrl}
          displayMetaTags={!!seccion?.Descripcion}
          keywords={keywords}
        />
      )}

      <Layout>
        <Alert></Alert>
        <div className="flex flex-col gap-2 sticky top-[108px] md:top-[120px] lg:hidden p-2 z-20 bg-white pb-1">
          <BuscadorGeneral />
          {/* <div className="flex items-center p-2">
            <h4 className="text-center text-black mb-0 block md:hidden">
              {seccion.Descripcion}
            </h4>
          </div> */}
          <div className="w-full relative z-0 flex gap-1">
            <div
              className={classNames(
                `review-swiper-button-prev-${SeccionId} text-gray-500 hover:text-gray-800 disabled:text-gray-300 flex items-center`,
                {
                  "opacity-1": arraySeccion?.length > 0,
                  "opacity-0": arraySeccion?.length === 0,
                }
              )}
            >
              <span className="bg-white border shadow-sm p-1 rounded-md">
                <FontAwesomeIcon icon={faAngleLeft} size="lg" />
              </span>
            </div>
            <Swiper
              slidesPerView="auto"
              freeMode
              spaceBetween={5}
              navigation={{
                nextEl: `.review-swiper-button-next-${SeccionId}`,
                prevEl: `.review-swiper-button-prev-${SeccionId}`,
              }}
              modules={[FreeMode, Navigation]}
              className="mySwiper"
            >
              {arraySeccion &&
                arraySeccion !== null &&
                arraySeccion !== undefined &&
                arraySeccion.map((seccion_item, index) => (
                  <SwiperSlide key={index} className="w-auto">
                    <div
                      className={classNames(
                        "py-1.5 px-2 rounded-lg cursor-pointer text-sm",
                        {
                          "border-1 hover:border-hiperorange hover:bg-hiperorange hover:text-white":
                            Number(seccion_item.IdSeccion) !==
                            Number(seccion.IdSeccion),
                        }
                      )}
                      style={{
                        backgroundColor:
                          Number(seccion_item.IdSeccion) ===
                          Number(seccion.IdSeccion)
                            ? "var(--primario)"
                            : "transparent",
                        color:
                          Number(seccion_item.IdSeccion) ===
                          Number(seccion.IdSeccion)
                            ? "#FFFFFF"
                            : "#000000",
                      }}
                      onClick={() => selectSeccion(seccion_item)}
                    >
                      {seccion_item.Descripcion}
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
            <div
              className={classNames(
                `review-swiper-button-next-${SeccionId} text-gray-500 hover:text-gray-800 disabled:text-gray-300 flex items-center`,
                {
                  "opacity-1": arraySeccion?.length > 0,
                  "opacity-0": arraySeccion?.length === 0,
                }
              )}
            >
              <span className="bg-white border shadow-sm p-1 rounded-md">
                <FontAwesomeIcon icon={faAngleRight} size="lg" />
              </span>
            </div>
          </div>
        </div>

        <main className="relative flex-grow">
          <div
            className="fixed md:top-[13.5rem] lg:top-[7.5rem] md:h-[calc(100%-13.5rem)] lg:h-[calc(100%-7.5rem)] md:w-60 lg:w-72 z-10 overflow-y-auto bg-white border-r-[1px] border-l-gray-200 hidden md:block"
            style={{ scrollbarWidth: "thin" }}
          >
            <div className="flex p-2 pt-3 sticky top-0 bg-white">
              <h3 className="text-black mb-0 text-center">Secciones</h3>
            </div>
            <div
              className="flex flex-col m-0 p-2 pt-0"
              style={{ scrollbarWidth: "thin" }}
            >
              {isFetchingHomes ? (
                <LoaderSpinner bool={true} />
              ) : (
                <>
                  {arraySeccion &&
                    arraySeccion !== null &&
                    arraySeccion !== undefined &&
                    arraySeccion.length > 0 &&
                    arraySeccion.map((seccion_item, index) => (
                      <button
                        key={index}
                        className={classNames(
                          "break-words py-1 px-2 rounded-lg w-full text-left font-semibold",
                          {
                            "hover:border-1 hover:border-gray-200 hover:bg-gray-200":
                              Number(seccion_item.IdSeccion) !==
                              Number(seccion.IdSeccion),
                          }
                        )}
                        style={{
                          backgroundColor:
                            Number(seccion_item.IdSeccion) ===
                            Number(seccion.IdSeccion)
                              ? "var(--primario)"
                              : "transparent",
                          color:
                            Number(seccion_item.IdSeccion) ===
                            Number(seccion.IdSeccion)
                              ? "#FFFFFF"
                              : "#000000",
                        }}
                        onClick={() => selectSeccion(seccion_item)}
                      >
                        {seccion_item.Descripcion}
                      </button>
                    ))}
                </>
              )}
            </div>
          </div>

          <div className="md:ml-60 lg:ml-72">
            <div className="w-full p-4 px-3">
              <InfiniteScroll
                scrollableTarget="scrollableDiv"
                dataLength={obtenerCantidad()}
                next={handleSetPage}
                hasMore={true}
                scrollThreshold={0.95}
                loader={
                  isFetchingProductosSeccion || isUserAuthenticated === null ? (
                    <div className="mt-4">
                      <LoaderSpinner bool={productos_peticion_seccion} />
                    </div>
                  ) : (
                    <div className="flex w-full justify-center py-2">
                      {end_message_producto_seccion && (
                        <div className="flex w-full justify-center">
                          {obtenerCantidad() > 0 ? (
                            <div className="text-gray-400 ">
                              No hay más productos para mostrar
                            </div>
                          ) : (
                            <div className="flex items-center justify-center h-[50vh] flex-col gap-5">
                              <FontAwesomeIcon
                                className="text-gray-500"
                                icon={faCartCircleXmark}
                                size="6x"
                              />
                              <div className="flex flex-col gap-2">
                                <div className="text-gray-500 text-lg md:text-xl text-center">
                                  La sección seleccionada no tiene productos
                                  para mostrar.
                                </div>
                                <MensajeTelefonoProductoDisponible />
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )
                }
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>No hay más productos para mostrar</b>
                  </p>
                }
              >
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-7 gap-x-3 md:gap-x-6 gap-y-8">
                  {productos_seccion &&
                    productos_seccion !== null &&
                    productos_seccion !== undefined &&
                    productos_seccion.map((producto, index) => (
                      <ItemProducto
                        key={index}
                        producto={producto}
                        ps_IdSucursal={sucursal.IdSucursal}
                      />
                    ))}
                </div>
              </InfiniteScroll>
            </div>
          </div>

          {/* <button
        className="footer-button  bg-hiperorange hover:bg-hiperorange_hover opacity-80 text-white font-bold py-1 px-3 rounded"
        type="button"
        onClick={() => mover_scroll()}
      >
        <FontAwesomeIcon icon={faArrowUp} />
      </button> */}
        </main>
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => ({
  sucursal: state.BranchOffice.sucursal,
  homes: state.Home.homes,
  secciones: state.Home.secciones,
  oferta: state.Home.oferta,
  novedades: state.Home.novedades,
  mas_vendidos: state.Home.mas_vendidos,
  tipo_entrega: state.BranchOffice.tipo_entrega,
  direccion: state.Location.direccion,
  carritos: state.Carrito.carrito,
  producto_delete: state.Carrito.producto_delete,
  productos_peticion_seccion: state.Producto.productos_peticion_seccion,
  productos_seccion: state.Producto.productos_seccion,
  isFetchingProductosSeccion: state.Producto.isFetchingProductosSeccion,
  profile: state.Profile.profile,
  end_message_producto_seccion: state.Producto.end_message_producto_seccion,
  isFetchingHomes: state.Home.isFetchingHomes,
});

export default connect(mapStateToProps, {
  get_homepage,
  get_producto_seccion,
  limpiar_array_producto,
  set_productos_peticion_seccion,
})(HomeSeccion);
