import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function BarcodeRead() {
  const [data, setData] = useState("");

  useEffect(() => {
    buscarProductoBarcode(data);
  }, [data]);

  const navigate = useNavigate();

  const buscarProductoBarcode = (filtro) => {
    if (filtro !== "" && filtro.trim() !== "") {
      navigate("/busqueda_producto_codigo/" + filtro.trim());
    }
  };

  return (
    <>
      <p>product code:{data}</p>
    </>
  );
}
