export const mapToURLSearchParams = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: Record<string, any>
): URLSearchParams => {
  const params = new URLSearchParams();
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      params.append(key, !!object[key] ? object[key] : "");
    }
  }
  return params;
};

export const valueToURLSearchParams = (
  key: string,
  value: string
): URLSearchParams => {
  const params = new URLSearchParams();
  params.append(key, value);
  return params;
};

export const mapToFormData = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: Record<string, any>
): FormData => {
  const formData = new FormData();
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      formData.append(key, object[key]);
    }
  }
  return formData;
};

export const webIsInDomain = (): boolean => {
  const domain = process.env.REACT_APP_DOMAIN || "";
  const webDomain = typeof window !== "undefined" && window.location.origin;
  if (!webDomain) return false;
  return webDomain?.includes(domain);
};

export const getBaseUrlBasedOnDomain = (
  backendUrl: string,
  domainBackendUrl: string
) => {
  const isInDomain = webIsInDomain();
  return isInDomain ? domainBackendUrl : backendUrl;
};
