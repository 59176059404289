import {
  GET_LIST_SUCCESS,
  GET_LIST_FAIL,
  GET_PEDIDO_SUCCESS,
  GET_PEDIDO_FAIL,
  SET_AUTH_LOADING,
  GET_LIST_FINALIZADOS_SUCCESS,
  GET_LIST_PENDIENTES_SUCCESS,
  REMOVE_AUTH_LOADING,
  SET_LIST_PEDIDOS_FIRE_SUCCESS,
  GET_LIST_PEDIDOS_FIRE_SUCCESS,
  CLEAR_PEDIDO_SUCCESS,
  CLEAR_PEDIDOS_SUCCESS,
} from "./types";
import axios from "axios";
import { setSwAlert, showRespuesta } from "./swalert";
import respuestas from "../../components/Alertas";

export const list_pedidos = () => async (dispatch) => {
  dispatch({
    type: SET_AUTH_LOADING,
  });

  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };

  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/pedidosmarket/?Pagina=1&Cantidad=20`,
      config
    );
    if (res.status === 200) {
      /*res.data.datosUsuario = res.data.Dato;*/
      const pedidos_aux = res.data.Dato.slice();
      // pedidos_aux.sort((a, b) => b.NroPedido - a.NroPedido);
      pedidos_aux.sort((a, b) => {
        const fechaA = new Date(a.FechaEntrega.split("/").reverse().join("-"));
        const fechaB = new Date(b.FechaEntrega.split("/").reverse().join("-"));
        return fechaB - fechaA;
      });

      res.data["dato"] = pedidos_aux;
      dispatch({
        type: GET_LIST_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_LIST_FAIL,
      });

      dispatch(showRespuesta(res));
    }

    dispatch({
      type: REMOVE_AUTH_LOADING,
    });
  } catch (err) {
    dispatch({
      type: GET_LIST_FAIL,
    });

    dispatch(setSwAlert(respuestas.peticionError, true, 500));

    //   dispatch(setAlert('Error al conectar con el servidor, intenta mas tarde.', 'red'));
  }
};

export const list_pedidos_finalizados = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/pedidosmarket/?Pagina=1&Cantidad=5&Confirmado=true`,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_LIST_FINALIZADOS_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_LIST_FAIL,
      });
      dispatch(showRespuesta(res));
    }
  } catch (err) {
    dispatch({
      type: GET_LIST_FAIL,
    });
    dispatch(setSwAlert(respuestas.peticionError, true, 500));
  }
};
export const list_pedidos_paginados = (datos, page) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
  try {
    let Confirmado = datos.Confirmado;
    let pagina = page;
    let cantidad = datos.Cantidad;
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/pedidosmarket/?Pagina=${pagina}&Cantidad=${cantidad}&Confirmado=${Confirmado}`,
      config
    );
    if (res.status === 200) {
      if (Confirmado) {
        dispatch({
          type: GET_LIST_FINALIZADOS_SUCCESS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_LIST_PENDIENTES_SUCCESS,
          payload: res.data,
        });
      }
    } else {
      dispatch({
        type: GET_LIST_FAIL,
      });
      dispatch(showRespuesta(res));
    }
  } catch (err) {
    dispatch({
      type: GET_LIST_FAIL,
    });
    dispatch(setSwAlert(respuestas.peticionError, true, 500));
    //   dispatch(setAlert('Error al conectar con el servidor, intenta mas tarde.', 'red'));
  }
};

export const getpedido = (nropedido) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/pedidosmarket/{idpedido}?=` +
        nropedido,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_PEDIDO_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_PEDIDO_FAIL,
      });

      dispatch(showRespuesta(res));
    }
  } catch (err) {
    dispatch({
      type: GET_PEDIDO_FAIL,
    });
    dispatch(setSwAlert(respuestas.peticionError, true, 500));
  }
};

export const setPedidosUsuarioFireb = (arraydato) => async (dispatch) => {
  dispatch({
    type: SET_AUTH_LOADING,
  });

  try {
    /*res.data.datosUsuario = res.data.Dato;*/
    dispatch({
      type: SET_LIST_PEDIDOS_FIRE_SUCCESS,
      payload: arraydato,
    });
  } catch (err) {
    //   dispatch(setAlert('Error al conectar con el servidor, intenta mas tarde.', 'red'));
  }
};
export const getPedidosUsuarioFireb = (arraydato) => async (dispatch) => {
  dispatch({
    type: SET_AUTH_LOADING,
  });

  try {
    /*res.data.datosUsuario = res.data.Dato;*/
    dispatch({
      type: GET_LIST_PEDIDOS_FIRE_SUCCESS,
      payload: arraydato,
    });
  } catch (err) {
    dispatch({
      type: GET_PEDIDO_FAIL,
    });

    //   dispatch(setAlert('Error al conectar con el servidor, intenta mas tarde.', 'red'));
  }
};

export const clear_pedido = () => async (dispatch) => {
  dispatch({
    type: CLEAR_PEDIDO_SUCCESS,
  });
};

export const clear_pedidos = (bool) => async (dispatch) => {
  dispatch({
    type: CLEAR_PEDIDOS_SUCCESS,
    payload: bool,
  });
};
