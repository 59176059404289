import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../hocs/Layout";
import Modal from "react-modal";
import ItemTarjeta from "../../widgets/item_tarjeta";
import { connect } from "react-redux";
import { get_tarjeta, delete_tarjeta } from "../../redux/actions/tarjeta";
import Swal from "sweetalert2";
import "../../assets/styles/globalColorPrincipal.css";
import "../../assets/styles/stylePageGlobals.scss";
import ButtonText from "../../widgets/button_text";
import Alert from "../../components/Swalert";
import NuevaTarjeta from "./NuevaTarjeta";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCreditCard,
  faSubtitlesSlash,
} from "@fortawesome/pro-solid-svg-icons";
import { Helmet } from "react-helmet-async";
import { useIsDefaultStore } from "../../hooks/useIsDefaultStore";

const customStyles = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "32",
  },
  content: {
    padding: "0px",
    border: "0px",
    background: "transparent",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "calc(100% - 16px)",
    overflow: "hidden",
  },
};

const ListaMisTarjetas = ({
  get_tarjeta,
  tarjetas,
  delete_tarjeta,
  profile,
  sucursal,
}) => {
  const [modalEditListPurchase, setEditListPurchase] = React.useState(false);
  const startEditListPurchase = () => setEditListPurchase(true);
  const endAdEditListPurchase = () => setEditListPurchase(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };
  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };
  const { ciudadp, sucursalp } = useParams();

  const eliminarTarjeta = (Id) => {
    Swal.fire({
      title:
        "La tarjeta seleccionada será eliminada.¿Estás seguro que deseas continuar?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#32338E",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTarjeta(Id);
      }
    });
  };
  async function deleteTarjeta(id) {
    await delete_tarjeta(id);
    await obtenerDatos();
  }

  useEffect(() => {
    obtenerDatos();
  }, [profile]);
  const obtenerDatos = async () => {
    get_tarjeta();
  };

  const registrar_nuevo = async () => {
    handleOpenAddModal();
  };
  const { isDefaultStore } = useIsDefaultStore();

  return (
    <>
      <Helmet>
        <title>{`Mis tarjetas | ${
          isDefaultStore ? "Hipermaxi" : sucursal?.Descripcion || ""
        }`}</title>
        {/* <meta name="“robots”" content="noindex" /> */}
      </Helmet>
      <Layout>
        <Alert></Alert>
        <div className="content_info py-4">
          <div className="container">
            <h1 className="text-2xl md:text-3xl text-center text-hiperorange mb-4">
              Mis tarjetas
            </h1>
            <div className="flex justify-center">
              <div className=" w-[35rem] md:h-[40rem] border border-hiperorange bg-white shadow rounded-xl">
                <div
                  className="flex justify-between flex-column h-100"
                  style={{ paddingBottom: "1rem" }}
                >
                  <div className="flex flex-col h-full overflow-y-auto min-h-[15rem] px-4 pt-3">
                    <div className=" flex flex-col gap-2">
                      <div className=" flex justify-center flex-wrap gap-2">
                        {tarjetas != null && (
                          <>
                            {tarjetas.length > 0 ? (
                              <>
                                {tarjetas.map((item_card, index) => (
                                  <ItemTarjeta
                                    key={index}
                                    ps_tarjeta={item_card}
                                    ps_deleteTarjeta={eliminarTarjeta}
                                  ></ItemTarjeta>
                                ))}
                              </>
                            ) : (
                              <div className="flex flex-col gap-4 justify-center items-center mt-2 min-h-60 text-gray-400">
                                <FontAwesomeIcon
                                  icon={faCreditCard}
                                  size="6x"
                                />
                                <div className="text-xl">
                                  No tienes tarjetas agregadas
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="bg-white h-[5.5rem] flex justify-around flex-column px-4 sticky bottom-0 z-10 md:static">
                    <ButtonText
                      type="button"
                      label="Registrar nueva tarjeta"
                      principal={true}
                      className="bg-hiperorange text-white hover:bg-hiperorange_hover"
                      onClick={() => registrar_nuevo()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={openAddModal}
          ariaHideApp={false}
          onRequestClose={handleCloseAddModal}
          style={customStyles}
          contentLabel="create credit card"
        >
          <NuevaTarjeta close={handleCloseAddModal} />
        </Modal>
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => ({
  tarjetas: state.Tarjeta.tarjetas,
  profile: state.Profile.profile,
  sucursal: state.BranchOffice.sucursal,
});

export default connect(mapStateToProps, {
  get_tarjeta,
  delete_tarjeta,
})(ListaMisTarjetas);
