import {
  GET_NOTIFICACION_SUCCESS,
  GET_NOTIFICACION_FAIL,
  SET_IS_FETCHING_NOTIFICACIONES,
} from "../actions/types";

const initialState = {
  notificaciones: [],
  isFetching: false,
};

export default function Notification(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_IS_FETCHING_NOTIFICACIONES:
      return {
        ...state,
        isFetching: payload,
      };
    case GET_NOTIFICACION_SUCCESS:
      return {
        ...state,
        notificaciones: payload.Dato,
      };
    case GET_NOTIFICACION_FAIL:
      return {
        ...state,
      };

    default:
      return state;
  }
}
