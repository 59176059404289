import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import {
  actualizar_lista_compra,
  store_lista_compra,
} from "../../../redux/actions/shoppingList";
import { useNavigate, useParams } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import { useForm } from "react-hook-form";
import "../../../assets/styles/responsive.scss";
import ButtonClose from "../../../widgets/button_close";
import { listaColores as listColor } from "../../../components/Utils/utils-colores";
const ContenidoNuevaLista = ({
  info,
  opcion,
  ps_productos,
  store_lista_compra,
  ps_closeModal,
  ps_lista_compartida,
  ps_IdSucursal,
  loading,
}) => {
  
  const { ciudadp, sucursalp } = useParams();

  const [formData, setFormData] = useState({
    Descripcion: "",
    Color: "",
  });
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const messages = {
    required: "Este campo es obligatorio",
    minLength: "No puede ingresar menos de 3 caracteres en este campo",
    maxLength: "No puede ingresar más 30 caracteres en este campo",
    espacioBlanco: "No es permitido espacios en blanco",
    requiredColor: "Debe seleccionar un color",
  };
  const patterns = {
    espacioBlanco: /^(?!\s)/,
  };

  useEffect(() => {
    cargarListaCompartida();
  }, [opcion]);
  function cargarListaCompartida() {
    setFormData({
      Descripcion: ps_lista_compartida.Titulo,
      Color: ps_lista_compartida.Color,
    });
  }
  const selectColor = (color) => {
    setFormData((formData) => ({
      ...formData,
      Color: color,
    }));
  };
  const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };
  const onSubmit = (listinfo) => {
    let descripcion = listinfo.Descripcion;
    if (formData.Color != "" && descripcion != "") {
      guardarListaCompra(descripcion);
    }
  };

  const guardarListaCompra = async (description) => {
    const datos = {
      Descripcion: description,
      Color: formData.Color,
    };

    await store_lista_compra(datos, ps_productos, ps_IdSucursal);
    await ps_closeModal();
    await delay(2000);
    navigate_menu("lista-compras");
  };

  const navigate_menu = async (url_menu) => {
    let param = await get_url();
    navigate(param + "/" + url_menu);
  };

  const get_url = async () => {
    let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
    if (ciudadp && sucursalp) {
      return "/" + ciudadp + "/" + sucursalp;
    } else if (url_nav) {
      return "/" + url_nav.url_parameters;
    }
  };

  return (
    <>
      <div className="content_modal rounded-hiper_g overflow-hidden">
        <div className="bg-hiperorange w-100 relative text-white flex items-center justify-center h-[52px]  content_title">
          <h2 className="p-0 m-0 title_carrito_responsivo">{info}</h2>
          <div className="absolute right-2 ">
            <ButtonClose
              className="text-white bg-transparent"
              onClick={() => ps_closeModal()}
            />
          </div>
        </div>

        <div className="contenedor_nuevo p-3">
          <div className="contenido_form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-3">
                <label
                  htmlFor="email"
                  className="block fontzize_label text-black "
                >
                  Nombre de la lista:
                </label>
                <div className="mt-1">
                  <input
                    id="Descripcion"
                    name="Descripcion"
                    type="text"
                    autoComplete="Descripcion"
                    placeholder="Nombre de la lista"
                    defaultValue={formData.Descripcion}
                    required
                    className="appearance-none block w-full px-3 py-1 border border-black rounded-md  text-black placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 "
                    {...register("Descripcion", {
                      required: {
                        value: true,
                        message: messages.required,
                      },
                      minLength: {
                        value: 3,
                        message: messages.minLength + ", 3 caracteres",
                      },
                      maxLength: {
                        value: 50,
                        message: messages.maxLength,
                      },
                      pattern: {
                        value: patterns.espacioBlanco,
                        message: messages.espacioBlanco,
                      },
                    })}
                  />
                  {errors.Descripcion && (
                    <small className="text-red-600">
                      {errors.Descripcion.message}
                    </small>
                  )}
                </div>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="email"
                  className="block fontzize_label text-black"
                >
                  Seleccione el color:
                </label>
                <div className="flex justify-center border border-black rounded-hiper_g px-2 py-4">
                  <div className="grid grid-cols-3 grid-rows-3 gap-2 ">
                    {listColor.map((item_list) => (
                      <div
                        className="w-11 h-11 rounded-md border-2 cursor-pointer"
                        key={item_list.id}
                        style={{
                          backgroundColor: item_list.color,
                          borderColor:
                            item_list.color === formData.Color
                              ? "#000000"
                              : item_list.color,
                        }}
                        onClick={() => selectColor(item_list.color)}
                      ></div>
                    ))}
                  </div>
                </div>
              </div>
              <div>
                <button
                  className="flex justify-center items-center gap-1 bg-hiperblue hover:hiperblue_hover py-2 px-4 rounded-lg  text-white w-full mt-1"
                  type="submit"
                  disabled={loading}
                >
                  <Circles
                    color="#DC842D"
                    width={20}
                    height={20}
                    ariaLabel="circles-loading"
                    visible={loading}
                  />
                  Crear nueva lista
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.Shopping_list.loading,
});

export default connect(mapStateToProps, {
  actualizar_lista_compra,
  store_lista_compra,
})(ContenidoNuevaLista);
