import { Helmet } from "react-helmet-async";

export function ProductSEO({
  title,
  store,
  description,
  breadcrumbListJSONLdScript,
  productJSONLdScript,
  image,
  url,
  productPrice,
  currency,
  brand,
  availability,
  condition,
  retailerItemId,
  productDiscount,
  keywords = [],
}) {
  return (
    <Helmet title={title}>
      {/* Meta tags para Open Graph (Facebook) */}
      {!!retailerItemId && <meta property="og:title" content={title} />}
      {!!retailerItemId && (
        <meta property="og:description" content={description} />
      )}
      {!!retailerItemId && <meta property="og:image" content={image} />}
      {!!retailerItemId && <meta property="og:url" content={url} />}
      {!!retailerItemId && <meta property="og:type" content="website" />}
      {!!retailerItemId && <meta property="og:site_name" content={store} />}

      {!!retailerItemId && (
        <meta property="product:price:amount" content={productPrice} />
      )}
      {!!retailerItemId && (
        <meta property="product:price:currency" content={currency} />
      )}
      {!!retailerItemId && brand && (
        <meta property="product:brand" content={brand} />
      )}
      {!!retailerItemId && (
        <meta property="product:availability" content={availability} />
      )}
      {!!retailerItemId && (
        <meta property="product:condition" content={condition} />
      )}
      {!!retailerItemId && (
        <meta property="product:retailer_item_id" content={retailerItemId} />
      )}
      {!!retailerItemId && productDiscount && (
        <meta property="product:sale_price:amount" content={productDiscount} />
      )}
      {!!retailerItemId && productDiscount && (
        <meta property="product:sale_price:currency" content={currency} />
      )}

      {/* Meta tags para Twitter Cards */}
      {!!retailerItemId && (
        <meta name="twitter:card" content="summary_large_image" />
      )}
      {!!retailerItemId && <meta name="twitter:title" content={title} />}
      {!!retailerItemId && (
        <meta name="twitter:description" content={description} />
      )}
      {!!retailerItemId && <meta name="twitter:image" content={image} />}
      {!!retailerItemId && <meta name="twitter:url" content={url} />}
      {/* <meta name="twitter:site" content="@tu_usuario_twitter" /> */}

      <meta name="store" content={store} />
      <meta name="description" content={description} />
      {keywords.length > 0 && (
        <meta
          name="keywords"
          content={keywords.join(", ")}
        />
      )}
      {breadcrumbListJSONLdScript}
      {!!retailerItemId && productJSONLdScript}
    </Helmet>
  );
}
