import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import Map from "../../components/Map";
import { connect } from "react-redux";
import { post_ubicacion } from "../../redux/actions/location";
import ButtonText from "../../widgets/button_text";
import "../../assets/styles/seccionResponsive.scss";
import "../../assets/styles/responsive.scss";
import { useForm } from "react-hook-form";
import "../../assets/styles/globalColorPrincipal.css";
import Alert from "../../components/Swalert";
import { cleadSwAlert } from "../../redux/actions/swalert";
import ButtonClose from "../../widgets/button_close";

const Direccion = ({
  show_alert,
  redirect,
  post_ubicacion,
  cleadSwAlert,
  locations,
  loading,
  close = () => {},
}) => {
  const [Longitud, setLongitud] = useState("");
  const [Latitud, setLatitud] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const messages = {
    required: "Este campo es obligatorio",
    minLength: "No puede ingresar menor caracteres de lo establecido",
    maxLength: "No puede ingresar más caracteres en este campo",
    espacioBlanco: "No es permitido espacios en blanco",
    requiredColor: "Debe seleccionar un color",
    aceptaNumeros: "Solo se acepta números en este campo",
    aceptaLetra: "Solo se acepta letras en este campo",
  };
  const patterns = {
    espacioBlanco: /^(?!\s)/,
    aceptaNumeros: /^[0-9,-]+$/,
    aceptaLetra: /^[A-Za-z\s]+$/,
  };

  useEffect(() => {
    if (show_alert) {
      if (redirect) {
        if (redirect.type === "modal") {
          if (redirect.ruta === "back") {
            window.history.back();
          }
        }
      }
    }
  }, [show_alert]);

  useEffect(() => {
    return () => {
      cleadSwAlert();
    };
  }, []);

  const setDireccion = (value) => {
    setValue("Direccion", value);
  };

  const setLatLng = (latlng) => {
    setLatitud(latlng.lat);
    setLongitud(latlng.lng);
  };

  const onSubmit = async (formInfo) => {
    let EsPrincipal = false;
    if (locations.length === 0) {
      EsPrincipal = true;
    }
    const datos = {
      Descripcion: formInfo.Descripcion,
      Direccion: formInfo.Direccion,
      Referencia: formInfo.Referencia,
      Longitud: Longitud,
      Latitud: Latitud,
      EsPrincipal: EsPrincipal,
    };
    let object = {
      type: "modal",
      type_modal: true,
      type_title: "direccion",
      ruta: "back",
    };
    await post_ubicacion(datos, null);
    close();
  };

  return (
    <>
      <Alert></Alert>
      <div className="w-[23rem] md:w-[40rem] bg-white h-full flex flex-col">
        <div className="bg-hiperorange w-100 relative text-white flex items-center justify-center h-[52px]  content_title">
          <h2 className="p-0 m-0 title_carrito_responsivo">Nueva dirección</h2>
          <div className="absolute right-2 ">
            <ButtonClose
              className="text-white bg-transparent"
              onClick={() => close()}
            />
          </div>
        </div>
        <div className="flex flex-grow">
          <div className="rounded-lg flex w-full">
            <div className="flex flex-col w-full">
              <div className="flex flex-col col-sm-12 flex-grow">
                <Map
                  onlatlng={setLatLng}
                  onClicDireccion={setDireccion}
                  editable={true}
                />
              </div>
              <div className="col-sm-12">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="grid gap-2 p-3 pt-0">
                    <div>
                      <label
                        className="block fontzize_label text-black  mb-2"
                        htmlFor="alias"
                      >
                        Alias*
                      </label>
                      <input
                        className="text-sm md:text-base block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                        id="alias"
                        type="text"
                        placeholder=""
                        {...register("Descripcion", {
                          required: {
                            value: true,
                            message: "El campo Alias es obligatorio",
                          },
                          minLength: {
                            value: 3,
                            message:"El campo Alias debe tener 3 caracteres como mínimo",
                          },
                          maxLength: {
                            value: 100,
                            message: "El campo Alias debe tener máximo 100 caracteres",
                          },
                          pattern: {
                            value: patterns.espacioBlanco,
                            message: "El campo Alias no debe tener espacios en blanco",
                          },
                        })}
                      />
                      {errors.Descripcion && (
                        <span style={{ color: "#920909" }}>
                          {errors.Descripcion.message}
                        </span>
                      )}
                    </div>
                    <div>
                      <label
                        className="block fontzize_label text-black  mb-2"
                        htmlFor="referencia"
                      >
                        Referencia*
                      </label>
                      <input
                        className="text-sm md:text-base block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                        id="referencia"
                        type="text"
                        placeholder=""
                        {...register("Referencia", {
                          required: {
                            value: true,
                            message: "El campo Referencia es obligatorio",
                          },
                          minLength: {
                            value: 3,
                            message: "El campo Referencia debe tener 3 caracteres como mínimo",
                          },
                          maxLength: {
                            value: 100,
                            message: "El campo Referencia debe tener máximo 100 caracteres",
                          },
                          pattern: {
                            value: patterns.espacioBlanco,
                            message: "El campo Referencia no debe tener espacios en blanco",
                          },
                        })}
                      />
                      {errors.Referencia && (
                        <span style={{ color: "#920909" }}>
                          {errors.Referencia.message}
                        </span>
                      )}
                    </div>
                    <div>
                      <label
                        className="block fontzize_label text-black  mb-2"
                        htmlFor="direccion"
                      >
                        Dirección*
                      </label>
                      <textarea
                        className="text-sm md:text-base block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                        id="direccion"
                        placeholder=""
                        rows={2}
                        {...register("Direccion", {
                          required: {
                            value: true,
                            message: "El campo Dirección es obligatorio",
                          },
                          minLength: {
                            value: 3,
                            message: "El campo Dirección debe tener 3 caracteres como mínimo",
                          },
                          maxLength: {
                            value: 100,
                            message: "El campo Dirección debe tener máximo 100 caracteres",
                          },
                          pattern: {
                            value: patterns.espacioBlanco,
                            message: "El campo Dirección no debe tener espacios en blanco",
                          },
                        })}
                      ></textarea>
                      {errors.Direccion && (
                        <span style={{ color: "#920909" }}>
                          {errors.Direccion.message}
                        </span>
                      )}
                    </div>

                    <ButtonText
                      type="submit"
                      label="Guardar"
                      principal={true}
                      className="bg-hiperorange hover:bg-hiperorange_hover text-white text-sm md:text-base"
                      onClick={() => {}}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  locations: state.Location.locations,
  loading: state.Location.loading,
  show_alert: state.Swalerts.show_alert,
  redirect: state.Swalerts.redirect,
});

export default connect(mapStateToProps, {
  post_ubicacion,
  cleadSwAlert,
})(Direccion);
