import { buildStoreJSONLd } from "../../libs/richSnippetsUtils/buildStoreJSONLd";

const TIPO_SERVICIO = {
  FARMACIA: "http://schema.org/Pharmacy",
  SUPERMERCADO: "http://schema.org/Supermarket",
};

export function useStoreJSONLd(
  {
    name: storeName,
    streetAddress: storeStreetAddress,
    addressLocality: storAddressLocality,
    addressRegion: storAddressRegion,
    addressCountry: storAddressCountry,
    telephone: storTelephone,
    url: storUrl,
    openingHours: storOpeningHours,
    latitude,
    longitude,
    tipoServicio,
  },
  {
    url: organizationUrl,
    logo: organizationLogo,
    name: organizationName,
    mediaLinks: organizationMediaLinks,
    contactPoint: organizationContactPoint,
  }
) {
  const storeJSONLd = buildStoreJSONLd(
    {
      name: storeName,
      address: {
        streetAddress: storeStreetAddress,
        addressLocality: storAddressLocality,
        addressRegion: storAddressRegion,
        // postalCode,
        addressCountry: storAddressCountry,
      },
      parentOrganization: {
        url: organizationUrl,
        logo: organizationLogo,
        name: organizationName,
        mediaLinks: organizationMediaLinks,
        contactPoint: organizationContactPoint,
      },
      telephone: storTelephone,
      url: storUrl,
      openingHours: storOpeningHours,
      latitude,
      longitude,
      additionalType: TIPO_SERVICIO[tipoServicio],
    },
    "https://schema.org"
  );

  const storeJSONLdScript = (
    <script type="application/ld+json">{JSON.stringify(storeJSONLd)}</script>
  );

  return { storeJSONLd, storeJSONLdScript };
}
