export function useLocalStorage() {
  const setItem = (key, value) => {
    localStorage.setItem(key, value);
  }

  const getItem = (key) => {
      const data = localStorage.getItem(key);
      try {
        return data ? JSON.parse(data) : undefined;
      } catch (error) {
        // Si hay un error al parsear JSON, devolvemos el valor sin modificar
        return data;
      }
  }

  const removeItem = (key) => {
    localStorage.removeItem(key);
  }

  const clear = (key) => {
    localStorage.clear();
  }

  return {
    setItem,
    getItem,
    removeItem,
    clear,
  };
}
