import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faLocationDotSlash,
  faPenToSquare,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  delete_ubicacion,
  get_ubicacion,
  update_ubicacion,
} from "../../redux/actions/location";
import { get_sucursales_cerca } from "../../redux/actions/branch_office";
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import ButtonText from "../../widgets/button_text";
import ButtonClose from "../../widgets/button_close";
import { cleadSwAlert } from "../../redux/actions/swalert";
import Page_message from "../Page_message";
import "../../assets/styles/responsive.scss";
import { faCircle, faCircleDot } from "@fortawesome/pro-regular-svg-icons";
import ActualizarDireccionRegistro from "../../containers/direccion/ActualizarDireccionRegistro";
import Modal from "react-modal";
import DireccionInit from "../../containers/direccion/DireccionInit";
import LoaderSpinner from "../../widgets/loaderSpinner";

const customStyles = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "32",
  },
  content: {
    padding: "0px",
    border: "0px",
    background: "transparent",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "calc(100% - 32px)",
    overflow: "hidden",
  },
};

const Direccion = ({
  show_alert,
  redirect,
  cleadSwAlert,
  get_ubicacion,
  update_ubicacion,
  delete_ubicacion,
  locations,
  direccion,
  close_modal,
  peticion,
  isEditable = true,
  title = "Mis direcciones",
  get_sucursales_cerca,
}) => {
  const [addressToEdit, setAddressToEdit] = useState(null);
  const [openAddModal, setOpenAddModal] = useState(false);

  const handleOpenUpdateModal = (addressId) => {
    setAddressToEdit(addressId);
  };

  const handleCloseUpdateModal = () => {
    setAddressToEdit(null);
  };

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  useEffect(() => {
    if (show_alert && redirect !== null) {
      if (redirect.type === "modal" && !redirect.type_modal) {
        cleadSwAlert();
        if (!redirect.type_modal) {
          close_modal();
        }
      }
    }
  }, [show_alert]);

  useEffect(() => {
    if (locations.length == 0) {
      get_ubicacion();
    }
  }, []);

  useEffect(() => {
    return () => {
      cleadSwAlert();
    };
  }, []);
  const delete_registro = async (id) => {
    let resp = await Page_message.delete_register_confirmation();
    if (resp) {
      delete_ubicacion(id);
    }
  };

  const establecer_ubicacion_principal = async (locationp, e) => {
    if (validar_location(locationp) && direccion.Id !== locationp.Id) {
      let datos = {
        Descripcion: locationp.Descripcion,
        Direccion: locationp.Direccion,
        Referencia: locationp.Referencia,
        Latitud: locationp.Latitud,
        Longitud: locationp.Longitud,
        EsPrincipal: true,
        Id: locationp.Id,
      };
      let object = {
        type: "modal",
        type_modal: false,
        ruta: null,
      };
      try {
        let res = await update_ubicacion(datos, locationp.Id, object);
        await get_sucursales_cerca(locationp);
        await cleadSwAlert();
        await close_modal();
      } catch (error) {}
    }
  };

  const eliminarDireccion = async (locationp) => {
    if (direccion.Id !== locationp.Id) {
      delete_registro(locationp.Id);
    }
  };

  const validar_location = (locationp) => {
    return typeof locationp != "undefined" && locationp != null;
  };

  return (
    <>
      <div className="bg-white rounded-lg overflow-hidden w-full h-full md:max-h-[600px]">
        <div className="flex flex-col h-full">
          <div className="bg-hiperorange w-full text-white flex items-center justify-center  content_title relative">
            <h2 className="p-0 m-0 title_carrito_responsivo">{title}</h2>
            <div className="absolute right-2 ">
              <ButtonClose
                className="text-white bg-transparent"
                onClick={() => close_modal()}
              />
            </div>
          </div>

          <div className="bg-white overflow-y-auto flex flex-col h-full gap-2 p-3">
            {peticion && <LoaderSpinner bool={peticion} />}
            <div className="flex flex-column gap-2">
              {locations && locations !== null && locations !== undefined && (
                <>
                  {locations.length > 0 ? (
                    <>
                      {locations.map((location) => (
                        <div
                          key={location.Id}
                          className={classNames(
                            "flex items-center justify-between gap-2 px-2 mx-1 py-0.5 rounded-lg border-1 text-gray-800 cursor-pointer",
                            {
                              "bg-hiperorange border-hiperorange text-white ":
                                location.EsPrincipal,
                              "border-gray-800": !location.EsPrincipal,
                            }
                          )}
                          style={{ height: "fit-content" }}
                        >
                          <button
                            onClick={(e) =>
                              establecer_ubicacion_principal(location, e)
                            }
                            className="w-full truncate flex items-center py-1 gap-2"
                          >
                            <FontAwesomeIcon
                              icon={
                                location.EsPrincipal ? faCircleDot : faCircle
                              }
                            />
                            <div className="w-full text-left truncate text-sm md:text-base ">
                              {`${location.Descripcion}, ${location.Direccion}`}
                            </div>
                          </button>
                          {isEditable && (
                            <div className="flex gap-1 items-center">
                              <FontAwesomeIcon
                                icon={faPenToSquare}
                                className={classNames(
                                  "cursor-pointer hover:scale-110",
                                  {
                                    "text-white": location.EsPrincipal,
                                    "text-gray-800": !location.EsPrincipal,
                                  }
                                )}
                                onClick={() =>
                                  handleOpenUpdateModal(location.Id)
                                }
                              />
                              {direccion.Id !== location.Id && (
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className="cursor-pointer hover:scale-110"
                                  onClick={() => eliminarDireccion(location)}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="flex flex-col gap-4 justify-center items-center mt-2 min-h-60 text-gray-400">
                      <FontAwesomeIcon icon={faLocationDot} size="6x" />
                      <div className="text-xl">
                        No tienes direcciones agregadas
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="p-3 text-white">
            <ButtonText
              label="Añadir nueva dirección"
              principal={false}
              className="bg-hiperorange hover:bg-hiperorange_hover text-white text-sm md:text-base"
              onClick={handleOpenAddModal}
            />
          </div>
        </div>
      </div>
      <Modal
        isOpen={!!addressToEdit}
        ariaHideApp={false}
        onRequestClose={handleCloseUpdateModal}
        style={customStyles}
        contentLabel="update address"
      >
        <ActualizarDireccionRegistro
          locationId={addressToEdit}
          close={handleCloseUpdateModal}
        />
      </Modal>
      <Modal
        isOpen={openAddModal}
        ariaHideApp={false}
        onRequestClose={handleCloseAddModal}
        style={customStyles}
        contentLabel="create address"
      >
        <DireccionInit close={handleCloseAddModal} />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  locations: state.Location.locations,
  direccion: state.Location.direccion,
  show_alert: state.Swalerts.show_alert,
  redirect: state.Swalerts.redirect,
  peticion: state.Peticion.peticion,
});

export default connect(mapStateToProps, {
  get_ubicacion,
  update_ubicacion,
  delete_ubicacion,
  cleadSwAlert,
  get_sucursales_cerca,
})(Direccion);
