import Layout from "../hocs/Layout";
import { React, useEffect, useState } from "react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  get_producto_item,
  limpiar_item_producto,
} from "../redux/actions/producto";
import {
  actualizar_item_carrito,
  add_al_carrito,
  dec_al_carrito,
  inc_al_carrito,
  remove_al_carrito,
  set_peso_al_carrito,
} from "../redux/actions/carrito";
import {
  faCircleXmark,
  faHeart,
  faMinus,
  faPlus,
  faSquareMinus,
  faSquarePlus,
  faTag,
  faTrashCan,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import Modal from "react-modal";
import BackToPage from "../components/BackPage/BackToPage";
import FullScreenImage from "./../widgets/fullScreenImage";
import { useNavigate } from "react-router-dom";
import { set_all_prod_category } from "../redux/actions/home";
import Favorito from "../widgets/favoritoMycart";
import convertSlug from "../components/Utils/slug";
import "../../src/assets/styles/seccionResponsive.scss";
import Alert from "../components/Swalert";
import ButtonText from "../widgets/button_text";

const customStyles = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "1",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    borderRadius: "0.75rem",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "visible",
  },
};
const customStyles2 = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "100",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    borderRadius: "0.75rem",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "visible",
  },
};

const pesajes = [
  { name: "500g", peso: "500" },
  { name: "1Kg", peso: "1000" },
  { name: "2Kg", peso: "2000" },
  { name: "3Kg", peso: "3000" },
  { name: "5Kg", peso: "5000" },
  { name: "10Kg", peso: "10000" },
  { name: "15Kg", peso: "15000" },
  { name: "20Kg", peso: "20000" },
  { name: "25Kg", peso: "25000" },
  { name: "30Kg", peso: "30000" },
  { name: "35Kg", peso: "35000" },
  { name: "40Kg", peso: "40000" },
  { name: "45Kg", peso: "45000" },
  { name: "50Kg", peso: "50000" },
];

const style_favorito = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "31",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: "0",
    borderRadius: "0.75rem",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "auto",
  },
};
const breakpoints = {
  300: {
    slidesPerView: 1,
    spaceBetween: 5,
  },
  400: {
    slidesPerView: 1,
    spaceBetween: 5,
  },
  500: {
    slidesPerView: 1,
    spaceBetween: 5,
  },
  640: {
    slidesPerView: 1,
    spaceBetween: 5, //10
  },
  768: {
    slidesPerView: 2,
    spaceBetween: 5, //10
  },

  992: {
    slidesPerView: 2,
    spaceBetween: 5, //10
  },
  1200: {
    slidesPerView: 3,
    spaceBetween: 5, //10
  },
  1300: {
    slidesPerView: 3,
    spaceBetween: 5, //30
  },

  1400: {
    slidesPerView: 4,
    spaceBetween: 5, //30
  },
};
const InformacionProducto = ({
  limpiar_item_producto,
  get_producto_item,
  producto,
  carrito,
  sucursal,
  set_peso_al_carrito,
  add_al_carrito,
  inc_al_carrito,
  dec_al_carrito,
  remove_al_carrito,
  actualizar_item_carrito,
  tipo_entrega,
  direccion,
  producto_delete,
  set_all_prod_category,
  sucursales,
}) => {
  const { productoId } = useParams();
  const { ciudadp, sucursalp } = useParams();
  let subtitle;
  const [imagenes, guardarImagenes] = useState([]);
  const [pesos, guardarPesos] = useState([]);
  const [cantidad, guardarCantidad] = useState(0);
  const [monto, guardarMonto] = useState(0);
  const [hubo_cambios, setHuboCambios] = useState(false);
  const [modal, setModal] = useState(false);
  const [modal_favorito, setModalFavorito] = useState(false);
  const startModal = () => setModal(true);
  const endModal = () => setModal(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [anchoPantalla, setAnchoPantalla] = useState(window.innerWidth);
  const [sizeImagen, setSizeImagen] = useState("400x400");
  const [url_navigation, setUrl_navigation] = useState(null);

  const openFullScreen = () => {
    setShowFullScreen(true);
  };

  const openModalFavorito = () => setModalFavorito(true);
  const closeModalFavorito = () => setModalFavorito(false);

  const closeFullScreen = () => {
    //
    setShowFullScreen(false);
  };
  const handleClick = () => {
    setShowFullScreen(false);
  };

  const actualizarAnchoPantalla = () => {
    setAnchoPantalla(window.innerWidth);
  };

  const total = (monto * cantidad).toFixed(2);

  const descuento = _descuento();
  const estimado = _estimado();
  const total_estimado = _total_estimado();
  const cantidad_peso = _cantidad_peso();

  function _ahorrado() {
    let resultado = 0;
    if (producto) {
      let precio_venta = producto.ConOferta
        ? producto.PrecioOriginal
        : producto.PrecioVenta;

      if (_es_pieza()) {
        let cantidad_pieza = _estimado();
        let total_sin_oferta = cantidad_pieza * producto.PrecioOriginal;
        let total_oferta = cantidad_pieza * producto.PrecioVenta;
        resultado = total_sin_oferta - total_oferta;
      } else {
        resultado = precio_venta * cantidad - total;
      }
    }

    return resultado.toFixed(2);
  }
  const ahorrado = _ahorrado();
  function _descuento() {
    let resultado = 0;
    if (producto) {
      if (producto.ConOferta) {
        resultado =
          100 - (producto.PrecioOferta * 100) / producto.PrecioOriginal;
      }
    }
    return resultado.toFixed(1);
  }

  function _cantidad_peso() {
    let result = cantidad * 1000;
    return result.toFixed(0) + "g";
  }

  function _estimado() {
    let result = 0;
    if (_es_pieza()) {
      pesos.forEach((peso) => {
        result += peso.Piezas * peso.PesoAproxMax;
      });
    } else {
      result = cantidad;
    }
    return Number(result).toFixed(2);
  }

  function _total_estimado() {
    let result = 0;
    result = _es_pieza() ? monto * estimado : monto * cantidad;
    return result.toFixed(2);
  }

  function getIdsucursal(slug) {
    let seleccionado_id = null;
    sucursales.forEach((sucursal) => {
      let item = sucursal.Locatarios.find(
        (item) => convertSlug.data_slug(item.Descripcion) === slug
      );
      if (item) {
        seleccionado_id = item.IdSucursal;
      }
    });
    return seleccionado_id;
  }

  async function cargar_url_var() {
    let resp = ciudadp + " " + sucursalp;
    let url_parameters = await convertSlug.data_barra(resp);
    setUrl_navigation(url_parameters);
  }

  useEffect(() => {
    let sucursal_id = getIdsucursal(sucursalp);
    // cargar_url_var();
    get_producto_item(sucursal_id, productoId);
    //   get_producto_item(sucursal.IdSucursal, productoId);
  }, [sucursales]);
  useEffect(() => {
    if (ciudadp != null && sucursalp != null) {
      cargar_url_var();
    }
  }, [ciudadp, sucursalp]);

  useEffect(() => {
    return () => {
      limpiar_item_producto();

      window.addEventListener("resize", actualizarAnchoPantalla);
    };
  }, []);

  useEffect(() => {
    let cantidad = 0;
    pesos.forEach((peso) => {
      cantidad += peso.Piezas;
    });
    guardarCantidad(cantidad);
  }, [pesos]);
  useEffect(() => {
    if (cantidad === 0) {
      if (_es_pieza() && hubo_cambios) {
        remove_al_carrito(producto);
      }
    } else {
      if (_es_pieza() && hubo_cambios) {
        let pesos_aux = [];
        pesos.forEach((elem) => {
          if (elem.Piezas > 0) {
            pesos_aux.push(elem);
          }
        });
        set_peso_al_carrito(producto, cantidad, pesos_aux);
      }
    }
  }, [cantidad]);

  useEffect(() => {
    if (producto) {
      if (_es_pieza()) {
        _peso_definido();
      }
      let producto_carrito = carrito.find(
        (prd) => prd.IdProducto === producto.IdProducto
      );
      let monto_aux = producto.ConOferta
        ? producto.PrecioOferta
        : producto.PrecioVenta;
      guardarMonto(monto_aux);
      if (producto_carrito) {
        guardarCantidad(producto_carrito.Cantidad);
      }

      let fotos = [];
      if (producto.Fotos) {
        fotos = producto.Fotos;
      } else {
        let object = {
          orden: 1,
          Url: producto.UrlFoto,
        };
        fotos.push(object);
      }

      guardarImagenes(fotos);
    }
  }, [producto]);
  useEffect(() => {}, [carrito]);

  async function add_carrito() {
    let pesaje = 1;
    if (_es_peso()) {
      pesaje = 0.1;
    }
    await guardarCantidad(Number(cantidad) + pesaje);
    if (cantidad === 0) {
      add_al_carrito(producto, pesaje);
    } else {
      inc_al_carrito(producto);
    }
  }

  //cuando tiene array peso
  function _es_pieza() {
    if (producto) {
      return producto.Fraccionado && producto.PesoDefinido ? true : false;
    } else {
      return false;
    }
  }

  function _peso_definido() {
    let producto_carrito = carrito.find(
      (prd) => prd.IdProducto === producto.IdProducto
    );
    let pesos_aux = set_piezas_carrito(null);
    if (producto_carrito) {
      if (_es_pieza()) {
        pesos_aux = set_piezas_carrito(producto_carrito.Pesos);
      }
      guardarCantidad(producto_carrito.Cantidad);
    }
    guardarPesos(pesos_aux);
  }

  function actualizarDato(peso, operacion) {
    setHuboCambios(true);
    if (operacion === "incremento") {
      guardarPesos(
        pesos.map((ps) => {
          if (ps.IdPeso === peso.IdPeso) {
            return {
              ...ps,
              Piezas: Number(ps.Piezas) + 1,
            };
          } else {
            return ps;
          }
        })
      );
    } else if (operacion === "decremento") {
      guardarPesos(
        pesos.map((ps) => {
          if (ps.IdPeso === peso.IdPeso && ps.Piezas >= 1) {
            return {
              ...ps,
              Piezas: Number(ps.Piezas) - 1,
            };
          } else {
            return ps;
          }
        })
      );
    }
  }

  function set_piezas_carrito(pesos) {
    producto.Pesos.forEach((peso) => {
      let peso_aux = null;
      if (pesos) {
        peso_aux = pesos.find((ps) => ps.IdPeso === peso.IdPeso);
      }
      if (peso_aux) {
        peso.Piezas = peso_aux.Piezas;
      } else {
        peso.Piezas = 0;
      }
    });
    return producto.Pesos;
  }

  function inputchangePeso(e, peso) {
    const esValido = e.target.validity.valid;
    if (esValido) {
      let peso_value = e.target.value;
      if (peso_value) {
        guardarPesos(
          pesos.map((ps) => {
            if (ps.IdPeso === peso.IdPeso) {
              setHuboCambios(true);
              return {
                ...ps,
                Piezas: Number(e.target.value),
              };
            } else {
              return ps;
            }
          })
        );
      }
    }
  }

  //finaliza array peso su lógica
  //cuando es por pieza la logica
  function _es_peso() {
    if (producto) {
      return producto.Fraccionado && !producto.PesoDefinido ? true : false;
    } else {
      return false;
    }
  }

  function abrirModal() {
    startModal(true);
  }

  function actualizar_item(valor, operacion) {
    let resultado = 0;
    resultado = operacion == "incremento" ? cantidad + valor : cantidad - valor;
    if (resultado < 0) {
      return;
    }
    if (operacion == "incremento" && cantidad == 0) {
      guardarCantidad(resultado);
      add_al_carrito(producto, resultado);
    } else if (operacion == "decremento" && cantidad == valor) {
      guardarCantidad(resultado);
      remove_al_carrito(producto);
    } else {
      guardarCantidad(resultado);
      actualizar_item_carrito(producto, resultado);
    }
  }

  function set_peso_pieza(peso) {
    let resultado = peso.peso / 1000;
    guardarCantidad(resultado);
    actualizar_item_carrito(producto, resultado);
  }

  //end cuando es por pieza la logica

  function reducir_carrito() {
    if (cantidad === 1) {
      guardarCantidad(cantidad - 1);
      remove_al_carrito(producto);
    } else if (cantidad >= 1) {
      guardarCantidad(cantidad - 1);
      dec_al_carrito(producto);
    }
  }

  function inputchange(e) {
    const esValido = e.target.validity.valid;
    if (esValido) {
      let cant = e.target.value;
      if (cant) {
        if (producto.Fraccionado & !producto.PesoDefinido) {
        } else {
          guardarCantidad(cant);
          if (cant > 0) {
            add_al_carrito(producto, Number(cant));
          } else {
            remove_al_carrito(producto);
          }
        }
      }
    }
  }

  function afterOpenModal() {
    setTimeout(function () {
      subtitle.style.color = "blue";
    }, 2000);
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function show_subcategoria(cat_id, subcat_id) {
    navigate("/" + url_navigation + `/categoria/${cat_id}/${subcat_id}/`);
  }

  function show_categoria(cat_id) {
    navigate("/" + url_navigation + `/categoria/${cat_id}`);
  }

  function formatPrice(value) {
    let val = (value / 1).toFixed(2).replace(",", ".");
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const fueraFoco = () => {};

  const show_botones = () => {
    add_carrito();
  };
  return (
    <Layout>
      {producto && (
        <>
          <BackToPage
            ps_url_page={""}
            ps_contenerdor={"sinContenedor"}
          ></BackToPage>
          <Alert></Alert>
          <div className="container">
            <div className="contenedorInfo">
              <div className="columnaImagen  ">
                <div className="imagenes-vertical  ">
                  <Swiper
                    breakpoints={breakpoints}
                    pagination={true}
                    direction="vertical"
                    navigation={true}
                    modules={[Pagination]}
                    className="mySwiper2"
                  >
                    {imagenes &&
                      imagenes !== null &&
                      imagenes !== undefined &&
                      imagenes.map((foto, index) => (
                        <SwiperSlide key={"ima" + index}>
                          <div className="py-4">
                            <div className="flex justify-center">
                              <img
                                src={
                                  foto.Url +
                                  (anchoPantalla >= 770
                                    ? `&size=400x400`
                                    : "&size=120x120")
                                }
                                alt=""
                                onClick={openModal}
                              />
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}

                    <SwiperSlide>
                      <div className="py-4">
                        <div className="flex justify-center">
                          <img
                            src={
                              "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.pinterest.com%2Fandrecrislastra24%2Fim%25C3%25A1genes-random%2F&psig=AOvVaw2kzlxWAL3lC9ijGRBS5lA1&ust=1714852115450000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCPii6oyg8oUDFQAAAAAdAAAAABAE"
                            }
                            alt=""
                            onClick={openModal}
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
                <div className="imagen-grande  ">
                  <div className="header-corazon">
                    <div className="float-right">
                      <div className="relative inline">
                        <button
                          className="absolute right-2 top-2"
                          onClick={() => openModalFavorito()}
                        >
                          {producto.EnListadoCliente ? (
                            <FontAwesomeIcon
                              icon={faHeart}
                              className="text-hiperorange w-10 h-10"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faHeart}
                              className="hipergrisdark text-hipergrisdark w-10 h-10"
                            />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="body-imagen"></div>
                </div>
              </div>

              <div className="columnaInfoProd pl-2 ">
                <div className="style-categorias  ">
                  <div className="  px-0  py-3">
                    <div className="flex justify-start">
                      <div
                        className="bg-gray-400 px-2 py-1 font-bold text-white rounded mx-1"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          show_categoria(
                            convertSlug.data_slug(
                              producto.Categoria.Descripcion
                            )
                          )
                        }
                      >
                        {producto.Categoria.Descripcion}
                      </div>
                      <div
                        className="bg-gray-500 px-2  py-1 font-bold  text-white rounded mx-1"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          show_subcategoria(
                            convertSlug.data_slug(
                              producto.Categoria.Descripcion
                            ),
                            convertSlug.data_slug(
                              producto.SubCategoria.Descripcion
                            )
                          )
                        }
                      >
                        {producto.SubCategoria.Descripcion}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="style-name-producto style_letras  ">
                  {producto.Descripcion}
                </div>
                {producto.ConOferta && (
                  <div className="descuento-tag  py-2">
                    <div
                      className="px-2 bg-hiperorange text-white"
                      style={{
                        borderRadius: "20px",
                        width: "fit-content",
                        float: "left",
                      }}
                    >
                      <p className="mb-0 text-lg">
                        <div className="  d-inline-block py-2 px-2 rounded-3  ">
                          <FontAwesomeIcon
                            icon={faTag}
                            className="w-[1.5rem] h-[1.5rem] text-white pr-2"
                            style={{ borderRadius: "50%" }}
                          />

                          <span className="text-white font-semibold">
                            -{descuento}% Dcto.
                          </span>
                        </div>
                      </p>
                    </div>
                  </div>
                )}

                <div className="precio-tag  ">
                  <div className="texto-precio style-letra-info-prod">
                    {" "}
                    Precio por Und.
                  </div>
                  <div>
                    {" "}
                    <span className="valor-ahora-info-prod style-letra-info-prod">
                      {" "}
                      Bs. 66.00{" "}
                    </span>{" "}
                    <span className="valor-antes-info-prod style-letra-info-prod">
                      {" "}
                      Bs. 74.00
                    </span>{" "}
                  </div>
                </div>
                <div className="controles-add-minus py-2">
                  {!producto.Fraccionado && cantidad > 0 /* esnoremal*/ && (
                    <div className="flex align-items-end controles_info_prod">
                      <div className="flex">
                        {cantidad > 1 && (
                          <FontAwesomeIcon
                            icon={faSquareMinus}
                            className="w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer"
                            onClick={() => reducir_carrito()}
                          />
                        )}
                        {cantidad == 1 && (
                          <FontAwesomeIcon
                            icon={faTrashCan}
                            className="w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer"
                            onClick={() => reducir_carrito()}
                          />
                        )}

                        <div
                          className="mx-1 flex justify-center"
                          style={{ width: "100px" }}
                        >
                          <input
                            type="text"
                            className="w-100 h-[2rem] text-center outline-none mx-2 bg-hipergris rounded-lg px-2"
                            name="cantidad"
                            pattern="[0-9]{0,13}"
                            value={cantidad + " Und"}
                            onChange={(e) => inputchange(e)}
                          />
                        </div>

                        <FontAwesomeIcon
                          icon={faSquarePlus}
                          className="w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer"
                          onClick={() => add_carrito()}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {cantidad == 0 && (
                  <div className="bnt-agregar-agregar">
                    <ButtonText
                      type="submit"
                      label="Agregar"
                      principal={true}
                      className="bg-hiperorange  text-white mb-2 hover:bg-hiperorange_hover  w-7/12"
                      onClick={() => show_botones()}
                    />
                  </div>
                )}

                <div className="tag-agregar-listas text-hiperorange ">
                  <span
                    className="cursor-pointer hover:bg-hiperorange_hover "
                    onClick={() => openModalFavorito()}
                  >
                    {" "}
                    Agregar a tus listas de compras{" "}
                  </span>
                </div>
              </div>
            </div>

            <div className=" border-[1px] border-orange-500 rounded-xl p-4">
              <div className="  container_info_prod">
                <div className="col-sm-5 col-md-3 col-lg-3 col-xl-2 sm:px-0">
                  <Swiper
                    // breakpoints={breakpoints}
                    pagination={true}
                    modules={[Pagination]}
                    className="mySwiper"
                  >
                    {imagenes &&
                      imagenes !== null &&
                      imagenes !== undefined &&
                      imagenes.map((foto, index) => (
                        <SwiperSlide key={index}>
                          <div className="py-4">
                            <div className="flex justify-center">
                              <img
                                src={
                                  foto.Url +
                                  (anchoPantalla >= 770
                                    ? `&size=400x400`
                                    : "&size=120x120")
                                }
                                alt=""
                                onClick={openModal}
                              />
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </div>
                <div
                  className={classNames(
                    "col-sm-7",
                    "col-md-9",
                    "col-lg-9",
                    "col-xl-10",
                    "container_info_detalle_prod",
                    {
                      "flex align-items-center": !producto.ConOferta,
                    }
                  )}
                >
                  {producto.ConOferta && (
                    <div className="border-[1.5px] border-red-600 d-inline-block py-1 px-2 rounded-3 mb-2">
                      <FontAwesomeIcon
                        icon={faTag}
                        className="w-[1.5rem] h-[1.5rem] text-red-600 pr-2"
                        style={{ borderRadius: "50%" }}
                      />

                      <span className="text-red-600 font-semibold">
                        Desc.: -{descuento}%
                      </span>
                    </div>
                  )}
                  <div className="bg-[#EEEEEE] rounded-xl p-2 w-100 overflow-auto">
                    <div className="flex justify-between">
                      <div>
                        <h6 className="text-gray-500 font-bold">
                          {producto.Descripcion}
                        </h6>
                      </div>
                      <div className="float-right">
                        <div className="relative inline">
                          <button
                            className="absolute right-2 top-2"
                            onClick={() => openModalFavorito()}
                          >
                            {producto.EnListadoCliente ? (
                              <FontAwesomeIcon
                                icon={faHeart}
                                className="text-hiperorange w-7 h-7"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faHeart}
                                className="hipergrisdark text-hipergrisdark w-7 h-7"
                              />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between">
                      {!producto.PesoDefinido && <p>{producto.Descripcion}</p>}
                      <div></div>
                      {_es_pieza() /* espieza*/ ? (
                        <div className="flex flex-col">
                          {pesos.map((peso, index) => (
                            <div
                              className=" flex  mt-2 ml-auto w-full bg-white mb-2"
                              key={index}
                            >
                              <div className="bg-white h-[2rem] px-2 rounded-l flex align-items-center">
                                <span className="text-gray-500 ml-auto">
                                  Unidad {peso.PesoAproxMin} -{" "}
                                  {peso.PesoAproxMax} kg
                                </span>
                              </div>
                              <div className="flex ml-auto align-items-center">
                                <FontAwesomeIcon
                                  icon={faSquareMinus}
                                  className="w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer"
                                  onClick={() =>
                                    actualizarDato(peso, "decremento")
                                  }
                                />
                                <div className="w-8 ">
                                  <input
                                    type="text"
                                    className="w-full h-[2rem] text-center outline-none"
                                    name="cantidad"
                                    pattern="[0-9]{0,13}"
                                    value={peso.Piezas}
                                    onChange={(e) => inputchangePeso(e, peso)}
                                  />
                                </div>
                                <FontAwesomeIcon
                                  icon={faSquarePlus}
                                  className="w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer"
                                  onClick={() =>
                                    actualizarDato(peso, "incremento")
                                  }
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className=" container_det_prod_last_row">
                      <div className="flex border-t-4 border-white pt-2">
                        <div className="px-2 mx-2">
                          <p className="mb-0 font-bold">Precio Por Kg</p>
                          {producto.ConOferta ? (
                            <p className="mb-0">
                              {producto.Moneda}{" "}
                              {formatPrice(producto.PrecioOferta)}
                              <del className="text-zinc-400 text-sm px-2">
                                {formatPrice(producto.PrecioOriginal)}
                              </del>
                            </p>
                          ) : (
                            <p className="mb-0">
                              {producto.Moneda}{" "}
                              {formatPrice(producto.PrecioVenta)}
                            </p>
                          )}
                        </div>
                        <div className="px-2 mx-2">
                          <p className="mb-0 font-bold">
                            {producto.Fraccionado
                              ? "Peso Estimado"
                              : "Cantidad"}
                          </p>
                          <p className="mb-0">{estimado} KG</p>
                        </div>
                        <div className="px-2 mx-2">
                          <p className="mb-0 font-bold">
                            {producto.Fraccionado ? "Total Estimado" : "Total"}
                          </p>
                          <p className="mb-0">
                            {producto.Moneda} {total_estimado}
                          </p>
                        </div>
                        {producto.ConOferta && (
                          <div className="bg-hiperorange text-white px-2 mx-2 rounded texto_ahorrado_info_prod">
                            <p className="mb-0 font-bold">Ahorrado</p>
                            <p className="mb-0">Bs. {ahorrado}</p>
                          </div>
                        )}
                      </div>
                      {!producto.Fraccionado /* esnoremal*/ && (
                        <div className="flex align-items-end controles_info_prod">
                          <div className="flex">
                            ....
                            <FontAwesomeIcon
                              icon={faSquareMinus}
                              className="w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer"
                              onClick={() => reducir_carrito()}
                            />
                            <div className="w-8">
                              <input
                                type="text"
                                className="w-100 h-[2rem] text-center outline-none"
                                name="cantidad"
                                pattern="[0-9]{0,13}"
                                value={cantidad}
                                onChange={(e) => inputchange(e)}
                              />
                            </div>
                            <FontAwesomeIcon
                              icon={faSquarePlus}
                              className="w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer"
                              onClick={() => add_carrito()}
                            />
                          </div>
                        </div>
                      )}
                      {_es_peso() /* espeso*/ ? (
                        <div className="flex align-items-end controles_info_prod">
                          <div className="flex">
                            <FontAwesomeIcon
                              icon={faSquareMinus}
                              className="w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer"
                              onClick={() => actualizar_item(0.1, "decremento")}
                            />
                            <div
                              className=" flex justify-center align-items-center bg-white cursor-pointer"
                              onClick={() => abrirModal()}
                            >
                              <span>{cantidad_peso}</span>
                            </div>
                            <FontAwesomeIcon
                              icon={faSquarePlus}
                              className="w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer"
                              onClick={() => actualizar_item(0.1, "incremento")}
                            />
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            isOpen={modalIsOpen}
            appElement={document.getElementById("root")}
            onRequestClose={closeModal}
            style={customStyles2}
            contentLabel="Example Modal"
            key={"keyModal"}
          >
            <FullScreenImage
              imagenes={imagenes}
              onClose={closeFullScreen}
              tiene_varias_imagenes={"si"}
              imagen={null}
            ></FullScreenImage>
          </Modal>
          <Modal
            isOpen={modal}
            ariaHideApp={false}
            onRequestClose={endModal}
            style={customStyles}
            contentLabel="address"
          >
            <div className="w-80 relative">
              <div className="card border-0 relative">
                <div className="absolute top-[-30px] right-[-2rem]">
                  <FontAwesomeIcon
                    icon={faCircleXmark}
                    className="w-[2.5rem] h-[2.5rem] rounded-full text-hiperorange hover:text-hiperorange_hover bg-white cursor-pointer "
                    onClick={() => endModal()}
                  />
                </div>
                <div className="text-center">
                  <h5 className="font-bold">Seleccione el Peso</h5>
                </div>
                <div className="card-body pt-0 ">
                  <div className="row">
                    {pesajes.map((pesaje, index) => (
                      <div className="col-6" key={index}>
                        <div
                          className="bg-[#EEEEEE] m-2 px-3 py-2 rounded text-center cursor-pointer hover:bg-gray-400"
                          onClick={() => set_peso_pieza(pesaje)}
                        >
                          <span className="font-semibold text-xl">
                            {pesaje.name}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="border-t-2 border-[#EEEEEE] p-2 flex justify-center">
                  <div className="flex border-[3px] border-hiperorange p-1 rounded">
                    <button
                      className="w-8 h-8 bg-hiperorange hover:bg-hiperorange_hover text-white rounded flex items-center justify-center"
                      onClick={() => actualizar_item(0.1, "decremento")}
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </button>
                    <div className="mx-2 flex align-items-center">
                      <span>{cantidad_peso}</span>
                    </div>
                    <button
                      className="w-8 h-8 bg-hiperorange hover:bg-hiperorange_hover text-white rounded flex items-center justify-center"
                      onClick={() => actualizar_item(0.1, "incremento")}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={modal_favorito}
            ariaHideApp={false}
            onRequestClose={closeModalFavorito}
            style={style_favorito}
            contentLabel="favorito"
          >
            <Favorito
              idProducto={producto.IdProducto}
              IdSucursal={sucursal.IdSucursal}
              cantidad={cantidad}
              pesos={pesos}
              onBlur={fueraFoco}
              delete_producto={false}
            ></Favorito>
          </Modal>
        </>
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  carrito: state.Carrito.carrito,
  producto: state.Producto.producto,
  sucursal: state.BranchOffice.sucursal,
  sucursales: state.BranchOffice.sucursales,
  tipo_entrega: state.BranchOffice.tipo_entrega,
  direccion: state.Location.direccion,
  producto_delete: state.Carrito.producto_delete,
});

export default connect(mapStateToProps, {
  limpiar_item_producto,
  get_producto_item,
  set_peso_al_carrito,
  add_al_carrito,
  inc_al_carrito,
  dec_al_carrito,
  remove_al_carrito,
  actualizar_item_carrito,
  set_all_prod_category,
})(InformacionProducto);
