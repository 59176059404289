import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import "../../../assets/styles/globalColorPrincipal.css";
import "../../../assets/styles/responsive.scss";
import { reset_activate_sin_autologin, reset_social_user_data } from "../../../redux/actions/auth";
import Registro_user from "./registro_user";
import Registro_activate from "./registro_activate";
import Termino_condicion from "./termino_condicion";

const customStyles2 = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "31",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    border: "none",
    background: "none",
    padding: "0px 10px 10px 10px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
};

function RegistroInit({
  profile,
  setCloseModal,
  reset_activate_sin_autologin,
  reset_social_user_data
}) {
  const { ciudadp, sucursalp } = useParams();
  const [es_anonimo, setEsAnonimo] = useState(false);
  const [posicion, setPosicion] = useState(1);
  const [modalRegistro, setModalRegistro] = useState(true);
  useEffect(() => {
    if (profile) {
      if (!profile.Telefono) {
        setEsAnonimo(true);
        return;
      } else {
        setEsAnonimo(false);
      }
    }
  }, [profile]);

  const endModalRegistro = () => {
    setModalRegistro(false);
    setCloseModal(true);
    reset_activate_sin_autologin();
    reset_social_user_data();
    if (!verifyParametroUrl()) {
      //   window.history.back();
    }
  };
  function verifyParametroUrl() {
    if (sucursalp == "" && ciudadp == "") {
      return true;
    }
    return false;
  }
  async function cambiar_posicion(numero) {
    setPosicion(numero);
  }

  return (
    <Modal
      isOpen={modalRegistro}
      ariaHideApp={false}
      onRequestClose={endModalRegistro}
      style={customStyles2}
      contentLabel="Ingresar"
      id="theIdRegister"
    >
      {posicion === 1 && (
        <Registro_user
          ps_posicion={cambiar_posicion}
          ps_endModalRegistro={endModalRegistro}
        ></Registro_user>
      )}
      {posicion === 2 && (
        <Registro_activate
          ps_posicion={cambiar_posicion}
          ps_endModalRegistro={endModalRegistro}
        ></Registro_activate>
      )}
      {posicion === 3 && (
        <Termino_condicion
          ps_posicion={cambiar_posicion}
          ps_endModalRegistro={endModalRegistro}
        ></Termino_condicion>
      )}
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  profile: state.Profile.profile,
});

export default connect(mapStateToProps, {
  reset_activate_sin_autologin,
  reset_social_user_data
})(RegistroInit);
