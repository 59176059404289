import { faSearch, faX } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

export function ListaSucursalSearch({handleSearchTerm}) {
  const [searchTerm, setSearchTerm] = useState("");


  const handleOnSubmit = (event) => {
    event.preventDefault();
    handleSearchTerm(searchTerm);
  };

  const resetSearchTerm = () => {
    setSearchTerm("");
    handleSearchTerm("");
  };

  return (
    <div className="w-full">
      <form
        onSubmit={handleOnSubmit}
        className="relative flex w-full flex-wrap items-stretch "
      >
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Buscar sucursal"
          className="w-full py-2 placeholder-slate-400 text-slate-600 relative  bg-gray-200 rounded-lg text-sm border-1 border-gray-500  focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange pl-2 pr-11"
          style={{ paddingRight: "2rem", paddingLeft: "0.5rem" }}
        />
        {!!searchTerm.trim() && (
          <div className="z-10 absolute right-10 h-full flex items-center">
            <button
              onClick={resetSearchTerm}
              type="button"
              className="top-2 flex items-center justify-center w-6 h-6 rounded-full bg-white hover:bg-gray-50 hover:shadow-md"
            >
              <FontAwesomeIcon size="xs" icon={faX} />
            </button>
          </div>
        )}
        <button
          onClick={handleOnSubmit}
          type="submit"
          className="z-10 absolute right-0 flex items-center justify-center w-8 h-full bg-hiperorange rounded-r-lg text-white"
        >
          <FontAwesomeIcon icon={faSearch} />
        </button>
      </form>
    </div>
  );
}
