import classNames from "classnames";
import React from "react";
import { ColorRing } from "react-loader-spinner";
import { connect } from "react-redux";

const ButtonText = ({
  type = "button",
  label,
  className,
  onClick,
  peticion,
  peticion_label,
  principal = false,
  disabled = false
}) => {

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={peticion || disabled}
      style={peticion ? { cursor: "not-allowed" } : {}}
      className={classNames(
        "flex justify-center items-center py-2 px-4 w-full rounded-lg hover:text-white hover:bg-[#4645e6]  transition-colors duration-300 font-medium",
        className
      )}
    >
      {principal && peticion ? (
        <>
          <span className="mr-2">
            <ColorRing
              colors={["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"]}
              width={25}
              height={25}
              ariaLabel="circles-loading"
            />
          </span>
          <span>{peticion_label !== "" ? peticion_label : label}</span>
        </>
      ) : (
        <span>{label}</span>
      )}
    </button>
  );
};

const mapStateToProps = (state) => ({
  peticion: state.Peticion.peticion,
  peticion_label: state.Peticion.peticion_label,
});

export default connect(mapStateToProps, {})(ButtonText);
