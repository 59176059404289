import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Layout from "../../../../hocs/Layout";
import LoaderSpinner from "../../../../widgets/loaderSpinner";
import { createSweetAlertNotificationService } from "../../../notifications/infrastructure/sweet-alert-notification-service";
import { FormStatus } from "../../../shared/domain/enums/form-status";
import { CustomerBagHistoryGetParams } from "../../domain/dto/customer-bag-history-get-params";
import { createApiHiperPuntosRepository } from "../../infrastructure/api-hiper-puntos-repository";
import { CustomerBagSearch } from "../components/customer-bag/customer-bag-search";
import { useGetCustomerBagHistory } from "../hooks/useGetCustomerBagHistory";
import { CustomerBagHistoryCard } from "../components/customer-bag-history/customer-bag-history-card";
import { CustomerBagHistoryPointCard } from "../components/customer-bag-history/customer-bag-history-point-card";
import InfiniteScroll from "react-infinite-scroll-component";
import useScreenSize from "../../../../widgets/useScreenSize";
import { useGetCustomerBagHistoryData } from "../hooks/useGetCustomerBagHistoryData";
import { HiperPuntosContainer } from "../containers/hiper-puntos-container";
import { useIsDefaultStore } from "../../../../hooks/useIsDefaultStore";
const hiperPuntosRepository = createApiHiperPuntosRepository();
const notificationService = createSweetAlertNotificationService();

export function CustomerBagHistoryPage() {
  const { idBolsa } = useParams();
  const { width } = useScreenSize();
  const sucursal = useSelector((state: any) => state.BranchOffice.sucursal);
  const { isDefaultStore } = useIsDefaultStore();


  const [customerBagHistoryGetParams, setCustomerBagHistoryGetParams] =
    useState<CustomerBagHistoryGetParams>({
      idBolsaPunto: Number(idBolsa),
      page: 1,
      pageSize: 10,
    });
  const { customerBagHistory, customerBagHistoryInfo, getCustomerBagHistory } =
    useGetCustomerBagHistory(hiperPuntosRepository, notificationService);
  const { customerBagPoints } = useGetCustomerBagHistoryData(
    customerBagHistory?.Historico
  );
  useEffect(() => {
    getCustomerBagHistory(customerBagHistoryGetParams);
  }, [customerBagHistoryGetParams]);

  const updatePage = () => {
    setCustomerBagHistoryGetParams((prevState) => ({
      ...prevState,
      page: prevState.page + 1,
    }));
  };

  return (
    <>
      <Helmet>
        <title>{`${
          !!customerBagHistoryInfo
            ? `${customerBagHistoryInfo.DescripcionBolsa} | `
            : ""
        }Hiper Puntos${
          !isDefaultStore && sucursal?.Descripcion ? ` | ${sucursal?.Descripcion}` : " | Hipermaxi"
        }`}</title>
        {/* <meta name="“robots”" content="noindex" /> */}
      </Helmet>
      <Layout>
        <HiperPuntosContainer>
          <div className="pt-4 z-10">
            <div className="container w-full md:w-[40rem] flex flex-col gap-3">
              <div className="flex flex-col">
                <h1 className="text-2xl md:text-3xl">Hiper puntos</h1>
                {!!customerBagHistoryInfo && (
                  <CustomerBagHistoryCard
                    descripcionBolsa={customerBagHistoryInfo.DescripcionBolsa}
                    saldoPuntos={customerBagHistoryInfo.SaldoPuntos}
                  />
                )}
              </div>

              <div className="flex w-full flex-col pb-4 justify-center items-center h-full">
                <div className="w-full h-full md:h-[33rem] md:border bg-white md:shadow-md rounded-xl">
                  <div className="flex justify-between flex-col h-full">
                    <div
                      id="scrollableDiv"
                      className="flex flex-col h-full md:overflow-y-auto min-h-[15rem] md:p-3"
                    >
                      {!!customerBagHistory && (
                        <>
                          {customerBagPoints.length > 0 ? (
                            <InfiniteScroll
                              scrollableTarget={
                                width >= 768 ? "scrollableDiv" : undefined
                              }
                              dataLength={
                                customerBagHistory.Historico.Page *
                                customerBagHistory.Historico.PageSize
                              } //This is important field to render the next data
                              next={updatePage}
                              hasMore={
                                customerBagPoints.length <
                                customerBagHistory.Historico.totalRecords
                              }
                              loader={<LoaderSpinner bool={true} />}
                              endMessage={
                                <p className="text-center my-2">
                                  <b>No hay más items para mostrar</b>
                                </p>
                              }
                            >
                              <div className="grid grid-cols-1 gap-2 w-full">
                                {customerBagPoints.map((customerBagPoint) => (
                                  <CustomerBagHistoryPointCard
                                    key={customerBagPoint.Id}
                                    {...customerBagPoint}
                                  />
                                ))}
                              </div>
                            </InfiniteScroll>
                          ) : (
                            <div className="col-span-2 flex flex-col gap-4 justify-center items-center mt-2 min-h-60 text-gray-400">
                              <FontAwesomeIcon
                                icon={faExclamationCircle}
                                size="6x"
                              />
                              <div className="text-xl text-center">
                                {
                                  "¡Aún no tienes transacciones! Haz tu primera compra para empezar a acumular puntos y canjear premios."
                                }
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </HiperPuntosContainer>
      </Layout>
    </>
  );
}
