import Layout from "../../hocs/Layout";
import { React, useEffect, useState } from "react";
import { FreeMode, Mousewheel, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { get_producto_item } from "../../redux/actions/producto";
import "../../../src/assets/styles/seccionResponsive.scss";
import "../../../src/assets/styles/seccionResponsiveSwiper.scss";
import {
  get_detalle_lista_compra,
  actualizar_item_carrito,
  inc_al_carrito_lista_compra,
  dec_al_carrito_lista_compra,
  store_carrito_lista_compra,
  actualizar_detalles_lista_compra,
  actualizar_producto_pieza,
  limpiar_data_lista_compra,
} from "../../redux/actions/shoppingList";
import {
  faCircleXmark,
  faMinus,
  faPlus,
  faSquareMinus,
  faSquarePlus,
  faTag,
  faTrashCan,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import Modal from "react-modal";
import FullScreenImage from "../../widgets/fullScreenImage";
import ButtonText from "../../widgets/button_text";

const customStyles = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "1",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    borderRadius: "0.75rem",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "visible",
  },
};

const pesajes = [
  { name: "500g", peso: "500" },
  { name: "1Kg", peso: "1000" },
  { name: "2Kg", peso: "2000" },
  { name: "3Kg", peso: "3000" },
  { name: "5Kg", peso: "5000" },
  { name: "10Kg", peso: "10000" },
  { name: "15Kg", peso: "15000" },
  { name: "20Kg", peso: "20000" },
  { name: "25Kg", peso: "25000" },
  { name: "30Kg", peso: "30000" },
  { name: "35Kg", peso: "35000" },
  { name: "40Kg", peso: "40000" },
  { name: "45Kg", peso: "45000" },
  { name: "50Kg", peso: "50000" },
];

const customStyles2 = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "100",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    borderRadius: "0.75rem",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "visible",
  },
};

const ProductoPieza = ({
  get_detalle_lista_compra,
  store_carrito_lista_compra,
  get_producto_item,
  producto,
  carrito_lista_compra,

  sucursal,
  inc_al_carrito_lista_compra,
  dec_al_carrito_lista_compra,
  actualizar_item_carrito,
  tipo_entrega,
  direccion,
  producto_delete,
  shopping_list_detalle,
  actualizar_detalles_lista_compra,
  actualizar_producto_pieza,
  productos_delete_lista_compra,
  estado_cambios,
  limpiar_data_lista_compra,
  profile,
}) => {
  const { productoId, listaCompraId } = useParams();

  let subtitle;
  const [imagenes, guardarImagenes] = useState([]);
  const [pesos, guardarPesos] = useState([]);
  const [pesos_axuliar, guardarPesosAuxiliar] = useState([]);
  const [cantidad, guardarCantidad] = useState(0);
  const [monto, guardarMonto] = useState(0);
  const [hubo_cambios, setHuboCambios] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [modal, setModal] = useState(false);
  const startModal = () => setModal(true);
  const endModal = () => setModal(false);

  const [verifyCambio, setVerifyCambio] = useState(false);

  const total = (monto * cantidad).toFixed(2);

  const [imagengrande, setImagengrande] = useState(null);
  const [index_imagen, setIndex_imagen] = useState(1);
  const [modal_favorito, setModalFavorito] = useState(false);
  const [anchoPantalla, setAnchoPantalla] = useState(window.innerWidth);
  const [swiperRef, setSwiperRef] = useState(null);
  const actualizarAnchoPantalla = () => {
    setAnchoPantalla(window.innerWidth);
  };

  const _es_pieza = () => {
    if (producto) {
      return producto.Fraccionado && producto.PesoDefinido ? true : false;
    } else {
      return false;
    }
  };
  const closeFullScreen = () => {
    setShowFullScreen(false);
  };
  useEffect(() => {
    return () => {
      window.addEventListener("resize", actualizarAnchoPantalla);
    };
  }, []);
  useEffect(() => {
    if (imagenes.length > 0) {
      setImagengrande(imagenes[0].Url);
    }
  }, [imagenes]);

  const _descuento = () => {
    let resultado = 0;
    if (producto) {
      if (producto.ConOferta) {
        resultado =
          100 - (producto.PrecioOferta * 100) / producto.PrecioOriginal;
      }
    }
    return resultado.toFixed(1);
  };
  const descuento = _descuento();

  const _cantidad_peso = () => {
    let result = cantidad * 1000;
    return result.toFixed(0) + "g";
  };
  const cantidad_peso = _cantidad_peso();

  const _estimado = () => {
    let result = 0;
    if (_es_pieza()) {
      pesos.forEach((peso) => {
        result += peso.Piezas * peso.PesoAproxMax;
      });
    } else {
      result = cantidad;
    }
    return result.toFixed(2);
  };
  const estimado = _estimado();

  const _ahorrado = () => {
    let resultado = 0;
    if (producto) {
      let precio_venta = producto.ConOferta
        ? producto.PrecioOriginal
        : producto.PrecioVenta;

      if (_es_pieza()) {
        let precio_venta_p = producto.ConOferta
          ? producto.PrecioOferta
          : producto.PrecioVenta;

        let cantidad_pieza = _estimado();
        let total_sin_oferta = cantidad_pieza * producto.PrecioOriginal;
        let total_oferta = cantidad_pieza * precio_venta_p;
        resultado = total_sin_oferta - total_oferta;
      } else {
        resultado = precio_venta * cantidad - total;
      }
    }
    return resultado.toFixed(2);
  };
  const ahorrado = _ahorrado();

  const _total_estimado = () => {
    let result = 0;
    result = _es_pieza() ? monto * estimado : monto * cantidad;
    return result.toFixed(2);
  };
  const total_estimado = _total_estimado();

  useEffect(() => {
    get_producto_item(sucursal.IdSucursal, productoId);
  }, []);
  useEffect(() => {
    if (shopping_list_detalle === null) {
      fetchData();
    }
  }, [shopping_list_detalle]);

  useEffect(() => {
    let cantidad = 0;
    pesos.forEach((peso) => {
      cantidad += peso.Piezas;
    });
    guardarCantidad(cantidad);

    verifyCambioPieza();
  }, [pesos]);
  useEffect(() => {
    if (cantidad === 0) {
      if (_es_pieza() && hubo_cambios) {
        //  remove_al_carrito(producto);
      }
    } else {
      if (_es_pieza() && hubo_cambios) {
        let pesos_aux = [];
        pesos.forEach((elem) => {
          if (elem.Piezas > 0) {
            pesos_aux.push(elem);
          }
        });
        // set_peso_al_carrito(producto, cantidad, pesos_aux);
      }
    }
  }, [cantidad]);

  useEffect(() => {
    if (producto && carrito_lista_compra.length > 0) {
      if (_es_pieza()) {
        _peso_definido();
      }
      let producto_carrito = carrito_lista_compra.find(
        (prd) => prd.IdProducto === producto.IdProducto
      );
      let monto_aux = producto.ConOferta
        ? producto.PrecioOferta
        : producto.PrecioVenta;
      guardarMonto(monto_aux);
      if (producto_carrito) {
        guardarCantidad(producto_carrito.Cantidad);
      }
      guardarImagenes(producto.Fotos ?? []);
    }
  }, [producto, carrito_lista_compra]);

  const fetchData = async () => {
    try {
      const parametro = { Id: listaCompraId, Sucursal: sucursal.IdSucursal };
      await get_detalle_lista_compra(parametro);
    } catch (e) {}
  };

  useEffect(() => {
    return () => {
      guardarBD1();
    };
  }, []);

  const guardarBD1 = async () => {
    let listaux = JSON.parse(localStorage.getItem("item_lista_compra"));

    const datos = {
      Id: listaux.Id,
      Descripcion: listaux.Descripcion,
      Color: listaux.Color,
    };
    let IdSucursal = sucursal.IdSucursal;
    let productoaux =
      JSON.parse(localStorage.getItem("carrito_lista_compra")) ?? [];
    let prod_delete =
      JSON.parse(localStorage.getItem("productos_delete_lista_compra")) ?? [];
    await actualizar_detalles_lista_compra(
      datos,
      productoaux,
      prod_delete,
      IdSucursal
    );
    await limpiar_data_lista_compra();
  };

  const add_carrito = async () => {
    let pesaje = 1;
    if (_es_peso()) {
      pesaje = 0.1;
    }
    await guardarCantidad(Number(cantidad) + pesaje);
    if (cantidad == 0) {
      // add_al_carrito(producto, pesaje);
    } else {
      inc_al_carrito_lista_compra(producto);
    }
  };

  //cuando tiene array peso pieza_pieza

  //INIT CARGAR PESOS DE PIEZA DEL PRODUCTO
  const _peso_definido = () => {
    let producto_carrito = carrito_lista_compra.find(
      (prd) => prd.IdProducto === producto.IdProducto
    );
    // let pesos_aux = set_piezas_carrito(null);
    let pesos_aux = [];
    if (producto_carrito) {
      pesos_aux = set_piezas_carrito(producto_carrito.Pesos);

      guardarCantidad(producto_carrito.Cantidad);
    }
    guardarPesos(pesos_aux);
    guardarPesosAuxiliar(pesos_aux);
  };

  const verifyCambioPieza = () => {
    let cantidad_axu = 0;
    let cantidad_act = 0;
    pesos_axuliar.map((pso) => {
      cantidad_axu += pso.Piezas;
    });
    pesos.map((ps) => {
      cantidad_act += ps.Piezas;
    });

    for (let index = 0; index < pesos.length; index++) {
      const element = pesos[index];
      if (!array_axuliar(element.IdPeso, element.Piezas)) {
        setVerifyCambio(true);
        break;
      } else {
        setVerifyCambio(false);
      }
    }
  };

  const array_axuliar = (id, pieza) => {
    let peso_aux = pesos_axuliar.find((ps) => ps.IdPeso === id);
    if (peso_aux.Piezas === pieza) {
      return true;
    } else {
      return false;
    }
  };

  const actualizarDato = async (peso, operacion) => {
    setHuboCambios(true);

    if (operacion === "incremento") {
      guardarPesos(
        pesos.map((ps) => {
          if (ps.IdPeso === peso.IdPeso) {
            return {
              ...ps,
              Piezas: ps.Piezas + 1,
            };
          } else {
            return ps;
          }
        })
      );
    } else if (operacion === "decremento") {
      guardarPesos(
        pesos.map((ps) => {
          if (ps.IdPeso === peso.IdPeso && ps.Piezas >= 1) {
            return {
              ...ps,
              Piezas: ps.Piezas - 1,
            };
          } else {
            return ps;
          }
        })
      );
    }

    await actualizar_producto_pieza(productoId, peso, operacion);
  };

  const set_piezas_carrito = (pesos) => {
    producto.Pesos.forEach((peso) => {
      let peso_aux = null;
      if (pesos) {
        peso_aux = pesos.find((ps) => ps.IdPeso === peso.IdPeso);
      }
      if (peso_aux) {
        peso.Piezas = peso_aux.Piezas;
      } else {
        peso.Piezas = 0;
      }
    });
    return producto.Pesos;
  };

  const inputchangePeso = async (cant_peso, peso) => {
    let cant = Number(cant_peso);
    guardarPesos(
      pesos.map((ps) => {
        if (ps.IdPeso === peso.IdPeso) {
          // setHuboCambios(true);
          return {
            ...ps,
            Piezas: cant,
          };
        } else {
          return ps;
        }
      })
    );
    let operacion = "definido";
    await actualizar_producto_pieza(productoId, peso, operacion, cant);
  };

  //finaliza array peso su lógica
  //cuando es por pieza la logica
  const _es_peso = () => {
    if (producto) {
      return producto.Fraccionado && !producto.PesoDefinido ? true : false;
    } else {
      return false;
    }
  };

  const abrirModal = () => {
    startModal(true);
  };

  const actualizar_item = (valor, operacion) => {
    let resultado = 0;
    resultado = operacion == "incremento" ? cantidad + valor : cantidad - valor;
    if (operacion == "incremento" && cantidad == 0) {
      guardarCantidad(resultado);
      // add_al_carrito(producto, resultado);
    } else if (operacion == "decremento" && cantidad == valor) {
      guardarCantidad(resultado);
      //  remove_al_carrito(producto);
    } else {
      guardarCantidad(resultado);
      actualizar_item_carrito(producto, resultado);
    }
  };

  const set_peso_pieza = (peso) => {
    let resultado = peso.peso / 1000;
    guardarCantidad(resultado);
    actualizar_item_carrito(producto, resultado);
  };

  //end cuando es por pieza la logica

  const reducir_carrito = () => {
    guardarCantidad(cantidad - 1);
    if (cantidad == 1) {
      // remove_al_carrito(producto);
    } else {
      dec_al_carrito_lista_compra(producto);
    }
  };

  const inputchange = (cantidad) => {
    if (producto.Fraccionado & !producto.PesoDefinido) {
    } else {
      guardarCantidad(cantidad);
      if (cantidad > 0) {
        // add_al_carrito(producto, Number(cantidad));
      } else {
        // remove_al_carrito(producto);
      }
    }
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const formatPrice = (value) => {
    let val = (value / 1).toFixed(2).replace(",", ".");
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleSlideChange = (swiper) => {
    const currentSlideIndex = swiper.activeIndex + 1; // Adjust for 0-based indexing

    setIndex_imagen(currentSlideIndex);
  };

  const selec_imagen = (foto, index) => {
    setImagengrande(foto);
    swiperRef.slideTo(index, 0);
  };

  const show_botones = () => {
    add_carrito();
    if (_es_pieza()) {
      //  actualizarDato(peso, "incremento");
    }
  };

  return (
    <Layout>
      {producto && (
        <>
          <div className="container mt-4">
            <div className="header-responsive-categorias   ">
              <div className="  px-0  py-3">
                <div className="flex justify-start">
                  <div
                    className="bg-gray-400 font-bold text-white rounded mr-1"
                    style={{ cursor: "pointer", padding: "10px 8px 10px 8px" }}
                  >
                    {producto.Categoria.Descripcion}
                  </div>
                  <div
                    className="bg-gray-500   font-bold  text-white rounded mx-1"
                    style={{ cursor: "pointer", padding: "10px 8px 10px 8px" }}
                  >
                    {producto.SubCategoria.Descripcion}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="container-favorito-resposive">

                            <div className="header-corazon-responsive">
                                <div className="float-right">
                                    <div className="relative inline">
                                        <button
                                            className="absolute right-2 top-2"
                                            onClick={() => openModalFavorito()}
                                        >
                                            {producto.EnListadoCliente ? (
                                                <FontAwesomeIcon
                                                    icon={faHeart}
                                                    className="text-hiperorange w-10 h-10"
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={faHeart}
                                                    className="hipergrisdark text-hipergrisdark w-10 h-10"
                                                />
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div> */}

            <div className="contenedorInfo">
              <div className="columnaImagen    ">
                <div
                  className="imagenes-vertical      "
                  style={{ height: "500px", maxHeight: "500px" }}
                >
                  <Swiper
                    direction={"vertical"}
                    slidesPerView={3}
                    centeredSlides={true}
                    spaceBetween={0}
                    navigation={true}
                    freeMode={true}
                    mousewheel={true}
                    scrollbar={{ draggable: true }}
                    modules={[Mousewheel, FreeMode, Pagination, Navigation]}
                    className="  direccionvertical"
                    onSlideChange={handleSlideChange}
                    id="mySwiperInfoProductov"
                    style={{
                      "--swiper-navigation-color": "#32338E",
                      "--swiper-navigation-size": "30px",
                      // marginLeft:'1rem'
                    }}
                  >
                    {imagenes &&
                      imagenes !== null &&
                      imagenes !== undefined &&
                      imagenes.map((foto, index) => (
                        <SwiperSlide key={"ima" + index}>
                          <div className="py-4 ">
                            <div
                              className={classNames("flex", "justify-center")}
                              style={{
                                border:
                                  index + 1 === index_imagen
                                    ? "2px solid #ED6A0F"
                                    : "none",
                                borderRadius:
                                  index + 1 === index_imagen ? "15px" : "0px",
                              }}
                            >
                              <img
                                src={
                                  foto.Url +
                                  (anchoPantalla >= 770
                                    ? `&size=400x400`
                                    : "&size=120x120")
                                }
                                alt=""
                                onClick={() => selec_imagen(foto.Url, index)}
                                style={{ borderRadius: "15px" }}
                              />
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </div>
                <div className="imagen-carrusel">
                  <Swiper
                    slidesPerView={1}
                    spaceBetween={0}
                    navigation={true}
                    freeMode={true}
                    onSwiper={setSwiperRef}
                    mousewheel={true}
                    scrollbar={{ draggable: true }}
                    modules={[Mousewheel, FreeMode, Pagination, Navigation]}
                    className="mySwiper direccionvertical text-black"
                    onSlideChange={handleSlideChange}
                    style={{
                      "--swiper-navigation-color": "#32338E",
                      "--swiper-navigation-size": "30px",
                      // marginLeft:'1rem'
                    }}
                  >
                    {imagenes &&
                      imagenes !== null &&
                      imagenes !== undefined &&
                      imagenes.map((foto, index) => (
                        <SwiperSlide key={"ima" + index}>
                          <div className="py-4 ">
                            <div className="flex justify-center">
                              <img
                                src={
                                  foto.Url +
                                  (anchoPantalla >= 770
                                    ? `&size=400x400`
                                    : "&size=120x120")
                                }
                                alt=""
                                onClick={() => selec_imagen(foto.Url, index)}
                              />
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </div>
                <div className="imagen-grande    ">
                  {/* <div className="header-corazon" style={{paddingRight:"9px" }}>
                                        <div className="float-right">
                                            <div className="relative inline">
                                                <button
                                                    className="absolute right-2 top-2"
                                                    onClick={() => openModalFavorito()}
                                                >
                                                    {producto.EnListadoCliente ? (
                                                        <FontAwesomeIcon
                                                            icon={faHeart}
                                                            className="text-hiperorange w-10 h-10"
                                                        />
                                                    ) : (
                                                        <FontAwesomeIcon
                                                            icon={faHeart}
                                                            className="hipergrisdark text-hipergrisdark w-10 h-10"
                                                        />
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div> */}
                  <div className="body-image    ">
                    <img
                      src={imagengrande}
                      className="w-full h-full imagen_body"
                      alt=""
                    />
                    <div className="imagenes-responsive-footer  ">
                      {imagenes &&
                        imagenes !== null &&
                        imagenes !== undefined &&
                        imagenes.map((foto, index) => (
                          <div
                            className=" flex  py-2 justify-start mx-2"
                            key={"ima-resposive" + index}
                            style={{
                              border:
                                index + 1 === index_imagen
                                  ? "2px solid #ED6A0F"
                                  : "none",
                              borderRadius:
                                index + 1 === index_imagen ? "15px" : "0px",
                            }}
                          >
                            <img
                              src={
                                foto.Url +
                                (anchoPantalla >= 770
                                  ? `&size=400x400`
                                  : "&size=120x120")
                              }
                              style={{
                                borderRadius: "15px",
                                paddingLeft: "5px",
                                paddingRight: "5px",
                                objectFit: "cover",
                              }}
                              alt=""
                              onClick={() => selec_imagen(foto.Url, index)}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                  <div
                    className="bg-hiperorange text-white rounded-md px-2  font-semibold  style_indice_number_imagen"
                    style={{ marginRight: "10px !important" }}
                  >
                    {index_imagen}/{imagenes.length}
                  </div>
                </div>
              </div>

              <div className="columnaInfoProd   ">
                <div className="style-categorias  ">
                  <div className="flex justify-start">
                    <div
                      className="bg-gray-400   font-bold text-white rounded mr-1  flex align-items-center "
                      style={{
                        cursor: "pointer",
                        padding: "10px 8px 10px 8px",
                      }}
                    >
                      {producto.Categoria.Descripcion}
                    </div>
                    <div
                      className="bg-gray-500  flex align-items-center font-bold  text-white rounded mx-1"
                      style={{
                        cursor: "pointer",
                        padding: "10px 8px 10px 8px",
                      }}
                    >
                      {producto.SubCategoria.Descripcion}
                    </div>
                  </div>
                </div>
                <div className="style-name-producto style_letras  ">
                  {producto.Descripcion}
                </div>
                {producto.ConOferta && (
                  <div className="descuento-tag  py-2">
                    <div
                      className="px-2 bg-hiperorange text-white"
                      style={{
                        borderRadius: "20px",
                        width: "fit-content",
                        float: "left",
                      }}
                    >
                      <p className="mb-0 text-lg">
                        <div className="  d-inline-block py-2 px-2 rounded-3  ">
                          <FontAwesomeIcon
                            icon={faTag}
                            className="w-[1.5rem] h-[1.5rem] text-white pr-2"
                            style={{ borderRadius: "50%" }}
                          />

                          <span className="text-white font-semibold">
                            -{descuento}% Dcto.
                          </span>
                        </div>
                      </p>
                    </div>
                  </div>
                )}

                <div className="precio-tag  ">
                  <div className="texto-precio style-letra-info-prod">
                    {_es_peso() ? (
                      <span className="style_precio_kg font-bold">
                        {" "}
                        Precio por Kg{" "}
                      </span>
                    ) : (
                      <></>
                    )}
                    {_es_pieza() ? (
                      <span className="font-bold"> Precio por Kg </span>
                    ) : (
                      <></>
                    )}
                    {!_es_pieza() && !_es_peso() ? (
                      <span className="style_precio_und font-bold">
                        {" "}
                        Precio por Und{" "}
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div>
                    {" "}
                    <span className="valor-ahora-info-prod style-letra-info-prod">
                      {producto.ConOferta ? (
                        <p className="mb-0">
                          {producto.Moneda} {formatPrice(producto.PrecioOferta)}
                          <del
                            className="text-zinc-400   px-2"
                            style={{ fontSize: "24px !important" }}
                          >
                            {formatPrice(producto.PrecioOriginal)}
                          </del>
                        </p>
                      ) : (
                        <p className="mb-0 pl-0">
                          {producto.Moneda} {formatPrice(producto.PrecioVenta)}
                        </p>
                      )}
                    </span>
                    {/* {producto.ConOferta &&( <span className="valor-antes-info-prod style-letra-info-prod">
                                             <del className="text-zinc-400 text-sm px-2">
                                                  {formatPrice(producto.PrecioOriginal)}
                                              </del>
                                         </span> ) } */}
                  </div>
                </div>
                <div
                  className="controles-add-minus py-2"
                  style={{
                    borderBottom: cantidad > 0 ? "1px solid  #ABABAD" : "none",
                  }}
                >
                  {!producto.Fraccionado && cantidad > 0 /* esnoremal*/ && (
                    <div className="flex align-items-end controles_info_prod">
                      <div className="flex">
                        {cantidad > 1 && (
                          <FontAwesomeIcon
                            icon={faSquareMinus}
                            className="w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer"
                            onClick={() => reducir_carrito()}
                          />
                        )}
                        {cantidad == 1 && (
                          <span className="bg-hiperorange  icono-delete ">
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              className="w-[1.1rem] h-[1.1rem] text-white cursor-pointer "
                              onClick={() => reducir_carrito()}
                            />
                          </span>
                        )}

                        <div
                          className="mx-1 flex justify-center"
                          style={{ width: "100px" }}
                        >
                          <input
                            type="text"
                            className="w-100 h-[2rem] text-center outline-none mx-2 bg-hipergris rounded-lg px-2 font-bold"
                            name="cantidad"
                            pattern="[0-9]{0,13}"
                            value={cantidad + " Und"}
                            onChange={(e) => inputchange(e)}
                          />
                        </div>

                        <FontAwesomeIcon
                          icon={faSquarePlus}
                          className="w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer"
                          onClick={() => add_carrito()}
                        />
                      </div>
                    </div>
                  )}
                  {_es_peso() && cantidad > 0 ? (
                    <div className="flex align-items-end controles_info_prod">
                      <div className="flex">
                        {cantidad > 0.1 && (
                          <FontAwesomeIcon
                            icon={faSquareMinus}
                            className="w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer"
                            onClick={() => actualizar_item(0.1, "decremento")}
                          />
                        )}

                        {cantidad == 0.1 && (
                          <span className="bg-hiperorange  icono-delete ">
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              className="w-[1.1rem] h-[1.1rem] text-white cursor-pointer "
                              onClick={() => actualizar_item(0.1, "decremento")}
                            />
                          </span>
                        )}
                        <div
                          className=" flex justify-center align-items-center bg-white cursor-pointer"
                          onClick={() => abrirModal()}
                        >
                          <span className=" mx-2 bg-hipergris rounded-lg px-3 py-1 font-bold">
                            {" "}
                            {cantidad_peso}
                          </span>
                        </div>

                        <FontAwesomeIcon
                          icon={faSquarePlus}
                          className="w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer"
                          onClick={() => actualizar_item(0.1, "incremento")}
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {_es_pieza() /* espieza*/ ? (
                    <div className="flex flex-col">
                      <div
                        className="style-letra-info-prod"
                        style={{ fontSize: "16px" }}
                      >
                        Peso de unidades disponibles
                      </div>
                      {pesos.map((peso, index) => (
                        <div
                          className=" flex  mt-2   w-full bg-white mb-2   "
                          key={index}
                        >
                          <div className="bg-white h-[2rem] pr-2 rounded-l flex align-items-center style_dato_peso_unidad  ">
                            <span className="   font-bold entre_peso_aprox">
                              Entre {peso.PesoAproxMin} - {peso.PesoAproxMax} kg
                            </span>
                          </div>
                          <div className="flex  justify-content-center align-items-center  style_dato_peso_unidad">
                            {peso.Piezas == 1 && (
                              <FontAwesomeIcon
                                icon={faTrashCan}
                                className="w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer"
                                onClick={() =>
                                  actualizarDato(peso, "decremento")
                                }
                              />
                            )}

                            {peso.Piezas > 1 && (
                              <FontAwesomeIcon
                                icon={faSquareMinus}
                                className="w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer"
                                onClick={() =>
                                  actualizarDato(peso, "decremento")
                                }
                              />
                            )}

                            {peso.Piezas > 0 && (
                              <input
                                type="text"
                                className="  h-[2rem] text-center outline-none  mx-2 bg-hipergris rounded-lg px-3 py-1 font-bold "
                                name="cantidad"
                                pattern="[0-9]{0,13}"
                                value={peso.Piezas + " Und"}
                                onChange={(e) => inputchangePeso(e, peso)}
                                style={{ width: "auto", maxWidth: "100px" }}
                              />
                            )}

                            {peso.Piezas > 0 && (
                              <FontAwesomeIcon
                                icon={faSquarePlus}
                                className="w-[2rem] h-[2rem] text-hiperorange bg-white cursor-pointer"
                                onClick={() =>
                                  actualizarDato(peso, "incremento")
                                }
                              />
                            )}

                            {peso.Piezas == 0 && (
                              <div className="w-full ">
                                <ButtonText
                                  type="submit"
                                  label="Agregar"
                                  principal={true}
                                  className="bg-hiperorange  text-white mb-0 hover:bg-hiperorange_hover  w-full"
                                  onClick={() =>
                                    actualizarDato(peso, "incremento")
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {(cantidad == 0 || cantidad_peso == "0g") &&
                  _es_pieza() === false && (
                    <div className="bnt-agregar-agregar">
                      <ButtonText
                        type="submit"
                        label="Agregar"
                        principal={true}
                        className="bg-hiperorange  text-white mb-2 hover:bg-hiperorange_hover  w-7/12"
                        onClick={() => show_botones()}
                      />
                    </div>
                  )}

                {cantidad > 0 && (
                  <div className="block-total ">
                    <div>
                      <p className="mb-0 font-bold">
                        {_es_peso() ? "Peso" : ""}
                        {_es_pieza() ? "Peso estimado" : ""}
                      </p>
                      {_es_peso() ||
                        (_es_pieza() && (
                          <p className="mb-0 font-bold">{estimado} KG</p>
                        ))}
                    </div>
                    {!_es_pieza() && (
                      <div>
                        <span className="style-letra-info-prod  "> Total </span>{" "}
                      </div>
                    )}
                    {_es_pieza() && (
                      <div>
                        <span className="style-letra-info-prod  ">
                          {" "}
                          Total estimado{" "}
                        </span>{" "}
                      </div>
                    )}
                    <div className="listado flex    justify-start  ">
                      <div className="style-letra-info-prod  style-texto-total  ">
                        {producto.Moneda} {total_estimado}
                      </div>

                      {producto.ConOferta && (
                        <div className="bg-hiperorange text-white    texto_ahorrado_info_prod  d-inline-block py-2    font-bold">
                          Bs. {ahorrado}&nbsp; Ahorrado
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {/* <div className="tag-agregar-listas text-hiperorange pt-2 hover:text-hiperorange_hover "    >
                                    <span className="cursor-pointer font-bold " onClick={() => openModalFavorito()}>   Agregar a tus listas de compras </span>

                                </div>*/}
              </div>
            </div>
          </div>

          <Modal
            isOpen={modalIsOpen}
            appElement={document.getElementById("root")}
            onRequestClose={closeModal}
            style={customStyles2}
            contentLabel="Example Modal"
            key={"keyModal"}
          >
            <FullScreenImage
              imagenes={imagenes}
              onClose={closeFullScreen}
              tiene_varias_imagenes={"si"}
              imagen={null}
            ></FullScreenImage>
          </Modal>

          <Modal
            isOpen={modal}
            ariaHideApp={false}
            onRequestClose={endModal}
            style={customStyles}
            contentLabel="address"
          >
            <div className="w-80 relative">
              <div className="card border-0 relative">
                <div className="absolute top-[-30px] right-[-2rem]">
                  <FontAwesomeIcon
                    icon={faCircleXmark}
                    className="w-[2.5rem] h-[2.5rem] rounded-full text-hiperorange bg-white cursor-pointer "
                    onClick={() => endModal()}
                  />
                </div>
                <div className="text-center">
                  <h5 className="font-bold">Seleccione el Peso</h5>
                </div>
                <div className="card-body pt-0">
                  <div className="row">
                    {pesajes.map((pesaje, index) => (
                      <div className="col-6" key={index}>
                        <div
                          className="bg-[#EEEEEE] m-2 px-3 py-2 rounded text-center cursor-pointer hover:bg-gray-400"
                          onClick={() => set_peso_pieza(pesaje)}
                        >
                          <span className="font-semibold text-xl">
                            {pesaje.name}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="border-t-2 border-[#EEEEEE] p-2 flex justify-center">
                  <div className="flex border-[3px] border-hiperorange p-1 rounded">
                    <button
                      className="w-8 h-8 bg-hiperorange hover:bg-hiperorange_hover text-white rounded flex items-center justify-center"
                      onClick={() => actualizar_item(0.1, "decremento")}
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </button>
                    <div className="mx-2 flex align-items-center">
                      <span>{cantidad_peso}</span>
                    </div>
                    <button
                      className="w-8 h-8 bg-hiperorange hover:bg-hiperorange_hover text-white rounded flex items-center justify-center"
                      onClick={() => actualizar_item(0.1, "incremento")}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </>
      )}
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  // carrito_lista_compra: state.Carrito.carrito_lista_compra,
  carrito_lista_compra: state.Shopping_list.carrito_lista_compra,
  producto: state.Producto.producto,
  sucursal: state.BranchOffice.sucursal,
  tipo_entrega: state.BranchOffice.tipo_entrega,
  direccion: state.Location.direccion,
  // producto_delete: state.Carrito.producto_delete,
  shopping_list_detalle: state.Shopping_list.shopping_list_detalle,
  // carrito_lista_compra: state.Shopping_list.carrito_lista_compra,
  productos_delete_lista_compra:
    state.Shopping_list.productos_delete_lista_compra,
  estado_cambios: state.Shopping_list.estado_cambios,
  profile: state.Profile.profile,
});

export default connect(mapStateToProps, {
  get_detalle_lista_compra,
  store_carrito_lista_compra,
  get_producto_item,
  // set_peso_al_carrito,
  // add_al_carrito,
  inc_al_carrito_lista_compra,
  dec_al_carrito_lista_compra,
  // remove_al_carrito,
  actualizar_item_carrito,
  actualizar_detalles_lista_compra,
  actualizar_producto_pieza,
  limpiar_data_lista_compra,
})(ProductoPieza);
