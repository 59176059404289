import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faLocationDot,
  faMessage,
  faMoneyBill,
  faMotorcycle,
  faCheck,
  faMoped,
} from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import "../../../assets/styles/responsive.scss";

const Indice = ({ posicion, tipo_entrega }) => {
  const verify_position = (pos_current) => {
    if (pos_current < posicion) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div
        className="w-100 bg-white rounded-hiper_g overflow-hidden h-auto"
        style={{ minHeight: "100px" }}
      >
        <div
          className={classNames(
            "py-2.5",
            "px-2",
            "text-hiperplomo_fuerte",
            "flex",
            "justify-between",
            {
              "bg-hiperplomo_fuerte text-white": posicion === 1,
            }
          )}
          style={{
            borderBottom: "1px solid #9CA3AF",
          }}
        >
          <div className="flex gap-2">
            <div
              className="flex justify-center items-center"
              style={{ width: "1.7rem" }}
            >
              <FontAwesomeIcon
                icon={faCartShopping}
                className="mx-2  icon_indice"
              />
            </div>
            Mi carrito
          </div>
          {verify_position(1) && (
            <FontAwesomeIcon
              icon={faCheck}
              className="mx-2 self-center text-hiperplomo_fuerte icon_indice"
            />
          )}
        </div>
        <div
          className={classNames(
            "py-2.5",
            "px-2",
            "text-hiperplomo_fuerte",
            "flex",
            "justify-between",
            {
              "bg-hiperplomo_fuerte text-white": posicion === 2,
            }
          )}
          style={{ borderBottom: "1px solid #9CA3AF" }}
        >
          <div className="flex gap-2">
            <div
              className="flex justify-center items-center"
              style={{ width: "1.7rem" }}
            >
              <FontAwesomeIcon
                style={{ width: "1.7rem" }}
                icon={faMoped}
                className="mx-2 icon_indice"
              />
            </div>
            Modo de entrega
          </div>
          {verify_position(2) && (
            <FontAwesomeIcon
              icon={faCheck}
              className="mx-2 self-center icon_indice"
            />
          )}
        </div>
        {tipo_entrega.Id === 2 && (
          <div
            className={classNames(
              "py-2.5",
              "px-2",
              "text-hiperplomo_fuerte",
              "flex",
              "justify-between",
              {
                "bg-hiperplomo_fuerte text-white": posicion === 3,
              }
            )}
            style={{ borderBottom: "1px solid #9CA3AF" }}
          >
            <div className="flex gap-2">
              <div
                className="flex justify-center items-center"
                style={{ width: "1.7rem" }}
              >
                <FontAwesomeIcon
                  icon={faLocationDot}
                  className="mx-2 self-center icon_indice"
                />
              </div>{" "}
              Datos
            </div>
            {verify_position(3) && (
              <FontAwesomeIcon icon={faCheck} className="mx-2 icon_indice" />
            )}
          </div>
        )}

        <div
          className={classNames(
            "py-2.5",
            "px-2",
            "text-hiperplomo_fuerte",
            "flex",
            "justify-between",
            {
              "bg-hiperplomo_fuerte text-white": posicion === 4,
            }
          )}
          style={{ borderBottom: "1px solid #9CA3AF" }}
        >
          <div className="flex gap-2">
            <div
              className="flex justify-center items-center"
              style={{ width: "1.7rem" }}
            >
              <FontAwesomeIcon
                icon={faMoneyBill}
                className="mx-2 icon_indice"
              />
            </div>
            Formas de pago
          </div>
          {verify_position(4) && (
            <FontAwesomeIcon
              icon={faCheck}
              className="mx-2 self-center icon_indice"
            />
          )}
        </div>
        <div
          className={classNames(
            "py-2.5",
            "px-2",
            "text-hiperplomo_fuerte",
            "flex",
            "justify-between",
            {
              "bg-hiperplomo_fuerte text-white": posicion === 5,
            }
          )}
          style={{ borderBottom: "1px solid #9CA3AF" }}
        >
          <div className="flex gap-2">
            <div
              className="flex justify-center items-center"
              style={{ width: "1.7rem" }}
            >
              <FontAwesomeIcon icon={faMessage} className="mx-2 icon_indice" />
            </div>
            Confirmación de pedidos
          </div>
          {verify_position(5) && (
            <FontAwesomeIcon
              icon={faCheck}
              className="mx-2 self-center icon_indice"
            />
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  tipo_entrega: state.BranchOffice.tipo_entrega,
});

export default connect(mapStateToProps, {})(Indice);
