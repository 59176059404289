import {
  GET_URL_NAVIGATION,
  POST_URL_NAVIGATION,
  UPDATE_URL_NAVIGATION,
} from "./types";
import { end_peticion } from "./peticion";

export const end_peticion_total = () => async (dispatch) => {
  dispatch(end_peticion());
};
export const get_url_navigation = () => async (dispatch) => {
  dispatch({ type: GET_URL_NAVIGATION });
};

export const update_url_navigation = (url) => async (dispatch) => {
  dispatch({
    type: UPDATE_URL_NAVIGATION,
    payload: url,
  });
};
