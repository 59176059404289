import { buildItemListJSONLd } from "../../libs/richSnippetsUtils/buildItemListJSONLd";

export function useItemListJSONLd({ name, itemListOrder, itemListElement }) {
  const itemListJSONLd = buildItemListJSONLd(
    {
      name,
      itemListOrder,
      itemListElement,
    },
    "https://schema.org"
  );
  const itemListJSONLdScript = (
    <script type="application/ld+json">{JSON.stringify(itemListJSONLd)}</script>
  );

  return { itemListJSONLdScript, itemListJSONLd };
}
