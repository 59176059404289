import { buildWhatsappLink } from "../modules/shared/ui/utils/build-whatsapp-link";
import { formatPhoneNumber } from "../modules/shared/ui/utils/format-phone-number";
import { useGetSucursalContactoTelefonos } from "../modules/sucursal-contactos/ui/hooks/useGetSucursalContactoTelefonos";

export function MensajeTelefonoProductoDisponible() {
  const { telefonoProductoNoDisponible } = useGetSucursalContactoTelefonos();

  if (!telefonoProductoNoDisponible) return null;
  return (
    <div className="text-gray-500 text-lg md:text-xl text-center">
      Te ayudamos a encontrar el producto que buscas, escríbenos al{" "}
      <a
        href={buildWhatsappLink(telefonoProductoNoDisponible)}
        target="_blank"
        rel="noreferrer"
        className="text-hiperorange hover:underline"
      >
        {formatPhoneNumber(telefonoProductoNoDisponible)}
      </a>
    </div>
  );
}
