import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  get_ciudades,
  get_sucursales,
  set_sucursal,
} from "../../redux/actions/branch_office";

import { faStore } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import "../../assets/styles/responsive.scss";
import convertSlug from "../../components/Utils/slug";
import { useGetBackLink } from "../../hooks/useGetBackLink";
import { info_session } from "../../redux/actions/profile";
import LoaderSpinner from "../../widgets/loaderSpinner";
import ListaSucursal from "./lista-sucursal";
import { ListaSucursalSearch } from "./lista-sucursal-search";
import { useFilterSucursales } from "../../hooks/useFilterListaSucursales";

const ListaSucursales = ({
  get_sucursales,
  set_sucursal,
  sucursales,
  get_ciudades,
  ciudades,
  info_session,
  profile,
  isFetchingSucursales,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { backLink } = useGetBackLink();
  const scrolleableContainer = useRef(null);

  const { filteredSucursales } = useFilterSucursales({
    sucursales,
    searchTerm,
  });

  useEffect(() => {
    if (profile) {
      getCiudades();
      if (sucursales?.length === 0) get_sucursales();
    } else {
      info_session();
    }
  }, [profile, sucursales]);

  const actualizarSucursal = async (item_sucursal) => {
    await set_sucursal(item_sucursal);
    goToSucursal(item_sucursal);
  };

  const getUrl = (item_sucursal) => {
    let ciudad = getCiudadSucursal(item_sucursal.idRegion);
    let sucursal_select = sucursalSeleccionado(item_sucursal.IdSucursal);
    let parametro_url = {
      ciudad: convertSlug.data_slug(ciudad.Nombre),
      sucursal: convertSlug.data_slug(sucursal_select.Descripcion),
    };
    let resp = parametro_url.ciudad + " " + parametro_url.sucursal;
    let new_url = convertSlug.data_barra(resp);

    let url_parameters = new_url + backLink;
    return "/" + url_parameters;
  };

  const goToSucursal = async (item_sucursal) => {
    let url_parameters = getUrl(item_sucursal);
    // navigate("/" + url_parameters);
    window.location.href = url_parameters;
  };
  const sucursalSeleccionado = (IdSucursal) => {
    let seleccionado = null;
    sucursales.forEach((sucursal) => {
      let item = sucursal.Locatarios.find(
        (pre) => parseInt(pre.IdSucursal) === parseInt(IdSucursal)
      );
      if (item) {
        seleccionado = item;
      }
    });
    return seleccionado;
  };

  const getCiudadSucursal = (id_region) => {
    let seleccionado = ciudades?.find(
      (pre) => parseInt(pre.IdRegion) === parseInt(id_region)
    );
    return seleccionado;
  };

  const getCiudades = async () => {
    if (ciudades.length === 0) {
      await get_ciudades();
    }
  };

  const getSucursalesCiudad = (sucursales, ciudad) => {
    const sucursalesPorCiudad =
      sucursales?.filter((sucursal) => sucursal.IdRegion === ciudad.IdRegion) ||
      [];
    return [...sucursalesPorCiudad];
  };

  const handleSetSearchTerm = (value) => {
    setSearchTerm(value);
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="md:px-2">
        <ListaSucursalSearch handleSearchTerm={handleSetSearchTerm} />
      </div>
      <div className="bg-white rounded-lg overflow-hidden w-full h-full">
        <div className="flex flex-col h-full">
          <div
            ref={scrolleableContainer}
            className="flex flex-col gap-2  overflow-y-auto"
          >
            <div className={classNames("flex flex-col gap-4 md:p-2")}>
              {isFetchingSucursales ? (
                <LoaderSpinner bool={true} />
              ) : (
                <>
                  {filteredSucursales.length > 0 ? (
                    <>
                      {ciudades?.map((ciudad) => {
                        const sucursalesCiudad = getSucursalesCiudad(
                          filteredSucursales,
                          ciudad
                        );
                        if (sucursalesCiudad.length === 0) return null;
                        return (
                          <div
                            key={ciudad.Nombre}
                            className="flex flex-col gap-2 border bg-gray-50 rounded-lg shadow-md md:p-4"
                            style={{ padding: 8 }}
                          >
                            <div className="text-xl font-bold text-hiperorange">
                              Sucursales en {ciudad.Nombre}
                            </div>
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-3">
                              {sucursalesCiudad?.map((branch_office, index) => (
                                <ListaSucursal
                                  key={`branch-${branch_office.IdMarket}`}
                                  branch_office={branch_office}
                                  onClick={actualizarSucursal}
                                  ciudad={getCiudadSucursal(
                                    branch_office.IdRegion
                                  )}
                                  backLink={backLink}
                                />
                              ))}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div className="flex flex-col gap-4 justify-center items-center mt-2 min-h-60 text-gray-400">
                      <FontAwesomeIcon icon={faStore} size="6x" />
                      <div className="text-xl">
                        No hay sucursales para mostrar
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  profile: state.Profile.profile,
  sucursales: state.BranchOffice.branch_offices,
  sucursal: state.BranchOffice.sucursal,
  ciudades: state.BranchOffice.ciudades,
  isFetchingSucursales: state.BranchOffice.isFetchingSucursales,
});

export default connect(mapStateToProps, {
  info_session,
  get_sucursales,
  set_sucursal,
  get_ciudades,
})(ListaSucursales);
