import { faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LoginSocialFacebook } from "reactjs-social-login";
import "../../assets/fontawesome";
import background from "../../assets/images/login/Background.jpg";
import texture from "../../assets/images/login/texture_complete.jpg";
import "../../assets/styles/globalColorPrincipal.css";
import "../../assets/styles/hiper.scss";
import "../../assets/styles/mystyle.scss";
import "../../assets/styles/stylePageGlobals.scss";
import {
  codigo_acceso_externo,
  get_anonimo_token,
  get_token,
  set_social_user_data,
} from "../../redux/actions/auth";

import { useGoogleLogin } from "@react-oauth/google";

import { info_session } from "../../redux/actions/profile";
import ButtonIcon from "../../widgets/button_icon";
import {
  SOCIAL_LOGIN_LOCALSTORAGE_KEY,
  TIPO_ACCESO_REDES_SOCIALES_VALUES,
} from "./constants";
const containerStyle = {
  backgroundImage: `url(${background})`,
  width: "100%",
  height: "92.5vh",
};
const containerStyle2 = {
  backgroundImage: `url(${texture})`,
  width: "100%",
};

const Login = ({
  get_anonimo_token,
  loading,
  sendAccesoAnonimo,
  authenticated,
  codigo_acceso_externo,
  profile,
  datoLogin,
  get_token,
  access_token,
  info_session,
  closeModal = () => {},
  handleSetCurrentSocialLogin = () => {},
  set_social_user_data,
}) => {
  const navigate = useNavigate();
  const [idFacebook, setIdFacebook] = useState(null);
  const [idGoogle, setIdGoogle] = useState(null);

  const get_codigo_login = async (token, codigoAcceso) => {
    return await codigo_acceso_externo(token, codigoAcceso);
  };

  // useEffect(() => {
  //   if (access_token) {
  //     info_session();
  //   }
  // }, [access_token]);

  
  useEffect(() => {
    let tipo_user = localStorage.getItem("user");
    if (profile) {
      if (profile.TerminoCondicion.AceptoTerminoActual) {
        if (profile.Ubicaciones.length > 0) {
          // navigate("/");
        } else if (tipo_user !== '"anonimo"') {
          // navigate("/");
        }
      } else {
        navigate("/terminoscondiciones");
      }
    }
  }, [profile]);

  // useEffect(() => {
  //   if (datoLogin != null) {
  //     if (datoLogin.ConError == true) {
  //       if (localStorage.getItem("access_token") != null) {
  //       } else {
  //       }
  //     } else {
  //       get_token(localStorage.getItem(SOCIAL_LOGIN_LOCALSTORAGE_KEY.TOKEN), datoLogin.Dato);
  //     }
  //   }
  //   /*
  //    */
  // }, [datoLogin]);

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const response = await fetch(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } }
      );

      const user = await response.json();
      setIdGoogle(user.sub);
      localStorage.setItem(SOCIAL_LOGIN_LOCALSTORAGE_KEY.TOKEN, user.sub);
      localStorage.setItem(
        SOCIAL_LOGIN_LOCALSTORAGE_KEY.TIPO_ACCESO,
        TIPO_ACCESO_REDES_SOCIALES_VALUES.GOOGLE
      );
      try {
        const result = await get_codigo_login(user.sub, 2);
        if (result) {
          await info_session();
          // closeModal();
        } else {
          set_social_user_data({
            socialUserId: user.sub,
            socialTipoAcceso: TIPO_ACCESO_REDES_SOCIALES_VALUES.GOOGLE,
            socialEmail: user.email,
          });
        }
        // handleSetCurrentSocialLogin(null);
      } catch (error) {
        handleSetCurrentSocialLogin(null);
      }
    },
    onError: (errorResponse) => {
      handleSetCurrentSocialLogin(null);
      console.error(errorResponse);
    },
    onNonOAuthError: (errorResponse) => {
      handleSetCurrentSocialLogin(null);
      console.error(errorResponse);
    },
  });

  return (
    <div className="grid gap-2 pb-4 text-white">
      {/* <button
          className=" hover:text-[#DC842D] text-[#DC842D] bg-white  font-light hover:bg-[#ffffff] py-1 px-4 border border-white hover:border-transparent rounded-lg w-full mt-2 flex items-center justify-between"
          type="button"
        >
          <FontAwesomeIcon
            icon={faFacebook}
            style={{ height: "25px" }}
            className="w-8"
          />
          <span className="text-left">
            <LoginSocialFacebook
              appId={process.env.REACT_APP_FB_APP_ID || "653327285310405"}
              onResolve={(response) => {
                setIdFacebook(response.data.id);
                localStorage.setItem(SOCIAL_LOGIN_LOCALSTORAGE_KEY.TOKEN, response.data.id);
                get_codigo_login(response.data.id, 1);
              }}
              onReject={(error) => {}}
            >
              Iniciar con Facebook
            </LoginSocialFacebook>
          </span>{" "}
          <span></span>
        </button> */}
      <ButtonIcon
        type="submit"
        icon={faGoogle}
        label="Inicia sesión con Google"
        principal={false}
        onClick={(e) => {
          handleSetCurrentSocialLogin(TIPO_ACCESO_REDES_SOCIALES_VALUES.GOOGLE);
          googleLogin();
        }}
        className={"text-white bg-hiperorange hover:bg-hiperorange_hover"}
      />
    </div>
  );
};
const mapStateToProps = (state) => ({
  loading: state.Auth.loading,
  datoLogin: state.Auth.datoLogin,
  sendAccesoAnonimo: state.Auth.sendAccesoAnonimo,
  authenticated: state.Auth.authenticated,
  profile: state.Profile.profile,
  access_token: state.Auth.access_token,
});

export default connect(mapStateToProps, {
  get_anonimo_token,
  codigo_acceso_externo,
  get_token,
  info_session,
  set_social_user_data,
})(Login);
