import classNames from "classnames";
import { CustomerBagPoint } from "../../../domain/interfaces/customer-bag-point";
import { useMemo } from "react";
import { CustomerBagPointType } from "../../../domain/enums/customer-bag-point-type";
import { faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";

export function CustomerBagHistoryPointCard(
  customerBagPoint: CustomerBagPoint
) {
  const tipoIcon = useMemo(() => {
    switch (customerBagPoint.Tipo) {
      case CustomerBagPointType.INGRESO:
        return faPlus;
      case CustomerBagPointType.EGRESO:
        return faMinus;
      default:
        return null;
    }
  }, [customerBagPoint.Tipo]);

  return (
    <div
      className={classNames(
        "flex items-center rounded-md border-1 shadow-md w-full bg-white text-black gap-2 p-2"
      )}
    >
      <div>
        <div
          className={classNames(
            "w-8 h-8 md:w-10 md:h-10 p-2 text-base md:text-lg font-semibold text-white flex items-center justify-center rounded-full",
            {
              "bg-red-500":
                customerBagPoint.Tipo === CustomerBagPointType.EGRESO,
              "bg-green-500":
                customerBagPoint.Tipo === CustomerBagPointType.INGRESO,
            }
          )}
        >
          {tipoIcon && <FontAwesomeIcon icon={tipoIcon} />}
        </div>
      </div>
      <div className="flex flex-col gap-1 flex-grow">
        <div className="leading-tight text-sm md:text-base">{customerBagPoint.Descripcion}</div>
        <div className="text-xs md:text-sm text-gray-500">
          {format(new Date(customerBagPoint.Fecha_Crea), "dd/MM/yyyy hh:mm")}
        </div>
      </div>
      <div className="flex flex-col">
        <div className="text-right text-base md:text-lg flex items-center justify-end gap-1">
          {tipoIcon && (
            <FontAwesomeIcon
              className={classNames({
                "text-red-500":
                  customerBagPoint.Tipo === CustomerBagPointType.EGRESO,
                "text-green-600":
                  customerBagPoint.Tipo === CustomerBagPointType.INGRESO,
              })}
              icon={tipoIcon}
              size="xs"
            />
          )}{" "}
          {customerBagPoint.Punto}
        </div>
        <div className="text-xs md:text-sm text-gray-500 text-right">
          Saldo: {customerBagPoint.Saldo}
        </div>
      </div>
    </div>
  );
}
