import { ThreeDots } from "react-loader-spinner";

const LoaderSpinner = ({ bool }) => {
  return (
    <div
      className="flex justify-center w-100"
      style={{ visibility: bool === true ? "visible" : "hidden" }}
    >
      <ThreeDots
        color="#ED6A0F"
        width={80}
        height={80}
        ariaLabel="circles-loading"
        visible={bool}
      />
    </div>
  );
};

export default LoaderSpinner;
