import { SwitchCodesComponent } from "../../../shared/ui/components/switch-codes-component";

interface UserProfileAuthorizationTokenProps {
  profileAuthorizationToken: string;
}

export function UserProfileAuthorizationToken({
  profileAuthorizationToken,
}: UserProfileAuthorizationTokenProps) {
  return (
    <SwitchCodesComponent
      code={profileAuthorizationToken}
      defaultCodeType="qrcode"
      displayValue={false}
      enableSwitch={false}
    />
  );
}
