import { useEffect, useState } from "react";
import { Modal } from "../../../../shared/ui/components/modal";
import { Tab } from "../../../../shared/ui/components/tabs/tab";
import { Tabs } from "../../../../shared/ui/components/tabs/tabs";
import { UserProfileAuthorizationTokenQrModalContent } from "../../../../user-profile/ui/components/user-profile-authorization-token-qr-modal-content";
import { HiperPuntosProfileButton } from "./hiper-puntos-profile-button";
import { HiperPuntosProfileQrModalContent } from "./hiper-puntos-profile-qr-modal-content";
import { useGetUserProfileById } from "../../../../user-profile/ui/hooks/useGetUserProfileById";
import { createApiUserProfilesRepository } from "../../../../user-profile/infrastructure/api-user-profiles-repository";
import classNames from "classnames";
import { useGetMensajeCodigoClienteParameter } from "../../hooks/useGetMensajeCodigoClienteParameter";
import { createApiConfigParametersRepository } from "../../../../config-parameters/infrastructure/api-config-parameters-repository";
import { createSweetAlertNotificationService } from "../../../../notifications/infrastructure/sweet-alert-notification-service";
const userProfilesRepository = createApiUserProfilesRepository();
const configParametersRepository = createApiConfigParametersRepository();
const notificationsRepository = createSweetAlertNotificationService();

interface HiperPuntosProfileQrModalProps {
  onCloseParentModal?: () => void;
  profileCode: string;
  buttonClassName?: string;
  spanClassName?: string;
}

export function HiperPuntosProfileQrModal({
  onCloseParentModal,
  profileCode,
  buttonClassName,
  spanClassName,
}: HiperPuntosProfileQrModalProps) {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { getUserProfileById, userProfile, isLoading } = useGetUserProfileById(
    userProfilesRepository
  );

  const { getMensajeCodigoClienteParameter, mensajeCodigoClienteParameter } =
    useGetMensajeCodigoClienteParameter(
      configParametersRepository,
      notificationsRepository
    );

  useEffect(() => {
    getUserProfileById();
    getMensajeCodigoClienteParameter();
  }, []);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    if (onCloseParentModal) onCloseParentModal();
  };
  return (
    <>
      <HiperPuntosProfileButton
        profileCode={profileCode}
        onClick={handleOpenModal}
        className={buttonClassName}
        spanClassName={spanClassName}
      />
      <Modal
        isOpen={openModal}
        ariaHideApp={false}
        onRequestClose={handleCloseModal}
        contentLabel="Perfil Hiper Puntos"
      >
        {!isLoading && userProfile ? (
          <Tabs>
            <Tab
              title="Código Cliente"
              buttonWrapperClassName="border-b-[2px] border-hiperorange_hover"
              wrapClassName="bg-hiperorange md:max-h-[700px] rounded-lg md:rounded-lg"
              activeButtonClassName="bg-hiperorange_hover text-white"
              buttonClassName="text-white"
            >
              <HiperPuntosProfileQrModalContent
                profileCode={profileCode}
                onCloseModal={handleCloseModal}
                mensaje={mensajeCodigoClienteParameter?.Valor || ""}
              />
            </Tab>
            <Tab
              title="Token de Autorización"
              buttonWrapperClassName="border-b-[2px] border-[#86AB89]"
              wrapClassName="bg-[#CBE2B5] md:max-h-[700px] rounded-lg md:rounded-lg"
              activeButtonClassName="bg-[#86AB89] text-white"
              buttonClassName="text-black"
            >
              <UserProfileAuthorizationTokenQrModalContent
                onCloseModal={handleCloseModal}
              />
            </Tab>
          </Tabs>
        ) : (
          <div
            className={classNames(
              "w-full flex flex-col h-full p-2 md:max-h-[700px] bg-hiperorange rounded-lg md:rounded-lg"
            )}
          >
            <HiperPuntosProfileQrModalContent
              profileCode={profileCode}
              onCloseModal={handleCloseModal}
              mensaje={mensajeCodigoClienteParameter?.Valor || ""}
            />
          </div>
        )}
      </Modal>
    </>
  );
}
