import { Helmet } from "react-helmet-async";

export function HomeCategoriaSEO({
  title,
  store,
  description,
  breadcrumbListJSONLdScript,
  itemListJSONLdScript,
  image,
  url,
  displayMetaTags = true,
  keywords = [],
}) {
  return (
    <Helmet title={title}>
      {/* Meta tags para Open Graph (Facebook) */}
      {displayMetaTags && <meta property="og:title" content={title} />}
      {displayMetaTags && (
        <meta property="og:description" content={description} />
      )}
      {displayMetaTags && <meta property="og:image" content={image} />}
      {displayMetaTags && <meta property="og:url" content={url} />}
      {displayMetaTags && <meta property="og:type" content="website" />}
      {displayMetaTags && <meta property="og:site_name" content={store} />}

      {/* Meta tags para Twitter Cards */}
      {displayMetaTags && (
        <meta name="twitter:card" content="summary_large_image" />
      )}
      {displayMetaTags && <meta name="twitter:title" content={title} />}
      {displayMetaTags && (
        <meta name="twitter:description" content={description} />
      )}
      {displayMetaTags && <meta name="twitter:image" content={image} />}
      {displayMetaTags && <meta name="twitter:url" content={url} />}
      {/* <meta name="twitter:site" content="@tu_usuario_twitter" /> */}

      <meta name="store" content={store} />
      <meta name="description" content={description} />
      {keywords.length > 0 && (
        <meta
          name="keywords"
          content={keywords.join(", ")}
        />
      )}
      {breadcrumbListJSONLdScript}
      {displayMetaTags && itemListJSONLdScript}
    </Helmet>
  );
}
