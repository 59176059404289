import {
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  ACTIVATION_SUCCESS,
  ACTIVATION_FAIL,
  SET_AUTH_LOADING,
  REMOVE_AUTH_LOADING,
  ACTIVACION_ANONIMO_SUCCESS,
  REFRESH_SUCCESS,
  REFRESH_FAIL,
  CLEAR_AUTH,
  PREFIJO_SUCCESS,
  PREFIJO_FAIL,
  CODE_ACCESS_EXTERM_SUCCESS,
  CODE_ACCESS_EXTERM_FAILS,
  DEPARTAMENTO_SUCCESS,
  DEPARTAMENTO_FAIL,
  SET_SOCIAL_USER_DATA,
} from "../actions/types";

const initialState = {
  access_token: localStorage.getItem("access_token"),
  isAuthenticated: null,
  user: null,
  loading: false,
  phone: localStorage.getItem("phone"),
  datosUsuario: {},
  sendAcceso: false,
  sendAccesoAnonimo: false,
  authenticated: localStorage.getItem("user"), //anonimo & usuario
  array_prefijo: [],
  array_departamento: [],
  datoLogin: null,
  socialUserData: null
};

export default function Auth(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_SOCIAL_USER_DATA:
      return {
        ...state,
        socialUserData: payload,
      };
    case SET_AUTH_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_AUTH:
      return {
        ...state,
        access_token: null,
        isAuthenticated: null,
        user: null,
        loading: false,
        phone: "",
        datosUsuario: {},
        sendAcceso: false,
        sendAccesoAnonimo: false,
        authenticated: null,
        datoLogin: null,
      };

    case REMOVE_AUTH_LOADING:
      return {
        ...state,
        loading: false,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem("access_token", payload.access_token);
      return {
        ...state,
        isAuthenticated: true,
        access_token: localStorage.getItem("access_token"),
      };
    case ACTIVATION_SUCCESS:
      localStorage.setItem("access_token", payload.access_token);
      return {
        ...state,
        isAuthenticated: true,
        authenticated: "usuario",
        access_token: localStorage.getItem("access_token"),
      };
    case ACTIVACION_ANONIMO_SUCCESS:
      localStorage.setItem("access_token", payload.access_token);
      return {
        ...state,
        sendAccesoAnonimo: true,
        authenticated: "anonimo",
        access_token: localStorage.getItem("access_token"),
      };
    case REFRESH_SUCCESS:
      localStorage.setItem("access_token", payload.access_token);
      return {
        ...state,
        isAuthenticated: true,
        access_token: localStorage.getItem("access_token"),
      };
    case REFRESH_FAIL:
      localStorage.removeItem("access_token");
      return {
        isAuthenticated: false,
      };

    case ACTIVATION_FAIL:
      return {
        ...state,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        sendAcceso: true,
        phone: payload.phone,
      };

    case SIGNUP_FAIL:
      return {
        ...state,
        sendAcceso: false,
      };

    case CODE_ACCESS_EXTERM_SUCCESS:
      return {
        ...state,
        datoLogin: payload,
      };
    case CODE_ACCESS_EXTERM_FAILS:
      return {
        ...state,
        datoLogin: payload,
      };
    case PREFIJO_SUCCESS:
      return {
        ...state,
        array_prefijo: payload.Dato,
      };
    case PREFIJO_FAIL:
      return {
        ...state,
        array_prefijo: [],
      };
    case DEPARTAMENTO_SUCCESS:
      return {
        ...state,
        array_departamento: payload.Dato,
      };
    case DEPARTAMENTO_FAIL:
      return {
        ...state,
        array_departamento: [],
      };
    case LOGIN_FAIL:
      localStorage.removeItem("access");
      return {
        ...state,
        access_token: null,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
}
