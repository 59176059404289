import Barcode from "react-barcode";

interface QRCodeComponentProps {
  value: string;
  width?: number;
}
export function BarcodeComponent({ value, width = 2 }: QRCodeComponentProps) {
  return (
    <Barcode value={value} format="CODE128" displayValue={false} width={width} />
  );
}
