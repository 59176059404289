import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faPenToSquare,
  faShareNodes,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import { colorEnUmbralDeBlanco } from "../components/Utils/utils-colores";
import { useThousandSeparator } from "../hooks/useThousandSeparator";

const ItemListaCompra = ({
  ps_shopping_list,
  ps_opcSelectedList,
  ps_deleteList,
  ps_openModalShare,
}) => {
  const { numberFormatter } = useThousandSeparator();
  const navigate = useNavigate();
  const { ciudadp, sucursalp } = useParams();

  const colorEsEnUmbralDeBlanco = colorEnUmbralDeBlanco(
    ps_shopping_list.Color,
    180
  );
  const SelectedOpc = (valor, opcion) => {
    ps_opcSelectedList(valor, opcion);
  };
  const eliminarList = (Id) => {
    ps_deleteList(Id);
  };
  const formatPrice = (value) => {
    return numberFormatter.format(value);
  };
  const verdetalle = async (id) => {
    let param = await get_url();
    navigate(param + `/detalle-lista-compra/${id}`);
  };
  const get_url = async () => {
    let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
    if (ciudadp && sucursalp) {
      return "/" + ciudadp + "/" + sucursalp;
    } else if (url_nav) {
      return "/" + url_nav.url_parameters;
    }
  };
  return (
    <div
      className={classNames(
        "flex flex-col justify-between rounded-md border-1 items-center w-full",
        {
          "text-white": !colorEsEnUmbralDeBlanco,
          "text-black": colorEsEnUmbralDeBlanco,
        }
      )}
      style={{
        backgroundColor: ps_shopping_list.Color,
        borderColor: colorEsEnUmbralDeBlanco
          ? "#000000"
          : ps_shopping_list.Color,
      }}
    >
      <button
        className="cursor-pointer grid grid-cols-6 w-full pt-2 px-2"
        // style={{
        //   width: "100%",
        //   // minWidth: "20rem",
        //   maxWidth: "23rem",
        //   overflow: "hidden",
        //   textOverflow: "ellipsis",
        //   whiteSpace: "nowrap",
        // }}
        key={ps_shopping_list.Id}
        onClick={() => verdetalle(ps_shopping_list.Id)}
      >
        <div className="col-span-4 truncate text-left">
          {" "}
          {ps_shopping_list.Descripcion}{" "}
        </div>
        <div className="col-span-2 font-medium text-right">
          Bs. {formatPrice(ps_shopping_list.ImporteTotal)}
        </div>
      </button>
      <div className="z-2 flex gap-2 items-center justify-between w-full">
        <button className="pb-2 pl-2 w-full text-left" onClick={() => verdetalle(ps_shopping_list.Id)}>
          (
          {`${ps_shopping_list.NroItems} producto${
            ps_shopping_list.NroItems > 1 ? "s" : ""
          }`}
          )
        </button>
        <div className="flex gap-3 pb-2 pr-2">
          <FontAwesomeIcon
            icon={faShareNodes}
            onClick={() => SelectedOpc(ps_shopping_list, "share")}
            className="cursor-pointer transition duration-200 ease-in-out transform hover:scale-125 text-xl md:text-base"
          />
          <FontAwesomeIcon
            icon={faPenToSquare}
            onClick={() => SelectedOpc(ps_shopping_list, "edit")}
            className="cursor-pointer transition duration-200 ease-in-out transform hover:scale-110 text-xl md:text-base"
          />

          <FontAwesomeIcon
            icon={faTrash}
            onClick={() => eliminarList(ps_shopping_list.Id)}
            className="cursor-pointer transition duration-200 ease-in-out transform hover:scale-110 text-xl md:text-base"
          />
        </div>
      </div>
    </div>
  );
};

export default ItemListaCompra;
