import QRCode from "react-qr-code";

interface QRCodeComponentProps {
  value: string;
  maxWidth: number;
}
export function QRCodeComponent({ value, maxWidth }: QRCodeComponentProps) {
  return (
    <div style={{ height: "auto", margin: "0 auto", maxWidth, width: "100%" }}>
      <QRCode
        size={256}
        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
        value={value}
        viewBox={`0 0 256 256`}
      />
    </div>
  );
}
