import React, { useEffect, useState } from "react";
import ItemHorario from "../../widgets/item_Horario";
import { connect } from "react-redux";
import {
  get_horarios_disponible_carrito,
  get_lista_carrito_compras,
} from "../../../src/redux/actions/carrito";
import { get_fechas_entrega_sucursal_carrito } from "../../../src/redux/actions/carrito";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoped,
  faMotorcycle,
  faStore,
} from "@fortawesome/pro-solid-svg-icons";
import { get_ubicacion } from "../../../src/redux/actions/location";
import {
  info_session,
  update_usuario,
} from "../../../src/redux/actions/profile";
import { useNavigate } from "react-router-dom";
import { set_tipo_entrega } from "../../../src/redux/actions/branch_office";
import Swal from "sweetalert2";
import classNames from "classnames";
import "../../assets/styles/responsive.scss";
import ButtonClose from "../../widgets/button_close";
import { CarritoResumen } from "../../components/modal/carrito/carrito_resumen";
import { useThousandSeparator } from "../../hooks/useThousandSeparator";
const EntregaCompraRapida = ({
  exit,
  mt_next,
  productos,
  importeTotal,
  set_tipo_entrega,
  tipo_entrega,
  sucursal,
  profile,
  get_lista_carrito_compras,
  list_carrito,
  fechas_disponibles_entrega,
  get_fechas_entrega_sucursal_carrito,
  horarios_disponibles,
  get_horarios_disponible_carrito,
  get_ubicacion,
  locations,
  direccion,
  info_session,
  products_shoping,
  width,
  height,
  importeAhorrodo,
  update_usuario,
}) => {
  const { numberFormatter } = useThousandSeparator();

  const [tipo_entregas, guardarTipoEntrega] = useState([]);
  const [arrayFechaEntregas, setArrayFechasEntregas] = useState([]);
  const [arrayHorarios, setArrayHorarios] = useState([]);

  const formatNumber = (value) => {
    return numberFormatter.format(value);
  };

  const [formData, setFormData] = useState({
    telefono: "",
    direccion: "",
    nombres: "",
    apellidoPaterno: "",
  });

  const [errors, setErrors] = useState({});
  const [dia, setDia] = useState({});
  const [horario, setHorario] = useState({});

  const [dias] = useState([
    {
      name: "Lunes",
      cod: "LU",
    },
    {
      name: "Martes",
      cod: "MA",
    },
    {
      name: "Miércoles",
      cod: "MI",
    },
    {
      name: "Jueves",
      cod: "JU",
    },
    {
      name: "Viernes",
      cod: "VI",
    },
    {
      name: "Sábado",
      cod: "SA",
    },
    {
      name: "Domingo",
      cod: "DO",
    },
  ]);
  const [meses] = useState([
    {
      name: "en.",
      cod: "01",
    },
    {
      name: "feb",
      cod: "02",
    },
    {
      name: "mar.",
      cod: "03",
    },
    {
      name: "abr.",
      cod: "04",
    },
    {
      name: "may.",
      cod: "05",
    },
    {
      name: "jun.",
      cod: "06",
    },
    {
      name: "jul.",
      cod: "07",
    },
    {
      name: "ag.",
      cod: "08",
    },
    {
      name: "sept.",
      cod: "09",
    },
    {
      name: "oct.",
      cod: "10",
    },
    {
      name: "nov.",
      cod: "11",
    },
    {
      name: "dic.",
      cod: "12",
    },
  ]);

  useEffect(() => {
    if (sucursal !== null) {
      guardarTipoEntrega(sucursal.TipoEntregas);
    }
  }, [sucursal]);

  useEffect(() => {
    if (profile && direccion) {
      setFormData({
        telefono: profile.Telefono,
        direccion: direccion.Descripcion,
        nombres: profile.Nombres,
        apellidoPaterno: profile.ApellidoPaterno,
      });
    }
  }, [profile, direccion]);

  useEffect(() => {
    if (!fechas_disponibles_entrega) {
      if (tipo_entrega && sucursal) {
        let sucursal_ids = [sucursal.IdSucursal];
        get_fechas_entrega_sucursal_carrito(tipo_entrega.Id, sucursal_ids);
      }
    }
  }, []);

  useEffect(() => {
    if (fechas_disponibles_entrega) {
      setArrayFechasEntregas(fechas_disponibles_entrega[0].Dias);
      let dia1 = fechas_disponibles_entrega[0].Dias[0];
      setDia(dia1);
    }
  }, [fechas_disponibles_entrega]);
  useEffect(() => {
    if (Object.keys(dia).length > 0) {
      let data = {
        IdSucursal: sucursal.IdSucursal,
        FechaEntrega: dia.Fecha,
        IdFecha: dia.IdFecha,
        Importe: importeTotal,
        IdTipoEntrega: tipo_entrega.Id,
      };
      get_horarios_disponible_carrito(data);
    }
  }, [dia]);
  useEffect(() => {
    if (horarios_disponibles) {
      setArrayHorarios(horarios_disponibles.Horarios);
      if (horarios_disponibles.Horarios.length > 0) {
        setHorario(horarios_disponibles.Horarios[0]);
      } else {
        setHorario({});
      }
    }
  }, [horarios_disponibles]);

  const actualizarTipoEntrega = (item_tipo_entrega) => {
    set_tipo_entrega(item_tipo_entrega);
  };

  const validateNombresYApellidoPaterno = (nombres, apellidoPaterno) => {
    // const errors = {
    //   nombres: {
    //     required: !nombres,
    //     minLength: !!nombres && nombres.length < 3,
    //   },
    //   apellidoPaterno: {
    //     required: !apellidoPaterno,
    //     minLength: !!apellidoPaterno && apellidoPaterno.length < 3,
    //   },
    // };

    const errors = {};
    if (!nombres) {
      errors.nombres = {
        required: "El campo Nombre (s) es obligatorio",
      };
    } else if (nombres.length < 3) {
      errors.nombres = {
        minLength: "El campo Nombre (s) debe tener 3 caracteres como mínimo",
      };
    }

    if (!apellidoPaterno) {
      errors.apellidoPaterno = {
        required: "El campo Apellido Paterno es obligatorio",
      };
    } else if (apellidoPaterno.length < 3) {
      errors.apellidoPaterno = {
        minLength:
          "El campo Apellido Paterno debe tener 3 caracteres como mínimo",
      };
    }

    setErrors(!!errors.nombres || !!errors.apellidoPaterno ? errors : null);
    return !!errors.nombres || !!errors.apellidoPaterno;
  };

  const save_datos_entrega = async (e) => {
    if (Object.keys(dia).length == 0) {
      mensaje_alert("Debe Seleccionar una fecha");
      return;
    }
    if (Object.keys(horario).length == 0) {
      mensaje_alert("Debe Seleccionar un horario de entrega");
      return;
    }

    if (!profile.Nombres || !profile.ApellidoPaterno) {
      const hasValidNombresYApellidoPaterno = validateNombresYApellidoPaterno(
        formData.nombres?.trim(),
        formData.apellidoPaterno?.trim()
      );

      if (hasValidNombresYApellidoPaterno) return;

      let data_profile = {
        Nombres: formData.nombres?.trim(),
        ApellidoPaterno: formData.apellidoPaterno?.trim(),
        Telefono: formData.telefono,
      };
      try {
        const result = await update_usuario(data_profile);
        if (!result) return;
      } catch (error) {
        return;
      }
    }
    let data = {
      productos: productos,
      IdTipoEntrega: tipo_entrega.Id,
      IdUbicacion: direccion.Id,
      Horario: {
        IdSucursal: sucursal.IdSucursal,
        IdFecha: dia.IdFecha,
        IdHorario: horario.IdHorario,
        IdHorarioEspecial: horario.IdHorarioEspecial,
        CostoDelivery: horario.CostoDelivery,
        Costo: horario.Costo,
      },
    };
    mt_next(data);
  };

  const existe_lista_horarios = () => {
    return arrayHorarios.length > 0 ? true : false;
  };

  const Cerrar = () => {
    exit();
  };

  const cambiarSeleccion = (
    horarioId,
    IdHorarioEspecial,
    IdSucursal,
    CostoDelivery,
    Costo
  ) => {
    let horario = arrayHorarios.find((h) => h.IdHorario === horarioId);
    setHorario(horario);
  };

  const is_item_check = (h1) => {
    return horario == h1 ? true : false;
  };

  const mensaje_alert = (text) => {
    Swal.fire({
      icon: "warning",
      text: text,
      showConfirmButton: true,
      confirmButtonText: "Cerrar",
      confirmButtonColor: "#32338E",
    });
  };
  const get_dia_litera = (fecha) => {
    var aux = fecha.split(" ");
    let d = dias_text(aux[0]);
    if (d) {
      return d;
    }
    return aux[0];
  };
  const dias_text = (dia) => {
    let di = dias.find((item) => item.cod === dia);
    if (di) {
      return di.name;
    }
    return dia;
  };
  const get_dia = (fecha) => {
    var aux = fecha.split("/");
    return aux[0];
  };
  const get_mes = (fecha) => {
    var aux = fecha.split("/");
    let m = mes_text(aux[1]);
    if (m) {
      return m;
    }
    return m;
  };
  const mes_text = (mes) => {
    let me = meses.find((item) => item.cod === mes);
    if (me) {
      return me.name;
    }
    return me;
  };

  return (
    <>
      <div
        className={classNames("w-full md:w-[60%] flex flex-col h-full")}
      >
        <div className="w-full bg-white rounded-t-lg md:rounded-lg h-full overflow-hidden">
          <div className="flex flex-col h-full">
            <div className="bg-hiperorange w-full text-white flex items-center justify-center  content_title relative">
              <h2 className="p-0 m-0 title_carrito_responsivo ">
                Modo de entrega
              </h2>
              <div className="absolute right-2 ">
                <ButtonClose
                  className="text-white bg-transparent"
                  onClick={() => exit()}
                />
              </div>
            </div>
            <div
              className={classNames(
                "bg-white overflow-y-auto"
              )}
            >
              <div className="my-2 mx-2 p-2 bg-hiperplomo_claro rounded-hiper_g">
                <p className="text-black text-md md:text-base mb-1 font-bold">
                  Selecciona el modo de entrega
                </p>

                <div className="flex flex-wrap gap-2">
                  {tipo_entregas &&
                    tipo_entregas !== null &&
                    tipo_entregas !== undefined &&
                    tipo_entregas
                      .filter(
                        (x) =>
                          x.Id !=
                          process.env.REACT_APP_TIPO_ENTREGA_COMPRA_EN_SALA
                      )
                      .map((item_tipo_entrega, index) => (
                        <button
                          key={index}
                          className={classNames(
                            "border-1 font-medium rounded-lg flex items-center gap-2 px-2 py-1 text-sm text-hiperplomo_fuerte hover:bg-hiperorange hover:text-white hover:border-hiperorange leading-none",
                            {
                              "bg-hiperorange text-white border-hiperorange":
                                item_tipo_entrega.Id ===
                                (tipo_entrega != null && tipo_entrega.Id),
                              "border-black":
                                item_tipo_entrega.Id !==
                                (tipo_entrega != null && tipo_entrega.Id),
                            }
                          )}
                          onClick={() => {
                            actualizarTipoEntrega(item_tipo_entrega);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={
                              item_tipo_entrega.Id === 2 ? faMoped : faStore
                            }
                            style={{ height: "20px" }}
                            className="hover:text-white "
                          />
                          <span>{item_tipo_entrega.Descripcion}</span>
                        </button>
                      ))}
                </div>
              </div>

              <div
                className="bg-hiperplomo_claro rounded-hiper_g overflow-hidden my-1  mx-2 px-2 py-2 "
                key={sucursal.IdSucursal}
              >
                <div className="flex mb-2 gap-2">
                  <p className="text-md md:text-base text-black font-bold mb-0 text-uppercase">
                    {"Sucursal"}
                  </p>
                  <p className="text-md md:text-base text-black font-bold mb-0">
                    {sucursal.Descripcion}
                  </p>
                </div>

                <div className="flex  flex-column h-[calc(100%-4.5rem)]">
                  <div className="p-2 bg-white  rounded-hiper_g mb-2 ">
                    {!profile.Nombres && (
                      <div className="mb-3">
                        <label
                          className="block fontzize_label text-black  mb-2"
                          htmlFor="name"
                        >
                          Nombre(s)*
                        </label>
                        <input
                          className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                          id="name"
                          type="text"
                          name="name"
                          value={formData.nombres}
                          onChange={(e) => {
                            setFormData((prevData) => ({
                              ...prevData,
                              nombres: e.target.value
                                .trim()
                                .replace(/[^a-zA-ZáéíóúñÁÉÍÓÚÑ\s\d]/g, ""),
                            }));
                            setErrors((prevData) => {
                              const newData = prevData ? { ...prevData } : null;
                              if (newData && newData.nombres) {
                                delete newData.nombres;
                              }
                              return newData;
                            });
                          }}
                          minLength={3}
                        />
                        {errors?.nombres && (
                          <span style={{ color: "#920909" }}>
                            {errors.nombres.required ||
                              errors.nombres.minLength}
                          </span>
                        )}
                      </div>
                    )}
                    {!profile.ApellidoPaterno && (
                      <div className="mb-3">
                        <label
                          className="block fontzize_label text-black  mb-2"
                          htmlFor="name"
                        >
                          Apellido Paterno*
                        </label>
                        <input
                          className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                          id="name"
                          type="text"
                          name="name"
                          value={formData.apellidoPaterno}
                          onChange={(e) => {
                            setFormData((prevData) => ({
                              ...prevData,
                              apellidoPaterno: e.target.value
                                .trim()
                                .replace(/[^a-zA-ZáéíóúñÁÉÍÓÚÑ\s\d]/g, ""),
                            }));
                            setErrors((prevData) => {
                              const newData = prevData ? { ...prevData } : null;
                              if (newData && newData.apellidoPaterno) {
                                delete newData.apellidoPaterno;
                              }
                              return newData;
                            });
                          }}
                        />
                        {errors?.apellidoPaterno && (
                          <span style={{ color: "#920909" }}>
                            {errors.apellidoPaterno.required ||
                              errors.apellidoPaterno.minLength}
                          </span>
                        )}
                      </div>
                    )}
                    <div className="mb-3">
                      <label
                        className="block fontzize_label text-black  mb-2"
                        htmlFor="name"
                      >
                        Teléfono
                      </label>
                      <input
                        className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                        id="name"
                        type="number"
                        name="telefono"
                        value={formData.telefono}
                        disabled
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        className="block fontzize_label text-black  mb-2"
                        htmlFor="name"
                      >
                        Dirección
                      </label>
                      <input
                        className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                        id="name"
                        type="text"
                        name="direccion"
                        value={formData.direccion}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="bg-white rounded-hiper_g p-2">
                    <p className="text-black font-bold mb-2">
                      Selecciona el día de tu entrega
                    </p>

                    <div className="flex flex-wrap gap-2">
                      {arrayFechaEntregas.map((fecha, index4) => (
                        <button
                          className={` rounded-hiper_g text-white mx-1 px-2 transition duration-300 ease-in-out hover:scale-105 ${
                            dia.IdFecha === fecha.IdFecha
                              ? "bg-hiperorange"
                              : "bg-white border-1 border-black"
                          }   `}
                          style={{
                            cursor: "pointer",
                            width: "5rem",
                            height: "5rem",
                          }}
                          onClick={async (e) => await setDia(fecha)}
                          key={index4}
                        >
                          <div className="text-white">
                            <span
                              className={`text-[12px] d-block text-black font-bold ${
                                dia.IdFecha === fecha.IdFecha
                                  ? "text-white"
                                  : "text-hiperplomo_suave"
                              } `}
                            >
                              {get_dia_litera(fecha.Titulo)}
                            </span>
                            <span
                              className={`text-[12px] d-block text-black font-bold ${
                                dia.IdFecha === fecha.IdFecha
                                  ? "text-white"
                                  : "text-hiperplomo_suave"
                              } `}
                            >
                              {get_dia(fecha.Fecha)} {get_mes(fecha.Fecha)}{" "}
                            </span>
                          </div>
                        </button>
                      ))}
                    </div>
                  </div>

                  <div className="h-[calc(100%-4.5rem)] bg-white overflow-y-auto mt-2 p-2 mb-32 md:mb-0">
                    <div className="text-start py-1">
                      <p className="text-black font-bold mb-2">
                        Selecciona la franja horaria
                      </p>
                    </div>
                    {tipo_entrega?.Id === 2 && (
                      <div className="font-semibold text-right pr-2 mb-1">
                        Costo envío
                      </div>
                    )}
                    {existe_lista_horarios() ? (
                      <div className="flex flex-col gap-1">
                        {arrayHorarios.map((h1, index) => (
                          <ItemHorario
                            key={index}
                            idHorario={h1.IdHorario}
                            IdHorarioEspecial={h1.IdHorarioEspecial}
                            Descripcion={h1.Descripcion}
                            CostoDelivery={h1.CostoDelivery}
                            Costo={h1.Costo}
                            sucursal={sucursal.IdSucursal}
                            tipo_entrega={tipo_entrega}
                            is_check={is_item_check(h1)}
                            changeSelec={cambiarSeleccion}
                          ></ItemHorario>
                        ))}
                      </div>
                    ) : (
                      <> No hay horarios disponibles</>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:w-[22%] sticky bottom-0">
        <CarritoResumen
          costo_delivery={
            horario.Costo > 0 ? formatNumber(horario.Costo) : "Gratis"
          }
          ahorro_total={formatNumber(importeAhorrodo)}
          total_carrito={formatNumber(importeTotal)}
          volverAtras={Cerrar}
          guardarProceso={save_datos_entrega}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  list_carrito: state.Carrito.list_carrito,
  fechas_disponibles_entrega: state.Carrito.fechas_disponibles_entrega,
  horarios_disponibles: state.Carrito.horarios_disponibles,
  sucursal: state.BranchOffice.sucursal,
  locations: state.Location.locations,
  direccion: state.Location.direccion,
  profile: state.Profile.profile,
  products_shoping: state.Shopping_list.products_shoping,
  tipo_entrega: state.BranchOffice.tipo_entrega,
});

export default connect(mapStateToProps, {
  get_lista_carrito_compras,
  get_fechas_entrega_sucursal_carrito,
  get_horarios_disponible_carrito,
  get_ubicacion,
  info_session,
  set_tipo_entrega,
  update_usuario,
})(EntregaCompraRapida);
