import React, { useEffect, useState } from "react";
import texture from "../../../../assets/images/login/texture_complete.jpg";
import "../../../../assets/fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMessage,
  faMobileAndroidAlt,
} from "@fortawesome/pro-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "../../../../assets/styles/mystyle.scss";
import "../../../../assets/styles/globalColorPrincipal.css";
import "../../../../assets/styles/stylePageGlobals.scss";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
// import "../../../../containers/auth/input-phone.css";
import { connect } from "react-redux";
import {
  codigo_acceso,
  get_token,
  get_prefijos,
} from "../../../../redux/actions/auth";
import { useNavigate } from "react-router-dom";
import { info_session } from "../../../../redux/actions/profile";
import { useForm } from "react-hook-form";
import ButtonIcon from "../../../../widgets/button_icon";
import LoaderSpinner from "../../../../widgets/loaderSpinner";

const containerStyle2 = {
  backgroundImage: `url(${texture})`,
  width: "70%",
  borderRadius: "20px",
};

const Registro_user = ({
  array_prefijo,
  peticion,
  loading,
  sendAcceso,
  get_prefijos,
  codigo_acceso,
  profile,
  datoLogin,
  get_token,
  access_token,
  ps_posicion,
}) => {
  const navigate = useNavigate();
  const [value, setValue] = useState("+591");
  const [paises, setPaises] = useState([]);
  const [canalEnvio, setCanalEnvio] = useState([]);
  const [formData, setFormData] = useState({
    telefono: "",
    prefijo: "",
    TipoEnvioAcceso: "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (array_prefijo.length === 0) {
      get_prefijos();
    }
  }, [array_prefijo]);
  useEffect(() => {
    if (array_prefijo.length > 0) {
      let countries = array_prefijo.map((p) => {
        return p.ISO2;
      });
      let seleccionado = array_prefijo.find((pre) => pre.NumeroPrefijo === 591);
      setCanalEnvio(seleccionado.IdCanalesEnvio);
      setPaises(countries);
    }
  }, [array_prefijo]);
  useEffect(() => {
    if (value && array_prefijo.length > 0) {
      let numero = value.replace(/[^0-9]+/g, "");
      let seleccionado = array_prefijo.find(
        (pre) => Number(pre.NumeroPrefijo) === Number(numero)
      );

      if (seleccionado !== undefined) {
        setCanalEnvio(seleccionado.IdCanalesEnvio);
      }
    }
  }, [value]);

  useEffect(() => {
    if (sendAcceso) {
      ps_posicion(2);
    }
  }, [sendAcceso]);

  function is_whatsapp() {
    return canalEnvio.find((c) => c === 1);
  }
  function is_sms() {
    return canalEnvio.find((c) => c === 2);
  }

  const messages = {
    required: "Este campo es obligatorio",
    minLength: "No puede ingresar menor caracteres de lo establecido",
    maxLength: "No puede ingresar más caracteres en este campo",
    espacioBlanco: "No es permitido espacios en blanco",
    requiredColor: "Debe seleccionar un color",
    aceptaNumeros: "Solo se acepta números en este campo",
    aceptaLetra: "Solo se acepta letras en este campo",
  };
  const patterns = {
    espacioBlanco: /^(?!\s)/,
    aceptaNumeros: /^[0-9,-]+$/,
    aceptaLetra: /^[A-Za-z\s]+$/,
  };

  const onSubmit = async (data) => {
    formData.telefono = data.telefono;
    if (typeof value == "undefined" || value === "") {
      formData.prefijo = 591;
    } else {
      formData.prefijo = value.replace(/[^0-9]+/g, "");
    }

    await codigo_acceso(
      data.telefono,
      formData.prefijo,
      formData.TipoEnvioAcceso
    );
  };
  const tipoWhatsapp = (e) => {
    formData.TipoEnvioAcceso = 1;
  };
  const tipoSms = (e) => {
    formData.TipoEnvioAcceso = 2;
  };

  return (
    <>
      <div
        className="relative  h-auto flex justify-center content_modal_registro"
        style={{ minHeight: "40rem" }}
      >
        <div
          className="hiper-color absolute bottom-0 w-full"
          style={containerStyle2}
        >
          <div className="h-[auto] max-h-full p-4">
            <h1 className="text-4xl font-normal text-center text-white my-4">
              Ingresar
            </h1>
            <LoaderSpinner bool={peticion} />
            <div
              className="content_info text-center bg-white px-4 mx-4 py-1 mb-2"
              style={{ borderRadius: "10px" }}
            >
              <p className="text-gray-400">
                Para tener una mejor experiencia y poder utilizar todas las
                funcionalidades de la aplicación verifica tu número de teléfono
              </p>
            </div>

            <form
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
              className="flex justify-center m-0 pb-4"
            >
              <div className="box-input">
                <div className="centenido_form">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-white"
                  >
                    <FontAwesomeIcon
                      icon={faMobileAndroidAlt}
                      style={{ height: "20px" }}
                    />{" "}
                    Con tu número de celular
                  </label>
                  <div className="mt-1">
                    <div className="w-full flex gap-1">
                      {paises.length > 0 && (
                        <div className="codigo" style={{ width: "30%" }}>
                          <PhoneInput
                            countries={paises}
                            className=" rounded-md "
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry="BO"
                            value={value}
                            onChange={setValue}
                            rules={{ required: true }}
                            readOnly={true}
                          />
                        </div>
                      )}

                      <div className="input_tel" style={{ width: "70%" }}>
                        <input
                          name="telefono"
                          className=" w-full h-100 focus:outline-none rounded-md"
                          style={{ padding: "0px 8px" }}
                          type="text"
                          autoComplete="false"
                          {...register("telefono", {
                            required: {
                              value: true,
                              message: messages.required,
                            },
                            minLength: {
                              value: 8,
                              message: messages.minLength + ", 8 caracteres",
                            },
                            maxLength: {
                              value: 8,
                              message: messages.maxLength,
                            },
                            pattern: {
                              value: patterns.espacioBlanco,
                              message: messages.espacioBlanco,
                            },
                            pattern: {
                              value: patterns.aceptaNumeros,
                              message: messages.aceptaNumeros,
                            },
                          })}
                        />
                        {errors.telefono && (
                          <small className="text-red-600">
                            {errors.telefono.message}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-2 mt-2 text-white">
                  {canalEnvio.length > 0 && (
                    <>
                      {is_whatsapp() && (
                        <ButtonIcon
                          type="submit"
                          icon={faWhatsapp}
                          label="Recibir código por WhatsApp"
                          principal={false}
                          onClick={(e) => tipoWhatsapp()}
                          className={"text-[#DC842D] bg-white"}
                        />
                      )}
                      {is_sms() && (
                        <ButtonIcon
                          type="submit"
                          icon={faMessage}
                          principal={false}
                          label="Recibir código por SMS"
                          onClick={() => tipoSms()}
                          className={"text-[#DC842D] bg-white"}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  loading: state.Auth.loading,
  datoLogin: state.Auth.datoLogin,
  sendAccesoAnonimo: state.Auth.sendAccesoAnonimo,
  authenticated: state.Auth.authenticated,
  profile: state.Profile.profile,
  access_token: state.Auth.access_token,
  peticion: state.Peticion.peticion,
  array_prefijo: state.Auth.array_prefijo,
  sendAcceso: state.Auth.sendAcceso,
});

export default connect(mapStateToProps, {
  get_prefijos,
  codigo_acceso,
  get_token,
  info_session,
})(Registro_user);
