import { combineReducers } from "redux";
import Auth from "./auth";
import Profile from "./profile";
import Location from "./location";
import Shopping_list from "./shoppingList";
import Mycard_list from "./myCardList";
import BranchOffice from "./branch_office";
import Pedido from "./pedido";
import Home from "./home";
import Carrito from "./carrito";
import MetodoPago from "./metodoPagos";
import Producto from "./producto";
import Notification from "./notification";
import Tarjeta from "./tarjeta";
import Swalerts from "./swalerts";
import Peticion from "./Peticion";
import UrlNavegacion from "./url_navegacion";
import HiperPuntos from "../../modules/hiper-puntos/ui/ui/redux/reducers/hiper-puntos-reducer";
import SucursalContactos from "../../modules/sucursal-contactos/ui/redux/reducers/sucursal-contactos-reducer";

export default combineReducers({
  Auth,
  Profile,
  Location,
  Shopping_list,
  BranchOffice,
  Pedido,
  Home,
  Carrito,
  Mycard_list,
  MetodoPago,
  Producto,
  Notification,
  Tarjeta,
  Swalerts,
  Peticion,
  UrlNavegacion,
  HiperPuntos,
  SucursalContactos
});
