import { ConfigParameter } from "../../../../../config-parameters/domain/interface/config-parameter";
import { CustomerBag } from "../../../../domain/interfaces/customer-bag";
import {
  CustomerBagsDispatchType,
  CustomerBagsSearchTermDispatchType,
  HiperPuntosDispatchType,
  SET_CUSTOMER_BAGS,
  SET_CUSTOMER_BAGS_SEARCH_TERM,
  SET_VER_HIPERPUNTOS_PARAMETER,
} from "../types/hiper-puntos-types";

export const setVerHiperPuntosParameter =
  (verHiperPuntosParameter?: ConfigParameter) =>
  (dispatch: HiperPuntosDispatchType) => {
    dispatch({
      type: SET_VER_HIPERPUNTOS_PARAMETER,
      payload: verHiperPuntosParameter,
    });
  };

export const setCustomerBags =
  (customerBags?: CustomerBag[]) => (dispatch: CustomerBagsDispatchType) => {
    dispatch({
      type: SET_CUSTOMER_BAGS,
      payload: customerBags,
    });
  };

export const setCustomerBagsSearchTerm =
  (searchTerm: string) => (dispatch: CustomerBagsSearchTermDispatchType) => {
    dispatch({
      type: SET_CUSTOMER_BAGS_SEARCH_TERM,
      payload: searchTerm,
    });
  };
