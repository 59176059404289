import { responseHandler } from "../../../http/application/response/response-handler";
import { UserProfile } from "../../domain/interface/user-profile";
import { UserProfilesRepository } from "../../domain/user-profiles-repository";

export function getHashCuentaCorriente(
  userProfilesRepository: UserProfilesRepository
) {
  return async (
  ): Promise<UserProfile> => {
    const response = await userProfilesRepository.getHashCuentaCorriente(
    );
    return responseHandler<UserProfile, null>(response);
  };
}
