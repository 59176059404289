import classNames from "classnames";
import { CustomerBag } from "../../../domain/interfaces/customer-bag";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight } from "@fortawesome/pro-solid-svg-icons";

export function CustomerBagCard(customerBag: CustomerBag) {
  const { ciudadp, sucursalp } = useParams();
  return (
    <Link
      to={`/${ciudadp}/${sucursalp}/hiper-puntos/bolsa/${customerBag.IdBolsaPunto}`}
      className={classNames(
        "flex rounded-md border-1 hover:border-hiperorange shadow-md items-center w-full bg-white text-black p-3 group"
      )}
    >
      <div className="flex flex-col gap-1 w-full">
        <div className="w-full truncate text-left font-semibold text-lg">
          {customerBag.descripcionBolsa}
        </div>
        <div className="w-full inline-block">
          <span className="font-semibold">HiperPuntos: </span>
          {customerBag.SaldoPuntos}
        </div>
      </div>
      <div>
        <div className="w-12 h-12 bg-hiperorange transition ease-in-out duration-300 group-hover:scale-110 text-white flex items-center justify-center rounded-full">
          <FontAwesomeIcon icon={faAnglesRight} size="xl" />
        </div>
      </div>
    </Link>
  );
}
