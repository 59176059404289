import { faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

export default function FooterAccordion({
  toggleAccordion,
  title,
  isOpen,
  children,
}) {
  return (
    <div className="rounded-lg mb-1">
      <button
        className={classNames(
          "h-8 rounded-lg w-full text-left font-semibold text-white flex justify-between items-center"
        )}
        onClick={toggleAccordion}
      >
        {title}
        <FontAwesomeIcon icon={isOpen ? faMinus : faPlus} />
      </button>
      {isOpen && (
        <div
          className={classNames("px-2",{
            "opacity-0": !isOpen,
            "opacity-100": isOpen,
          })}
        >
          {children}
        </div>
      )}
    </div>
  );
}
