import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "../lazyLoadImage";
import {
  limpiar_carrito,
  store_carrito,
  limpiar_solo_carrito,
} from "../../redux/actions/carrito";

const Capsula3 = ({
  Items: productos,
  limpiar_carrito,
  carritos,
  producto_delete,
  direccion,
  tipo_entrega,
  store_carrito,
  limpiar_solo_carrito,
}) => {
  const redirectToSucursal = (url) => {
    window.location.href = url;
  };

  const handleGoToSucursal = async (url) => {
    const localStorageSucursal = localStorage.getItem("sucursal");
    const previousSucursal = localStorageSucursal
      ? JSON.parse(localStorageSucursal)
      : null;
    if (carritos && carritos.length > 0 && previousSucursal) {
      await store_carrito(
        previousSucursal,
        tipo_entrega,
        direccion,
        carritos,
        producto_delete,
        false
      );
      limpiar_solo_carrito();
    }
    await limpiar_carrito();
    // get_carrito_por_sucursal(sucursal);
    redirectToSucursal(url);
  };
  return (
    <>
      <div className="w-100">
        <div className="gap-3 md:px-0 grid md:grid-cols-2 lg:grid-cols-4">
          {productos?.map((producto, index) => (
            <a
              key={`capsula-3-${producto.Id}`}
              href={producto.Url_Destino}
              onClick={(e) => {
                e.preventDefault();
                handleGoToSucursal(producto.Url_Destino);
              }}
              // reloadDocument
              className="w-full cursor-pointer rounded-lg overflow-hidden transition duration-200 ease-in-out transform hover:scale-105"
            >
              <div className="w-full h-0 pb-[100%] relative">
                <LazyLoadImage
                  src={producto.UrlFoto}
                  alt=""
                  className="absolute top-0 left-0 w-full h-full object-cover"
                />
              </div>
            </a>
          ))}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  banners: state.Home.banners,
  carritos: state.Carrito.carrito,
  producto_delete: state.Carrito.producto_delete,
  direccion: state.Location.direccion,
  tipo_entrega: state.BranchOffice.tipo_entrega,
});

export default connect(mapStateToProps, {
  limpiar_carrito,
  store_carrito,
  limpiar_solo_carrito,
})(Capsula3);
