import { useSelector } from "react-redux";
import { CustomerBag } from "../../domain/interfaces/customer-bag";

export function useFilterCustomerBags(customerBags: CustomerBag[]) {
  const customerBagsSearchTerm = useSelector(
    (state: any) => state.HiperPuntos.customerBagsSearchTerm
  );

  const filteredCustomerBags = customerBags.filter((customerBags) =>
    customerBags.descripcionBolsa
      .toLowerCase()
      .includes(customerBagsSearchTerm.toLowerCase())
  );
  return { filteredCustomerBags, customerBagsSearchTerm };
}
