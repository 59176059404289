import {
  faFacebook,
  faInstagram,
  faTiktok,
  faXTwitter,
  faYoutube,
  IconDefinition,
} from "@fortawesome/free-brands-svg-icons";
import { MediaType } from "../../../sucursal-contactos/domain/types/media-types";
import { MediaTypeName } from "../../../sucursal-contactos/domain/enums/media-type-names-";
import { useGetSucursalContactoMediaLinks } from "../../../sucursal-contactos/ui/hooks/useGetSucursalContactoMediaLinks";

export interface MediaLink {
  name: string;
  href: string;
  icon?: IconDefinition;
}

export function useBuildFooterMediaLinks() {
  const { sucursalContactoMediaLinks } = useGetSucursalContactoMediaLinks();
  const getMediaLinksIcon = (mediaType: MediaType) => {
    switch (mediaType) {
      case "facebook":
        return faFacebook;
      case "instagram":
        return faInstagram;
      case "youtube":
        return faYoutube;
      case "twitter":
        return faXTwitter;
      case "tiktok":
        return faTiktok;
      default:
        return undefined;
    }
  };

  const mediaLinks: MediaLink[] = sucursalContactoMediaLinks.map(
    (mediaLink) => ({
      href: mediaLink.url,
      name: MediaTypeName[mediaLink.type],
      icon: getMediaLinksIcon(mediaLink.type),
    })
  );
  return { mediaLinks };
}
