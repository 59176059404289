import { useSelector } from "react-redux";
import { SucursalContacto } from "../../domain/interface/sucursal-contacto";

export function useGetSucursalContactoEmails() {
  const sucursalContacto = useSelector(
    (state: { SucursalContactos: { sucursalContacto: SucursalContacto } }) =>
      state.SucursalContactos.sucursalContacto
  );

  const emailConsultas = sucursalContacto?.Correo1;

  return { emailConsultas };
}
