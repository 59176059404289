import { createAxiosHttpService } from "../../http/infrastructure/axios-http-service";
import { ConfigParametersRepository } from "../domain/config-parameters-repository";
import { MensajeApi } from "../../http/domain/interfaces/mensaje-api";
import { ConfigParameter } from "../domain/interface/config-parameter";
import { ConfigParametesGetByKeyParameters } from "../domain/dto/config-parameters-get-by-key-parametes";
import { mapToURLSearchParams } from "../../http/infrastructure/utils/http-utils";

const http = createAxiosHttpService();
const apiVersion = process.env.REACT_APP_API_VERSION;
// const baseUrl = getBaseUrlBasedOnDomain(
// 	env.fidelizacionAtencionClienteBackendApiPath,
// 	env.fidelizacionAtencionClienteBackendApiDomainPath
// );
const baseUrl = process.env.REACT_APP_API_URL;
const backendPath = `${baseUrl}/api/${apiVersion}`;

export function createApiConfigParametersRepository(): ConfigParametersRepository {
  async function getByKey(
    configParametesGetByKeyParameters: ConfigParametesGetByKeyParameters
  ): Promise<MensajeApi<ConfigParameter>> {
    const searchParams = mapToURLSearchParams(
      configParametesGetByKeyParameters
    );
    const response = await http.get<MensajeApi<ConfigParameter>>(
      `${backendPath}/configparametro/obtener-por-keyconfig?${searchParams}`
    );

    return response;
  }

  return {
    getByKey,
  };
}
