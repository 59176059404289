export const buildStoreOrganizationJSONLd = ({ name }, context) => {
  return {
    "@context": context,
    "@type": "Organization",
    name,
  };
};

export const buildPostalAddressJSONLd = (
  { streetAddress, addressLocality, addressRegion, postalCode, addressCountry },
  context
) => {
  const postalAddressJSON = {
    "@context": context,
    "@type": "PostalAddress",
    streetAddress,
    addressLocality,
    addressRegion,
    // postalCode,
    addressCountry,
  };
  return postalAddressJSON;
};

export const buildStoreJSONLd = (
  {
    name,
    parentOrganization,
    address,
    telephone,
    openingHours,
    url,
    latitude,
    longitude,
    additionalType,
  },
  context
) => {
  // Recommended data from https://developers.google.com/search/docs/data-types/product
  const organizationJSON = {
    "@context": context,
    "@type": "Store",
    additionalType,
    name,
    parentOrganization: buildStoreOrganizationJSONLd(parentOrganization),
    address: buildPostalAddressJSONLd(address),
    telephone,
    openingHours,
    url,
    latitude,
    longitude,
  };

  return organizationJSON;
};
