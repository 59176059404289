import React, { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartCircleXmark,
  faHeart,
  faMinus,
  faPlus,
  faTrashCan,
  faX,
} from "@fortawesome/pro-solid-svg-icons";
import classNames from "classnames";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import FullScreenImage from "./../widgets/fullScreenImage";
import Page_message from "../components/Page_message";
import "../assets/styles/carrito.scss";
import { useThousandSeparator } from "../hooks/useThousandSeparator";
import { Image } from "../components/image";

const customStyles2 = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "30",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    borderRadius: "0 px",
    border: "none",
    background: "none",
    padding: "0px 0px 0px 0px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
    maxHeight: "calc(100% - 32px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

const ItemCarritoModal = ({
  producto,
  indice,
  ps_updateDato,
  colour,
  descripcion,
  cantidad,
  precioVenta,
  moneda,
  importe,
  imagen,
  unidadMedida,
  IdMarket,
  IdSucursal,
  ps_deleteDato,
  favorito,
  changeFavorito,
  ps_startModalPieza,
  ps_startModalFavorito,
  ps_endModal,
  ps_pedidoCheked,
  carritoTieneCobertura,
}) => {
  const navigate = useNavigate();
  const { numberFormatter } = useThousandSeparator();
  const { ciudadp, sucursalp } = useParams();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [disponible, SetDisponible] = useState(true);
  const disableButtons = useMemo(
    () => !ps_pedidoCheked || !carritoTieneCobertura,
    [carritoTieneCobertura, ps_pedidoCheked]
  );

  const [imageLoadead, SetImageLoadead] = useState(false);

  useEffect(() => {
    if (!producto.Disponible) {
      SetDisponible(false);
    }
  }, []);

  const _es_pieza = () => {
    if (producto) {
      return producto.EsFraccionado && producto.PesoDefinido ? true : false;
    } else {
      return false;
    }
  };
  const iniciar_modal_peso = () => {
    if (_es_pesso()) {
      ps_startModalPieza(producto, IdMarket);
    }
  };

  const iniciar_modal_favorito = () => {
    ps_startModalFavorito(
      favorito,
      producto.Cantidad,
      producto.IdProducto,
      IdMarket,
      producto.Pesos
    );
  };

  const decrement_carrito = () => {
    if (_es_pieza()) {
      navegarDetalleProducto();
    } else {
      normal("decremento");
    }
  };

  const incremento_carrito = () => {
    if (_es_pieza()) {
      navegarDetalleProducto();
    } else {
      normal("incremento");
    }
  };

  const navegarDetalleProducto = () => {
    navigate(
      `/${ciudadp + "/" + sucursalp}/carrito/${
        producto.IdProducto
      }/${IdMarket}/${IdSucursal}`
    );
  };

  const _es_pesso = () => {
    if (producto) {
      return producto.EsFraccionado && !producto.PesoDefinido ? true : false;
    } else {
      return false;
    }
  };

  const _cantidad = (cantidad) => {
    if (cantidad % 1 === 0) {
      return cantidad;
    } else {
      return cantidad.toFixed(2);
    }
  };

  const normal = (operacion) => {
    if (operacion === "incremento") {
      ps_updateDato(producto.IdProducto, "incremento", IdMarket, null);
    } else {
      ps_updateDato(producto.IdProducto, "decremento", IdMarket, null);
    }
  };

  async function delete_item_carrito(idproducto) {
    if (_es_pieza()) {
      navegarDetalleProducto();
      return;
    }
    let title = "¿Está seguro de quitar este producto del carrito?";
    let resp = await Page_message.delete_register_confirmation(title, "");
    if (resp) {
      ps_deleteDato(idproducto, IdMarket, IdSucursal);
    }
  }

  const delete_item = (idProducto) => {
    delete_registro(idProducto, IdMarket, IdSucursal);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const closeFullScreen = () => {
    setShowFullScreen(false);
    SetImageLoadead(false);
  };

  const formatNumber = (value) => {
    return numberFormatter.format(value);
  };
  const delete_registro = async (idproducto, idmarket, idsucursal) => {
    let title = "¿Está seguro de quitar este producto del carrito?";
    let resp = await Page_message.delete_register_confirmation(title, "");
    if (resp) {
      ps_deleteDato(idproducto, idmarket, idsucursal);
    }
  };

  const _cantidad_pieza = (producto) => {
    let cantidad = 0;
    producto?.Pesos?.forEach((elem) => {
      cantidad += elem.Piezas * elem.PesoAproxMax;
    });

    return Number(cantidad).toFixed(2);
  };

  const displayTrashCan = useMemo(() => {
    const esProductoPorPeso = _es_pesso();
    if (esProductoPorPeso) {
      if (esProductoPorPeso && producto.Cantidad <= 0.1) return true;
    } else {
      if (producto.Cantidad <= 1) return true;
    }

    // if (producto.Cantidad === 0.1) return true;
    // if (producto.Cantidad === 1) return true;

    return false;
  }, [producto, _es_pesso]);
  const _descuento = () => {
    let resultado = 0;
    if (producto) {
      if (producto.ConOferta) {
        resultado =
          100 - (producto.PrecioOferta * 100) / producto.PrecioOriginal;
      }
    }
    return resultado.toFixed(1);
  };
  const descuento = _descuento();

  return (
    <div className="flex justify-between p-2 px-2 py-0.5 border-b-2">
      <div className="grid grid-cols-12 gap-2 w-full">
        <div className="overflow-hidden col-span-3 md:col-span-2 bg-white flex justify-center items-center transition duration-200 ease-in-out transform hover:scale-110">
          <button
            className="relative w-full h-full"
            onClick={() => {
              openModal();
            }}
          >
            {producto.ConOferta && (
              <div className="absolute top-4 left-0 z-10 -rotate-45 leading-none">
                <div
                  className={classNames(
                    "py-0.5 px-1.5 text-[8px] md:text-[10px] bg-hiperorange text-white inline-flex items-center gap-1 relative h-[18px] md:h-[20px] rounded-r-sm",
                    {
                      "bg-hiperplomo_fuerte bg:text-hiperplomo_fuerte_hover":
                        !ps_pedidoCheked,
                    }
                  )}
                >
                  <span className="font-semibold mr-1 leading-none">
                    -{descuento} %
                  </span>
                  <div
                    className={classNames(
                      "absolute -right-[6px] rotate-45 h-3.5 w-3.5 flex items-center justify-center bg-hiperorange rounded-sm",
                      {
                        "bg-hiperplomo_fuerte bg:text-hiperplomo_fuerte_hover":
                          !ps_pedidoCheked,
                      }
                    )}
                  >
                    <div className="w-1.5 h-1.5 bg-white rounded-full"></div>
                  </div>
                </div>
              </div>
            )}
            <Image
              className="w-full"
              src={producto.UrlFoto + "&size=120x120"}
              alt={producto.Descripcion}
              style={{
                filter: ps_pedidoCheked ? "none" : "grayscale(100%)",
              }}
            />
          </button>
        </div>
        <div className="col-span-6 md:col-span-7 flex flex-col h-100 w-full">
          <div className="flex gap-1 flex-col h-full">
            <div
              className="font-bold text-sm leading-tight md:leading-normal md:text-lg cursor-pointer hover:underline"
              onClick={() => navegarDetalleProducto()}
            >
              {producto.Descripcion}
            </div>
            <div className="flex flex-col">
              <div className="font-bold m-0 text-black text-sm md:text-base">
                {_es_pieza() ? "Precio por Kg" : "Precio por Und"}
              </div>
              <div className="flex items-center gap-2">
                <span className="text-sm md:text-base">
                  Bs.{" "}
                  {producto.ConOferta
                    ? formatNumber(producto.PrecioOferta)
                    : formatNumber(producto.PrecioVenta)}
                </span>
                {producto.ConOferta && (
                  <span className="font-semibold  text-zinc-400 line-through text-xs md:text-sm">
                    Bs. {formatNumber(producto.PrecioOriginal)}
                  </span>
                )}
              </div>
            </div>
            <div className="cantidad flex items-end  gap-1 text-white mt-auto">
              {disponible ? (
                <div className="flex gap-1 md:gap-2">
                  <button
                    className={classNames(
                      "bg-hiperorange hover:bg-hiperorange_hover flex w-6 h-6 md:h-7 md:w-7 p-2 rounded-md text-white self-center justify-center items-center disabled:cursor-not-allowed",
                      {
                        "bg-hiperplomo_fuerte hover:bg-hiperplomo_fuerte_hover":
                          !ps_pedidoCheked,
                      }
                    )}
                    disabled={disableButtons}
                    onClick={() =>
                      displayTrashCan
                        ? delete_item_carrito(producto.IdProducto)
                        : decrement_carrito()
                    }
                  >
                    <FontAwesomeIcon
                      icon={displayTrashCan ? faTrashCan : faMinus}
                      className="text-white text-xs"
                    />
                  </button>
                  <button
                    className="bg-gray-100 rounded-lg py-1 px-1.5 md:py-1 md:px-3 font-bold text-sm md:text-base text-black disabled:cursor-not-allowed"
                    style={{
                      cursor: _es_pesso() === true ? "pointer" : "default",
                    }}
                    disabled={disableButtons}
                    onClick={() => iniciar_modal_peso()}
                  >
                    {_es_pesso() ? (
                      <span>
                        {(_cantidad(producto.Cantidad) * 1000).toFixed(0)}g
                      </span>
                    ) : (
                      <span>{_cantidad(producto.Cantidad)}Und</span>
                    )}
                  </button>
                  <button
                    className={classNames(
                      "bg-hiperorange hover:bg-hiperorange_hover flex w-6 h-6 md:h-7 md:w-7 p-2 rounded-md text-white self-center justify-center items-center disabled:cursor-not-allowed",
                      {
                        "bg-hiperplomo_fuerte hover:bg-hiperplomo_fuerte_hover":
                          !ps_pedidoCheked,
                      }
                    )}
                    disabled={disableButtons}
                    onClick={() => incremento_carrito()}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="text-white text-xs"
                    />
                  </button>
                </div>
              ) : (
                <div className="flex gap-2 px-2 py-1 bg-red-500 text-white items-center rounded-full font-bold">
                  <FontAwesomeIcon icon={faCartCircleXmark} size="lg" />
                  <span>Agotado</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-span-3 flex flex-col min-w-20">
          <div className="flex items-center justify-end">
            <button
              className="bg-white w-8 h-8 flex items-center justify-center rounded-full shadow-black hover:shadow-md group transition duration-200 ease-in-out transform hover:scale-110 hover:border"
              onClick={() => iniciar_modal_favorito()}
            >
              <FontAwesomeIcon
                size="lg"
                icon={faHeart}
                className={classNames("", {
                  "text-hiperorange": favorito,
                  "hipergrisdark text-hipergrisdark ": !favorito,
                })}
                style={{
                  filter: ps_pedidoCheked ? "none" : "grayscale(100%)",
                }}
              />
            </button>
          </div>
          {_es_pesso() && (
            <div className="font-bold text-right text-sm md:text-base">
              {_cantidad(producto.Cantidad)} Kg
            </div>
          )}
          {_es_pieza() && (
            <div className="font-bold text-right text-sm md:text-base">
              {_cantidad_pieza(producto)} Kg
            </div>
          )}

          <p className="font-bold text-right text-sm md:text-base h-full">
            {producto.Moneda}. {formatNumber(producto.Importe)}
          </p>
          <button
            className={classNames(
              "text-hiperorange hover:text-hiperorange_hover underline font-bold text-right text-sm md:text-base",
              {
                "text-hiperplomo_fuerte hover:text-hiperplomo_fuerte_hover":
                  !ps_pedidoCheked,
              }
            )}
            onClick={() => delete_item(producto.IdProducto)}
          >
            Eliminar
          </button>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        appElement={document.getElementById("root")}
        onRequestClose={closeModal}
        style={customStyles2}
        contentLabel="Example Modal"
        key={"keyModal"}
      >
        <FullScreenImage
          imagenes={[
            {
              original: producto.UrlFoto + "&size=900x900",
              loading: "eager",
              originalWidth: 600,
              originalHeight: 600,
              originalAlt: producto.Descripcion,
            },
          ]}
          onClose={closeModal}
          tiene_varias_imagenes={"no"}
          imagen={producto.UrlFoto}
          onImageLoad={() => {
            SetImageLoadead(true);
          }}
        />
      </Modal>
    </div>
  );
};

export default ItemCarritoModal;
