import classNames from "classnames";
import ReactModal from "react-modal";

export function Modal({
  children,
  className,
  overlayClassName,
  ...props
}: ReactModal.Props) {
  return (
    <ReactModal
      {...props}
      className={classNames(
        "fixed inset-0 flex items-center justify-center p-2 md:p-4 w-full md:max-w-md  md:mx-auto focus-visible:outline-none",
        className
      )}
      overlayClassName={classNames(
        "fixed inset-0 bg-black bg-opacity-50 z-30",
        overlayClassName
      )}
    >
      {children}
    </ReactModal>
  );
}
