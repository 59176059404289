import { useLocation, useParams } from "react-router-dom";

export function useGetCurrentUrl() {
  const { pathname } = useLocation();
  const { sucursalp, ciudadp } = useParams();

  const baseUrl =
    window?.location?.origin || process.env.REACT_APP_ORGANIZATION_URL;

  return {
    baseUrl,
    currentUrl: `${baseUrl}${pathname}`,
    sucursalBaseUrl:
      !!ciudadp && !!sucursalp ? `${baseUrl}/${ciudadp}/${sucursalp}` : "",
  };
}
