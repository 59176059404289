import { Helmet } from "react-helmet-async";

export const KEYWORD_BY_SERVICE_TYPE = {
  FARMACIA: "nuestra Farmacia",
  SUPERMERCADO: "nuestro Supermercado",
};
export const DEFAULT_KEYWORDS = !!process.env.REACT_APP_DEFAULT_KEYWORDS
  ? JSON.parse(process.env.REACT_APP_DEFAULT_KEYWORDS)
  : [];

export function HomeSEO({
  title,
  store,
  description,
  organizationSnippet,
  storeSnippet,
  image,
  url,
  keywords = [],
}) {
  return (
    <Helmet title={title}>
      {/* Meta tags para Open Graph (Facebook) */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={title} />

      {/* Meta tags para Twitter Cards */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:url" content={url} />
      {/* <meta name="twitter:site" content="@tu_usuario_twitter" /> */}

      <meta name="store" content={store} />
      <meta name="description" content={description} />
      {keywords.length > 0 && (
        <meta
          name="keywords"
          content={keywords.join(", ")}
        />
      )}
      {organizationSnippet}
      {storeSnippet}
    </Helmet>
  );
}
