import React, { useContext, useState } from 'react';
import { ZoomContext } from './ZoomContext';
import { Image } from '../image';
import classNames from 'classnames';

const ImageZoom = ({ imageSrc, highResImageSrc: largeImageSrc, zoomFactor = 2, alt = "", containerClassName = "" }) => {
  const { zoomData, setZoomData } = useContext(ZoomContext);
  const [lensPosition, setLensPosition] = useState({ x: 0, y: 0 });

  // Adjust this value to make the lens smaller
  const lensSize = 50 / zoomFactor;

  const handleMouseMove = (e) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const lensDiameter = lensSize / 100 * width;

    // Calculate mouse position relative to the image
    let mouseX = ((e.pageX - left) / width) * 100;
    let mouseY = ((e.pageY - top) / height) * 100;

    // Adjust the position to keep the lens inside the image boundaries
    let x = Math.max(lensDiameter / (2 * width) * 100, Math.min(100 - lensDiameter / (2 * width) * 100, mouseX));
    let y = Math.max(lensDiameter / (2 * height) * 100, Math.min(100 - lensDiameter / (2 * height) * 100, mouseY));

    // Set lens position
    setLensPosition({ x, y });

    // Calculate background position to allow reaching 0% and 100%
    const backgroundPositionX = Math.max(0, Math.min(100, mouseX));
    const backgroundPositionY = Math.max(0, Math.min(100, mouseY));

    setZoomData({
      imageSrc,
      highResImageSrc: largeImageSrc,
      backgroundPosition: `${backgroundPositionX}% ${backgroundPositionY}%`,
      zoomFactor,
      showZoom: true,
    });
  };

  const handleMouseLeave = () => {
    setZoomData((prevData) => ({
      ...prevData,
      showZoom: false,
    }));
  };

  return (
    <div
      className={classNames("relative overflow-hidden hover:cursor-pointer", containerClassName)}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <Image src={imageSrc} alt={alt} className="object-cover w-full h-full" />
      {zoomData.showZoom && (
        <div
          className="absolute"
          style={{
            width: `${lensSize}%`,
            height: `${lensSize}%`,
            top: `${lensPosition.y - lensSize / 2}%`,
            left: `${lensPosition.x - lensSize / 2}%`,
            background: 'radial-gradient(circle, transparent 50%, rgba(237,106,15,0.5) 50%)',
            backgroundSize: '3px 3px',
            pointerEvents: 'none',
          }}
        />
      )}
    </div>
  );
};

export default ImageZoom;
