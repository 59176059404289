import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useParams, useNavigate } from "react-router-dom";

import { useLocalStorage } from "react-use";
import convertSlug from "./slug";
import {
  get_ciudades,
  get_sucursales,
  set_sucursal,
  set_tipo_entrega,
} from "../../redux/actions/branch_office";
import {
  end_peticion_total,
  update_url_navigation,
} from "../../redux/actions/url_navegacion";
import { get_anonimo_token } from "../../redux/actions/auth";
import { info_session } from "../../redux/actions/profile";
import RegistroInit from "../modal/registroUser/registro_init";
import Error404 from "../../containers/errors/Error404";

import { connect } from "react-redux";
import LoaderSpinner from "../../widgets/loaderSpinner";
const ProtectedUserRegistered = ({ profile, info_session }) => {

  useEffect(() => {
    let token = localStorage.getItem("access_token");
    if (!profile && token) {
      info_session();
    }
  }, [profile]);

  const verifyEstado = () => {
    if (profile) {
      if (profile.Telefono !== "") {
        return true;
      }
    }

    return false;
  };

  if (!!profile) {
    if (verifyEstado()) {
      return <Outlet />;
    } else {
      return <Error404></Error404>;
    }
  } else {
    return (
      <div className="mt-4">
        <LoaderSpinner bool={true} />
      </div>
    );
  }
};
const mapStateToProps = (state) => ({
  profile: state.Profile.profile,
});

export default connect(mapStateToProps, {
  info_session,
})(ProtectedUserRegistered);
