import classNames from "classnames";
import { Link } from "react-router-dom";

export default function Accordion({
  toggleAccordion,
  title,
  isOpen,
  children,
  childSelected,
  categoryLink,
}) {
  return (
    <div className="rounded-lg mb-1">
      <a
        className={classNames(
          "flex break-words py-1 px-2 rounded-lg w-full text-left font-semibold",
          {
            "hover:border-1 hover:border-gray-200 hover:bg-gray-200":
              !isOpen || childSelected,
          }
        )}
        style={{
          backgroundColor:
            !childSelected && isOpen ? "var(--primario)" : "transparent",
          color: !childSelected && isOpen ? "#FFFFFF" : "#000000",
        }}
        href={categoryLink}
        onClick={(e) => {
          e.preventDefault();
          toggleAccordion();
        }}
      >
        {title}
      </a>
      {isOpen && <div className="bg-white">{children}</div>}
    </div>
  );
}
