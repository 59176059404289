import { Avatar } from "../../../../shared/ui/components/avatar";
import { useGetProfileInitials } from "../../hooks/useGetProfileInitials";
import { HiperPuntosProfileQrModal } from "./hiper-puntos-profile-qr-modal";

interface Props {
  nombre: string;
  apellidoPaterno: string;
  profileCode: string;
  onCloseParentModal: () => void | undefined;
}

export function HiperPuntosProfile({
  profileCode,
  nombre,
  apellidoPaterno,
  onCloseParentModal,
}: Props) {
  const { profileInitials } = useGetProfileInitials({
    nombre,
    apellidoPaterno,
  });

  return (
    <div className="flex gap-2 text-white">
      {!!profileInitials && (
        <div>
          <Avatar initials={profileInitials} size="md" />
        </div>
      )}
      <div className="flex flex-col gap-1 w-full">
        <div className="max-w-40 md:max-w-56 truncate">
          {!!nombre ? `${nombre} ${apellidoPaterno}`.trim() : "Sin nombre"}
        </div>
        <HiperPuntosProfileQrModal
          onCloseParentModal={onCloseParentModal}
          profileCode={profileCode}
        />
      </div>
    </div>
  );
}
