import React, { useEffect, useState } from "react";
import "../../../assets/fontawesome";
import "../../../assets/fontawesome";
import "../../../assets/styles/mystyle.scss";

import axios from "axios";
import { ColorRing } from "react-loader-spinner";
import { connect } from "react-redux";
import "../../../assets/styles/globalColorPrincipal.css";
import "../../../assets/styles/stylePageGlobals.scss";
import "../../../assets/styles/terminos_condiciones.css";
import {
  activate,
  activate_sin_autologin,
  codigo_acceso,
  get_prefijos,
  get_token,
  set_token_externo,
  terminos_condiciones
} from "../../../redux/actions/auth";
import { info_session } from "../../../redux/actions/profile";
import "../../../assets/styles/terminos_condiciones.css";
import Button_close from "../../../widgets/button_close";


const Termino_condicion = ({
  array_prefijo,
  peticion,
  sendAcceso,
  get_prefijos,
  codigo_acceso,
  datoLogin,
  get_token,
  access_token,
  ps_posicion,
  activate,
  activate_sin_autologin,
  phone,
  isAuthenticated,
  authenticated,
  set_token_externo,
  ps_save_pedidos,
  ps_endModalRegistro,
  ps_opcion_numero,
  ps_check_no_anonimo,
  terminos_condiciones,
  info_session,
  loading,
  profile,
}) => {
  const [objectTerminoCondicion, setObjectTerminoCondicion] = useState(null);
  const [html, setHtml] = useState(null);

  useEffect(() => {
    let token = localStorage.getItem("access_token");
    if (profile) {
      let terminos = profile.TerminoCondicion;
      if (!terminos.AceptoTerminoActual) {
        setObjectTerminoCondicion(profile.TerminoCondicion);
      }
    }
    if (!profile && token) {
      info_session();
    }
  }, [profile]);

  useEffect(() => {
    if (objectTerminoCondicion) {
      getTerminosCondiciones();
    }
  }, [objectTerminoCondicion]);

  const getTerminosCondiciones = async() => {
    let config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    try {
      let res = await axios.get(
        `${objectTerminoCondicion.ContenidoUrl}`,
        config
      );

      if (res.status === 200) {
        setHtml(res.data);
      }
    } catch (err) {}
  }

  const onSubmit = async (e) => {
    let result = await terminos_condiciones();

    if (result) {
      ps_endModalRegistro();
    }
  };
  return (
    <>
      <div className="bg-white rounded-lg content_modal_registro">
        <div className="bg-hiperorange rounded-t-lg w-100 relative text-white flex items-center justify-center h-[52px] content_title">
          <h2 className="p-0 m-0 title_carrito_responsivo">
            Términos y Condiciones
          </h2>
          <div className="absolute right-2 ">
            <Button_close
              className="text-white bg-transparent"
              onClick={() => ps_endModalRegistro()}
            />
          </div>
        </div>
        <div className="w-full">
          <div className="h-[20rem] max-h-full">
            <div className="flex justify-center">
              <div
                style={{
                  width: "90%",
                  minHeight: "17rem",
                  maxHeight: "17rem",
                  overflow: "auto",
                  borderTopLeftRadius: "20px",
                  borderBottomLeftRadius: "20px",
                }}
                className="bg-white p-4"
              >
                <div dangerouslySetInnerHTML={{ __html: html }} />

                <div className="p-4">
                  {loading ? (
                    <button className=" py-1 px-4 border bg-hiperorange border-white hover:border-transparent rounded-lg w-full mt-2 flex items-center justify-between">
                      <ColorRing
                        colors={["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"]}
                        width={20}
                        height={20}
                        ariaLabel="circles-loading"
                      />
                    </button>
                  ) : html !== null ? (
                    <button
                      className="flex justify-center py-1 px-4 border bg-hiperorange text-white border-white hover:border-transparent rounded-lg w-full mt-2  items-center"
                      onClick={() => onSubmit()}
                    >
                      Aceptar
                    </button>
                  ) : (
                    <p className="text-gray-400">
                      La información no se ha podido cargar
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  loading: state.Auth.loading,
  datoLogin: state.Auth.datoLogin,
  sendAccesoAnonimo: state.Auth.sendAccesoAnonimo,
  authenticated: state.Auth.authenticated,
  profile: state.Profile.profile,
  access_token: state.Auth.access_token,
  peticion: state.Peticion.peticion,
  array_prefijo: state.Auth.array_prefijo,
  sendAcceso: state.Auth.sendAcceso,
  phone: state.Auth.phone,
  isAuthenticated: state.Auth.isAuthenticated,
});

export default connect(mapStateToProps, {
  get_prefijos,
  codigo_acceso,
  get_token,
  activate,
  info_session,
  set_token_externo,
  activate_sin_autologin,
  terminos_condiciones,
})(Termino_condicion);

