import React from "react";
import '../assets/styles/itemDetallePedido.scss'
const ItemDetallePedido = ({UrlFoto, nombreproducto, precio, cantidad, subtotal, moneda}) => {
    const formatPrice = (value) => {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }

    return (
        <div className="flex justify-between items-center border-b-2 p-3">
            <div className="flex flex-column pl-3 h-auto relative text-zinc-400 w-2/3">
                <p className="mb-0 text_gray font-bold texto_name_producto">{nombreproducto}</p>
                <span className="font-semibold">Precio: {moneda} {formatPrice(precio)}</span>
                <span className="font-semibold text-hiperorange ">Cantidad: {formatPrice(cantidad)} </span>
            </div>
            
            <div className="product_price flex justify-end pr-3 w-1/3">
                <div className="font-medium text-xl text-hiperorange  "  >
                    <span>{moneda}. &nbsp; {formatPrice(subtotal)} </span>
                </div>
            </div>


        </div>
    );
};

export default ItemDetallePedido;