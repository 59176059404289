export function useScrollToElementTop({ id }) {
  const scrollableElement = document.getElementById(id);

  const scrollToElementTop = () => {
    if (scrollableElement) {
      scrollableElement.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  return { scrollToElementTop };
}
