import { SET_ALERT, REMOVE_ALERT, CLEAD_ALERT, NO_ALERT } from "./types";

export const showRespuesta = (res) => (dispatch) => {
  if (res.data.ConError) {
    if (res.data.Errores.length > 0) {
      let mensaje = res.data.Errores[0].Mensaje;
      dispatch({
        type: SET_ALERT,
        payload: {
          msg: mensaje,
          conError: res.data.ConError,
          codigoEstado: res.data.Estado,
          redireccion: null,
        },
      });
    } else {
      dispatch({
        type: SET_ALERT,
        payload: {
          msg: res.data.Mensaje,
          conError: res.data.ConError,
          codigoEstado: 400,
          redireccion: null,
        },
      });
    }
  }
};

export const setSwAlert =
  (msg, conError, codigoEstado, redireccion = null, timeout = 2000) =>
  (dispatch) => {
    dispatch({
      type: SET_ALERT,
      payload: { msg, conError, codigoEstado, redireccion },
    });
  };

export const setSwNoAlert =
  (redireccion = null, timeout = 2000) =>
  (dispatch) => {
    dispatch({
      type: NO_ALERT,
      payload: { redireccion },
    });
  };

export const endSwAlert = () => (dispatch) => {
  dispatch({ type: REMOVE_ALERT });
};

export const cleadSwAlert = () => (dispatch) => {
  dispatch({ type: CLEAD_ALERT });
};
