export const listaColores = [
  { id: 1, nombreColor: "Azul", color: "#2E9BFF" },
  { id: 3, nombreColor: "Rojo", color: "#F15353" },
  { id: 2, nombreColor: "Amarillo", color: "#FCC12E" },
  { id: 4, nombreColor: "Verde", color: "#53DD12" },
  { id: 5, nombreColor: "Naranja", color: "#CB6E18" },
  { id: 6, nombreColor: "Morado", color: "#826882" },
  { id: 7, nombreColor: "Magenta", color: "#CE45E4" },
  { id: 8, nombreColor: "Celeste", color: "#5CC4E4" },
];

export const colorEnUmbralDeBlanco = (color, umbral) => {
  if(!color) return false;
  // Convertir el color de formato hexadecimal a RGB
  var r = parseInt(color.substring(1, 3), 16);
  var g = parseInt(color.substring(3, 5), 16);
  var b = parseInt(color.substring(5, 7), 16);

  // Definir el umbral (por ejemplo, 200 para cada componente RGB)
  var umbralR = umbral;
  var umbralG = umbral;
  var umbralB = umbral;

  // Verificar si cada componente está por encima del umbral
  if (r >= umbralR && g >= umbralG && b >= umbralB) {
    return true;
  } else {
    return false;
  }
};

export const DEFAULT_COLOR = "#561ecb";
