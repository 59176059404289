export function useGetSucursalesMasCercanaPorTipo() {
  const getSucursalesMasCercanaPorTipo = (markets, tipoServicio) => {
    let sucursal = markets?.filter((market) => {
      let sucursal = market?.Locatarios.find(
        (locatario) => locatario.IdTipoServicio === tipoServicio
      );

      return !!sucursal;
    });

    return sucursal[0]?.Locatarios.find(
      (locatario) => locatario.IdTipoServicio === tipoServicio
    );
  };
  return { getSucursalesMasCercanaPorTipo };
}
