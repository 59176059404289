import { FunctionComponent, ReactNode } from "react";

export interface TabProps {
  title: string;
  children: ReactNode;
  wrapClassName?: string;
  buttonWrapperClassName?: string;
  buttonClassName?: string;
  activeButtonClassName?: string;
}

export const Tab: FunctionComponent<TabProps> = ({ children }) => {
  return <>{children}</>;
};
