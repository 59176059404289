import { useState } from "react";
import { NotificationService } from "../../../notifications/domain/notification-service";
import { FormStatus } from "../../../shared/domain/enums/form-status";
import { useHandleErrors } from "../../../shared/ui/hooks/use-handle-errors";
import { getCustomerBags as _getCustomerBags } from "../../application/get/get-customer-bags";
import { HiperPuntosRepository } from "../../domain/hiper-puntos-repository";
import { CustomerBag } from "../../domain/interfaces/customer-bag";

export const useGetCustomerBags = (
  hiperPuntosRepository: HiperPuntosRepository,
  notificationService: NotificationService
): {
  formStatus: FormStatus;
  getCustomerBags: () => void;
  resetFormStatus: () => void;
  customerBags: CustomerBag[] | undefined;
  resetCustomerBags: () => void;
  isLoading: boolean;
} => {
  // const dispatch: Dispatch<any> = useDispatch();
  const [formStatus, setFormStatus] = useState(FormStatus.INITIAL);
  const [customerBags, setCustomerBags] = useState<CustomerBag[] | undefined>(
    undefined
  );

  const { handleError } = useHandleErrors(notificationService);

  async function getCustomerBags() {
    setFormStatus(FormStatus.LOADING);

    try {
      const customerBags: CustomerBag[] = await _getCustomerBags(
        hiperPuntosRepository
      )();
      // dispatch(setCustomerBagsAction(customerBags));
      setCustomerBags(customerBags);
      setFormStatus(FormStatus.SUCCESS);
      //   notifySuccess(notificationService)("Clientes obtenidos correctamente.");
    } catch (error: unknown) {
      const message = "";
      handleError({ error, message });
      setFormStatus(FormStatus.ERROR);
    }
  }

  const resetFormStatus = () => setFormStatus(FormStatus.INITIAL);

  const resetCustomerBags = () => setCustomerBags(undefined);

  return {
    formStatus,
    getCustomerBags,
    resetFormStatus,
    customerBags,
    resetCustomerBags,
    isLoading: formStatus === FormStatus.LOADING,
  };
};
