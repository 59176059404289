import {
  SET_AUTH_LOADING,
  GET_METODO_PAGO_SUCCESS,
  GET_METODO_PAGO_FAIL,
  REMOVE_AUTH_LOADING,
  SET_DATOS_PAGO,
} from "./types";
import axios from "axios";
import { setSwAlert, showRespuesta } from "./swalert";
import { end_peticion, start_peticion } from "./peticion";
import respuestas from "../../components/Alertas";

export const eliminar_cliente_nit = (dato) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
  try {
    const res = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/v1/ClienteNit/eliminar`,
      dato,
      config
    );

    if (res.status === 200) {
      dispatch(get_list_metodos_pagos());
    } else {
      dispatch({
        // type: GET_METODO_PAGO_FAIL
      });
      dispatch(showRespuesta(res));
    }
  } catch (err) {
    dispatch({
      // type: GET_METODO_PAGO_FAIL
    });
    dispatch(setSwAlert(respuestas.peticionError, true, 500));
  }
};

export const get_list_metodos_pagos = () => async (dispatch) => {
  dispatch({
    type: SET_AUTH_LOADING,
  });

  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/pedidosmarket/metodos-pago`,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: GET_METODO_PAGO_SUCCESS,
        payload: res.data,
      });
    } else {
      dispatch({
        type: GET_METODO_PAGO_FAIL,
      });
      dispatch(showRespuesta(res));
    }
    dispatch({
      type: REMOVE_AUTH_LOADING,
    });
  } catch (err) {
    dispatch({
      type: GET_METODO_PAGO_FAIL,
    });
    dispatch(setSwAlert(respuestas.peticionError, true, 500));
  }
};

export const save_formas_pago = (datos) => async (dispatch) => {
  dispatch(start_peticion(""));
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
  try {
    const res = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/v1/pedidosmarket/carrito/datos-pago`,
      datos,
      config
    );
    if (res.status === 200) {
      dispatch({
        type: SET_DATOS_PAGO,
        payload: res.data,
      });
      if (res.data.ConError) {
        dispatch(
          setSwAlert(res.data.Mensaje, res.data.ConError, res.data.Estado)
        );
      }
    } else {
      dispatch(showRespuesta(res));
    }
    dispatch(end_peticion());
  } catch (err) {
    let response = err.response;

    dispatch({
      type: SET_DATOS_PAGO,
      payload: null,
    });
    dispatch(end_peticion());

    dispatch(setSwAlert(respuestas.peticionError, true, 500));
  }
};

export const reset_datos_pago = () => async (dispatch) => {
  dispatch({
    type: SET_DATOS_PAGO,
    payload: null,
  });
};

export const validar_nit = (nit) => async (dispatch) => {
  dispatch(start_peticion("Validando nit..."));
  if (nit.match(/^[0-9]+$/) === null) {
    dispatch(setSwAlert("El NIT solo puede contener números", true, 500));
    dispatch(end_peticion());
    return false;
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/facturacion/verificar-nit/?nit=${nit}`,
      config
    );

    if (res.status === 200 && res.data.Data === true) {
      // dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 200));
      return res.data.Data;
    } else {
      dispatch(setSwAlert(res.data.Mensaje, res.data.ConError, 500));
    }
    dispatch(end_peticion());
    return false;
  } catch (err) {
    dispatch(end_peticion());
    dispatch(setSwAlert(respuestas.peticionError, true, 500));
    return false;
  }
};
