import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { post_tarjeta } from "../../redux/actions/tarjeta";
import "../../assets/styles/globalColorPrincipal.css";
import "../../assets/styles/stylePageGlobals.scss";
import Alert from "../../components/Swalert";
import { useForm } from "react-hook-form";
import "../../assets/styles/responsive.scss";
import ButtonText from "../../widgets/button_text";
import { huellaDigital } from "./../../huellaDigital";
import { get_prefijos, get_departamento } from "../../redux/actions/auth";
import { get_tarjeta } from "../../redux/actions/tarjeta";
import useScreenSize from "./useScreenSize";
import { cleadSwAlert } from "../../redux/actions/swalert";
import { useNavigate } from "react-router-dom";
import ButtonClose from "../../widgets/button_close";
import { getMonth, getYear } from "date-fns";
import Modal from "react-modal";
import TerminoCondicion from "../../components/modal/carrito/Partials/termino_condicion";

const customStyles2 = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "35",
  },
  content: {
    padding: "0px",
    border: "0px",
    background: "transparent",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "calc(100% - 64px)",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
};

const NuevaTarjeta = ({
  show_alert,
  redirect,
  cleadSwAlert,
  get_prefijos,
  array_prefijo,
  get_departamento,
  array_departamento,
  post_tarjeta,
  mycard_list,
  loading,
  profile,
  close = () => {},
  get_tarjeta,
}) => {
  const [openModalTerminosCondiciones, setOpenModalTerminosCondiciones] =
    useState(false);

  const handleCloseModalTerminosCondiciones = () => {
    setOpenModalTerminosCondiciones(false);
  };

  const { width, height } = useScreenSize();
  const [ipAddress, setIPAddress] = useState("");
  const [formData, setFormData] = useState({
    Color: "",
    CodeTypeTarjeta: "visa",
    Preferida: false,
    IsoPais: "BO",
  });
  const [validateColor, setValidateColor] = useState(false);
  const [pais, setPais] = useState("BO");
  const [ciudades, setCiudades] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const mesExpiracion = watch("MesExpiracion");
  const anioExpiracion = watch("AnioExpiracion");
  const anioActual = getYear(new Date().setHours(12, 0, 0));
  useEffect(() => {
    if (mesExpiracion) {
      const handleBlur = () => {
        if (/^0+$/.test(mesExpiracion)) {
          setValue("MesExpiracion", "");
        } else if (
          parseInt(mesExpiracion) > 0 &&
          parseInt(mesExpiracion) < 10
        ) {
          setValue("MesExpiracion", `0${parseInt(mesExpiracion)}`, {
            shouldValidate: true,
          });
        } else if (/^0\d{2,}$/.test(mesExpiracion)) {
          let newValue = parseInt(mesExpiracion, 10);
          if (newValue > 12) {
            newValue = 12;
          } else if (newValue < 0) {
            setValue("MesExpiracion", "");
          }
          setValue("MesExpiracion", newValue.toString(), {
            shouldValidate: true,
          });
        } else if (parseInt(mesExpiracion, 10) > 12) {
          setValue("MesExpiracion", `12`, {
            shouldValidate: true,
          });
        } else if (parseInt(mesExpiracion, 10) < 0) {
          setValue("MesExpiracion", "");
        }
      };

      const inputElement = document.querySelector(
        "input[name='MesExpiracion']"
      );
      if (inputElement) {
        inputElement.addEventListener("blur", handleBlur);
        return () => {
          inputElement.removeEventListener("blur", handleBlur);
        };
      }
    }
  }, [mesExpiracion, setValue]);

  useEffect(() => {
    if (anioExpiracion) {
      const handleBlur = () => {
        const value = parseInt(anioExpiracion);
        const anioAComparar = anioActual - 2000;
        if (value >= anioAComparar && value < 99) {
          setValue("AnioExpiracion", `20${parseInt(value)}`, {
            shouldValidate: true,
          });
        } else if (value < anioActual) {
          setValue("AnioExpiracion", "");
        }
      };

      const inputElement = document.querySelector(
        "input[name='AnioExpiracion']"
      );
      if (inputElement) {
        inputElement.addEventListener("blur", handleBlur);
        return () => {
          inputElement.removeEventListener("blur", handleBlur);
        };
      }
    }
  }, [anioExpiracion, setValue]);

  const messages = {
    required: "Este campo es obligatorio.",
    minLength:
      "Debe ingresar al menos el número mínimo de caracteres requerido.",
    maxLength: "No puede exceder el número máximo de caracteres permitido.",
    espacioBlanco: "Los espacios en blanco no están permitidos.",
    requiredColor: "Debe seleccionar un color.",
    aceptaNumeros: "Solo se permiten números en este campo.",
    aceptaLetra: "Solo se permiten letras en este campo.",
    email:
      "El valor ingresado no coincide con el formato de correo electrónico",
  };
  const patterns = {
    espacioBlanco: /^(?!\s)/,
    aceptaNumeros: /^[0-9,-]+$/,
    aceptaLetra: /^[A-Za-z\s]+$/,
    email: /\S+@\S+\.\S+/,
  };

  useEffect(() => {
    if (validateColor) {
      window.scrollTo(0, 0);
    }
  }, [validateColor]);
  useEffect(() => {
    if (show_alert && redirect !== null) {
      cleadSwAlert();
      if (redirect.type === "back") {
        window.history.back();
      }
    }
  }, [show_alert]);

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIPAddress(data.ip))
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (array_prefijo.length === 0) {
      get_prefijos();
    } else {
      setValue("IsoPais", "BO");
      setValue("codigo_postal", "0000");
      setPais("BO");
    }
  }, [array_prefijo]);

  useEffect(() => {
    if (array_departamento.length === 0) {
      get_departamento();
    } else {
      if (ciudades.length === 0) {
        filtro_ciudad();
      }
    }
  }, [array_departamento]);

  useEffect(() => {
    setValue("IsoPais", pais);
    if (pais === "BO") {
      setValue("codigo_postal", "0000");
    } else {
      setValue("codigo_postal", "");
    }
    setValue("IsoDepartamento", "");
    if (array_departamento.length > 0) {
      filtro_ciudad();
    }
  }, [pais]);

  const filtro_ciudad = () => {
    let array_aux = array_departamento.filter((pd) => pd.PaisCode === pais);
    setCiudades(array_aux);
  };

  const listColor = [
    { id: 1, nombreColor: "Azul", color: "#2E9BFF" },
    { id: 3, nombreColor: "Rojo", color: "#F15353" },
    { id: 2, nombreColor: "Amarillo", color: "#FCC12E" },
    { id: 4, nombreColor: "Verde", color: "#53DD12" },
    { id: 5, nombreColor: "Naranja", color: "#CB6E18" },
    { id: 6, nombreColor: "Morado", color: "#826882" },
    { id: 7, nombreColor: "Magenta", color: "#CE45E4" },
    { id: 8, nombreColor: "Celeste", color: "#5CC4E4" },
  ];

  const selectColor = (color) => {
    setFormData((prevState) => ({
      ...prevState,
      ["Color"]: color,
    }));
  };

  const clearFormData = () => {
    setValidateColor(false);
  };

  const onSubmit = async (formInfo) => {
    if (!profile.TerminoCondicion.AceptoTerminoActual) {
      setOpenModalTerminosCondiciones(true);
      return;
    }
    var huella = await huellaDigital(profile.IdUsuario);
    const datos = {
      MesExpiracion: formInfo.MesExpiracion,
      Color: formData.Color,
      AnioExpiracion: formInfo.AnioExpiracion,
      NroTarjeta: Number(formInfo.NroTarjeta),
      CCV: formInfo.CCV,
      CodeTypeTarjeta: "visa",
      Preferida: formData.Preferida,
      Nombre: formInfo.Nombre,
      Apellido: formInfo.Apellido,
      Direccion: formInfo.Direccion,
      IsoPais: formData.IsoPais,
      IsoDepartamento: formInfo.IsoDepartamento,
      Correo: formInfo.Correo,
      Telefono: formInfo.Telefono,
      CodigoPostal: formInfo.codigo_postal,
      IdSession: huella,
      IPNavegador: ipAddress,
      AnchoPantalla: `${width}`,
      AltoPantalla: `${height}`,
    };
    if (formData.Color != "") {
      guardarTarjeta(datos);
    } else {
      setValidateColor(true);
    }
  };

  const guardarTarjeta = async (datos) => {
    try {
      const success = await post_tarjeta(datos);
      if (success) {
        await clearFormData();
        get_tarjeta();
        close();
      }
    } catch (error) {
      //   close();
    }
  };

  return (
    <>
      <Alert></Alert>
      <div className="bg-white flex flex-col" style={{ minHeight: "20rem" }}>
        <div className="bg-hiperorange w-100 relative text-white flex items-center justify-center h-[52px]  content_title">
          <h2 className="p-0 m-0 title_carrito_responsivo">Agregar tarjeta</h2>
          <div className="absolute right-2 ">
            <ButtonClose
              className="text-white bg-transparent"
              onClick={() => close()}
            />
          </div>
        </div>
        <div className="w-full md:w-[40rem] flex-grow max-h-[90vh] overflow-y-auto">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="p-3 flex flex-col gap-1"
          >
            <div className="flex flex-col gap-1">
              <label
                className="block fontzize_label text-black"
                htmlFor="color"
              >
                Color <span>*</span>
              </label>
              <div className="justify-center">
                <div className="flex flex-wrap gap-2 ">
                  {listColor.map((item_list) => (
                    <div
                      className="w-7 h-7 rounded-md border-2 cursor-pointer"
                      key={item_list.id}
                      name="Color"
                      style={{
                        backgroundColor: item_list.color,
                        borderColor:
                          item_list.color === formData.Color
                            ? "#000000"
                            : item_list.color,
                      }}
                      onClick={() => selectColor(item_list.color)}
                    ></div>
                  ))}
                </div>

                {validateColor && (
                  <div className="mt-2">
                    <div className="text-red-600 leading-tight text-sm mx-1 mt-1">
                      {messages.requiredColor}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div>
              <label
                className="block fontzize_label text-black"
                htmlFor="nombre_titular"
              >
                Nombre del titular <span>*</span>
              </label>
              <input
                className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                name="Nombre"
                id="Nombre"
                type="text"
                {...register("Nombre", {
                  required: {
                    value: true,
                    message: messages.required,
                  },
                  minLength: {
                    value: 3,
                    message: messages.minLength + ", 3 caracteres",
                  },
                  maxLength: {
                    value: 50,
                    message: messages.maxLength,
                  },
                  pattern: {
                    value: patterns.espacioBlanco,
                    message: messages.espacioBlanco,
                  },
                  pattern: {
                    value: patterns.aceptaLetra,
                    message: messages.aceptaLetra,
                  },
                })}
              />
              {errors.Nombre && (
                <div className="text-red-600 leading-tight text-sm mx-1 mt-1">
                  {errors.Nombre.message}
                </div>
              )}
            </div>
            <div>
              <label
                className="block fontzize_label text-black"
                htmlFor="nombre_titular"
              >
                Apellido <span>*</span>
              </label>
              <input
                className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                name="Apellido"
                id="Apellido"
                type="text"
                {...register("Apellido", {
                  required: {
                    value: true,
                    message: messages.required,
                  },
                  minLength: {
                    value: 3,
                    message: messages.minLength + ", 3 caracteres",
                  },
                  maxLength: {
                    value: 50,
                    message: messages.maxLength,
                  },
                  pattern: {
                    value: patterns.espacioBlanco,
                    message: messages.espacioBlanco,
                  },
                  pattern: {
                    value: patterns.aceptaLetra,
                    message: messages.aceptaLetra,
                  },
                })}
              />
              {errors.Apellido && (
                <div className="text-red-600 leading-tight text-sm mx-1 mt-1">
                  {errors.Apellido.message}
                </div>
              )}
            </div>
            <div>
              <label
                className="block fontzize_label text-black"
                htmlFor="numero_tarjeta"
              >
                Número de tarjeta <span>*</span>
              </label>
              <input
                className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                name="NroTarjeta"
                id="numero_tarjeta"
                type="text"
                {...register("NroTarjeta", {
                  required: {
                    value: true,
                    message: messages.required,
                  },
                  minLength: {
                    value: 16,
                    message: messages.minLength + ", 16 caracteres",
                  },
                  maxLength: {
                    value: 16,
                    message: messages.maxLength,
                  },
                  pattern: {
                    value: patterns.espacioBlanco,
                    message: messages.espacioBlanco,
                  },
                  pattern: {
                    value: patterns.aceptaNumeros,
                    message: messages.aceptaNumeros,
                  },
                })}
              />
              {errors.NroTarjeta && (
                <div className="text-red-600 leading-tight text-sm mx-1 mt-1">
                  {errors.NroTarjeta.message}
                </div>
              )}
            </div>
            <div>
              <div className="row">
                <div className="col-sm-6">
                  <label
                    className="block fontzize_label text-black"
                    htmlFor="fecha_expiracion"
                  >
                    Fecha expiración <span>*</span>
                  </label>
                  <div className="flex gap-1">
                    <div className="divide w-[50%]">
                      <input
                        className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                        id="mes"
                        name="MesExpiracion"
                        maxLength={2}
                        type="number"
                        placeholder="MM"
                        {...register("MesExpiracion", {
                          required: {
                            value: true,
                            message: messages.required,
                          },
                          minLength: {
                            value: 2,
                            message: messages.minLength + ", 2 caracteres",
                          },
                          maxLength: {
                            value: 2,
                            message: messages.maxLength,
                          },
                          pattern: {
                            value: patterns.espacioBlanco,
                            message: messages.espacioBlanco,
                          },
                          pattern: {
                            value: patterns.aceptaNumeros,
                            message: messages.aceptaNumeros,
                          },
                          min: {
                            value: 1,
                            message:
                              "El valor ingresado no puede ser menor a 01",
                          },
                          min: {
                            max: 12,
                            message:
                              "El valor ingresado no puede ser mayor a 12",
                          },
                        })}
                      />
                      {errors.MesExpiracion && (
                        <div className="text-red-600 leading-tight text-sm mx-1 mt-1">
                          {errors.MesExpiracion.message}
                        </div>
                      )}
                    </div>

                    <div className="divide w-[50%]">
                      <input
                        className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                        id="anio"
                        name="AnioExpiracion"
                        maxLength={4}
                        type="number"
                        placeholder="YYYY"
                        {...register("AnioExpiracion", {
                          required: {
                            value: true,
                            message: messages.required,
                          },
                          minLength: {
                            value: 4,
                            message: messages.minLength + ", 4 caracteres",
                          },
                          maxLength: {
                            value: 4,
                            message: messages.maxLength,
                          },
                          pattern: {
                            value: patterns.espacioBlanco,
                            message: messages.espacioBlanco,
                          },
                          pattern: {
                            value: patterns.aceptaNumeros,
                            message: messages.aceptaNumeros,
                          },
                        })}
                      />
                      {errors.AnioExpiracion && (
                        <div className="text-red-600 leading-tight text-sm mx-1 mt-1">
                          {errors.AnioExpiracion.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <label
                    className="block fontzize_label text-black"
                    htmlFor="codigo_seguridad"
                  >
                    Código de seguridad <span>*</span>
                  </label>
                  <input
                    className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                    maxLength={3}
                    name="CCV"
                    id="codigo_seguridad"
                    type="text"
                    placeholder="CVV"
                    {...register("CCV", {
                      required: {
                        value: true,
                        message: messages.required,
                      },
                      minLength: {
                        value: 3,
                        message: messages.minLength + ", 3 caracteres",
                      },
                      maxLength: {
                        value: 3,
                        message: messages.maxLength,
                      },
                      pattern: {
                        value: patterns.espacioBlanco,
                        message: messages.espacioBlanco,
                      },
                      pattern: {
                        value: patterns.aceptaNumeros,
                        message: messages.aceptaNumeros,
                      },
                    })}
                  />
                  {errors.CCV && (
                    <div className="text-red-600 leading-tight text-sm mx-1 mt-1">
                      {errors.CCV.message}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div>
              <div className="row">
                <div className="col-sm-6">
                  <label
                    className="block fontzize_label text-black"
                    htmlFor="pais"
                  >
                    País
                  </label>

                  <select
                    className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                    id="IsoPais"
                    name="IsoPais"
                    defaultValue={pais}
                    onChange={(e) => setPais(e.target.value)}
                    value={pais}
                  >
                    <option value="" disabled>
                      Seleccionar un pais
                    </option>
                    {array_prefijo &&
                      array_prefijo.length > 0 &&
                      array_prefijo.map((pais_item) => (
                        <option
                          value={pais_item.ISO2}
                          key={pais_item.NumeroPrefijo}
                        >
                          {" "}
                          {pais_item.Nombre}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-sm-6">
                  <label
                    className="block fontzize_label text-black"
                    htmlFor="ciudad"
                  >
                    Ciudad <span>*</span>
                  </label>
                  <select
                    className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                    id="IsoDepartamento"
                    name="IsoDepartamento"
                    {...register("IsoDepartamento", {
                      required: {
                        value: true,
                        message: messages.required,
                      },
                    })}
                  >
                    <option value="" disabled selected>
                      Seleccionar una ciudad
                    </option>
                    {ciudades.map((item_ciudad, index) => (
                      <option value={item_ciudad.Code} key={index}>
                        {" "}
                        {item_ciudad.Nombre}
                      </option>
                    ))}
                  </select>
                  {errors.IsoDepartamento && (
                    <div className="text-red-600 leading-tight text-sm mx-1 mt-1">
                      {errors.IsoDepartamento.message}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <label
                className="block fontzize_label text-black"
                htmlFor="codigo_postal"
              >
                Código Postal <span>*</span>
              </label>
              <input
                className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                name="codigo_postal"
                id="codigo_postal"
                type="text"
                placeholder="Código postal"
                {...register("codigo_postal", {
                  required: {
                    value: true,
                    message: messages.required,
                  },
                  minLength: {
                    value: 1,
                    message: messages.minLength + ", 1 carácter",
                  },
                  maxLength: {
                    value: 8,
                    message: messages.maxLength,
                  },
                  pattern: {
                    value: patterns.espacioBlanco,
                    message: messages.espacioBlanco,
                  },
                  pattern: {
                    value: patterns.aceptaNumeros,
                    message: messages.aceptaNumeros,
                  },
                })}
              />
              {errors.codigo_postal && (
                <div className="text-red-600 leading-tight text-sm mx-1 mt-1">
                  {errors.codigo_postal.message}
                </div>
              )}
            </div>
            <div>
              <div className="row">
                <div className="col-sm-6">
                  <label
                    className="block fontzize_label text-black"
                    htmlFor="telefono"
                  >
                    Tel&eacute;fono <span>*</span>
                  </label>
                  <input
                    className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                    id="telefono"
                    name="Telefono"
                    type="text"
                    {...register("Telefono", {
                      required: {
                        value: true,
                        message: messages.required,
                      },
                      minLength: {
                        value: 8,
                        message: messages.minLength + ", 8 caracteres",
                      },
                      maxLength: {
                        value: 8,
                        message: messages.maxLength,
                      },
                      pattern: {
                        value: patterns.espacioBlanco,
                        message: messages.espacioBlanco,
                      },
                      pattern: {
                        value: patterns.aceptaNumeros,
                        message: messages.aceptaNumeros,
                      },
                    })}
                  />
                  {errors.Telefono && (
                    <div className="text-red-600 leading-tight text-sm mx-1 mt-1">
                      {errors.Telefono.message}
                    </div>
                  )}
                </div>
                <div className="col-sm-6">
                  <label
                    className="block fontzize_label text-black"
                    htmlFor="correo"
                  >
                    Correo <span>*</span>
                  </label>
                  <input
                    className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                    id="correo"
                    name="Correo"
                    type="email"
                    {...register("Correo", {
                      required: {
                        value: true,
                        message: messages.required,
                      },
                      maxLength: {
                        value: 200,
                        message: messages.maxLength,
                      },
                      pattern: {
                        value: patterns.email,
                        message: messages.email,
                      },
                    })}
                  />
                  {errors.Correo && (
                    <div className="text-red-600 leading-tight text-sm mx-1 mt-1">
                      {errors.Correo.message}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <label
                className="block fontzize_label text-black"
                htmlFor="direccion"
              >
                Dirección <span>*</span>
              </label>
              <input
                className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                id="direccion"
                name="Direccion"
                type="text"
                {...register("Direccion", {
                  required: {
                    value: true,
                    message: messages.required,
                  },
                  minLength: {
                    value: 5,
                    message: messages.minLength + ", 5 caracteres",
                  },
                  maxLength: {
                    value: 300,
                    message: messages.maxLength,
                  },
                  pattern: {
                    value: patterns.espacioBlanco,
                    message: messages.espacioBlanco,
                  },
                })}
              />

              {errors.Direccion && (
                <div className="text-red-600 leading-tight text-sm mx-1 mt-1">
                  {errors.Direccion.message}
                </div>
              )}
            </div>

            <div className="flex gap-2 md:p-0 sticky bottom-0 bg-white ml-[-16px] mr-[-16px] md:ml-0 md:mr-0 py-2 mx-0">
              <ButtonText
                type="submit"
                label="Registrar"
                principal={true}
                className="bg-hiperorange text-white hover:bg-hiperorange_hover"
                onClick={() => {}}
              />
            </div>
          </form>
        </div>
      </div>
      <Modal
        isOpen={openModalTerminosCondiciones}
        ariaHideApp={false}
        onRequestClose={handleCloseModalTerminosCondiciones}
        style={customStyles2}
        contentLabel="Ingresar"
        id="theIdRegister"
      >
        <TerminoCondicion
          ps_save_pedidos={handleCloseModalTerminosCondiciones}
          ps_endModalRegistro={handleCloseModalTerminosCondiciones}
          ps_opcion_numero={() => {}}
          ps_check_no_anonimo={() => {}}
        />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  tarjetas: state.Tarjeta.tarjetas,
  loading: state.Tarjeta.loading,
  profile: state.Profile.profile,
  array_prefijo: state.Auth.array_prefijo,
  array_departamento: state.Auth.array_departamento,
  show_alert: state.Swalerts.show_alert,
  redirect: state.Swalerts.redirect,
});

export default connect(mapStateToProps, {
  post_tarjeta,
  get_prefijos,
  get_departamento,
  cleadSwAlert,
  get_tarjeta,
})(NuevaTarjeta);
