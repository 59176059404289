import {
    SET_ALERT,
    REMOVE_ALERT, CLEAD_ALERT, NO_ALERT
} from '../actions/types';

const initialState = {
    alert: null,
    redirect: null,
    show_alert: false
};

export default function Swalerts(state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case SET_ALERT:
            return {
                ...state,
                alert: payload,
                redirect: payload.redireccion,
                show_alert: false,
            };
        case NO_ALERT:
            return {
                ...state,
                alert: null,
                redirect: payload.redireccion,
                show_alert: true,
            };

        case REMOVE_ALERT:
            return {
                ...state,
                alert: null,
                show_alert: true
            }
        case CLEAD_ALERT:
            return {
                ...state,
                alert: null,
                show_alert: false,
                redirect: null
            }

        default:
            return state
    }
    ;
}