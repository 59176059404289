import {
  GET_URL_NAVIGATION,
  POST_URL_NAVIGATION,
  UPDATE_URL_NAVIGATION,
} from "../actions/types";

const initialState = {
  url_navigation: null,
  user_session_active: false,
};

export default function UrlNavegacion(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_URL_NAVIGATION:
      getStorageUrlNavigation();
      return {
        ...state,
        url_navigation: getStorageUrlNavigation(),
      };
    case UPDATE_URL_NAVIGATION:
      updateStorageUrlNavigation(payload);
      return {
        ...state,
        url_navigation: payload,
        user_session_active: true,
      };
    // case REMOVE_AUTH_LOADING:
    //     return {
    //         ...state,

    //     }
    default:
      return state;
  }
}
function getStorageUrlNavigation() {
  localStorage.getItem("url_navigation");
}
function updateStorageUrlNavigation(url) {
  localStorage.setItem("url_navigation", JSON.stringify(url));
}
function deleteStorageUrlNavigation() {
  localStorage.removeItem("url_navigation");
}
