import React, { useEffect, useState } from "react";
import "../../../../assets/fontawesome";
import texture from "../../../../assets/images/login/texture_complete.jpg";
import "../../../../assets/styles/mystyle.scss";
import axios from "axios";
import { ColorRing } from "react-loader-spinner";
import { connect } from "react-redux";
import "../../../../assets/styles/globalColorPrincipal.css";
import "../../../../assets/styles/stylePageGlobals.scss";
import "../../../../assets/styles/terminos_condiciones.css";
import {
  codigo_acceso,
  get_token,
  get_prefijos,
  activate,
  activate_sin_autologin,
  set_token_externo,
  terminos_condiciones,
} from "../../../../redux/actions/auth";
import { info_session } from "../../../../redux/actions/profile";
import DOMPurify from "dompurify";


const Termino_condicion = ({
  array_prefijo,
  peticion,
  sendAcceso,
  get_prefijos,
  codigo_acceso,
  datoLogin,
  get_token,
  access_token,
  ps_posicion,
  activate,
  activate_sin_autologin,
  phone,
  isAuthenticated,
  authenticated,
  set_token_externo,
  ps_save_pedidos,
  ps_endModalRegistro,
  ps_opcion_numero,
  ps_check_no_anonimo,
  terminos_condiciones,
  info_session,
  loading,
  profile,
}) => {
  const [objectTerminoCondicion, setObjectTerminoCondicion] = useState(null);
  const [html, setHtml] = useState(null);
  useEffect(() => {
    let token = localStorage.getItem("access_token");
    if (profile) {
      let terminos = profile.TerminoCondicion;
      if (!terminos.AceptoTerminoActual) {
        setObjectTerminoCondicion(profile.TerminoCondicion);
      }
    }
    if (!profile && token) {
      info_session();
    }
  }, [profile]);

  useEffect(() => {
    if (objectTerminoCondicion) {
      getTerminosCondiciones();
    }
  }, [objectTerminoCondicion]);

  const getTerminosCondiciones = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    };
    try {
      const res = await axios.get(
        `${objectTerminoCondicion.ContenidoUrl}`,
        config
      );

      if (res.status === 200) {
        const sanitizedHtml = DOMPurify.sanitize(res.data);
        setHtml(sanitizedHtml);
      }
    } catch (err) {}
  };

  const onSubmit = async (e) => {
    const result = await terminos_condiciones(objectTerminoCondicion.Id);

    if (result) {
      ps_check_no_anonimo();
      ps_opcion_numero(false);
      ps_save_pedidos();
      ps_endModalRegistro();
    }
  };
  return (
    <>
      <div
        className="relative h-auto flex flex-col bg-white  md:max-w-4xl overflow-y-auto"
        // style={{ minHeight: "40rem" }}
      >
        <div
          className="bg-white p-8"
          dangerouslySetInnerHTML={{ __html: html }}
        />

        <div className="p-4 bg-white flex justify-center">
          {html !== null ? (
            <button
              onClick={() => onSubmit()}
              className="max-w-56 py-1 px-4 border bg-hiperorange hover:bg-hiperorange_hover text-white rounded-lg w-full mt-2 flex items-center justify-center"
            >
              Aceptar
              {loading && (
                <ColorRing
                  colors={[
                    "#FFFFFF",
                    "#FFFFFF",
                    "#FFFFFF",
                    "#FFFFFF",
                    "#FFFFFF",
                  ]}
                  width={20}
                  height={20}
                  ariaLabel="circles-loading"
                />
              )}
            </button>
          ) : (
            <p className="text-gray-400">
              La información no se ha podido cargar
            </p>
          )}
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  loading: state.Auth.loading,
  datoLogin: state.Auth.datoLogin,
  sendAccesoAnonimo: state.Auth.sendAccesoAnonimo,
  authenticated: state.Auth.authenticated,
  profile: state.Profile.profile,
  access_token: state.Auth.access_token,
  peticion: state.Peticion.peticion,
  array_prefijo: state.Auth.array_prefijo,
  sendAcceso: state.Auth.sendAcceso,
  phone: state.Auth.phone,
  isAuthenticated: state.Auth.isAuthenticated,
});

export default connect(mapStateToProps, {
  get_prefijos,
  codigo_acceso,
  get_token,
  activate,
  info_session,
  set_token_externo,
  activate_sin_autologin,
  terminos_condiciones,
})(Termino_condicion);
