import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import Layout from "../../../../hocs/Layout";
import LoaderSpinner from "../../../../widgets/loaderSpinner";
import { createSweetAlertNotificationService } from "../../../notifications/infrastructure/sweet-alert-notification-service";
import { FormStatus } from "../../../shared/domain/enums/form-status";
import { createApiHiperPuntosRepository } from "../../infrastructure/api-hiper-puntos-repository";
import { CustomerBagCard } from "../components/customer-bag/customer-bag-card";
import { CustomerBagSearch } from "../components/customer-bag/customer-bag-search";
import { HiperPuntosProfileQrModal } from "../components/hiper-puntos-profile/hiper-puntos-profile-qr-modal";
import { useFilterCustomerBags } from "../hooks/useFilterCustomerBags";
import { useGetCustomerBags } from "../hooks/useGetCustomerBags";
import { HiperPuntosContainer } from "../containers/hiper-puntos-container";
import { useIsDefaultStore } from "../../../../hooks/useIsDefaultStore";
const hiperPuntosRepository = createApiHiperPuntosRepository();
const notificationService = createSweetAlertNotificationService();

export function CustomerBagsPage() {
  const { isDefaultStore } = useIsDefaultStore();

  const sucursal = useSelector((state: any) => state.BranchOffice.sucursal);
  const profile = useSelector((state: any) => state.Profile.profile);

  const { getCustomerBags, customerBags, formStatus } = useGetCustomerBags(
    hiperPuntosRepository,
    notificationService
  );

  const { filteredCustomerBags, customerBagsSearchTerm } =
    useFilterCustomerBags(customerBags || []);

  useEffect(() => {
    getCustomerBags();
  }, []);

  return (
    <>
      <Helmet>
        <title>{`Hiper Puntos | ${isDefaultStore ? "Hipermaxi" : sucursal?.Descripcion || ""}`}</title>
        {/* <meta name="“robots”" content="noindex" /> */}
      </Helmet>
      <Layout>
        <HiperPuntosContainer>
          <div className="pt-4 z-10">
            <div className="container w-full md:w-[40rem]">
              <div className="flex flex-col gap-2 md:gap-1 mb-3">
                <div className="flex gap-2 items-center justify-between">
                  <h1 className="text-2xl md:text-3xl">Hiper puntos</h1>
                  <div>
                    <HiperPuntosProfileQrModal
                      profileCode={profile?.CodigoCliente || ""}
                      buttonClassName="bg-hiperorange hover:bg-hiperorange_hover text-white px-2 py-1 rounded-lg"
                      spanClassName="group-hover:no-underline"
                    />
                  </div>
                </div>
                <CustomerBagSearch />
              </div>

              <div className="flex w-full flex-col pb-4 justify-center items-center h-full">
                <div className="w-full h-full md:h-[40rem] md:border bg-white md:shadow-md rounded-xl">
                  <div className="flex justify-between flex-col h-full md:p-3">
                    <div className="flex flex-col h-full overflow-y-auto min-h-[15rem]">
                      <div className="flex flex-col items-center">
                        {formStatus === FormStatus.LOADING && (
                          <LoaderSpinner bool={true} />
                        )}
                        {formStatus === FormStatus.SUCCESS && (
                          <>
                            {filteredCustomerBags.length > 0 ? (
                              <div className="grid grid-cols-1 md:grid-cols-2 gap-2 w-full">
                                {filteredCustomerBags.map((customerBag) => (
                                  <CustomerBagCard
                                    key={customerBag.IdBolsaPunto}
                                    {...customerBag}
                                  />
                                ))}
                              </div>
                            ) : (
                              <div className="col-span-2 flex flex-col gap-4 justify-center items-center mt-2 min-h-60 text-gray-400">
                                <FontAwesomeIcon
                                  icon={faExclamationCircle}
                                  size="6x"
                                />
                                <div className="text-xl text-center">
                                  {!customerBagsSearchTerm
                                    ? "¡Actualmente no hay promociones disponibles! Mantente atento para no perderte las próximas ofertas."
                                    : "No hay promociones que cumplan el criterio de búsqueda"}
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </HiperPuntosContainer>
      </Layout>
    </>
  );
}
