import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Image } from "../../components/image";
import slug from "../Utils/slug";
export default function SucursalCard({
  branch_office,
  selectedSucursal,
  onClick,
  ciudad,
  backLink,
}) {
  const getUrl = (item_sucursal) => {
    let sucursal_select = item_sucursal;
    let parametro_url = {
      ciudad: ciudad ? slug.data_slug(ciudad.Nombre) : "",
      sucursal: slug.data_slug(sucursal_select.Descripcion),
    };
    let resp = parametro_url.ciudad + " " + parametro_url.sucursal;
    let new_url = slug.data_barra(resp);

    let url_parameters = new_url + backLink;
    return "/" + url_parameters;
  };
  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-1">
        <div className="font-bold flex-1">{branch_office.Descripcion}</div>
        {branch_office.distancia && <div>{branch_office.distancia} km</div>}
      </div>
      <div className="grid grid-cols-1 gap-3 md:gap-4 md:grid-cols-2 ">
        {branch_office.Locatarios.map((locatario, index) => {
          const isSelected =
            locatario.IdSucursal ===
            (selectedSucursal && selectedSucursal.IdSucursal);
          const url = getUrl(locatario);
          return (
            <a
              key={`locatario-${locatario.IdMarket}-${locatario.IdSucursal}-${index}`}
              // className={classNames(
              //   "cursor-pointer flex items-center gap-2 p-2 mb-1 rounded-lg",
              //   {
              //     "bg-white hover:bg-gray-200": !isSelected,
              //     "bg-hiperorange_claro": isSelected
              //   }
              // )}
              className={`text-inherit cursor-pointer flex items-center gap-2 py-2 px-2.5 rounded-lg ${
                isSelected
                  ? "bg-hiperorange_claro"
                  : "bg-gray-200 hover:bg-hiperorange_claro"
              }`}
              id={locatario.IdSucursal}
              href={url}
              onClick={(e) => {
                e.preventDefault();
                onClick(locatario);
              }}
            >
              <div className="w-12 h-12 relative">
                <Image
                  className="w-12 h-12"
                  width={48}
                  height={48}
                  src={locatario.TipoServicio.UrlFoto}
                  alt=""
                />
              </div>
              <div className="flex-1 py-2 text-left">
                {locatario.TipoServicio.Descripcion}
              </div>
              {isSelected ? (
                <div className="bg-hiperorange text-white py-1 px-2 rounded-full text-sm leading-none">
                  Seleccionada
                </div>
              ) : (
                <FontAwesomeIcon
                  icon={faChevronRight}
                  style={{ height: "14px" }}
                />
              )}
            </a>
          );
        })}
      </div>
    </div>
  );
}
