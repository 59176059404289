export const TIPO_SERVICIO = {
  FARMACIA: 2,
  SUPERMERCADO: 1,
};

export const DEFAULT_STORE_BY_TIPO_SERVICIO = {
  2: process.env.REACT_APP_DEFAULT_STORE_FARMACIA,
  1: process.env.REACT_APP_DEFAULT_STORE,
};

export function useGetSucursalTipoServicioBySlug() {
  const getSucursalTipoServicioBySlug = (sucursalSlug) => {
    if (sucursalSlug?.toUpperCase().includes("FARMACIA"))
      return TIPO_SERVICIO.FARMACIA;
    return TIPO_SERVICIO.SUPERMERCADO;
  };
  return { getSucursalTipoServicioBySlug };
}
