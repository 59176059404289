import axios from "axios";
import { createLocalStoragePersistRepository } from "../../persist/infrastructure/api-local-storage-persist-repository";
import { ResponseError } from "../application/response/response.error";
import { HttpService } from "../domain/http-service";

const TOKEN_PERSIST_ID = "access_token";
const TIMEOUT = 60000;
// const localStorageRepository = createLocalStoragePersistRepository();
const storageRepository = createLocalStoragePersistRepository();

export const getHeaders = () => {
  const authorization = getToken();
  let headers = {};
  if (authorization !== null) {
    headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Methods': '*',
      Authorization: `bearer ${authorization}`,
    };
  } else {
    headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Methods': '*',
    };
  }

  return headers;
};

export const getHeadersForFileUpload = () => {
  const authorization = getToken();
  let headers = {};
  if (authorization !== null) {
    headers = {
      // 'Content-Type': 'multipart/form-data',
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Methods': '*',
      Authorization: `bearer ${authorization}`,
    };
  } else {
    headers = {
      // 'Content-Type': 'multipart/form-data',
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Methods': '*',
    };
  }

  return headers;
};

export const getToken = () => {
  // if (typeof window !== 'undefined') {
  return storageRepository.getItem(TOKEN_PERSIST_ID);
  // }
  // return null;
};

export function createAxiosHttpService(): HttpService {
  const get = async <T>(url: string) => {
    const headers = getHeaders();
    const response = await axios.get(url, {
      signal: AbortSignal.timeout(TIMEOUT),
      headers,
    });

    if (response.status !== 200) {
      throw new ResponseError(response.statusText, response.status);
    }
    return (await response.data) as T;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // const getWithParams = async <T>(url: string, body: any) => {
  //   const headers = getHeaders();
  //   const response = await fetch(url, {
  //     method: "GET",
  //     headers,
  //     body,
  //     signal: AbortSignal.timeout(TIMEOUT),
  //   });
  //   if (!response.ok) {
  //     throw new ResponseError(response.statusText, response.status);
  //   }
  //   return (await response.json()) as T;
  // };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const post = async <T>(url: string, body: any) => {
    const headers = getHeaders();
    const response = await axios.post(url, body, {
      signal: AbortSignal.timeout(TIMEOUT),
      headers,
    });
    if (response.status !== 200) {
      throw new ResponseError(response.statusText, response.status);
    }
    return (await response.data) as T;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const patch = async <T>(url: string, body: any) => {
    const headers = getHeaders();

    const response = await axios.patch(url, body, {
      signal: AbortSignal.timeout(TIMEOUT),
      headers,
    });

    if (response.status !== 200) {
      throw new ResponseError(response.statusText, response.status);
    }
    return (await response.data) as T;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const put = async <T>(url: string, body: any) => {
    const headers = getHeaders();
    const response = await axios.put(url, body, {
      signal: AbortSignal.timeout(TIMEOUT),
      headers,
    });
    if (response.status !== 200) {
      throw new ResponseError(response.statusText, response.status);
    }
    return (await response.data) as T;
  };

  const _delete = async <T>(url: string) => {
    const headers = getHeaders();
    const response = await axios.delete(url, {
      signal: AbortSignal.timeout(TIMEOUT),
      headers,
    });
    if (response.status !== 200) {
      throw new ResponseError(response.statusText, response.status);
    }
    return (await response.data) as T;
  };

  const downloadFile = async (url: string, fileName: string) => {
    const headers = getHeaders();
    const response = await axios.get(url, {
      signal: AbortSignal.timeout(TIMEOUT),
      headers,
    });

    if (response.status !== 200) {
      throw new ResponseError(response.statusText, response.status);
    }
    const blob = await response.data.blob();

    const fileURL = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = fileURL;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(fileURL);
    document.body.removeChild(a);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const uploadFile = async <T>(url: string, body: any) => {
    const headers = getHeadersForFileUpload();
    const response = await axios.post(url, body, {
      signal: AbortSignal.timeout(TIMEOUT),
      headers,
    });
    if (response.status !== 200) {
      throw new ResponseError(response.statusText, response.status);
    }
    return (await response.data) as T;
  };

  return {
    get,
    // getWithParams,
    post,
    put,
    patch,
    delete: _delete,
    downloadFile,
    uploadFile,
  };
}
