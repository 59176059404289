export const buildContactPointJSONLd = ({ telephone }, context) => {
  return {
    "@context": context,
    "@type": "ContactPoint",
    telephone,
    contactType: "Customer Service",
  };
};

export const buildOrganizationJSONLd = (
  { name, url, logo, mediaLinks = [], contactPoint = [] },
  context
) => {
  // Recommended data from https://developers.google.com/search/docs/data-types/product

  return {
    "@context": context,
    "@type": "Organization",
    name,
    url,
    logo,
    sameAs: mediaLinks,
    contactPoint: contactPoint.map((contact) =>
      buildContactPointJSONLd(contact)
    ),
  };
};
