import React, { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import "../assets/styles/full_scren.scss";
import "react-awesome-slider/dist/custom-animations/cube-animation.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartCircleXmark } from "@fortawesome/pro-solid-svg-icons";
import { faX } from "@fortawesome/pro-regular-svg-icons";
import { Image } from "../components/image";

const FullScreenImage = ({
  imagenes,
  tiene_varias_imagenes,
  imagen,
  onImageLoad,
  onClose,
}) => {
  const [images, guardarImagenes] = useState([]);

  const dar_formato = (imagenes) => {
    var aux = [];
    for (let item of imagenes) {
      var dato = {
        original: item.Url + "&size=900x900",
        thumbnail: item.Url + "&size=120x120",
      };
      aux.push(dato);
    }
    guardarImagenes(aux);
  };

  const dar_formato_una_imagen = (imagenv) => {
    //
    var aux = [];

    var dato = {
      original: imagenv + "&size=900x900",
    };
    aux.push(dato);
    guardarImagenes(aux);
  };
  useEffect(() => {
    if (tiene_varias_imagenes == "si") {
      dar_formato(imagenes);
    } else {
      dar_formato_una_imagen(imagen);
    }
  }, []);

  const [loaded, setLoaded] = useState(images.map(() => false));
  const handleImageLoad = (index) => {
    setLoaded((prevLoaded) => {
      const newLoaded = [...prevLoaded];
      newLoaded[index] = true;
      return newLoaded;
    });
  };

  const renderImage = (item, index) => {
    return (
      <div className="relative w-full h-[360px] md:h-[700px]">
        {!loaded[index] && (
          <div className="absolute animate-pulse inset-0 left-2 right-2 top-2 bottom-2 backdrop-blur-lg bg-slate-300 text-slate-300 rounded-lg" />
         )}
        <Image
          src={item.original}
          alt={item.originalAlt}
          className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-300 ${
            loaded[index] ? "opacity-100" : "opacity-0"
          }`}
          onLoad={() => handleImageLoad(index)}
          width={item.originalWidth}
          height={item.originalHeight}
        />
      </div>
      // <div>
      //   {!loaded[index] && (
      //     <div className=" height animate-pulse w-[360px] h-[360px] md:w-[700px] md:h-[700px] backdrop-blur-lg bg-slate-200/40 text-slate-200/40 rounded-lg" />
      //   )}
      //   <img
      //     src={item.original}
      //     alt={item.originalAlt}
      //     style={{ display: loaded[index] ? "block" : "none" }}
      //     onLoad={() => handleImageLoad(index)}
      //     width={item.originalWidth}
      //     height={item.originalHeight}
      //   />
      // </div>
    );
  };

  return (
    <div className="bg-white relative w-[360px] h-[360px] md:w-[700px] md:h-[700px]">
      <button
        className="absolute top-4 right-4 z-20 bg-white w-8 h-8 flex items-center justify-center rounded-full shadow-black hover:shadow-md group transition duration-200 ease-in-out transform hover:scale-110 hover:border"
        onClick={onClose}
      >
        <FontAwesomeIcon icon={faX} size="sm" />
      </button>
      <ImageGallery
        showFullscreenButton={false}
        showPlayButton={false}
        items={imagenes.map((image, index) => ({
          ...image,
          renderItem: () => renderImage(image, index),
        }))}
        onMouseOver={(e) => {
          e.preventDefault();
        }}
      />
    </div>
  );
};

export default FullScreenImage;
