import { useSearchParams } from "react-router-dom";

export function useQueryParams(queryParam) {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramValue = searchParams.get(queryParam);

  const updateParamValue = (term) => {
    setSearchParams((params) => {
      params.set(queryParam, term);
      return params;
    });
  };

  const deleteParamValue = () => {
    searchParams.delete(queryParam);
    setSearchParams(searchParams);
  };
  return { paramValue, updateParamValue, deleteParamValue };
}
