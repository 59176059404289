import React, { useState, useEffect } from "react";
import Layout from "../../hocs/Layout";
import "../../assets/styles/globalColorPrincipal.css";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { store_lista_compra } from "../../redux/actions/shoppingList";
import "../../assets/styles/stylePageGlobals.scss";
import Alert from "../../components/Swalert";
import ButtonText from "../../widgets/button_text";
import { cleadSwAlert } from "../../redux/actions/swalert";
import "../../assets/styles/responsive.scss";
import { listaColores as listColor } from "../../components/Utils/utils-colores";

const NuevaListaCompra = ({
  show_alert,
  redirect,
  cleadSwAlert,
  store_lista_compra,
  loading,
  sucursal,
}) => {
  const [validateColor, setValidateColor] = useState(false);

  let IdSucursal = sucursal.IdSucursal;

  const [formData, setFormData] = useState({
    Id: "",
    Descripcion: "",
    Color: "",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (show_alert && redirect !== null) {
      cleadSwAlert();
      if (redirect.type === "back") {
        window.history.back();
      }
    }
  }, [show_alert]);

  const selectColor = (color) => {
    setFormData((formData) => ({
      ...formData,
      ["Color"]: color,
    }));
  };

  const clearFormData = () => {
    setFormData({ Id: "", Descripcion: "", Color: "" });
    setValidateColor(false);
  };

  const messages = {
    required: "Este campo es obligatorio",
    minLength: "No puede ingresar menos de 3 caracteres en este campo",
    maxLength: "No puede ingresar más 30 caracteres en este campo",
    espacioBlanco: "No es permitido espacios en blanco",
    requiredColor: "Debe seleccionar un color",
  };
  const patterns = {
    espacioBlanco: /^(?!\s)/,
  };

  const onSubmit = (listinfo) => {
    let descripcion = listinfo.Descripcion;
    if (formData.Color !== "" && descripcion !== "") {
      guardarListaCompra(descripcion);
    } else {
      setValidateColor(true);
    }
  };

  const guardarListaCompra = async (dtoDescripcion) => {
    const datos = {
      Descripcion: dtoDescripcion,
      Color: formData.Color,
    };
    let productos = [];
    let object = {
      type: "back",
    };
    await store_lista_compra(datos, productos, IdSucursal, object);
    clearFormData();
  };

  return (
    <Layout>
      <Alert></Alert>
      <div className="content_info mt-4">
        <div className="container">
          <h1 className=" text-3xl font-normal text-center text-hiperorange mb-4">
            Crear nueva lista
          </h1>
          <div className="flex flex-column justify-center items-center ">
            <div className=" contenedor_mobile p-4 h-auto border bg-white border-hiperorange shadow rounded-xl">
              <div className="flex justify-center">
                <div className="w-full h-auto">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-col">
                      <div className="mb-3 mt-3">
                        <label
                          htmlFor="descripcion"
                          className="block fontzize_label text-black mb-2"
                        >
                          Nombre de la lista
                        </label>
                        <div className="mt-1">
                          <input
                            id="Descripcion"
                            name="Descripcion"
                            type="text"
                            placeholder="Nombre de la lista"
                            className=" appearance-none border_black radius_input  w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            {...register("Descripcion", {
                              required: {
                                value: true,
                                message: messages.required,
                              },
                              minLength: {
                                value: 3,
                                message: messages.minLength + ", 3 caracteres",
                              },
                              maxLength: {
                                value: 50,
                                message: messages.maxLength,
                              },
                              pattern: {
                                value: patterns.espacioBlanco,
                                message: messages.espacioBlanco,
                              },
                            })}
                          />
                          {errors.Descripcion && (
                            <small className="text-red-600">
                              {errors.Descripcion.message}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="color"
                          className="block fontzize_label text-black mb-2"
                        >
                          Seleccione el color
                        </label>
                        <div className="flex justify-center  border_black rounded-hiper_g px-2 py-4">
                          <div className="grid grid-cols-3 grid-rows-3 gap-2 ">
                            {listColor.map((item_list) => (
                              <div
                                className="w-11 h-11 rounded-md border-2 cursor-pointer"
                                key={item_list.id}
                                style={{
                                  backgroundColor: item_list.color,
                                  borderColor:
                                    item_list.color === formData.Color
                                      ? "#000000"
                                      : item_list.color,
                                }}
                                onClick={() => selectColor(item_list.color)}
                              ></div>
                            ))}
                          </div>
                        </div>
                        <div className="mt-2">
                          {validateColor && (
                            <small className="text-red-600">
                              {messages.requiredColor}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="mb-3 text-white">
                        <ButtonText
                          type="submit"
                          label="Crear"
                          principal={true}
                          className="bg-hiperblue hover:bg-hiperblue_hover text-white"
                          onClick={() => {}}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  loading: state.Shopping_list.loading,
  sucursal: state.BranchOffice.sucursal,
  show_alert: state.Swalerts.show_alert,
  redirect: state.Swalerts.redirect,
});

export default connect(mapStateToProps, {
  store_lista_compra,
  cleadSwAlert,
})(NuevaListaCompra);
