import React from "react";

import { Link } from "react-router-dom";
import { LazyLoadImage } from "../components/lazyLoadImage";

import { connect } from "react-redux";
import {
  limpiar_carrito,
  store_carrito,
  limpiar_solo_carrito,
} from "../redux/actions/carrito";

const SeccionInicio = ({
  texto1,
  texto2,
  imagen,
  url,
  limpiar_carrito,
  carritos,
  producto_delete,
  direccion,
  tipo_entrega,
  store_carrito,
  limpiar_solo_carrito,
}) => {
  const redirectToSucursal = (url) => {
    window.location.href = url;
  };

  const handleGoToSucursal = async (url) => {
    const localStorageSucursal = localStorage.getItem("sucursal");
    const previousSucursal = localStorageSucursal
      ? JSON.parse(localStorageSucursal)
      : null;
    if (carritos && carritos.length > 0 && previousSucursal) {
      await store_carrito(
        previousSucursal,
        tipo_entrega,
        direccion,
        carritos,
        producto_delete,
        false
      );
      limpiar_solo_carrito();
    }
    await limpiar_carrito();
    // get_carrito_por_sucursal(sucursal);
    redirectToSucursal(url);
  };
  return (
    <a
      href={url}
      onClick={(e) => {
        e.preventDefault();
        handleGoToSucursal(url);
      }}
      // reloadDocument
      className="w-full cursor-pointer group"
    >
      <article className="bg-white flex flex-col items-center">
        <div className="w-full  h-0 pb-[100%] relative transition duration-200 ease-in-out transform group-hover:scale-105 rounded-lg overflow-hidden">
          <LazyLoadImage
            src={imagen}
            className="absolute top-0 left-0 w-full h-full object-cover"
            alt={texto1}
          />
        </div>
        {(texto1 || texto2) && (
          <div className="flex overflow-visible mx-auto transition-all duration-300 relative items-end w-full h-1/2">
            <div className="p-1 w-full h-full bg-white rounded-b-lg text-white text-center flex flex-col justify-center items-center">
              {texto1 && (
                <label
                  className="text-hiperorange"
                  style={{
                    fontSize: "11pt",
                    fontWeight: "bold",
                    lineHeight: "1",
                  }}
                >
                  {texto1}
                </label>
              )}
              {texto2 && (
                <label
                  className="text-black pt-2"
                  style={{ fontSize: "8pt", lineHeight: "1.2" }}
                >
                  {texto2}
                </label>
              )}
            </div>
          </div>
        )}
      </article>
    </a>
  );
};

const mapStateToProps = (state) => ({
  carritos: state.Carrito.carrito,
  producto_delete: state.Carrito.producto_delete,
  direccion: state.Location.direccion,
  tipo_entrega: state.BranchOffice.tipo_entrega,
});

export default connect(mapStateToProps, {
  limpiar_carrito,
  store_carrito,
  limpiar_solo_carrito,
})(SeccionInicio);
