import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { limpiar_horario } from "../../../redux/actions/carrito";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { useForm } from "react-hook-form";
import {
  eliminar_cliente_nit,
  get_list_metodos_pagos,
  save_formas_pago,
  validar_nit,
} from "../../../redux/actions/metodoPagos";
import ItemOpcionPago from "../../../widgets/item_opcion_pago";
import "../../../assets/styles/globalColorPrincipal.css";
import "../../../assets/styles/stylePageGlobals.scss";
import "../../../assets/styles/responsive.scss";
import Tarjeta from "./tarjeta";
import Modal from "react-modal";
import ButtonClose from "../../../widgets/button_close";
import Page_message from "../../Page_message";
import classNames from "classnames";
import { CarritoResumen } from "./carrito_resumen";
import NuevaTarjeta from "../../../containers/MisTarjetas/NuevaTarjeta";
import { useThousandSeparator } from "../../../hooks/useThousandSeparator";
import LoaderSpinner from "../../../widgets/loaderSpinner";
import { faFileInvoice } from "@fortawesome/pro-regular-svg-icons";
const addCardModalStyles = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "32",
  },
  content: {
    padding: "0px",
    border: "0px",
    background: "transparent",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "calc(100% - 16px)",
    overflow: "hidden",
  },
};

const style_factura = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "31",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "auto",
    padding: "0",
    background: "transparent",
    border: "none",
  },
};

function FormaPago({
  mt_indice,
  get_list_metodos_pagos,
  metodopagos,
  save_formas_pago,
  profile,
  tipo_entrega,
  datos_pago,
  limpiar_horario,
  eliminar_cliente_nit,
  list_carrito,
  width,
  height,
  exit,
  validar_nit,
}) {
  const { numberFormatter } = useThousandSeparator();
  // const [tipoDocumento, setTipoDocumento] = useState(5);
  const [pago_selec, setPagoSelec] = useState(1);
  const [facturaciones, setFacturacion] = useState(null);
  const [facturacionPedido, setFacturacionPedido] = useState(null);
  const [tarjeta_selec, setTarjetaSelec] = useState();
  const [tarjeta_online, setTarjetaOnline] = useState(false);
  const [modal_factura, setModalFactura] = useState(false);
  const [facturarSinNombre, setFacturarSinNombre] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      documento: "",
    },
  });

  const tipoDocumento = watch("documento");
  const [subPedidos, guardarsubPedidos] = useState([]);

  const [openAddCardModal, setOpenAddCardModal] = useState(false);

  const costoEnvio = subPedidos?.reduce((total, item) => {
    const costo = item.CostoEnvio.replace("Bs. ", "");
    return total + (costo ? Number(costo) : 0);
  }, 0);

  const formatNumber = (value) => {
    return numberFormatter.format(value);
  };

  const handleOpenAddCardModal = () => {
    setOpenAddCardModal(true);
  };

  const handleCheckboxChange = (e) => {
    setFacturarSinNombre(e.target.checked);

    if (e.target.checked) {
      setValue("nit", "0");
      setValue("razon", "Sin Nombre");
      setValue("complemento", "");
      setValue("documento", 5);
    } else {
      if (facturaciones.length > 0) {
        let data = facturaciones[facturaciones.length - 1];
        setValue("nit", data.Nit);
        setValue("razon", data.RazonSocial);
        setValue("documento", data.TipoDocumento);
        if (data.TipoDocumento == 1) {
          setValue("complemento", data.Complemento);
        } else {
          setValue("complemento", "");
        }
      }
    }
  };

  const handleCloseAddCardModal = () => {
    setOpenAddCardModal(false);
  };

  const endModalFactura = () => setModalFactura(false);

  const startModalFactura = () => {
    setModalFactura(true);
  };

  const _ahorroTotal = () => {
    let total = 0;
    subPedidos.forEach((sp) => {
      if (sp.check) {
        total += Number(sp.ahorro);
      }
    });
    return formatNumber(total);
  };
  const ahorro_total = _ahorroTotal();
  const messages = {
    required: "Este campo es obligatorio",
    minLength: "No puede ingresar menor caracteres de lo establecido",
    maxLength: "No puede ingresar más caracteres en este campo",
    espacioBlanco: "No es permitido espacios en blanco",
    requiredColor: "Debe seleccionar un color",
    aceptaNumeros: "Solo se acepta números en este campo",
    aceptaLetra: "Solo se acepta letras en este campo",
  };
  const patterns = {
    espacioBlanco: /^(?!\s)/,
    aceptaNumeros: /^[0-9,-]+$/,
    aceptaLetra: /^[A-Za-z\s]+$/,
  };
  useEffect(() => {
    if (list_carrito) {
      let subPedido = list_carrito.SubPedidos.filter((pedido) => pedido.check);
      guardarsubPedidos(subPedido);
    }
  }, [list_carrito]);

  useEffect(() => {
    if (metodopagos) {
      setFacturacion(metodopagos.Facturaciones);
      setFacturacionPedido(metodopagos.FacturacionPedido);
    }
  }, [metodopagos]);
  useEffect(() => {
    
    if (facturacionPedido) {
      setValue("nit", facturacionPedido.Nit);
      setValue("razon", facturacionPedido.RazonSocial);
      setValue("documento", facturacionPedido.TipoDocumento.toString());
      setValue("complemento", facturacionPedido.TipoDocumento == 1 ? facturacionPedido.Complemento : "");
    } else {
      if (facturaciones?.length > 0) {
        let data = facturaciones[facturaciones.length - 1];

        setValue("nit", data.Nit);
        setValue("razon", data.RazonSocial);
        setValue("documento", data.TipoDocumento.toString());
        setValue("complemento", data.TipoDocumento == 1 ? data.Complemento : "");
      }
    }
  }, [facturaciones, facturacionPedido]);

  useEffect(() => {
    if (datos_pago) {
      if (!datos_pago.ConError) {
        mt_indice(5);
      }
    }
  }, [datos_pago]);

  useEffect(() => {
    get_list_metodos_pagos();
    limpiar_horario();
  }, []);

  useEffect(() => {
    if (tipoDocumento != 1) {
      setValue("complemento", "");
    }
  }, [tipoDocumento]);

  function cambiar_seleccion(id) {
    setPagoSelec(id);
    id == 12 ? setTarjetaOnline(true) : setTarjetaOnline(false);
  }

  function cambiar_seleccion_tarjeta(id) {
    setTarjetaSelec(id);
  }

  function existe_metodos_pagos() {
    return metodopagos != null;
  }

  const onSubmit = async (data) => {
    if (tipoDocumento == 5 && !facturarSinNombre) {
      const nitValido = await validar_nit(data.nit);
      if (!nitValido) return;
    }
    var metodo_pago = {
      IdMetodoPago: pago_selec,
      Nit: data.nit,
      RazonSocial: data.razon,
      tipoDocumento: tipoDocumento,
      complemento:
        tipoDocumento == 1
          ? data.complemento
            ? data.complemento.substring(0, 2)
            : ""
          : "",
      IdTarjeta: tarjeta_selec,
    };
    localStorage.setItem("metodo_pago", JSON.stringify(metodo_pago));
    await save_formas_pago(metodo_pago);
  };

  function volverAtras() {
    if (tipo_entrega.Id == 2) {
      mt_indice(3);
    } else {
      mt_indice(2);
    }
  }

  const seleccionar_opcion = (facturacion, e) => {
    setValue("nit", facturacion.Nit);
    setValue("razon", facturacion.RazonSocial);
    setValue("documento", facturacion.TipoDocumento.toString());
    endModalFactura();
  };
  const eliminarFactura = (facturacion) => {
    let title = "¿Estás seguro que quiere eliminar el nit seleccionado?";
    let txt = `Nit:  ${facturacion.Nit}`;
    delete_registro(facturacion, title, txt);
  };

  const delete_registro = async (factura, title, txt) => {
    let resp = await Page_message.delete_register_confirmation(title, txt);
    if (resp) {
      let dato = {
        IdCliente: 0,
        Nit: factura.Nit,
      };
      eliminar_cliente_nit(dato);
    }
  };

  const get_total_carrito = () => {
    let total = 0;
    let precio_no_disponible = 0;
    subPedidos.forEach((sp) => {
      if (sp.check) {
        sp.Productos.forEach((prod) => {
          if (!prod.Disponible) {
            precio_no_disponible += prod.Importe;
          }
        });
      }
    });

    subPedidos.forEach((sp) => {
      if (sp.check) {
        total += sp.ImporteSubtotal;
      }
    });

    let result = parseFloat(total) - parseFloat(precio_no_disponible);
    return formatNumber(Number(result));
  };

  return (
    <>
      <div className="w-full md:w-[82%] flex flex-col h-full">
        <form
          className="flex flex-col md:flex-row md:gap-2 h-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="w-full md:w-[70%] flex flex-col h-full">
            <div className="w-full bg-white rounded-t-lg md:rounded-lg h-full overflow-hidden">
              <div className="flex flex-col h-full">
                <div className="bg-hiperorange w-full text-white flex items-center justify-center  content_title relative">
                  <h2 className="p-0 m-0 title_carrito_responsivo ">
                    Formas de pago
                  </h2>
                  <div className="absolute right-2 ">
                    <ButtonClose
                      className="text-white bg-transparent"
                      onClick={() => exit()}
                    />
                  </div>
                </div>
                <div
                  className={classNames(
                    "bg-white overflow-y-auto flex flex-col h-full mb-32 md:mb-0"
                  )}
                >
                  <div className="px-2 m-2 bg-hiperplomo_claro pb-2 pt-3 rounded-hiper_g  text-start">
                    <p className="text-black font-bold mb-2">
                      Seleccionar el método de pago
                    </p>

                    {existe_metodos_pagos() && (
                      <div className="flex flex-col gap-2">
                        <div className="flex flex-col gap-1">
                          {metodopagos.Metodos.map((m) => (
                            <ItemOpcionPago
                              key={m.Id}
                              id={m.Id}
                              Descripcion={m.Descripcion}
                              change_selec={cambiar_seleccion}
                              valor={pago_selec}
                            ></ItemOpcionPago>
                          ))}
                        </div>
                        {tarjeta_online && (
                          <Tarjeta
                            change_selec={cambiar_seleccion_tarjeta}
                            valor={tarjeta_selec}
                            startAddCard={handleOpenAddCardModal}
                          ></Tarjeta>
                        )}
                      </div>
                    )}
                  </div>

                  <div className="m-2 p-2 rounded-hiper_g bg-hiperplomo_claro mt-3">
                    <div className="flex space">
                      <p className="text-black font-bold mb-2">
                        Datos de facturación
                      </p>
                      <label
                        htmlFor=""
                        className="text-hiperorange hover:text-hiperorange_hover ml-auto underline font-bold cursor-pointer"
                        onClick={(e) => startModalFactura()}
                      >
                        Cambiar
                      </label>
                    </div>

                    <div className="flex flex-row  content-center mb-3">
                      <label className="cursor-pointer flex items-center gap-3">
                        <input
                          type="checkbox"
                          id="id"
                          name="name"
                          className="checked:bg-hiperorange checked:accent-hiperorange w-5 h-5 rounded-lg"
                          onChange={(e) => handleCheckboxChange(e)}
                        />
                        <span> Facturar sin nombre</span>
                      </label>
                    </div>
                    <div hidden={facturarSinNombre}>
                      <div className="mb-3">
                        <label
                          className="block text-black fontzize_label mb-2"
                          htmlFor="documento"
                        >
                          Tipo de documento*
                        </label>
                        <select
                          id="documento"
                          name="documento"
                          {...register("documento", {
                            required:
                              "El campo Tipo de documento es obligatorio",
                          })}
                          className="block w-full rounded-md border-1 border-gray-400 py-2 p-3 pr-4 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                        >
                          <option value="" disabled>
                            Seleccionar
                          </option>
                          <option value="1">Cédula de identidad</option>
                          <option value="2">
                            Cédula de identidad extranjero
                          </option>
                          <option value="3">Pasaporte</option>
                          <option value="4">Otros</option>
                          <option value="5">Nit</option>
                        </select>
                        {errors.documento && (
                          <div style={{ color: "#920909" }}>
                            {errors.documento.message}
                          </div>
                        )}
                      </div>

                      <div className="mb-3">
                        <label
                          className="block text-black fontzize_label  mb-1"
                          htmlFor="nit"
                        >
                          Número de documento*
                        </label>
                        <input
                          className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500 "
                          id="nit"
                          type="text"
                          name="nit"
                          onInput={(e) =>
                            (e.target.value = e.target.value.slice(0, 12))
                          }
                          {...register("nit", {
                            required: {
                              value: true,
                              message:
                                "El campo Número de documento es obligatorio",
                            },
                            maxLength: {
                              value: 13,
                              message:
                                "El campo Número de documento acepta un máximo de 13 caracteres",
                            },

                            // pattern: {
                            //   value: patterns.aceptaNumeros,
                            //   message:
                            //     "El campo Número de documento sólo acepta números",
                            // },
                          })}
                        />
                        {errors.nit && (
                          <div style={{ color: "#920909" }}>
                            {errors.nit.message}
                          </div>
                        )}
                      </div>
                      <div className="mb-3">
                        <label
                          className="block text-black fontzize_label mb-1"
                          htmlFor="username"
                        >
                          Razón social*
                        </label>
                        <input
                          className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                          id="username"
                          type="text"
                          name="razon"
                          {...register("razon", {
                            required: "El campo Razón social es obligatorio",
                          })}
                        />

                        {errors.razon?.type === "required" && (
                          <div style={{ color: "#920909" }}>
                            El campo razón social es obligatorio
                          </div>
                        )}
                      </div>
                      {tipoDocumento === "1" && (
                        <div className="mb-3">
                          <label
                            className="block text-black fontzize_label mb-1"
                            htmlFor="complemento"
                          >
                            Complemento
                          </label>
                          <input
                            className="block w-full rounded-md border-1 border-gray-400 py-2 px-3 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500"
                            id="complemento"
                            type="text"
                            name="complemento"
                            placeholder=""
                            {...register("complemento")}
                            maxLength={2}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-[30%] sticky bottom-0">
            <CarritoResumen
              costo_delivery={
                subPedidos?.length === 0
                  ? "Calculando"
                  : costoEnvio > 0
                  ? formatNumber(costoEnvio)
                  : "Gratis"
              }
              ahorro_total={ahorro_total}
              total_carrito={get_total_carrito()}
              volverAtras={volverAtras}
              guardarProceso={() => {}}
              backButtonLabel="Volver"
              nextButtonLabel="Continuar"
              nextButtonType="submit"
            />
          </div>
        </form>
      </div>
      <Modal
        isOpen={modal_factura}
        ariaHideApp={false}
        onRequestClose={endModalFactura}
        style={style_factura}
        contentLabel="factura"
      >
        <div
          className="bg-white overflow-hidden rounded-hiper_g   content_modal"
          style={{ height: "30rem", maxHeight: "30rem" }}
        >
          <div className="bg-hiperorange w-100 relative text-white flex items-center justify-center h-[52px]  content_title">
            <h2 className="p-0 m-0 title_responsivo  ">
              Seleccione una razón social
            </h2>
            <div className="absolute right-2 ">
              <ButtonClose
                className="text-white bg-transparent"
                onClick={() => endModalFactura()}
              />
            </div>
          </div>

          <div
            className="overflow-auto p-4"
            style={{ minHeight: "10rem", maxHeight: "25rem" }}
          >
            {!!facturaciones ? (
              <>
                {facturaciones.length > 0 ? (
                  facturaciones.map((fact, key) => {
                    return (
                      <div
                        key={`item-facturacion-${key}`}
                        className={classNames(
                          "flex gap-2 border border-black  justify-between items-center bg-white hover:bg-hiperorange hover:border-hiperorange text-black hover:text-white rounded w-full mb-2"
                        )}
                      >
                        <button
                          className="py-2 pl-4 flex-grow text-left"
                          onClick={(e) => seleccionar_opcion(fact, e)}
                        >
                          {fact.RazonSocial} - {fact.Nit}{" "}
                        </button>
                        <FontAwesomeIcon
                          icon={faTrashCan}
                          onClick={() => eliminarFactura(fact)}
                          style={{ fontSize: "1.4rem" }}
                          className="pr-4 cursor-pointer"
                        />
                      </div>
                    );
                  })
                ) : (
                  <div className="flex flex-col gap-4 justify-center items-center mt-2 min-h-60 text-gray-400">
                    <FontAwesomeIcon icon={faFileInvoice} size="6x" />
                    <div className="text-xl">
                      No hay datos de facturación para mostrar
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                <LoaderSpinner bool={true} />
              </>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={openAddCardModal}
        ariaHideApp={false}
        onRequestClose={handleCloseAddCardModal}
        style={addCardModalStyles}
        contentLabel="create credit card"
      >
        <NuevaTarjeta close={handleCloseAddCardModal} />
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  metodopagos: state.MetodoPago.metodopagos,
  datos_pago: state.MetodoPago.datos_pago,
  profile: state.Profile.profile,
  tipo_entrega: state.BranchOffice.tipo_entrega,
  list_carrito: state.Carrito.list_carrito,
});

export default connect(mapStateToProps, {
  get_list_metodos_pagos,
  save_formas_pago,
  limpiar_horario,
  eliminar_cliente_nit,
  validar_nit,
})(FormaPago);
