import { SucursalContacto } from "../../../domain/interface/sucursal-contacto";
import {
  SucursalContactosDispatchType,
  SET_SUCURSAL_CONTACTO,
} from "../types/sucursal-contactos-types";

export const setSucursalContacto =
  (sucursalContacto?: SucursalContacto) =>
  (dispatch: SucursalContactosDispatchType) => {
    dispatch({
      type: SET_SUCURSAL_CONTACTO,
      payload: sucursalContacto || null,
    });
  };
