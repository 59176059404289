import { responseHandler } from "../../../http/application/response/response-handler";
import { CustomerBagHistoryGetParams } from "../../domain/dto/customer-bag-history-get-params";
import { HiperPuntosRepository } from "../../domain/hiper-puntos-repository";
import { CustomerBagHistory } from "../../domain/interfaces/customer-bag-history";

export function getCustomerBagHistory(
  customerRepository: HiperPuntosRepository
) {
  return async (
    params: CustomerBagHistoryGetParams
  ): Promise<CustomerBagHistory> => {
    const response = await customerRepository.getCustomerBagHistory(params);
    return responseHandler<CustomerBagHistory, null>(response);
  };
}
