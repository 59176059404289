import {
    GET_LIST_SUCCESS,
    GET_PEDIDO_SUCCESS,
    GET_LIST_FAIL,
    GET_PEDIDO_FAIL,
    GET_LIST_FINALIZADOS_SUCCESS,
    GET_LIST_PENDIENTES_SUCCESS,
    GET_LIST_PEDIDOS_FIRE_SUCCESS,
    SET_LIST_PEDIDOS_FIRE_SUCCESS,
    CLEAR_PEDIDO_SUCCESS,
    CLEAR_PEDIDOS_SUCCESS
} from '../actions/types';

const initialState = {

    pedidos: [],
    pedidosfinalizados: [],
    pedidospendientes: [],
    pedido: null,
    productos: null,
    pedidosfinalizados_peticion: true,
    pedidospendientes_peticion: true,
    peticion_pedidos : false,
    pedidos_firebase: null,
};

export default function Pedido(state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case GET_LIST_SUCCESS:
            let bool_peticion_pedidos = payload.Dato.length>0 ? false: true;
            return {
                ...state,
                pedidos: payload.Dato,
                peticion_pedidos : bool_peticion_pedidos
            }
        case GET_LIST_PENDIENTES_SUCCESS:
            if (payload.Dato.length > 0) {
                return {
                    ...state,
                    pedidospendientes: [...state.pedidospendientes, ...payload.Dato],
                    pedidospendientes_peticion: true,
                }
            } else {
                return {
                    ...state,
                    pedidospendientes_peticion: false,
                }
            }
        case GET_LIST_FINALIZADOS_SUCCESS:
            if (payload.Dato.length > 0) {
                return {
                    ...state,
                    pedidosfinalizados: [...state.pedidosfinalizados, ...payload.Dato],
                    pedidosfinalizados_peticion: true,
                }
            } else {
                return {
                    ...state,
                    pedidosfinalizados_peticion: false,
                }
            }
        case GET_LIST_FAIL:
            return {
                ...state
            }

        case GET_PEDIDO_SUCCESS:
            return {
                ...state,
                pedido: payload.Dato,
            }
        case CLEAR_PEDIDO_SUCCESS:
            return {
                ...state,
                pedido: null,
                productos: null,
            }
        case CLEAR_PEDIDOS_SUCCESS:
            if (payload) {
                return {
                    ...state,
                    pedidos: [],
                    pedidosfinalizados: [],
                    pedidospendientes: [],
                    pedido: null,
                    productos: null,
                    pedidosfinalizados_peticion: true,
                    pedidospendientes_peticion: true,
                    peticion_pedidos: false,
                };
            }else{
                return {
                    ...state,
                    pedidos: [],
                    pedidosfinalizados: [],
                    pedidospendientes: [],
                    productos: null,
                    pedidosfinalizados_peticion: true,
                    pedidospendientes_peticion: true,
                    peticion_pedidos: false,
                };
            }


        case SET_LIST_PEDIDOS_FIRE_SUCCESS:
            return {
                ...state,
                pedidos_firebase: payload,
            }
        case GET_LIST_PEDIDOS_FIRE_SUCCESS:
            return {
                ...state,
                pedidos_firebase: payload,
            }
        case GET_PEDIDO_FAIL:
            return {
                ...state
            }

        default:
            return state
    }
}