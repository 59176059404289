import React from "react";
import "../assets/styles/globalColorPrincipal.css";
import "../assets/styles/confirmacionPedido.scss";
import { useThousandSeparator } from "../hooks/useThousandSeparator";
import { Image } from "../components/image";
import classNames from "classnames";

const ItemConfirmacionPedido = ({
  colour,
  producto,
  idProducto,
  descripcion,
  cantidad,
  precioVenta,
  moneda,
  importe,
  imagen,
  IdMarket,
  unidadMedida,
}) => {
  const { numberFormatter } = useThousandSeparator();

  const formatNumber = (value) => {
    return numberFormatter.format(value);
  };

  const _es_pieza = () => {
    if (producto) {
      return producto.EsFraccionado && producto.PesoDefinido ? true : false;
    } else {
      return false;
    }
  };

  const _es_pesso = () => {
    if (producto) {
      return producto.EsFraccionado && !producto.PesoDefinido ? true : false;
    } else {
      return false;
    }
  };

  const _cantidad_pieza = (producto) => {
    let cantidad = 0;
    producto.Pesos.forEach((elem) => {
      cantidad += elem.Piezas * elem.PesoAproxMax;
    });

    return formatNumber(cantidad);
  };

  const _descuento = () => {
    let resultado = 0;
    if (producto) {
      if (producto.ConOferta) {
        resultado =
          100 - (producto.PrecioOferta * 100) / producto.PrecioOriginal;
      }
    }
    return resultado.toFixed(1);
  };
  const descuento = _descuento();
  return (
    <div className="flex justify-between p-2 px-2 py-0.5 border-b-2">
      <div className="grid grid-cols-12 gap-2 w-ful">
        <div className="overflow-hidden col-span-3 md:col-span-2 bg-white flex justify-center items-center relative">
          <div className="relative w-full h-full">
            {producto.ConOferta && (
              <div className="absolute top-4 left-0 z-10 -rotate-45 leading-none">
                <div
                  className={classNames(
                    "py-0.5 px-1.5 text-[8px] md:text-[10px] bg-hiperorange text-white inline-flex items-center gap-1 relative h-[18px] md:h-[20px] rounded-r-sm"
                  )}
                >
                  <span className="font-semibold mr-1 leading-none">
                    -{descuento} %
                  </span>
                  <div
                    className={classNames(
                      "absolute -right-[6px] rotate-45 h-3.5 w-3.5 flex items-center justify-center bg-hiperorange rounded-sm"
                    )}
                  >
                    <div className="w-1.5 h-1.5 bg-white rounded-full"></div>
                  </div>
                </div>
              </div>
            )}
            <Image
              className="w-full"
              src={imagen + "&size=120x120"}
              alt={descripcion}
            />
          </div>
        </div>
        <div className="col-span-6 md:col-span-7 flex flex-col h-100 w-full">
          <div className="flex flex-col h-full">
            <div className="font-bold text-sm leading-tight md:leading-normal md:text-base">
              {producto.Descripcion}
            </div>
            <div className="flex flex-col">
              <div className="flex items-center gap-2">
                <span className="text-sm md:text-md">
                  Precio: Bs.{" "}
                  {producto.ConOferta
                    ? formatNumber(producto.PrecioOferta)
                    : formatNumber(producto.PrecioVenta)}
                </span>
                {producto.ConOferta && (
                  <span className="font-semibold  text-zinc-400 line-through text-xs md:text-sm">
                    Bs. {formatNumber(producto.PrecioOriginal)}
                  </span>
                )}
              </div>
            </div>
            <div className="cantidad flex items-end gap-1">
              <div className="flex gap-2">
                {_es_pesso() ? (
                  cantidad > 1 ? (
                    <p className="m-0 text-black text-sm md:text-md">
                      Cantidad: {formatNumber(cantidad)} kg
                    </p>
                  ) : (
                    <p className=" m-0 text-black text-sm md:text-md">
                      Cantidad: {Math.round(cantidad * 1000)} g
                    </p>
                  )
                ) : (
                  <p className="m-0 text-black text-sm md:text-md">
                    Cantidad: {cantidad} Und{" "}
                    {_es_pieza() && `(${_cantidad_pieza(producto)} kg)`}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-3 flex flex-col min-w-20 justify-center">
          <div className={`font-bold text-right text-sm md:text-base`}>
            {producto.Moneda}. {formatNumber(producto.Importe)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemConfirmacionPedido;
