import React, { useEffect, useState } from "react";
import { ColorRing } from "react-loader-spinner";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "../../../assets/styles/responsive.scss";
import {
  actualizar_lista_compra,
  store_lista_compra,
} from "../../../redux/actions/shoppingList";
import ButtonClose from "../../../widgets/button_close";
import ItemListaCompra from "../../../widgets/item_lista_compra_list";
const ContenidoCompartida = ({
  info,
  opcion,
  ps_productos,
  actualizar_lista_compra,
  store_lista_compra,
  formData,
  setFormData,
  ps_closeModal,
  ps_closeModalEstore,
  ps_lista_compra,
  ps_IdSucursal,
  loading,
}) => {
  const { ciudadp, sucursalp } = useParams();
  const [listaSeledted, setListaSelected] = useState({
    Id: "",
    Descripcion: "",
    Color: "",
  });
  const navigate = useNavigate();

  useEffect(() => {}, [opcion]);
  const selectedLista = (dato) => {
    setListaSelected(dato);
  };
  const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const actualzarListaCompra = async () => {
    const datos = {
      Id: listaSeledted.Id,
      Descripcion: listaSeledted.Descripcion,
      Color: listaSeledted.Color,
    };

    await actualizar_lista_compra(datos, ps_productos, ps_IdSucursal);
    await ps_closeModal();
    await delay(2000);
    navigate_menu("lista-compras");
  };
  const navigate_menu = async (url_menu) => {
    let param = await get_url();
    navigate(param + "/" + url_menu);
  };

  const get_url = async () => {
    let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
    if (ciudadp && sucursalp) {
      return "/" + ciudadp + "/" + sucursalp;
    } else if (url_nav) {
      return "/" + url_nav.url_parameters;
    }
  };

  return (
    <>
      <div className="w-full flex flex-col h-full md:max-h-[600px]">
        <div className="w-full bg-white rounded-lg md:rounded-lg h-full overflow-hidden">
          <div className="flex flex-col h-full">
            <div className="bg-hiperorange w-100 relative text-white flex items-center justify-center h-[52px]  content_title">
              <h2 className="p-0 m-0 title_carrito_responsivo">{info}</h2>
              <div className="absolute right-2 ">
                <ButtonClose
                  className="text-white bg-transparent"
                  onClick={() => ps_closeModal()}
                />
              </div>
            </div>
            <div className="bg-white overflow-y-auto flex flex-col h-full gap-2 p-2">
              {ps_lista_compra &&
                ps_lista_compra !== null &&
                ps_lista_compra !== undefined &&
                ps_lista_compra.map((item_shopping_list) => (
                  <ItemListaCompra
                    ps_shopping_list={item_shopping_list}
                    key={item_shopping_list.Id}
                    ps_opcSelectedList={selectedLista}
                    ps_selected={listaSeledted}
                  ></ItemListaCompra>
                ))}
            </div>
            <div className="p-2 sticky bottom-0">
              <button
                className="flex justify-center items-center gap-1 bg-hiperorange hover:bg-hiperorange_hover px-4 py-2 rounded-lg  text-white w-full mt-3 disabled:cursor-not-allowed"
                onClick={actualzarListaCompra}
                disabled={loading || !listaSeledted.Id}
              >
                <ColorRing
                  colors={[
                    "#DC842D",
                    "#DC842D",
                    "#DC842D",
                    "#DC842D",
                    "#DC842D",
                  ]}
                  width={20}
                  height={20}
                  ariaLabel="circles-loading"
                  visible={loading}
                />
                Modificar lista
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.Shopping_list.loading,
});

export default connect(mapStateToProps, {
  actualizar_lista_compra,
  store_lista_compra,
})(ContenidoCompartida);
