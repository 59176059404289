export class MensajeApiError<MensajeApiErrorDataType> extends Error {
	data: MensajeApiErrorDataType | null;

	constructor(message: string, data: MensajeApiErrorDataType | null) {
		super(message);
		this.name = 'Error';
		this.data = data;
		Object.setPrototypeOf(this, MensajeApiError.prototype);
	}
}
