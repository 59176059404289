import { useSelector } from "react-redux";
import { HiperPuntosState } from "../ui/redux/types/hiper-puntos-types";
import LoaderSpinner from "../../../../widgets/loaderSpinner";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ConfigParameter } from "../../../config-parameters/domain/interface/config-parameter";

interface HiperPuntosContainerProps {
  children: React.ReactNode;
}

export function HiperPuntosContainer({
  children,
}: HiperPuntosContainerProps): JSX.Element {
  const navigate = useNavigate();
  const verHiperPuntosParameter: ConfigParameter = useSelector(
    (state: any) => state.HiperPuntos.verHiperPuntosParameter
  );
  useEffect(() => {
    if (!!verHiperPuntosParameter && verHiperPuntosParameter.Valor === "0") {
      navigate("*", {
        replace: true,
      });
    }
  }, [verHiperPuntosParameter]);

  return !!verHiperPuntosParameter ? (
    <>{children}</>
  ) : (
    <LoaderSpinner bool={true} />
  );
}
