import React, { useEffect, useState, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faArrowUp,
  faCartCircleXmark,
} from "@fortawesome/pro-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation } from "swiper";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import Layout from "../hocs/Layout";
import Alert from "../components/Swalert";
import { useScrollToElementTop } from "../hooks/useScrollToElementTop";
import { useSearchTerm } from "../hooks/useSearchTerm";
import {
  get_all_producto,
  get_producto,
  limpiar_array_producto,
} from "../redux/actions/producto";
import BuscadorPorCategoria from "../widgets/BuscadorPorCategoria";
import ItemProducto from "../widgets/item_producto";
import LoaderSpinner from "../widgets/loaderSpinner";
import CategoriasCollapseDesktop from "./CategoriasCollapseDesktop";
import "../../src/assets/styles/categoria.scss";
import classNames from "classnames";
import convertSlug from "../components/Utils/slug";
import { get_categorias } from "../redux/actions/home";
import slug from "../components/Utils/slug";
import { Helmet } from "react-helmet-async";
import { useGetCurrentUrl } from "../hooks/useGetCurrentUrl";
import { useBreadcrumbListJSONLd } from "../hooks/richSnippets/useBreadcrumbListJSONLd";
import { useBreadcrumbs } from "../hooks/useBreadcrumbs";
import { useItemListJSONLd } from "../hooks/richSnippets/useItemListJSONLd";
import { ITEM_LIST_ORDER } from "../libs/richSnippetsUtils/buildItemListJSONLd";
import {
  AVAILABILITY,
  ITEM_CONDITION,
} from "../libs/richSnippetsUtils/buildProductJSONLd";
import { HomeCategoriaSEO } from "../components/SEOComponents/HomeCategoriaSEO";
import {
  DEFAULT_KEYWORDS,
  KEYWORD_BY_SERVICE_TYPE,
} from "../components/SEOComponents/HomeSEO";
import { useGetSucursalCiudad } from "../hooks/useGetSucursalCiudad";
import { useIsDefaultStore } from "../hooks/useIsDefaultStore";
import { MensajeTelefonoProductoDisponible } from "./MensajeTelefonoProductoDisponible";

const HomeCategoria = ({
  limpiar_array_producto,
  categorias,
  sucursal,
  get_producto,
  productos,
  productos_peticion,
  get_all_producto,
  peticion,
  end_message,
  profile,
}) => {
  const { isDefaultStore } = useIsDefaultStore();
  const { sucursalCiudad } = useGetSucursalCiudad({
    idRegion: sucursal.idRegion,
  });
  const { currentUrl } = useGetCurrentUrl();
  const { IdCategoria, subcategoriaId } = useParams();
  const { scrollToElementTop } = useScrollToElementTop({ id: "scrollableDiv" });
  const { searchTerm: search } = useSearchTerm();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  // const [categoria, setCategoria] = useState({});
  // const [subcategoria, setSubcategoria] = useState({});
  // const [arraySubcategorias, setArraySubcategorias] = useState([]);
  const { ciudadp, sucursalp } = useParams();
  const [url_navigation, setUrl_navigation] = useState(null);

  const categoria = useMemo(() => {
    if (categorias.length === 0) return null;
    let categId = categorias.find(
      (cat) => convertSlug.data_slug(cat.Descripcion) === IdCategoria
    )?.IdCategoria;
    return categorias?.find((cat) => cat.IdCategoria === categId) || {};
  }, [categorias, IdCategoria]);

  const arraySubcategorias = useMemo(() => {
    if (categoria === null) return null;
    return categoria?.SubCategorias || [];
  }, [categoria]);

  const subcategoria = useMemo(() => {
    if (arraySubcategorias === null) return null;
    return (
      arraySubcategorias.find(
        (sub) => convertSlug.data_slug(sub.Descripcion) === subcategoriaId
      ) || {}
    );
  }, [arraySubcategorias, subcategoriaId]);
  const IdMarket = sucursal.IdMarket;

  const isUserAuthenticated = useMemo(
    () => profile?.Telefono != null && profile?.Telefono !== "",
    [profile]
  );

  useEffect(() => {
    if (
      categorias?.length > 0 &&
      categoria !== null &&
      subcategoria !== null &&
      isUserAuthenticated !== null
    ) {
      actualizarSubcategoria();
    }
  }, [
    IdCategoria,
    subcategoriaId,
    categoria,
    subcategoria,
    categorias,
    isUserAuthenticated,
    search,
  ]);

  useEffect(() => {
    if (ciudadp && sucursalp) {
      const cargar_url_var = async () => {
        let url_parameters = await convertSlug.data_barra(
          `${ciudadp} ${sucursalp}`
        );
        setUrl_navigation(url_parameters);
      };
      cargar_url_var();
    }
  }, [ciudadp, sucursalp]);

  const actualizarSubcategoria = () => {
    // let categId = categorias.find(
    //   (cat) => convertSlug.data_slug(cat.Descripcion) === IdCategoria
    // )?.IdCategoria;
    // let categoria = categorias.find((cat) => cat.IdCategoria === categId);
    if (!!IdCategoria && !categoria.IdCategoria) {
      navigate(`/${url_navigation}`);
      return;
    }

    if (!!subcategoriaId && !subcategoria.IdSubcategoria) {
      navigate(`/${url_navigation}/categoria/${IdCategoria}`);
      return;
    }
    // setCategoria(categoria);
    // setArraySubcategorias(categoria.SubCategorias);

    if (!!subcategoriaId) {
      // let subcategoria = categoria.SubCategorias.find(
      //   (sub) => convertSlug.data_slug(sub.Descripcion) === subcategoriaId
      // );
      // setSubcategoria(subcategoria);
      if (subcategoriaId === slug.data_slug(subcategoria.Descripcion)) {
        obtenerProductos(subcategoria.IdSubcategoria, categoria.IdCategoria);
      } else {
        limpiar_array_producto();
      }
    } else if (slug.data_slug(categoria.Descripcion) === IdCategoria) {
      limpiar_array_producto();

      obtenerProductos(null, categoria.IdCategoria);
      // setSubcategoria({});
    }
  };

  const obtenerProductos = async (subcatId, categId) => {
    if (peticion) return;
    // setIsFetching(true);
    let datos = {
      IdMarket,
      IdLocatorio: sucursal.IdSucursal,
      IdCategoria: categId,
      IdSubcategoria: subcatId ? Number(subcatId) : null,
      texto_filtro: search || "",
    };
    setPage(1);
    await cargar_productos(datos, 1);
  };

  const cargar_productos = async (datos, page_aux) => {
    try {
      if (!subcategoriaId) {
        await get_all_producto(datos, page_aux);
      } else {
        await get_producto(datos, page_aux);
      }
    } finally {
      setIsFetching(false);
    }
  };

  const obtenerProductosTudo = () => {
    if (peticion) return;
    // setIsFetching(true);
    let subcatId = arraySubcategorias.find(
      (sub) => convertSlug.data_slug(sub.Descripcion) === subcategoriaId
    )?.IdSubcategoria;
    let categId = categorias.find(
      (cat) => convertSlug.data_slug(cat.Descripcion) === IdCategoria
    )?.IdCategoria;
    let datos = {
      IdMarket,
      IdLocatorio: sucursal.IdSucursal,
      IdCategoria: categId,
      IdSubcategoria: subcatId ? Number(subcatId) : null,
      texto_filtro: search || "",
    };
    if (productos_peticion) {
      const newPage = page + 1;
      setPage(newPage);
      cargar_productos(datos, newPage);
    } else {
      setIsFetching(false);
    }
  };

  const selectSubcategory = (subcat) => {
    setPage(1);
    limpiar_array_producto();
    let subcategoria_slug = convertSlug.data_slug(subcat.Descripcion);
    navigate(
      `/${url_navigation}/categoria/${IdCategoria}/${subcategoria_slug}`
    );
  };

  const obtenerCantidad = () => productos?.length || 0;

  const { breadcrumbs: itemListElement } = useBreadcrumbs({
    categoria: !!categoria?.IdCategoria ? categoria.Descripcion : null,
    subcategoria: !!subcategoria?.IdSubcategoria
      ? subcategoria.Descripcion
      : null,
  });

  const { breadcrumbListJSONLdScript } = useBreadcrumbListJSONLd({
    itemListElement,
  });
  const { itemListJSONLdScript } = useItemListJSONLd({
    name: !!subcategoria?.IdSubcategoria
      ? subcategoria?.Descripcion
      : categoria?.Descripcion,
    itemListOrder: ITEM_LIST_ORDER.ITEM_LIST_UNORDERED,
    itemListElement: productos?.map((product, index) => ({
      position: index + 1,
      item: {
        name: product.Descripcion,
        image: `${product.UrlFoto}&size=400x400`,
        url: `${currentUrl}/${slug.data_slug(product.Descripcion)}`,
        sku: product.IdProducto,
        offers: {
          priceCurrency: "BOB",
          price: product.PrecioVenta,
          itemCondition: ITEM_CONDITION.NEW,
          availability: AVAILABILITY.IN_STOCK,
          seller: {
            name: sucursal.Descripcion,
          },
        },
      },
    })),
  });

  const keywords = [
    `${process.env.REACT_APP_ORGANIZATION_NAME} ${
      sucursalCiudad?.Nombre || ""
    }`,
    sucursal?.Descripcion || "",
    `${sucursal?.TipoServicio.Descripcion || ""} ${
      sucursalCiudad?.Nombre || ""
    }`,
    `${sucursal?.TipoServicio.Descripcion || ""} online Bolivia`,
    `Tienda online ${sucursal?.Descripcion}`,
    `Compras online ${sucursalCiudad?.Nombre || ""}`,
    `Entrega a domicilio ${sucursalCiudad?.Nombre || ""}`,
    `Compras en línea ${sucursalCiudad?.Nombre || ""}`,
    `Ofertas ${sucursal?.Descripcion}`,
    `Descuentos ${sucursal?.Descripcion}`,
    `Marcas exclusivas ${sucursal?.Descripcion}`,
    `Promociones ${sucursal?.Descripcion}`,
    `Ofertas especiales ${sucursalCiudad?.Nombre || ""}`,
    `Delivery de ${sucursal?.TipoServicio.Descripcion || ""} Bolivia`,
    `Delivery de ${sucursal?.TipoServicio.Descripcion || ""} ${
      sucursalCiudad?.Nombre
    }`,
    `Delivery de ${sucursal?.TipoServicio.Descripcion || ""} ${
      sucursal?.Descripcion
    }`,
    DEFAULT_KEYWORDS,
  ];

  if (!!categoria?.IdCategoria) {
    keywords.push(categoria.Descripcion);
    keywords.push(`Compra de ${categoria.Descripcion}`);

    keywords.push(`${categoria.Descripcion} Bolivia`);
    keywords.push(`Compra de ${categoria.Descripcion} en Bolivia`);

    keywords.push(`${categoria.Descripcion} ${sucursal?.Descripcion || ""}`);
    keywords.push(
      `Compra de ${categoria.Descripcion} en ${sucursal?.Descripcion || ""}`
    );
  }

  if (!!subcategoria?.IdCategoria) {
    keywords.push(subcategoria.Descripcion);
    keywords.push(`Compra de ${subcategoria.Descripcion}`);
    keywords.push(`${subcategoria.Descripcion} Bolivia`);
    keywords.push(`Compra de ${subcategoria.Descripcion} en Bolivia`);
    keywords.push(`${subcategoria.Descripcion} ${sucursal?.Descripcion || ""}`);
    keywords.push(
      `Compra de ${subcategoria.Descripcion} en ${sucursal?.Descripcion || ""}`
    );
  }

  const displaySEO = useMemo(() => {
    if (!sucursal) return false;
    if (!sucursalCiudad) return false;
    if (IdCategoria && !categoria) return false;
    if (subcategoriaId && !subcategoria) return false;

    return true;
  }, [
    IdCategoria,
    categoria,
    subcategoria,
    subcategoriaId,
    sucursal,
    sucursalCiudad,
  ]);

  return (
    <>
      {displaySEO && (
        <HomeCategoriaSEO
          title={`${categoria?.Descripcion || ""} ${
            subcategoria?.Descripcion ? `| ${subcategoria?.Descripcion}` : ""
          } | ${isDefaultStore ? "Hipermaxi" : sucursal?.Descripcion || ""}`}
          store={`${sucursal?.Descripcion}`}
          description={`Realiza tu compra en línea de productos de ${
            !!subcategoria?.Descripcion
              ? subcategoria?.Descripcion
              : categoria?.Descripcion
          } en ${
            !sucursal || isDefaultStore
              ? "tu sucursal Hipermaxi más cercana"
              : `la sucursal ${sucursal.Descripcion}`
          } en Bolivia. Mediante unos simples pasos, envía tus productos al carrito de compra, decide cómo pagar y recíbelos en la puerta de tu casa. Disfruta de la conveniencia de ${
            !sucursal || isDefaultStore
              ? "nuestra sucursal"
              : `${
                  KEYWORD_BY_SERVICE_TYPE[
                    sucursal.TipoServicio.Descripcion.toUpperCase()
                  ]
                }`
          } en línea Hipermaxi y encuentra una amplia variedad de productos locales, productos importados y ofertas exclusivas.`}
          breadcrumbListJSONLdScript={breadcrumbListJSONLdScript}
          itemListJSONLdScript={itemListJSONLdScript}
          image={
            !!subcategoria?.UrlFoto
              ? `${subcategoria?.UrlFoto}&size=400x400`
              : `${categoria?.UrlFoto}&size=400x400`
          }
          url={currentUrl}
          keywords={keywords}
        />
      )}
      <Alert />

      <Layout>
        <div className="sticky top-[108px] md:top-[120px] lg:hidden p-2 z-20 bg-white flex flex-col gap-2 pb-1">
          <BuscadorPorCategoria
            categoriaId={IdCategoria}
            subCategoriaId={subcategoriaId}
            showLabel={false}
          />
          {/* <div className="flex items-center p-2">
            <h4 className="text-center text-black mb-0 block md:hidden">
              {categoria?.Descripcion}
            </h4>
          </div> */}
          <div className="w-full relative flex gap-1">
            <div
              className={classNames(
                `review-swiper-button-prev-${IdCategoria} text-gray-500 hover:text-gray-800 flex items-center`,
                {
                  "opacity-1": arraySubcategorias?.length > 0,
                  "opacity-0": arraySubcategorias?.length === 0,
                }
              )}
            >
              <span className="bg-white border shadow-sm p-1 rounded-md">
                <FontAwesomeIcon icon={faAngleLeft} size="lg" />
              </span>
            </div>
            <Swiper
              slidesPerView="auto"
              freeMode
              spaceBetween={5}
              navigation={{
                nextEl: `.review-swiper-button-next-${IdCategoria}`,
                prevEl: `.review-swiper-button-prev-${IdCategoria}`,
              }}
              modules={[FreeMode, Navigation]}
              className="mySwiper"
            >
              {arraySubcategorias?.map((subcategory, index) => (
                <SwiperSlide key={index} className="w-auto">
                  <div
                    className={classNames(
                      "py-1.5 px-2 rounded-lg cursor-pointer text-sm",
                      {
                        "border-1 hover:border-hiperorange hover:bg-hiperorange hover:text-white":
                          Number(subcategory.IdSubcategoria) !==
                          Number(subcategoria.IdSubcategoria),
                      }
                    )}
                    style={{
                      backgroundColor:
                        Number(subcategory.IdSubcategoria) ===
                        Number(subcategoria.IdSubcategoria)
                          ? "var(--primario)"
                          : "transparent",
                      color:
                        Number(subcategory.IdSubcategoria) ===
                        Number(subcategoria.IdSubcategoria)
                          ? "#FFFFFF"
                          : "#000000",
                    }}
                    onClick={() => selectSubcategory(subcategory)}
                  >
                    {subcategory.Descripcion}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div
              className={classNames(
                `review-swiper-button-next-${IdCategoria} text-gray-500 hover:text-gray-800 flex items-center`,
                {
                  "opacity-1": arraySubcategorias?.length > 0,
                  "opacity-0": arraySubcategorias?.length === 0,
                }
              )}
            >
              <span className="bg-white border shadow-sm p-1 rounded-md">
                <FontAwesomeIcon icon={faAngleRight} size="lg" />
              </span>
            </div>
          </div>
        </div>
        <main className="relative flex-grow">
          <CategoriasCollapseDesktop />
          <div className="md:ml-60 lg:ml-72">
            <div className="w-full py-4 px-3">
              <InfiniteScroll
                scrollableTarget="scrollableDiv"
                dataLength={obtenerCantidad()}
                next={obtenerProductosTudo}
                hasMore={true}
                scrollThreshold={0.95}
                loader={
                  peticion ||
                  categoria === null ||
                  subcategoria === null ||
                  isUserAuthenticated === null ? (
                    <div className="mt-4">
                      <LoaderSpinner bool={peticion} />
                    </div>
                  ) : (
                    end_message && (
                      <div className="flex w-full justify-center">
                        {obtenerCantidad() > 0 ? (
                          <p className="text-gray-400 ">
                            No hay más productos para mostrar
                          </p>
                        ) : (
                          <div className="flex items-center justify-center h-[50vh] flex-col gap-5">
                            <FontAwesomeIcon
                              className="text-gray-500"
                              icon={faCartCircleXmark}
                              size="6x"
                            />
                            <div className="flex flex-col gap-2">
                              <div className="text-gray-500 text-lg md:text-xl text-center">
                                {search
                                  ? "No encontramos productos que coincidan con tu búsqueda."
                                  : "No hay productos para mostrar"}
                              </div>
                              <MensajeTelefonoProductoDisponible />
                            </div>
                          </div>
                        )}
                      </div>
                    )
                  )
                }
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>No hay más productos para mostrar</b>
                  </p>
                }
              >
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-7 gap-x-3 md:gap-x-6 gap-y-8">
                  {productos?.map((producto, index) => (
                    <ItemProducto
                      key={index}
                      producto={producto}
                      ps_IdSucursal={sucursal.IdSucursal}
                      ps_ciudad_slug={ciudadp}
                      ps_sucursal_slug={sucursalp}
                    />
                  ))}
                </div>
              </InfiniteScroll>
            </div>
          </div>
          {/* <button
          className="footer-button  bg-hiperorange hover:bg-hiperorange_hover opacity-80 text-white font-bold py-1 px-3 rounded"
          type="button"
          onClick={scrollToElementTop}
        >
          <FontAwesomeIcon icon={faArrowUp} />
        </button> */}
        </main>
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => ({
  sucursal: state.BranchOffice.sucursal,
  categorias: state.Home.categorias,
  subcategorias: state.Home.subcategorias,
  category: state.Home.category,
  subcategoria_id: state.Home.subcategoria_id,
  loading: state.Producto.loading,
  productos: state.Producto.productos,
  productos_peticion: state.Producto.productos_peticion,
  productosCategory: state.Home.productosCategory,
  peticion: state.Peticion.peticion,
  end_message: state.Producto.end_message,
  profile: state.Profile.profile,
});

export default connect(mapStateToProps, {
  get_all_producto,
  get_producto,
  limpiar_array_producto,
})(HomeCategoria);
