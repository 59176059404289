import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Indice from "./compra_rapida/indice_compra_rapida";
import EntregaCompraRapida from "../../containers/cart/EntregaCompraRapida";
import FormaPagoCompraRapida from "../../containers/cart/FormaPagoCompraRapida";
import "../../assets/styles/responsive.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import useScreenSize from "../../widgets/useScreenSize";
import classNames from "classnames";

const Compra_rapida = ({
  cerrarModal,
  productos,
  importeTotal,
  importeAhorrodo,
}) => {
  
  const { width, height } = useScreenSize();
  const [productosCarrito, guardarProductosCarrito] = useState([]);
  const [data, setData] = useState({});
  const [posicion, guardarPosicion] = useState(1);
  const [is_carrito, guardarIsCarrito] = useState(true);
  const navigate = useNavigate();
  const { ciudadp, sucursalp } = useParams();
  useEffect(() => {
    let aux_productos = productos.filter((prod) => prod.Vigente);
    guardarProductosCarrito(aux_productos);
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const actualizar_posicion = (numero) => {
    guardarPosicion(numero);
  };

  const actualizar_data = (data) => {
    setData(data);
    guardarPosicion(2);
  };

  const exit = () => {
    cerrarModal();
  };

  const finalizado_exit = () => {
    guardarIsCarrito(false);
  };

  const ir_inicio = () => {
    let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
    if (ciudadp && sucursalp) {
      navigate("/" + ciudadp + "/" + sucursalp);
    } else if (url_nav) {
      navigate("/" + url_nav.url_parameters);
    } else {
      navigate("/");
    }
  };
  const navigate_menu = async (url_menu) => {
    let param = await get_url();
    navigate(param + "/" + url_menu);
  };

  const get_url = async () => {
    let url_nav = JSON.parse(localStorage.getItem("url_navigation"));
    if (ciudadp && sucursalp) {
      return "/" + ciudadp + "/" + sucursalp;
    } else if (url_nav) {
      return "/" + url_nav.url_parameters;
    }
  };

  return (
    <>
      {is_carrito ? (
        <div
          className="rounded-lg overflow-hidden w-full h-full"
        >
          <div className="w-full max-w-[1320px] h-full">
            <div
              className={classNames(
                "h-full flex flex-col md:flex-row md:gap-2 mx-0",
                {
                  "justify-center": width > 768,
                }
              )}
            >
              <div className="hidden md:flex w-[25rem] md:w-[18%]  flex-col h-full">
                <Indice posicion={posicion}></Indice>
              </div>
              {posicion === 1 && (
                <EntregaCompraRapida
                  exit={exit}
                  key={posicion}
                  width={width}
                  height={height}
                  mt_next={actualizar_data}
                  productos={productosCarrito}
                  importeTotal={importeTotal}
                  importeAhorrodo={importeAhorrodo}
                />
              )}
              {posicion === 2 && (
                <FormaPagoCompraRapida
                  mt_indice={actualizar_posicion}
                  data={data}
                  width={width}
                  height={height}
                  exit={exit}
                  finalizado={finalizado_exit}
                  importeTotal={importeTotal}
                  importeAhorrodo={importeAhorrodo}
                ></FormaPagoCompraRapida>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex w-full justify-center items-center h-full">
          <div className="w-full max-w-xl">
            <div className="card border-0 rounded-xl overflow-hidden">
              <div className="card-body  bg-hiperorange d-flex justify-center">
                <div className="py-5">
                  <div className=" text-center">
                    <FontAwesomeIcon
                      icon={faCheck}
                      className="text-white text-8xl"
                    />
                    <div>
                      <p className="text-white text-2xl">
                        Se realizó su pedido correctamente
                      </p>
                    </div>
                  </div>
                  <div className="grid grid-rows-2 gap-3 text-center">
                    <button
                      className="h-full w-full rounded-[7px] py-2 px-3 border_white text-white focus:outline-none focus:shadow-outline transition duration-300 ease-in-out hover:scale-105"
                      onClick={() => ir_inicio()}
                    >
                      Seguir comprando
                    </button>

                    <Link onClick={() => navigate_menu("mis-pedidos")}>
                      <button className="h-full w-full rounded-[7px] py-2 px-3 border_white text-white focus:outline-none focus:shadow-outline transition duration-300 ease-in-out hover:scale-105">
                        Detalle del pedido
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Compra_rapida);
