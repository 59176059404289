import { useState } from "react";
import { QRCodeComponent } from "./qr-code-component";
import { BarcodeComponent } from "./barcode-component";
import LoaderSpinner from "../../../../widgets/loaderSpinner";

interface SwitchCodesComponentProps {
  code: string;
  defaultCodeType?: CodeTypes;
  displayValue?: boolean;
  barcodeSize?: BarcodeSize;
  enableSwitch?: boolean;
}

type CodeTypes = "qrcode" | "barcode";

type BarcodeSize = "default" | "small";

const CODE_VALUES: Record<CodeTypes, string> = {
  qrcode: "código QR",
  barcode: "código de barras",
};

export function SwitchCodesComponent({
  code,
  defaultCodeType,
  displayValue = true,
  barcodeSize = "default",
  enableSwitch = true,
}: SwitchCodesComponentProps) {
  const [codeType, setCodeType] = useState<CodeTypes>(
    defaultCodeType || "barcode"
  );

  const handleSetCodeType = () => {
    if (codeType === "qrcode") setCodeType("barcode");
    else if (codeType === "barcode") setCodeType("qrcode");
  };

  const getCodeValueTranslationToDisplay = (codeValue: CodeTypes): string => {
    switch (codeValue) {
      case "qrcode":
        return CODE_VALUES.barcode;
      case "barcode":
        return CODE_VALUES.qrcode;
      default:
        return "";
    }
  };

  const codeValueTranslation = getCodeValueTranslationToDisplay(codeType);
  return (
    <div className="flex flex-col gap-8 items-center">
      <button
        onClick={handleSetCodeType}
        disabled={!code || !enableSwitch}
        className="w-60 h-60 flex flex-col items-center justify-center bg-white rounded-full gap-2"
      >
        {!code ? (
          <LoaderSpinner bool={true} />
        ) : (
          <>
            {codeType === "qrcode" && (
              <QRCodeComponent value={code} maxWidth={125} />
            )}
            {codeType === "barcode" && (
              <BarcodeComponent
                value={code}
                width={barcodeSize === "small" ? 0.5 : 2}
              />
            )}
            {displayValue && (
              <div className="text-center text-xl text-black font-semibold leading-snug">
                {code}
              </div>
            )}
          </>
        )}
      </button>
      {enableSwitch && (
        <div className="text-center text-lg font-semibold leading-snug">
          {`Haz click para cambiar a ${codeValueTranslation}`}
        </div>
      )}
    </div>
  );
}
