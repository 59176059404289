import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./tailwind.output.css";

import "bootstrap/dist/css/bootstrap.min.css";

import { dom } from "@fortawesome/fontawesome-svg-core";
import { register } from "./serviceWorker";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { HelmetProvider } from "react-helmet-async";

dom.watch();
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GG_APP_ID}>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </GoogleOAuthProvider>
);

register();
