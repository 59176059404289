import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  get_sucursales,
  set_sucursal,
  get_ciudades,
  get_sucursales_cerca,
} from "../../redux/actions/branch_office";

import classNames from "classnames";
import { isPointWithinRadius } from "geolib";
import "../../assets/styles/responsive.scss";
import convertSlug from "../../components/Utils/slug";
import { update_url_navigation } from "../../redux/actions/url_navegacion";
import {
  limpiar_carrito,
  store_carrito_data,
} from "../../redux/actions/carrito";
import { info_session } from "../../redux/actions/profile";
import ButtonClose from "../../widgets/button_close";
import SucursalCard from "./sucursal_card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faStore,
  faStoreSlash,
} from "@fortawesome/pro-solid-svg-icons";
import Modal from "react-modal";
import Direccion from "./direccion";
import { useGetBackLink } from "../../hooks/useGetBackLink";
import LoaderSpinner from "../../widgets/loaderSpinner";

const style_direccion = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "31",
  },
  content: {
    padding: "0px",
    border: "0px",
    background: "transparent",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "calc(100% - 64px)",
    overflow: "hidden",
  },
};

const Sucursal = ({
  get_sucursales,
  get_sucursales_cerca,
  set_sucursal,
  branch_offices,
  sucursales,
  onCloseModal,
  direccion,
  tipo_entrega,
  sucursal,
  carrito,
  producto_delete,
  update_url_navigation,
  get_ciudades,
  ciudades,
  limpiar_carrito,
  store_carrito_data,
  info_session,
  profile,
}) => {
  const { backLink } = useGetBackLink();
  const scrolleableContainer = useRef(null);
  const [array_sucursales, guardarSucursales] = useState(null);
  const [AuxSucursal, guardarAuxSucursal] = useState(sucursal.IdSucursal);
  const [selectedCiudad, setSelectedCiudad] = useState({
    Nombre: "",
  });
  const [sucursalPorCiudad, setSucursalPorCiudad] = useState(null);
  const [modal_direccion, setModalDireccion] = useState(false);
  const optionCiudad = (ciudad) => {
    setSelectedCiudad({
      Nombre: ciudad.Nombre,
      Latitud: ciudad.Latitud,
      Longitud: ciudad.Longitud,
    });
    let direccion = {
      Latitud: ciudad.Latitud,
      Longitud: ciudad.Longitud,
    };
    onloadSucursalesCiudad(branch_offices, ciudad);
  };
  const tieneCobertura = useMemo(() => {
    if (!direccion) return true;
    const sucursalEncontrada = array_sucursales?.find(
      (suc) => suc.IdMarket === sucursal.IdMarket
    );

    const locatarioEncontrado = sucursalEncontrada?.Locatarios.find(
      (locatario) => locatario.IdSucursal === sucursal.IdSucursal
    );
    return !!locatarioEncontrado;
  }, [sucursal, array_sucursales, direccion]);
  const navigate = useNavigate();

  useEffect(() => {
    if (profile) {
      if (profile.Ubicaciones.length === 0) {
        get_sucursales();
      }
    } else {
      info_session();
    }
  }, [profile]);

  useEffect(() => {
    if (sucursal.IdSucursal !== AuxSucursal) {
      guardarAuxSucursal(sucursal.IdSucursal);
      limpiar_carrito();
      // onload_slug(sucursal);
      // onCloseModal();
    }
  }, [sucursal]);

  useEffect(() => {
    if (direccion) {
      get_sucursales_cerca(direccion);
      guardarCarrito();
    }
    getCiudades();
  }, [direccion]);

  useEffect(() => {
    guardarSucursales(sucursales);
  }, [sucursales]);

  useEffect(() => {
    if (!direccion && ciudades.length > 0 && !selectedCiudad?.Nombre) {
      if (!sucursal) {
        optionCiudad(ciudades[0]);
      } else {
        let item_ciudad = ciudades.find(
          (c) => c.IdRegion === sucursal.idRegion
        );
        optionCiudad(item_ciudad);
      }
    }
  }, [ciudades, optionCiudad, selectedCiudad, sucursal]);

  const actualizarSucursal = async (item_sucursal) => {
    await set_sucursal(item_sucursal);
    goToSucursal(item_sucursal);
  };

  const guardarCarrito = async () => {
    let params = {
      IdMarket: sucursal.IdMarket,
      IdSucursal: sucursal.IdSucursal,
      IdTipoEntrega: tipo_entrega.Id,
      IdDireccion: direccion.Id,
    };
    await store_carrito_data(params, carrito, producto_delete);
  };

  const getUrl = (item_sucursal) => {
    let ciudad = ciudadSeleccionado(item_sucursal.idRegion);
    let sucursal_select = sucursalSeleccionado(item_sucursal.IdSucursal);
    let parametro_url = {
      ciudad: convertSlug.data_slug(ciudad.Nombre),
      sucursal: convertSlug.data_slug(sucursal_select.Descripcion),
    };
    let resp = parametro_url.ciudad + " " + parametro_url.sucursal;
    let new_url = convertSlug.data_barra(resp);

    let url_parameters = new_url + backLink;
    return "/" + url_parameters;
  };

  const goToSucursal = async (item_sucursal) => {
    let url_parameters = getUrl(item_sucursal);
    // navigate("/" + url_parameters);
    window.location.href = url_parameters;
  };
  const sucursalSeleccionado = (IdSucursal) => {
    let seleccionado = null;
    sucursales.forEach((sucursal) => {
      let item = sucursal.Locatarios.find(
        (pre) => parseInt(pre.IdSucursal) === parseInt(IdSucursal)
      );
      if (item) {
        seleccionado = item;
      }
    });
    return seleccionado;
  };

  const ciudadSeleccionado = (id_region) => {
    if (validateCiudad) {
      let seleccionado = ciudades.find(
        (pre) => parseInt(pre.IdRegion) === parseInt(id_region)
      );
      return seleccionado;
    }
  };

  const getCiudades = async () => {
    if (ciudades.length === 0) {
      await get_ciudades();
    }
  };

  function validateCiudad() {
    return ciudades.length > 0 ? true : false;
  }

  const onloadSucursalesCiudad = (sucursales, ciudad) => {
    const sucursalesPorCiudad = sucursales?.filter(
      (sucursal) => sucursal.IdRegion === ciudad.IdRegion
    ) || [];
    // let arrayDato = sucursales.length;
    // let array_aux = [];

    // if (arrayDato > 0) {
    //   sucursales.forEach((sucursal) => {
    //     const sucursalz = { ...sucursal };
    //     let coberturaSucursal = null;
    //     coberturaSucursal = isPointWithinRadius(
    //       {
    //         latitude: parseFloat(sucursalz.UbicacionLatitud),
    //         longitude: parseFloat(sucursalz.UbicacionLongitud),
    //       }, //punto dentro del perimetro
    //       {
    //         latitude: parseFloat(ciudad.Latitud),
    //         longitude: parseFloat(ciudad.Longitud),
    //       }, //punto centro desde
    //       100000
    //     );

    //     if (coberturaSucursal) {
    //       array_aux.push(sucursalz);
    //     }
    //   });
    //   if (array_aux.length == 0) {
    //     array_aux = sucursales;
    //   }
    // }
    setSucursalPorCiudad([...sucursalesPorCiudad]);
  };

  const endModalDireccion = () => setModalDireccion(false);
  const startModalDireccion = () => {
    setModalDireccion(true);
  };

  const sucursalesAMostrar =
    selectedCiudad.Nombre === "" ? array_sucursales : sucursalPorCiudad;

  return (
    <>
      <div className="bg-white rounded-lg overflow-hidden w-full h-full">
        <div className="flex flex-col h-full">
          <div className="bg-hiperorange w-full text-white flex items-center justify-center  content_title relative">
            <h2 className="p-0 m-0 title_carrito_responsivo">
              Seleccionar una sucursal
            </h2>
            <div className="absolute right-2 ">
              <ButtonClose
                className="text-white bg-transparent"
                onClick={() => onCloseModal()}
              />
            </div>
          </div>
          <div
            ref={scrolleableContainer}
            className="flex flex-col gap-2 p-2 pt-0 overflow-y-auto"
          >
            {sucursal && (
              <div className="text-center p-2 pb-0 leading-snug text-sm md:text-base">
                {tieneCobertura
                  ? `Te encuentras en la sucursal ${sucursal.TipoServicio.Descripcion} ${sucursal.Descripcion}`
                  : `Lamentablemente, su ubicación actual no está dentro del área de cobertura de la sucursal ${sucursal.TipoServicio.Descripcion} ${sucursal.Descripcion}. Le invitamos a seleccionar otra sucursal o registrar una dirección que se encuentre dentro de nuestra zona de servicio.`}
              </div>
            )}
            {direccion ? (
              <div className="flex flex-col p-2 gap-2">
                <div className="text-lg font-bold">Seleccionar dirección</div>
                <button
                  onClick={() => startModalDireccion()}
                  className="p-2 py-0 rounded-lg text-white mx-2 flex items-center text-left bg-hiperorange hover:bg-hiperorange_hover"
                >
                  <div className="p-2 truncate w-full">
                    {`${direccion.Descripcion}, ${direccion.Direccion}`}
                  </div>
                  <FontAwesomeIcon
                    style={{ width: "15px", height: "15px" }}
                    icon={faChevronDown}
                  />
                </button>
              </div>
            ) : (
              <>
                {ciudades && ciudades.length > 0 && (
                  <div className="flex flex-col gap-2 p-2 pb-0">
                    <div className="text-lg font-bold">Seleccionar ciudad</div>
                    <div className="w-full flex flex-wrap gap-2 items-center">
                      {ciudades.map((item_ciudad, index) => (
                        <button
                          key={index}
                          className={classNames(
                            "rounded-lg py-1 items-center text-center min-w-[120px]",
                            {
                              "bg-hiperorange hover:bg-hiperorange_hover border-solid border-1 border-hiperorange text-white":
                                selectedCiudad.Nombre === item_ciudad.Nombre,
                              "bg-white hover:bg-gray-300 text-black border-solid border-1 border-black":
                                selectedCiudad.Nombre !== item_ciudad.Nombre,
                            }
                          )}
                          onClick={() => {
                            optionCiudad(item_ciudad);
                            if (scrolleableContainer) {
                              scrolleableContainer.current.scrollTo(0, 0);
                            }
                          }}
                        >
                          {item_ciudad.Nombre}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </>
            )}
            <div className="text-lg font-bold">Seleccionar sucursal</div>
            <div className={classNames("flex flex-col gap-4 px-3")}>
              {!sucursalesAMostrar ? (
                <LoaderSpinner bool={true} />
              ) : (
                <>
                  {sucursalesAMostrar.length > 0 ? (
                    <>
                      {sucursalesAMostrar.map((branch_office, index) => (
                        <SucursalCard
                          key={`branch-${branch_office.IdMarket}`}
                          branch_office={branch_office}
                          selectedSucursal={sucursal}
                          onClick={actualizarSucursal}
                          ciudad={ciudadSeleccionado(branch_office.IdRegion)}
                        backLink={backLink}
                        />
                      ))}
                    </>
                  ) : (
                    <div className="flex flex-col gap-4 justify-center items-center mt-2 min-h-60 text-gray-400">
                      <FontAwesomeIcon icon={faStore} size="6x" />
                      <div className="text-xl">
                        No hay sucursales para mostrar
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modal_direccion}
        ariaHideApp={false}
        onRequestClose={endModalDireccion}
        className="fixed inset-0 flex items-center justify-center p-2 md:p-4 w-full md:w-[25rem] mx-auto focus-visible:outline-none"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-30"
        contentLabel="direccion"
      >
        <Direccion
          close_modal={endModalDireccion}
          isEditable={false}
          title="Seleccionar Dirección"
        />
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  profile: state.Profile.profile,
  branch_offices: state.BranchOffice.branch_offices,
  sucursales: state.BranchOffice.sucursales,
  sucursal: state.BranchOffice.sucursal,
  direccion: state.Location.direccion,
  tipo_entrega: state.BranchOffice.tipo_entrega,
  ciudades: state.BranchOffice.ciudades,
  carrito: state.Carrito.carrito,
  producto_delete: state.Carrito.producto_delete,
});

export default connect(mapStateToProps, {
  info_session,
  get_sucursales_cerca,
  get_sucursales,
  set_sucursal,
  update_url_navigation,
  get_ciudades,
  limpiar_carrito,
  store_carrito_data,
})(Sucursal);
