import {
  GET_BRANCH_OFFICE_SUCCESS,
  GET_BRANCH_OFFICE_FAIL,
  SET_BRANCH_OFFICE_SUCCESS,
  SET_DELIVERY_TYPE_SUCCESS,
  GET_BRANCH_OFFICE_CERCA_SUCCESS,
  GET_CIUDADES_SUCCESS,
  SET_CIUDAD_SUCCESS,
  CLEAR_BRANCH_OFFICE,
  SET_CAMBIO_SUCURSAL,
  SET_IS_FETCHING_SUCURSALES,
} from "../actions/types";
import { isPointInPolygon } from "geolib";
const LOCATARIOS_TO_FILTER = process.env.REACT_APP_LOCATARIOS_TO_FILTER
  ? JSON.parse(process.env.REACT_APP_LOCATARIOS_TO_FILTER)
  : [];
const initialState = {
  branch_offices: [],
  sucursales: [],
  ciudades: [],
  sucursal: JSON.parse(localStorage.getItem("sucursal")),
  tipo_entrega: JSON.parse(localStorage.getItem("tipo_entrega")),
  loading: false,
  cambio_sucursal: false,
  ciudad: null,
  isFetchingSucursales: false,
};

export default function BranchOffice(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_IS_FETCHING_SUCURSALES:
      return {
        ...state,
        isFetchingSucursales: payload,
      };
    case CLEAR_BRANCH_OFFICE:
      return {
        ...state,
        branch_offices: [],
        sucursales: [],
        ciudades: [],
        sucursal: null,
        tipo_entrega: null,
      };
    case GET_BRANCH_OFFICE_SUCCESS:
      const filteredBranches = filtrarSucursales(payload.Dato);
      return {
        ...state,
        branch_offices: filteredBranches,
        sucursales: filteredBranches,
      };

    case GET_BRANCH_OFFICE_CERCA_SUCCESS:
      const filteredClosedBranches = filtrarSucursales(payload.res.Dato);
      return {
        ...state,
        branch_offices: filteredClosedBranches,
        sucursales: onloadSucursales(filteredClosedBranches, payload.direccion),
      };

    case GET_BRANCH_OFFICE_FAIL:
      return {
        ...state,
      };
    case SET_BRANCH_OFFICE_SUCCESS:
      updateStorageSucursal(payload);
      return {
        ...state,
        sucursal: payload,
      };
    case SET_CIUDAD_SUCCESS:
      return {
        ...state,
        ciudad: payload,
      };

    case GET_CIUDADES_SUCCESS:
      return {
        ...state,
        ciudades: payload.Dato,
      };
    case SET_DELIVERY_TYPE_SUCCESS:
      deleteStorageTypeDelivery();
      updateStorage(payload);
      return {
        ...state,
        tipo_entrega: payload,
      };
    case SET_CAMBIO_SUCURSAL:
      return {
        ...state,
        cambio_sucursal: payload,
      };

    // case DELETE_TIPO_ENTREGA:

    default:
      return state;
  }
}
function filtrarSucursales(sucursales) {
  const sucursalesFiltradas = sucursales ? [...sucursales] : [];
  if (LOCATARIOS_TO_FILTER?.length > 0) {
    for (let index = 0; index < LOCATARIOS_TO_FILTER.length; index++) {
      const locatarioAFiltrar = { ...LOCATARIOS_TO_FILTER[index] };
      const marketIndex = sucursalesFiltradas.findIndex(
        (sucursal) => sucursal.IdMarket === locatarioAFiltrar.IdMarket
      );
      if (marketIndex >= 0) {
        const market = { ...sucursalesFiltradas[marketIndex] };
        const filteredLocatarios = market.Locatarios?.filter(
          (locatario) => locatario.IdSucursal !== locatarioAFiltrar.IdSucursal
        );
        market.Locatarios = filteredLocatarios ? [...filteredLocatarios] : [];
        sucursalesFiltradas[marketIndex] = market;
      }
    }
    return sucursalesFiltradas;
  }
  return sucursales;
}

function onloadSucursales(dato, direccion) {
  if (!direccion) return dato;
  let array_aux = [];
  if (
    direccion == null ||
    Object.keys(direccion).length === 0 ||
    typeof direccion == "undefined"
  ) {
    return array_aux;
  }
  if (dato.length > 0) {
    dato.forEach((sucursal) => {
      let array_location = [];
      sucursal.Locatarios.forEach((locatario) => {
        let array_zona_cobertura = [];
        locatario.ZonasCobertura.forEach((zona) => {
          const points = zona.PuntosCobertura.map((e) => {
            return {
              latitude: Number(e.Latitud),
              longitude: Number(e.Longitud),
            };
          });
          let coberturaSucursal = isPointInPolygon(
            {
              latitude: Number(direccion.Latitud),
              longitude: Number(direccion.Longitud),
            },
            points
          );
          if (coberturaSucursal) {
            array_zona_cobertura.push(zona);
          }
        });
        if (array_zona_cobertura.length > 0) {
          array_location.push({
            ...locatario,
            ZonasCobertura: array_zona_cobertura,
          });
        }
      });
      if (array_location.length > 0) {
        array_aux.push({
          ...sucursal,
          Locatarios: array_location,
          distancia: calcularDistanciaEntreDosCoordenadas(
            sucursal.UbicacionLatitud,
            sucursal.UbicacionLongitud,
            direccion.Latitud,
            direccion.Longitud
          ),
        });
      }
    });
  }
  array_aux.sort(function (a, b) {
    if (Number(a.distancia) > Number(b.distancia)) {
      return 1;
    }
    if (Number(a.distancia) < Number(b.distancia)) {
      return -1;
    }
    return 0;
  });
  return array_aux;
}

const calcularDistanciaEntreDosCoordenadas = (lat1, lon1, lat2, lon2) => {
  // Convertir todas las coordenadas a radianes
  lat1 = gradosARadianes(lat1);
  lon1 = gradosARadianes(lon1);
  lat2 = gradosARadianes(lat2);
  lon2 = gradosARadianes(lon2);
  // Aplicar fórmula
  const RADIO_TIERRA_EN_KILOMETROS = 6371;
  let diferenciaEntreLongitudes = lon2 - lon1;
  let diferenciaEntreLatitudes = lat2 - lat1;
  let a =
    Math.pow(Math.sin(diferenciaEntreLatitudes / 2.0), 2) +
    Math.cos(lat1) *
      Math.cos(lat2) *
      Math.pow(Math.sin(diferenciaEntreLongitudes / 2.0), 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return (RADIO_TIERRA_EN_KILOMETROS * c).toFixed(2);
};
const gradosARadianes = (grados) => {
  return (grados * Math.PI) / 180;
};

function updateStorageSucursal(sucursal) {
  localStorage.setItem("sucursal", JSON.stringify(sucursal));
}

function deleteStorageTypeDelivery() {
  localStorage.removeItem("tipo_entrega");
}

function updateStorage(tipo_entrega) {
  localStorage.setItem("tipo_entrega", JSON.stringify(tipo_entrega));
}
