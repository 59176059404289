import { faCircleX, faSearch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import slug from "../components/Utils/slug";
import { useSearchTerm } from "../hooks/useSearchTerm";
import { get_categorias } from "../redux/actions/home";
import LoaderSpinner from "../widgets/loaderSpinner";
import Accordion from "./Accordion";

const CategoriasCollapseDesktop = ({
  get_categorias,
  categorias,
  sucursal,
}) => {
  const { searchTerm } = useSearchTerm();
  const { ciudadp, sucursalp, IdCategoria, subcategoriaId } = useParams();

  const navigate = useNavigate();
  const [filtrosubcategoria, setFiltrosubcategoria] = useState("");
  const [sucursalCategorias, setSucursalCategorias] = useState(null);

  const [categoriasFiltradas, setCategoriasFiltradas] = useState(null);
  // useEffect(() => {
  //   get_categorias(sucursal.IdSucursal, sucursal.IdMarket);
  // }, [sucursal.IdSucursal, sucursal.IdMarket]);

  useEffect(() => {
    setFiltrosubcategoria("");
  }, [IdCategoria, subcategoriaId]);

  useEffect(() => {
    if (sucursalCategorias === null && categorias?.length > 0) {
      setSucursalCategorias(categorias);
    }
  }, [categorias, sucursalCategorias]);

  const categoria = useMemo(
    () =>
      categorias?.find(
        (categoria) => slug.data_slug(categoria.Descripcion) === IdCategoria
      ),
    [categorias, IdCategoria]
  );
  const subcategoria = useMemo(
    () =>
      categoria?.SubCategorias?.find(
        (subcategoria) =>
          slug.data_slug(subcategoria.Descripcion) === subcategoriaId
      ),
    [categoria, subcategoriaId]
  );

  const scrollToCategoria = (index) => {
    const botonCategoria = document.getElementById(`boton-categoria-${index}`);

    if (botonCategoria && contenedorRef.current) {
      contenedorRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const resetCategoria = (event, cat) => {
    let cate_slug = slug.data_slug(cat.Descripcion);
    let url = `/${ciudadp}/${sucursalp}/categoria/${cate_slug}`;
    if (searchTerm) {
      url += `/?s=${searchTerm}`;
    }

    // if (!!filtrosubcategoria) setFiltrosubcategoria("");
    scrollToCategoria(cat.IdCategoria);
    navigate(url);
  };

  const getUrlCategoria = (cat) => {
    let cate_slug = slug.data_slug(cat.Descripcion);
    let url = `/${ciudadp}/${sucursalp}/categoria/${cate_slug}`;
    if (searchTerm) {
      url += `/?s=${searchTerm}`;
    }

    return url;
  };

  const seleccionarCategoria = (cat) => {
    let url = getUrlCategoria(cat);

    // if (!!filtrosubcategoria) setFiltrosubcategoria("");
    scrollToCategoria(cat.IdCategoria);
    navigate(url);
  };

  const getUrlSubCategoria = (cat, subcat) => {
    let cate_slug = slug.data_slug(cat.Descripcion);
    let subcate_slug = slug.data_slug(subcat.Descripcion);

    let url = `/${ciudadp}/${sucursalp}/categoria/${cate_slug}/${subcate_slug}`;
    if (searchTerm) {
      url += `/?s=${searchTerm}`;
    }
    return url;
  };

  const seleccionarSubCategoria = (cat, subcat) => {
    let url = getUrlSubCategoria(cat, subcat);
    scrollToCategoria(cat.IdCategoria);
    if (subcat?.IdSubcategoria !== subcategoria?.IdSubcategoria) navigate(url);
  };

  const getCategorialFiltradaUrl = (categoria) => {
    let url = `/${ciudadp}/${sucursalp}`;
    if (!categoria.IdSubcategoria) {
      let cate_slug = slug.data_slug(categoria.Descripcion);
      url += `/categoria/${cate_slug}`;
    } else {
      let cate_slug = slug.data_slug(categoria.DescripcionCategoria);
      let subcate_slug = slug.data_slug(categoria.Descripcion);
      url += `/categoria/${cate_slug}/${subcate_slug}`;
    }

    if (searchTerm) {
      url += `/?s=${searchTerm}`;
    }

    // if (!!filtrosubcategoria) setFiltrosubcategoria("");
    // navigate(url);
    return url;
  };

  const navigateToCategoriaFiltrada = (categoria) => {
    const url = getCategorialFiltradaUrl(categoria);
    navigate(url);
  };

  // /* agregado*/
  const handleChange2 = (event) => {
    setFiltrosubcategoria(event.target.value);
  };

  const contenedorRef = useRef(null);

  const buscarPorDescripcion = useCallback(
    (termino) => {
      const resultados = [];

      categorias.forEach((objeto) => {
        if (objeto.Descripcion.toLowerCase().includes(termino)) {
          resultados.push(objeto);
        }
        if (objeto.SubCategorias) {
          objeto.SubCategorias.forEach((subcategoria) => {
            if (subcategoria.Descripcion.toLowerCase().includes(termino)) {
              resultados.push({
                ...subcategoria,
                DescripcionCategoria: objeto.Descripcion,
              });
            }
          });
        }
      });

      return resultados;
    },
    [categorias]
  );

  const buscarCategoriaPorDescripcion = useCallback(() => {
    if (categorias?.length > 0) {
      if (filtrosubcategoria === "") {
        setSucursalCategorias(categorias);
        setCategoriasFiltradas(null);
      } else {
        const categorias_filtradas = categorias.filter((item) =>
          item.Descripcion.toLowerCase().includes(
            filtrosubcategoria.toLowerCase()
          )
        );
        setSucursalCategorias(categorias_filtradas);

        const categoriasFiltradas = buscarPorDescripcion(
          filtrosubcategoria.toLowerCase()
        );
        setCategoriasFiltradas(categoriasFiltradas);
      }
    }
  }, [buscarPorDescripcion, categorias, filtrosubcategoria]);

  useEffect(() => {
    buscarCategoriaPorDescripcion();
  }, [buscarCategoriaPorDescripcion]);
  return (
    <div
      className="fixed md:top-[13.5rem] lg:top-[7.5rem] md:h-[calc(100%-13.5rem)] lg:h-[calc(100%-7.5rem)] md:w-60 lg:w-72 z-10 overflow-y-auto bg-white border-r-[1px] border-l-gray-200 hidden md:block"
      style={{ scrollbarWidth: "thin" }}
    >
      <div className="flex p-2 pt-3 sticky top-0 bg-white">
        <div className="relative w-full">
          <input
            type="text"
            className="w-100 p-2 placeholder-slate-400 text-slate-600 relative  bg-gray-200 rounded text-sm border-0  focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange"
            value={filtrosubcategoria}
            onChange={(e) => handleChange2(e)}
            placeholder="Buscar categoría"
            style={{ paddingRight: "2rem", paddingLeft: "0.5rem" }}
          />
          <span className="z-10 absolute right-0 top-0 bottom-0 flex items-center justify-center w-8 bg-hiperorange rounded-r-lg text-white">
            <FontAwesomeIcon
              icon={faSearch}
              onClick={() => buscarCategoriaPorDescripcion}
            />
          </span>
        </div>
      </div>
      {sucursalCategorias ? (
        <>
          {categoriasFiltradas ? (
            <>
              {categoriasFiltradas.length > 0 ? (
                <div
                  className="flex flex-col m-0 p-2 pt-0"
                  style={{ scrollbarWidth: "thin" }}
                >
                  {categoriasFiltradas?.map((cat) => (
                    <a
                      key={`categoria-filtrada-${cat.IdCategoria}-${
                        cat.IdRubro
                      }-${cat.IdSubcategoria ? cat.IdSubcategoria : ""}`}
                      className={classNames(
                        "break-words py-1 px-2 rounded-lg w-full text-left font-semibold text-black flex flex-col",
                        {
                          "hover:border-1 hover:border-gray-200 hover:bg-gray-200":
                            Number(cat.IdCategoria) !==
                            Number(categoria?.IdCategoria),
                        }
                      )}
                      onClick={(event) => {
                        event.preventDefault();
                        navigateToCategoriaFiltrada(cat);
                      }}
                      href={getCategorialFiltradaUrl(cat)}
                    >
                      {!!cat.DescripcionCategoria && (
                        <span className="text-xs">
                          ({cat.DescripcionCategoria})
                        </span>
                      )}
                      <span className="leading-none">{cat.Descripcion}</span>
                    </a>
                  ))}
                </div>
              ) : (
                <div className="font-semibold text-center my-2">
                  {" "}
                  No hay categorías para mostrar
                </div>
              )}
            </>
          ) : (
            <>
              {sucursalCategorias.length > 0 ? (
                <div
                  ref={contenedorRef}
                  className="flex flex-col m-0 p-2 pt-0"
                  style={{ scrollbarWidth: "thin" }}
                >
                  {sucursalCategorias?.map((cat) => (
                    <Accordion
                      key={`disclosure-${cat.IdCategoria}`}
                      title={cat.Descripcion}
                      isOpen={
                        Number(cat.IdCategoria) ===
                        Number(categoria?.IdCategoria)
                      }
                      toggleAccordion={() => {
                        seleccionarCategoria(cat);
                        contenedorRef.current.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      childSelected={!!subcategoria}
                      categoryLink={getUrlCategoria(cat)}
                    >
                      {cat.SubCategorias.map((subcat) => {
                        return (
                          <div
                            key={`${subcat.IdCategoria}-${subcat.IdSubcategoria}`}
                            className={classNames(
                              "px-3 rounded-lg w-full flex items-center gap-1",
                              {
                                "hover:border-1 hover:border-gray-200 hover:bg-gray-200":
                                  Number(subcat.IdSubcategoria) !==
                                  Number(subcategoria?.IdSubcategoria),
                              }
                            )}
                            style={{
                              backgroundColor:
                                Number(subcat.IdSubcategoria) ===
                                Number(subcategoria?.IdSubcategoria)
                                  ? "var(--primario)"
                                  : "transparent",
                              color:
                                Number(subcat.IdSubcategoria) ===
                                Number(subcategoria?.IdSubcategoria)
                                  ? "#FFFFFF"
                                  : "#000000",
                            }}
                          >
                            <a
                              href={getUrlSubCategoria(cat, subcat)}
                              className="px-1 py-1.5 leading-tight text-left break-words flex-grow text-inherit"
                              onClick={(event) => {
                                event.preventDefault();
                                seleccionarSubCategoria(cat, subcat);
                                contenedorRef.current.scrollTo({
                                  top: 0,
                                  behavior: "smooth",
                                });
                              }}
                            >
                              {subcat.Descripcion}
                            </a>
                            {Number(subcat.IdSubcategoria) ===
                              Number(subcategoria?.IdSubcategoria) && (
                              <button
                                className="w-5 h-5"
                                onClick={(event) => {
                                  resetCategoria(event, cat);
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faCircleX}
                                  className="text-white w-5 h-5 hover:text-hiperorange_claro "
                                />
                              </button>
                            )}
                          </div>
                        );
                      })}
                    </Accordion>
                  ))}
                </div>
              ) : (
                <div className="font-semibold text-center my-2">
                  {" "}
                  No hay categorías para mostrar
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <LoaderSpinner bool={true} />
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  categorias: state.Home.categorias,
  sucursal: state.BranchOffice.sucursal,
});

export default connect(mapStateToProps, {
  get_categorias,
})(CategoriasCollapseDesktop);
