import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

export function useGetBackLink() {
  const location = useLocation();
  const { ciudadp, sucursalp } = useParams();
  const backLink = useMemo(() => {
    if (!ciudadp || !sucursalp) return "";

    return location.pathname.replace(`/${ciudadp}/${sucursalp}`, "");
  }, [ciudadp, location.pathname, sucursalp]);

  return { backLink, searchParams: location?.search };
}
