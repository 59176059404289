import { useEffect, useState } from "react";

export function useImageCache({ src }) {
  const [imageSrc, setImageSrc] = useState("");

  const CACHE_NAME = "image-cache";
  const EXPIRATION_TIME = 86400000; // 1 día en milisegundos

  async function cacheImage(url) {
    const cache = await caches.open(CACHE_NAME);
    const response = await fetch(url);

    if (response.ok) {
      await cache.put(url, response.clone());
      const expirationDate = Date.now() + EXPIRATION_TIME;
      const metadata = { expirationDate };
      await cache.put(
        url + "-metadata",
        new Response(JSON.stringify(metadata))
      );
      return response;
    } else {
      throw new Error("Network response was not ok.");
    }
  }

  useEffect(() => {
    const loadImage = async () => {
      try {
        const cache = await caches.open(CACHE_NAME);
        const cachedResponse = await cache.match(src);
        const metadataResponse = await cache.match(src + "-metadata");

        if (cachedResponse && metadataResponse) {
          const metadata = await metadataResponse.json();
          if (Date.now() < metadata.expirationDate) {
            setImageSrc(URL.createObjectURL(await cachedResponse.blob()));
            return;
          } else {
            // Expired: delete the cached image and its metadata
            await cache.delete(src);
            await cache.delete(src + "-metadata");
          }
        }

        // If the image is not in the cache or expired, download it and cache it
        const response = await cacheImage(src);
        setImageSrc(URL.createObjectURL(await response.blob()));
      } catch (error) {
        console.error("Failed to load image:", error);
        setImageSrc(src);
      }
    };

    loadImage();
  }, [src]);

  return { imageSrc };
}
