import { useSelector } from "react-redux";

export function useGetSucursalCiudad({idRegion}) {
  const ciudades = useSelector((state) => state.BranchOffice.ciudades);

  const sucursalCiudad = ciudades?.find(
    (pre) => parseInt(pre.IdRegion) === parseInt(idRegion)
  );

  return {sucursalCiudad}
}
