
export function useFilterSucursales({ sucursales, searchTerm }) {

  const filteredSucursales = sucursales.filter((sucursal) =>
    sucursal.Descripcion
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );
  return { filteredSucursales, searchTerm };
}
