import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ColorRing } from "react-loader-spinner";
import { connect } from "react-redux";
import { cn } from "../libs/utils";

const ButtonIcon = ({
  icon: Icon,
  type = "button",
  label,
  className,
  onClick,
  principal = false,
  peticion,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={peticion}
      style={peticion ? { cursor: "not-allowed" } : {}}
      className={cn(
        "flex gap-2 items-center py-1.5 px-4 w-full rounded-lg transition-colors duration-300 font-medium",
        className
      )}
    >
      {principal && peticion ? (
        <ColorRing
          colors={["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"]}
          width={25}
          height={25}
          ariaLabel="circles-loading"
          visible={peticion}
        />
      ) : (
        Icon && <FontAwesomeIcon icon={Icon} size="lg" />
      )}

      <span>{label}</span>
    </button>
  );
};

const mapStateToProps = (state) => ({
  peticion: state.Peticion.peticion,
});

export default connect(mapStateToProps, {})(ButtonIcon);
