import classNames from "classnames";
import { useState } from "react";
import { LazyLoadImage as LazyLoadImageComponent } from "react-lazy-load-image-component";

export function LazyLoadImage({ src, alt, className, style }) {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <>
      {isLoading && (
        <div className="absolute animate-pulse inset-0 w-full h-full backdrop-blur-lg bg-slate-200/40 text-slate-200/40 rounded-lg" />
      )}
      <LazyLoadImageComponent
        style={style}
        src={src}
        alt={alt}
        className={classNames(
          `transition-opacity duration-100 ${
            !isLoading ? "opacity-100" : "opacity-0"
          }`,
          className
        )}
        placeholder={
          <span className="animate-pulse w-full backdrop-blur-lg bg-slate-200/40 text-slate-200/40 rounded-lg">
            ...
          </span>
        }
        onLoad={() => setIsLoading(false)}
        loading="lazy"
      />
    </>
  );
}
