import ItemEstadoPedido from "../../widgets/item_estado_pedido";
import {
  GET_BRANCH_OFFICE_SUCCESS,
  GET_BRANCH_OFFICE_FAIL,
  SET_AUTH_LOADING,
  REMOVE_AUTH_LOADING,
  SET_BRANCH_OFFICE_SUCCESS,
  GET_HOME_SUCCESS,
  GET_HOME_FAIL,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FILTRO_SUCCESS,
  GET_BANNER_SUCCESS,
  GET_SUBCATEGORY_SUCCESS,
  SET_ALL_PROD_CATEGORY,
  GET_HOME_CLEAR_SUCCESS,
  SET_IS_FETCHING_HOME,
} from "../actions/types";

const IdSeccion = {
  Ofertas: 3,
  Novedades: 11,
  MasVendidos: 4,
};

const initialState = {
  homes: null,
  categorias: [],
  banners: [],
  oferta: null,
  novedades: null,
  mas_vendidos: null,
  sucursal: JSON.parse(localStorage.getItem("sucursal")),
  loading: false,
  post_location: false,

  category: null,
  subcategoria_id: null,
  subcategorias: [],

  secciones: [],
  productosCategory: {},
  isFetchingHomes: true,
};

export default function Home(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_IS_FETCHING_HOME:
      return {
        ...state,
        isFetchingHomes: payload,
      };
    case SET_AUTH_LOADING:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_AUTH_LOADING:
      return {
        ...state,
        loading: false,
      };
    case GET_CATEGORY_SUCCESS:
      //
      return {
        ...state,
        categorias: obtenerCategorias(payload.res.Dato), //solo obtiene categoririas
        category: payload.cat,
        subcategoria_id: payload.subcateId,
        subcategorias: cargarSubcategoria(payload.res.Dato, payload.cat),
      };
    case GET_CATEGORY_FILTRO_SUCCESS:
      //
      return {
        ...state,
        categorias: obtenerCategorias_filtro(payload.res.Dato, payload.filtro), //solo obtiene categoririas
        category: payload.cat,
        subcategoria_id: payload.subcateId,
        subcategorias: cargarSubcategoria(payload.res.Dato, payload.cat),
      };
    case GET_SUBCATEGORY_SUCCESS:
      //
      return {
        ...state,
        categorias: obtenerCategorias(payload.res.Dato), //solo obtiene categoririas
        // category:payload.cat,
        // subcategoria_id:payload.subcateId,
        subcategorias: cargarSubcategoria(
          payload.res.Dato,
          payload.categoriaId
        ),
      };
    case GET_BANNER_SUCCESS:
      return {
        ...state,
        banners: payload.Dato,
      };
    case SET_ALL_PROD_CATEGORY:
      return {
        ...state,
        productosCategory: payload,
      };
    case GET_HOME_SUCCESS:
      return {
        ...state,
        homes: payload.Dato,
        oferta: find(IdSeccion.Ofertas, payload.Dato),
        novedades: find(IdSeccion.Novedades, payload.Dato),
        mas_vendidos: find(IdSeccion.MasVendidos, payload.Dato),
        secciones: selectedSecciones(payload.Dato),
        isFetchingHomes: false,
      };
    case GET_HOME_CLEAR_SUCCESS:
      return {
        ...state,
        oferta: null,
        novedades: null,
        mas_vendidos: null,
        secciones: [],
      };

    case GET_HOME_FAIL:
      return {
        ...state,
        isFetchingHomes: false,
      };
    case GET_HOME_FAIL:
      return {
        ...state,
        sucursal: localStorage.setItem("sucursal", JSON.stringify(payload)),
      };
    default:
      return state;
  }
}

function find(IdSeccion, Objeto) {
  let item = null;
  let secciones = Objeto.Secciones;
  secciones.forEach((seccion) => {
    if (seccion.IdSeccion === IdSeccion) {
      item = seccion;
    }
  });

  return item;
}

function selectedSecciones(data) {
  let datoSeccion = [];
  // seccion no visible en detalle
  let array_deselected = [
    { IdSeccion: 1, descripcion: "categorias" },
    { IdSeccion: 8, descripcion: "sucursales" },
    { IdSeccion: 7, descripcion: "listado de compras" },
  ];

  data.Secciones.forEach((item) => {
    let Idsecc = Number(item.IdSeccion);
    let resp = valorOmitido(Idsecc, array_deselected);
    if (!resp) {
      datoSeccion.push(item);
    }
  });
  return datoSeccion;
}

function valorOmitido(Id, array_deselected) {
  for (let index = 0; index < array_deselected.length; index++) {
    const element = array_deselected[index].IdSeccion;
    if (Number(element) === Number(Id)) {
      return true;
    }
  }
  return false;
}

function obtenerCategorias(data) {
  //
  let dato = [];
  if (typeof data !== "undefined" && data.length > 0) {
    data.forEach((rubro) => {
      rubro.Categorias.forEach((category) => {
        dato.push(category);
      });
    });
  }
  return dato;
}

function obtenerCategorias_filtro(data, textofilter) {
  //
  let dato = [];
  if (typeof data !== "undefined" && data.length > 0) {
    data.forEach((rubro) => {
      rubro.Categorias.forEach((category) => {
        dato.push(category);
      });
    });
  }
  // let newdato=[];
  let filtro = textofilter;
  //
  const objetosFiltrados = dato.filter((objeto) =>
    objeto.Descripcion.toLowerCase().includes(filtro.toLowerCase())
  );

  //

  return objetosFiltrados;
}

function cargarSubcategoria(data, cat) {
  let dato = [];
  if (typeof cat !== "undefined") {
    data.forEach((rubro) => {
      rubro.Categorias.forEach((category) => {
        category.SubCategorias.forEach((subcate) => {
          if (Number(subcate.IdCategoria) === Number(cat.IdCategoria)) {
            dato.push(subcate);
          }
        });
      });
    });
  }

  return dato;
}
