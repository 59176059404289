import { buildProductJSONLd } from "../../libs/richSnippetsUtils/buildProductJSONLd";

export function useProductJSONLd({ name, image, url, sku, offers }) {
  const productJSONLd = buildProductJSONLd(
    {
      name,
      image,
      url,
      sku,
      offers,
    },
    "https://schema.org"
  );
  const productJSONLdScript = (
    <script type="application/ld+json">{JSON.stringify(productJSONLd)}</script>
  );

  return { productJSONLdScript, productJSONLd };
}
