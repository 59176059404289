import moment from "moment";

export const huellaDigital = async (userCode) => {
  const huella = await uniqueTransactionCodeGenerator(userCode);
  var huella_id = `${process.env.REACT_APP_ID_SESSION}` + huella;
  return huella_id;
};

const generateRandomLetter = () => {
  const alphabet = "abcdefghijklmnopqrstuvwxyz";
  return alphabet[Math.floor(Math.random() * alphabet.length)];
};
const uniqueTransactionCodeGenerator = (userCode) => {
  try {
    let dateString = moment().utcOffset("-0400").format("DDMMYYYYHHmmssSSS");
    let randomLetter = generateRandomLetter();
    let idSession = userCode + dateString + randomLetter;
    return idSession;
  } catch (e) {}
};
