import { MensajeApi } from "../../http/domain/interfaces/mensaje-api";
import { createAxiosHttpService } from "../../http/infrastructure/axios-http-service";
import { UserProfile } from "../domain/interface/user-profile";
import { UserProfilesRepository } from "../domain/user-profiles-repository";

const http = createAxiosHttpService();
const apiVersion = process.env.REACT_APP_API_VERSION;
// const baseUrl = getBaseUrlBasedOnDomain(
// 	env.fidelizacionAtencionClienteBackendApiPath,
// 	env.fidelizacionAtencionClienteBackendApiDomainPath
// );
const baseUrl = process.env.REACT_APP_API_URL;
const backendPath = `${baseUrl}/api/${apiVersion}`;

export function createApiUserProfilesRepository(): UserProfilesRepository {
  async function getHashCuentaCorriente(): Promise<MensajeApi<UserProfile>> {
    const response = await http.post<MensajeApi<UserProfile>>(
      `${backendPath}/CuentasMarket/hash-cuenta-corriente`,
      null
    );

    return response;
  }

  return {
    getHashCuentaCorriente,
  };
}
