import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import axios from "axios";

const style = {
  height: 200,
  width: "10rem",
  border: "1px solid green",
  margin: 6,
  padding: 8,
  display: "flex",
  overflow: "auto",
};

export default function Productoscroll() {
  const [state, setState] = useState({
    items: [],
  });
  const [page, setPage] = useState(1);
  useEffect(() => {
    getPhotos();
  }, []);

  const getPhotos = () => {
    setPage(page + 1);
    setTimeout(() => {
      axios
        .get(
          `https://jsonplaceholder.typicode.com/photos?_page=${page}&_limit=20`
        )
        .then((res) => {
          setState({ items: [...state.items, ...res.data] });
        });
    }, 1500);
  };

  return (
    <>
      <div>
        <h1>demo: react-infinite-scroll-component</h1>
        <hr />
        <div className="flex flex-wrap">
          {state.items.length > 0 && (
            <InfiniteScroll
              dataLength={state.items.length}
              next={getPhotos}
              hasMore={true}
              loader={<h4>Loading...</h4>}
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              {state.items.map((i, index) => (
                <div style={style} key={index}>
                  div - #{i.id}
                  <img src={i.url} height={10} />
                </div>
              ))}
            </InfiniteScroll>
          )}
        </div>
      </div>
    </>
  );
}
