import ButtonText from "../../../widgets/button_text";

export const CarritoResumen = ({
  total_pagar = null,
  costo_delivery = null,
  ahorro_total,
  total_carrito,
  volverAtras,
  guardarProceso,
  backButtonLabel = "Seguir comprando",
  nextButtonLabel = "Continuar",
  nextButtonType = "button",
  disableGuardarProceso = false,
}) => {
  return (
    <div className="h-full">
      <div className="bg-white px-3 py-2 rounded-b-lg md:rounded-lg flex flex-col shadow-inner">
        <div className="pb-2">
          <div className="flex rounded-full bg-hiperorange text-white font-bold items-center py-1 px-2 text-sm md:text-base">
            <div className="m-0 w-6/12 text-left leading-tight">Ahorro </div>
            <div className="m-0 w-1/12 leading-tight"> : </div>
            <div className="m-0 w-5/12 text-right leading-tight">
              {" "}
              Bs. {ahorro_total}{" "}
            </div>
          </div>
          <div className="flex justify-between rounded-full font-bold items-center py-1 px-2 text-sm md:text-base">
            <div className="m-0 w-6/12 text-left leading-tight">
              Total parcial{" "}
            </div>
            <div className="m-0  w-1/12 leading-tight"> : </div>
            <div className="m-0 w-5/12 text-right leading-tight">
              {" "}
              Bs. {total_carrito}{" "}
            </div>
          </div>
          {costo_delivery !== null && (
            <div className="flex justify-between rounded-full font-bold items-center py-1 px-2 text-sm md:text-base">
              <div className="m-0 w-6/12 text-left leading-tight">
                Total envío{" "}
              </div>
              <div className="m-0  w-1/12 leading-tight"> : </div>
              <div className="m-0 w-5/12 text-right leading-tight">
                {" "}
                {costo_delivery?.toUpperCase() === "GRATIS" ? "Gratis" : `Bs. ${costo_delivery}`}
              </div>
            </div>
          )}
          {total_pagar !== null && (
            <div className="flex justify-between font-bold items-center py-1 px-2 border-t-[1px] border-black text-sm md:text-base">
              <div className="m-0 w-6/12 text-left leading-tight">
                Total a pagar{" "}
              </div>
              <div className="m-0  w-1/12 leading-tight"> : </div>
              <div className="m-0 w-5/12 text-right leading-tight">
                {" "}
                Bs. {total_pagar}{" "}
              </div>
            </div>
          )}
        </div>
        <div className="flex sm:flex-col justify-center  text-center gap-2 text-white">
          <ButtonText
            type="button"
            label={backButtonLabel}
            principal={false}
            className="bg-hiperorange_claro hover:bg-hiperorange_claro_hover text-black hover:text-black text-sm md:text-base"
            onClick={() => volverAtras()}
            style={{ border: "1px solid black" }}
          />
          <ButtonText
            disabled={disableGuardarProceso}
            type={nextButtonType}
            label={nextButtonLabel}
            principal={true}
            className="bg-hiperorange hover:bg-hiperorange_hover text-sm md:text-base disabled:cursor-not-allowed"
            onClick={() => guardarProceso()}
          />
        </div>
      </div>
      {/* <div onClick={volverAtras} className="w-full h-[70vh]"></div> */}
    </div>
  );
};
