import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import {
  confirmar_carrito,
  confirmar_pago,
  delete_list_carrito,
  get_lista_carrito_compras,
  save_pedido,
  set_cambio_carrito,
  store_carrito,
  store_carrito_data,
  update_carrito,
  update_list_carrito_compras,
  save_horarios,
  store_carrito_data_anonimo,
} from "../../../redux/actions/carrito";
import { save_formas_pago } from "../../../redux/actions/metodoPagos";
import { Disclosure, Transition } from "@headlessui/react";
import Modal from "react-modal";
import ItemConfirmacionPedido from "../../../widgets/item_confirmacion_pedido";
import { reset_datos_pago } from "../../../redux/actions/metodoPagos";
import "../../../assets/styles/globalColorPrincipal.css";
import "../../../assets/styles/responsive.scss";
import { huellaDigital } from "./../../../huellaDigital";
import Registro_user from "./Partials/registro_user";
import Registro_activate from "./Partials/registro_activate";
import Termino_condicion from "./Partials/termino_condicion";
import { clear_pedidos } from "../../../redux/actions/pedido";
import { end_peticion } from "../../../redux/actions/peticion";
import classNames from "classnames";
import RegistroVale from "./Partials/RegistroVale";
import ButtonClose from "../../../widgets/button_close";
import { CarritoResumen } from "./carrito_resumen";
import { useThousandSeparator } from "../../../hooks/useThousandSeparator";

const customStyles2 = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "35",
  },
  content: {
    padding: "0px",
    border: "0px",
    background: "transparent",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxHeight: "calc(100% - 64px)",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
};

const style_vale = {
  overlay: {
    backgroundColor: "#000000a6",
    zIndex: "31",
  },
  content: {
    top: "40%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: "0",
    borderRadius: "10px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "auto",
    border: "0px",
    background: "transparent",
  },
};

const ComentarioVale = ({
  end_peticion,
  clear_pedidos,
  profile,
  confirmar_pago,
  confirmar_carrito,
  reset_datos_pago,
  loading,
  save_pedido,
  respuesta_save_pedido,
  respuesta_confirmar_carrito,
  list_carrito,
  direccion,
  finalizado,
  mt_indice,
  exit,
  store_carrito_data,
  save_formas_pago,
  save_horarios,
  get_lista_carrito_compras,
  update_list_carrito_compras,

  remove_al_carrito_sucursal,
  branch_offices,
  respuesta_carrito_anonimo,
  store_carrito_data_anonimo,
  width,
  height,
  exitAnonimo,
}) => {
  const { numberFormatter } = useThousandSeparator();

  const [subPedidos, guardarsubPedidos] = useState([]);
  const [listaVales, setListaVales] = useState([]);
  const [listaComentarios, setListaComentarios] = useState([]);
  const [es_anonimo, setEsAnonimo] = useState(false);
  const [pedidoSeleccionado, SetPedidoSeleccionado] = useState(null);
  const [opc_numero, setOpcion_numero] = useState(true);
  const [posicion, setPosicion] = useState(1);

  const [modalRegistro, setModalRegistro] = useState(false);
  const [modalVale, setModalVale] = useState(false);

  const costoEnvio = subPedidos?.reduce((total, item) => {
    const costo = item.CostoEnvio.replace("Bs. ", "");
    return total + (costo ? Number(costo) : 0);
  }, 0);

  useEffect(() => {
    if (profile) {
      if (!profile.Telefono) {
        setEsAnonimo(true);
        return;
      } else {
        setEsAnonimo(false);
      }
    }
  }, [profile]);

  useEffect(() => {
    if (list_carrito) {
      let subPedido = list_carrito.SubPedidos.filter((pedido) => pedido.check);
      if (subPedido.length > 0) {
        guardarsubPedidos(subPedido);
      }
    }
  }, [list_carrito]);

  useEffect(() => {
    if (respuesta_confirmar_carrito) {
      if (!respuesta_confirmar_carrito.ConError) {
        finalizado(); //cambia estado para mostrar carro o mensaje finalizado
      }
    }
  }, [respuesta_confirmar_carrito]);

  useEffect(() => {
    if (respuesta_carrito_anonimo) {
      exitAnonimo();
    }
  }, [respuesta_carrito_anonimo]);
  useEffect(() => {
    async function fetchData() {
      try {
      } catch (e) {
        console.error(e);
      }
    }
    fetchData();
    reset_datos_pago();
  }, []);

  const formatNumber = (value) => {
    return numberFormatter.format(value);
  };

  const startModalVale = (pedido) => {
    SetPedidoSeleccionado(pedido);
    setModalVale(true);
  };

  const get_total_carrito = () => {
    let total = 0;
    let precio_no_disponible = 0;
    subPedidos.forEach((sp) => {
      if (sp.check) {
        sp.Productos.forEach((prod) => {
          if (!prod.Disponible) {
            precio_no_disponible += prod.Importe;
          }
        });
      }
    });
    subPedidos.forEach((sp) => {
      if (sp.check) {
        total += sp.ImporteSubtotal;
      }
    });
    let result = parseFloat(total) - parseFloat(precio_no_disponible);
    return result;
  };

  const totalCarrito = get_total_carrito();

  const _ahorroTotal = () => {
    let total = 0;
    subPedidos.forEach((sp) => {
      if (sp.check) {
        total += Number(sp.ahorro);
      }
    });
    return total;
  };
  const ahorro_total = _ahorroTotal();

  const volverAtras = () => {
    mt_indice(4);
  };

  const get_datos_pago = async () => {
    return await JSON.parse(localStorage.getItem("metodo_pago"));
  };

  const get_productos = async (sucursal_id) => {
    let array = [];
    let pesos = [];
    subPedidos.forEach((pedido) => {
      if (pedido.Sala.Id == sucursal_id) {
        pedido.Productos.forEach((pro) => {
          if (pro.PesoDefinido) {
            if (pro.Pesos != null) {
              pesos = pro.Pesos.map((e) => {
                return {
                  IdPeso: e.IdPeso,
                  Piezas: e.Piezas,
                };
              });
            }
          }
          array.push({
            IdProducto: pro.IdProducto,
            Cantidad: pro.Cantidad,
            Pesos: pesos,
          });
        });
      }
    });

    return array;
  };

  const setComentario = (idsucursal, texto) => {
    let aux = listaComentarios.slice();

    let index = aux.map((item) => item.idsucursal).indexOf(idsucursal);

    if (index === -1) {
      aux.push({ idsucursal: idsucursal, comentario: texto });
    } else {
      aux[index] = { idsucursal: idsucursal, comentario: texto };
    }
    setListaComentarios(aux);
  };

  const save_pedidos = async (e) => {
    if (profile.Telefono === "") {
      setModalRegistro(true);
      return;
    } else if (!profile.TerminoCondicion.AceptoTerminoActual) {
      selected_opcion(true);
      setModalRegistro(true);
      cambiar_posicion(3);
      return;
    }

    let SubpedidosConfirmado = [];

    for (let index = 0; index < subPedidos.length; index++) {
      let item = subPedidos[index];

      var tipoentrega = item.IdTipoEntrega;
      var ubicacion = direccion;
      var horario = item.Horario;
      var datospago = await get_datos_pago();
      var productos = await get_productos(item.Sala.Id);
      if (horario == -1) {
        return;
      }
      if (ubicacion == null) {
        return;
      }
      if (tipoentrega == -1) {
        return;
      }
      if (datospago == null) {
        return;
      }

      var datos = {
        IdSucursal: Number(item.Sala.Id),
        IdTipoEntrega: Number(tipoentrega),
        IdUbicacion: parseInt(ubicacion.Id),
        Horario: {
          IdFecha: horario["IdFecha"],
          IdHorario: horario["IdHorario"],
          IdHorarioEspecial: horario["IdHorarioEspecial"],
        },
        DatosPago: {
          IdMetodoPago: Number(datospago["IdMetodoPago"]),
          Nit: Number(datospago["Nit"]),
          RazonSocial: datospago["RazonSocial"],
        },
        Productos: productos,
      };

      await confirmar_pago(datos);

      var ele = listaVales.find((ele) => ele.idsucursal === item.Sala.Id);
      var aux_list = [];
      if (typeof ele != "undefined") {
        aux_list = get_new_formato_lista_vales(ele.vale);
      }

      SubpedidosConfirmado.push({
        IdSubPedido: Number(item.IdPedido),
        NotaPedido: getComentario(item.Sala.Id),
        ListaVale: aux_list,
      });
    }

    let huella = await huellaDigital(profile.IdUsuario);

    let datos_pedido = {
      SubpedidosConfirmado: SubpedidosConfirmado,
      SubPedidos: [],
      IdSession: huella,
      CanalVenta: process.env.REACT_APP_CANAL_VENTA_ECOMMERCE_WEB,
    };

    await confirmar_carrito(datos_pedido);
    clear_pedidos();
  };

  const save_pedidos_anonimo = async (e) => {
    if (profile.Telefono === "") {
      setModalRegistro(true);
      return;
    } else if (!profile.TerminoCondicion.AceptoTerminoActual) {
      selected_opcion(true);
      setModalRegistro(true);
      cambiar_posicion(3);
      return;
    }
    await actualizar_lista_carrito_db();
    await cargar_carrito_user();
  };

  const cargar_carrito_user = async () => {
    const token = localStorage.getItem("access_token");
    await get_lista_carrito_compras(token);
  };

  const actualizar_lista_carrito_db = async () => {
    for (let index = 0; index < subPedidos.length; index++) {
      let item = subPedidos[index];
      let params = {
        IdMarket: item.IdMarket,
        IdSucursal: item.Sala.Id,

        IdTipoEntrega: item.IdTipoEntrega,
        IdDireccion: direccion.Id,
      };
      let carritos_aux = item.Productos;

      await store_carrito_data_anonimo(params, carritos_aux, []);
    }
  };

  const endModalRegistro = () => {
    setModalRegistro(false);
  };
  const endModalVale = () => {
    setModalVale(false);
  };

  const get_new_formato_lista_vales = (vales) => {
    var aux = [];
    for (var item of vales) {
      aux.push({ Codigo: "", Monto: item });
    }
    return aux;
  };

  const getComentario = (sucursal_id) => {
    let comentario = "";
    let aux = listaComentarios.find((ele) => ele.idsucursal === sucursal_id);
    if (typeof aux != "undefined") {
      return aux.comentario;
    }
    return comentario;
  };

  const updateListaVales = (array, idSucursal) => {
    let object = {
      idsucursal: idSucursal,
      vale: array,
    };
    let check = listaVales.find((list) => list.idsucursal === idSucursal);
    if (check) {
      setListaVales(
        listaVales.map((lista) => {
          if (lista.idsucursal === idSucursal) {
            return {
              ...lista,
              vale: array,
            };
          } else {
            return lista;
          }
        })
      );
    } else {
      setListaVales([...listaVales, object]);
    }
  };

  const _countVales = (idSucursal) => {
    let check = listaVales.find((list) => list.idsucursal === idSucursal);
    if (check) {
      return check.vale.length;
    }
    return 0;
  };

  const selected_opcion = async (value) => {
    setOpcion_numero(value);
  };

  const cambiar_posicion = async (numero) => {
    setPosicion(numero);
  };

  const check_no_anonimo = async () => {
    setEsAnonimo(false);
  };

  const formatearCostoEnvio = (mask) => {
    let res = mask.replace(/\D/g, "");
    return parseFloat(res);
  };

  const totalAPagar = totalCarrito + costoEnvio;

  return (
    <>
      <div className={classNames("w-full md:w-[60%] flex flex-col h-full")}>
        <div className="w-full bg-white rounded-t-lg md:rounded-lg h-full overflow-hidden">
          <div className="flex flex-col h-full">
            <div className="bg-hiperorange w-full text-white flex items-center justify-center  content_title relative">
              <h2 className="p-0 m-0 title_carrito_responsivo">
                Confirmación de pedidos
              </h2>
              <div className="absolute right-2 ">
                <ButtonClose
                  className="text-white bg-transparent"
                  onClick={() => exit()}
                />
              </div>
            </div>
            <div
              className={classNames(
                "bg-white overflow-y-auto flex flex-col h-full"
              )}
            >
              {subPedidos.map((pedido, key) => {
                const delivery = formatearCostoEnvio(pedido.CostoEnvio);
                return (
                  <div
                    className="sm:w-100 border border-hiperorange shadow px-0 last-of-type:mb-44 md:last-of-type:mb-0"
                    key={key}
                  >
                    <Disclosure defaultOpen as="div" className="my-2 px-2">
                      {({ open }) => (
                        <>
                          {/*header*/}
                          <div className="flex flex-col px-4">
                            <h6 className=" font-bold text-black uppercase leading-snug md:text-lg md:leading-normal mb-0">
                              {`${pedido.TipoServicio.Descripcion} - ${pedido.Sala.Descripcion}`}
                            </h6>
                            <p className="m-0">
                              {" "}
                              {pedido.Productos.length}
                              {" productos"}
                            </p>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Disclosure.Panel className="flex justify-between  mt-1 flex-column px-2">
                              <div className="overflow-y-auto  container_scroll">
                                {pedido.Productos.map((producto, key) => (
                                  <ItemConfirmacionPedido
                                    colour={key % 2 === 0}
                                    producto={producto}
                                    idProducto={producto.IdProducto}
                                    key={producto.IdProducto}
                                    descripcion={producto.Descripcion}
                                    cantidad={producto.Cantidad}
                                    precioVenta={producto.PrecioVenta}
                                    moneda={producto.Moneda}
                                    importe={producto.Importe}
                                    imagen={producto.UrlFoto}
                                    IdMarket={pedido.IdMarket}
                                    unidadMedida={producto.UnidadMedida}
                                  ></ItemConfirmacionPedido>
                                ))}
                              </div>
                            </Disclosure.Panel>
                          </Transition>
                          {/* <div
                          className=" w-full mt-2 px-2 bg-hiperplomo_claro overflow-hidden py-0 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-75"
                          style={{
                            borderTopLeftRadius: "8px",
                            borderTopRightRadius: "8px",
                            borderBottom: "1px solid #9CA3AF",
                          }}
                        >
                          <div
                            className={classNames(
                              "w-full text-sm flex flex-wrap justify-between items-center md:px-2 gap-2 pb-2"
                            )}
                            style={{
                              boxShadow:
                                "0px 10px 10px -10px rgba(33, 35, 38, 0.1)",
                              color: pedido.check_activo
                                ? "#ffffff"
                                : "#494A4E",
                            }}
                          > */}
                          <div className="flex flex-col md:flex-row justify-between text-black px-2 font-bold mt-2 gap-2">
                            <button
                              className="flex items-center cursor-pointer bg-hiperorange hover:bg-hiperorange_hover group p-1 ml-4  text-white text-start self-end md:self-center"
                              style={{ borderRadius: "8px" }}
                              onClick={() => startModalVale(pedido)}
                            >
                              <p className="mx-2 mb-0 text-sm md:text-base inline">
                                Vales agregados
                              </p>
                              <span className="m-0 flex justify-center items-center w-5 h-5 bg-white rounded-full text-hiperorange group-hover:text-hiperorange_hover leading-none">
                                {_countVales(pedido.Sala.Id)}
                              </span>
                            </button>

                            <div className="grid grid-cols-12 md:w-1/2">
                              <div className="col-span-6 flex flex-col items-end text-right">
                                {pedido.ahorro > 0 && (
                                  <p className="rounded-bl-full rounded-tl-full pl-3 bg-hiperorange text-white py-1 mb-1">
                                    {" "}
                                    Total ahorro :
                                  </p>
                                )}
                                <p className="mb-1"> Total parcial :</p>
                                <p className="mb-1"> Costo envío : </p>
                              </div>
                              <div className="col-span-6  flex flex-col items-end text-right ">
                                {pedido.ahorro > 0 && (
                                  <p className="rounded-br-full w-full rounded-tr-full  bg-hiperorange pl-1 pr-2 text-white py-1 mb-1">
                                    Bs. {formatNumber(pedido.ahorro)}
                                  </p>
                                )}
                                <p className="pr-2 pl-1 mb-1">
                                  Bs. {formatNumber(pedido.ImporteSubtotal)}{" "}
                                </p>
                                <p className="pr-2 pl-1 mb-1">
                                  {delivery > 0
                                    ? `Bs. ${formatNumber(delivery)}`
                                    : " Gratis"}{" "}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="w-100  self-end px-3 mb-2">
                            <label
                              htmlFor="comentario"
                              className="block text-black fontzize_label mb-2"
                            >
                              Agrega un comentario o nota adicional (opcional)
                            </label>
                            <textarea
                              name="comentario"
                              id="comentario"
                              rows="4"
                              className="block w-full rounded-md border-1 border-gray-400 py-2 p-3 pr-4 2xl focus:outline-none focus:ring focus:ring-hiperorange_claro focus:border-hiperorange placeholder:text-gray-500 text-black"
                              placeholder="Comentario sobre tu pedido (Opcional)"
                              onChange={(e) =>
                                setComentario(pedido.Sala.Id, e.target.value)
                              }
                            ></textarea>
                          </div>

                          <div
                            className="w-100 bg-hiperorange flex justify-center overflow-hidden"
                            style={{
                              borderBottomLeftRadius: "10px",
                              borderBottomRightRadius: "10px",
                              height: "30px",
                            }}
                          >
                            <Disclosure.Button
                              className="bg-hiperorange hover:bg-hiperorange_hover"
                              style={{ width: "100%" }}
                            >
                              <FontAwesomeIcon
                                className={`${
                                  !open ? "rotate-180 transform" : ""
                                } h-5 w-5 text-white`}
                                style={{ width: "28px", height: "28px" }}
                                icon={faChevronUp}
                              />
                            </Disclosure.Button>
                          </div>
                        </>
                      )}
                    </Disclosure>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="md:w-[22%] sticky bottom-0">
        <CarritoResumen
          costo_delivery={
            subPedidos?.length === 0
              ? "Calculando"
              : costoEnvio > 0
              ? formatNumber(costoEnvio)
              : "Gratis"
          }
          ahorro_total={formatNumber(ahorro_total)}
          total_carrito={formatNumber(totalCarrito)}
          total_pagar={formatNumber(totalAPagar)}
          volverAtras={volverAtras}
          guardarProceso={save_pedidos}
          backButtonLabel="Volver"
          nextButtonLabel="Confirmar pedido"
        />
      </div>
      <Modal
        isOpen={modalRegistro}
        ariaHideApp={false}
        onRequestClose={endModalRegistro}
        style={customStyles2}
        contentLabel="Ingresar"
        id="theIdRegister"
      >
        {posicion == 1 && (
          <Registro_user ps_posicion={cambiar_posicion}></Registro_user>
        )}
        {posicion == 2 && (
          <Registro_activate
            ps_posicion={cambiar_posicion}
            ps_save_pedidos={save_pedidos_anonimo}
            ps_endModalRegistro={endModalRegistro}
            ps_opcion_numero={selected_opcion}
            ps_check_no_anonimo={check_no_anonimo}
          ></Registro_activate>
        )}
        {posicion == 3 && (
          <Termino_condicion
            ps_posicion={cambiar_posicion}
            ps_save_pedidos={save_pedidos_anonimo}
            ps_endModalRegistro={endModalRegistro}
            ps_opcion_numero={selected_opcion}
            ps_check_no_anonimo={check_no_anonimo}
          ></Termino_condicion>
        )}
      </Modal>

      {pedidoSeleccionado && (
        <Modal
          isOpen={modalVale}
          ariaHideApp={false}
          onRequestClose={endModalVale}
          className="fixed inset-0 flex items-center justify-center p-2 md:p-4 w-full md:max-w-lg md:mx-auto"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-30"
          contentLabel="VALE"
        >
          <RegistroVale
            idsucursal={pedidoSeleccionado.Sala.Id}
            listaVales={listaVales}
            updateVales={updateListaVales}
            endModalVale={endModalVale}
          ></RegistroVale>
        </Modal>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  branch_offices: state.BranchOffice.branch_offices,
  loading: state.Location.loading,
  list_carrito: state.Carrito.list_carrito,
  carrito_favorito: state.Carrito.carrito_favorito,
  sucursal: state.BranchOffice.sucursal,
  tipo_entrega: state.BranchOffice.tipo_entrega,
  direccion: state.Location.direccion,
  carritos: state.Carrito.carrito,
  producto_delete: state.Carrito.producto_delete,
  respuesta_confirmar_carrito: state.Carrito.respuesta_confirmar_carrito,
  profile: state.Profile.profile,
  respuesta_carrito_anonimo: state.Carrito.respuesta_carrito_anonimo,
});

export default connect(mapStateToProps, {
  get_lista_carrito_compras,
  update_carrito,
  store_carrito,
  store_carrito_data,
  delete_list_carrito,
  update_list_carrito_compras,
  set_cambio_carrito,
  reset_datos_pago,
  confirmar_pago,
  confirmar_carrito,
  save_pedido,
  save_formas_pago,
  save_horarios,
  store_carrito_data_anonimo,
  clear_pedidos,
  end_peticion,
})(ComentarioVale);
