import classNames from "classnames";

interface Props {
  initials: string;
  size: "xl" | "lg" | "md" | "sm" | "xs";
}

export function Avatar({ initials, size }: Props) {
  return (
    <div
      className={classNames(
        "rounded-full bg-white text-hiperorange flex items-center justify-center",
        {
          "w-6 h-6 text-xs": size === "xs",
          "w-8 h-8 text-sm": size === "sm",
          "w-10 h-10 text-base": size === "md",
          "w-12 h-12 text-lg": size === "lg",
          "w-14 h-14 text-xl": size === "xl",
        }
      )}
    >
      {initials}
    </div>
  );
}
