import { useState } from "react";
import { getConfigParametersByKey } from "../../../config-parameters/application/get/get-config-parameters-by-key";
import { ConfigParametersRepository } from "../../../config-parameters/domain/config-parameters-repository";
import { ConfigParametesGetByKeyParameters } from "../../../config-parameters/domain/dto/config-parameters-get-by-key-parametes";
import { ConfigParameter } from "../../../config-parameters/domain/interface/config-parameter";
import { NotificationService } from "../../../notifications/domain/notification-service";
import { FormStatus } from "../../../shared/domain/enums/form-status";
import { useHandleErrors } from "../../../shared/ui/hooks/use-handle-errors";

export const useGetMensajeCodigoClienteParameter = (
  configParametersRepository: ConfigParametersRepository,
  notificationService: NotificationService
): {
  formStatus: FormStatus;
  getMensajeCodigoClienteParameter: () => void;
  resetFormStatus: () => void;
  mensajeCodigoClienteParameter: ConfigParameter | undefined;
  resetConfigParameters: () => void;
  isLoading: boolean;
} => {
  const [formStatus, setFormStatus] = useState(FormStatus.INITIAL);
  const [mensajeCodigoClienteParameter, setMensajeCodigoClienteParameter] =
    useState<ConfigParameter | undefined>(undefined);

  const { handleError } = useHandleErrors(notificationService);

  async function getMensajeCodigoClienteParameter() {
    setFormStatus(FormStatus.LOADING);
    const params: ConfigParametesGetByKeyParameters = {
      keyconfig: "MensajeCodigoCliente",
    };
    try {
      const data: ConfigParameter = await getConfigParametersByKey(
        configParametersRepository
      )(params);
      setMensajeCodigoClienteParameter(data);
      setFormStatus(FormStatus.SUCCESS);
      //   notifySuccess(notificationService)("Clientes obtenidos correctamente.");
    } catch (error: unknown) {
      const message = "";
      handleError({ error, message });
      setFormStatus(FormStatus.ERROR);
    }
  }

  const resetFormStatus = () => setFormStatus(FormStatus.INITIAL);

  const resetConfigParameters = () =>
    setMensajeCodigoClienteParameter(undefined);

  return {
    formStatus,
    getMensajeCodigoClienteParameter,
    resetFormStatus,
    mensajeCodigoClienteParameter,
    resetConfigParameters,
    isLoading: formStatus === FormStatus.LOADING,
  };
};
