import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faMinus,
  faPlus,
  faSquareMinus,
  faSquarePlus,
} from "@fortawesome/pro-solid-svg-icons";

import Modal from "react-modal";
import ButtonClose from "../../widgets/button_close";

const ModalPeso = ({
  ps_objectProducto,
  ps_modal_peso,
  ps_closeModalPeso,
  ps_operacion_peso,
  ps_cantidad,
  ps_set_peso_pieza,
}) => {
  const pesajes = [
    { name: "500g", peso: "500" },
    { name: "1Kg", peso: "1000" },
    { name: "2Kg", peso: "2000" },
    { name: "3Kg", peso: "3000" },
    { name: "5Kg", peso: "5000" },
    { name: "10Kg", peso: "10000" },
    { name: "15Kg", peso: "15000" },
    { name: "20Kg", peso: "20000" },
    { name: "25Kg", peso: "25000" },
    { name: "30Kg", peso: "30000" },
    { name: "35Kg", peso: "35000" },
    { name: "40Kg", peso: "40000" },
    { name: "45Kg", peso: "45000" },
    { name: "50Kg", peso: "50000" },
  ];
  const customStyles = {
    overlay: {
      backgroundColor: "#000000a6",
      zIndex: "1",
      minHeight: "45rem",
    },
    content: {
      top: "40%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      borderRadius: "0.75rem",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      overflow: "visible",
    },
  };

  function convertKgToGramos(datoKg) {
    return (datoKg * 1000).toFixed(0);
  }

  return (
    <>
      <Modal
        isOpen={ps_modal_peso}
        ariaHideApp={false}
        onRequestClose={ps_closeModalPeso}
        className="fixed inset-0 flex items-center justify-center p-2 md:p-4 w-full md:max-w-md  md:mx-auto focus-visible:outline-none"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-30"
        contentLabel="Seleccionar peso"
      >
        <div className="w-full flex flex-col h-full md:max-h-[600px]">
          <div className="w-full bg-white rounded-lg md:rounded-lg h-full overflow-hidden">
            <div className="flex flex-col h-full">
              <div className="bg-hiperorange w-100 relative text-white flex items-center justify-center h-[52px]  content_title">
                <h2 className="p-0 m-0 title_carrito_responsivo">
                  Seleccione el Peso
                </h2>
                <div className="absolute right-2 ">
                  <ButtonClose
                    className="text-white bg-transparent"
                    onClick={() => ps_closeModalPeso()}
                  />
                </div>
              </div>
              <div className="bg-white overflow-y-auto grid grid-cols-2 w-full h-full gap-2 p-4">
                {pesajes.map((pesaje, index) => (
                  <button
                    key={`peso-${index}`}
                    className="w-full bg-[#EEEEEE] p-2 rounded hover:bg-gray-300 font-semibold text-xl flex items-center justify-center"
                    onClick={() => ps_set_peso_pieza(pesaje)}
                  >
                    {pesaje.name}
                  </button>
                ))}
              </div>

              <div className="border-t-2 border-[#EEEEEE] p-2 flex justify-center sticky bottom-0">
                <div className="flex border-[3px] border-hiperorange p-1 rounded">
                  <button
                    disabled={ps_cantidad <= 0}
                    className="w-8 h-8 bg-hiperorange hover:bg-hiperorange_hover text-white rounded flex items-center justify-center disabled:cursor-not-allowed"
                    onClick={() => ps_operacion_peso("decremento")}
                  >
                    <FontAwesomeIcon icon={faMinus} />
                  </button>
                  <div className="mx-2 flex items-center justify-center min-w-10">
                    {/* <span>{cantidad_pieza}</span> */}
                    <span>{convertKgToGramos(ps_cantidad)}g</span>
                  </div>
                  <button
                    className="w-8 h-8 bg-hiperorange hover:bg-hiperorange_hover text-white rounded flex items-center justify-center"
                    onClick={() => ps_operacion_peso("incremento")}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(ModalPeso);
